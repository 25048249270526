import { Box, Button, Drawer } from "@mui/material";
import {
  DEFAULT_BASKET,
  DEFAULT_FIRM_INFO,
  DEFAULT_FORM_FIELDS,
  DEFAULT_SESSION_INFO,
  DEFAULT_SNACKBAR_ALERT,
  NAVIGATION_BAR,
  SNACKBAR_ALERT,
} from "../../redux/actions/actionTypes";
import { ROUTE_URLS, SIGNOUT_MESSAGE } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "../dashboard/MenuList";
import React from "react";
import { log_out } from "../../api/apiCalls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export default function AccountMenu({ sx }) {
  const anchorElUser = useSelector((state) => state?.navBarMenu?.anchorElUser);
  const isUserMenuOpen = useSelector(
    (state) => state?.navBarMenu?.isUserMenuOpen
  );

  const sessionInfo = useSelector((state) => state?.sessionInfo);
  const slideAccountMenuVisible = useSelector(
    (state) => state?.navBarMenu?.slideAccountMenuVisible
  );
  const fullName = sessionInfo?.firstName + " " + sessionInfo?.lastName;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: DEFAULT_SNACKBAR_ALERT,
    });
    // eslint-disable-next-line
  }, []);

  const token = sessionInfo?.token;
  const { refetch } = useQuery(["logOut", token], () => log_out(token), {
    enabled: false,
  });

  const handleClose = () => {
    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        isUserMenuOpen: false,
        anchorElUser: null,
        slideAccountMenuVisible: false,
      },
    });
  };

  const handleClick = () => {
    handleClose();

    navigate(ROUTE_URLS.DASHBOARD);
  };

  const handleLogOut = () => {
    refetch();

    dispatch({
      type: DEFAULT_FIRM_INFO,
    });
    dispatch({
      type: DEFAULT_SESSION_INFO,
    });
    dispatch({
      type: DEFAULT_FORM_FIELDS,
    });
    dispatch({
      type: DEFAULT_BASKET,
    });
    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        isUserMenuOpen: false,
        anchorElUser: null,
        slideAccountMenuVisible: false,
      },
    });
    dispatch({
      type: SNACKBAR_ALERT,
      payload: {
        isAlert: true,
        data: "Successfull logout",
        messageObj: SIGNOUT_MESSAGE,
        type: "success",
      },
    });

    navigate(ROUTE_URLS.SIGN_IN);
  };

  return (
    <>
      <Menu
        anchorEl={document.getElementById(anchorElUser)}
        id="account-menu"
        open={isUserMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          display: { xs: "none", md: "flex" },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClick}>
          <Avatar /> {fullName}
        </MenuItem>

        {/* TODO */}
        {/* <Divider /> */}
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}

        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Odhlásiť
        </MenuItem>
      </Menu>

      <Drawer
        anchor={"right"}
        open={slideAccountMenuVisible}
        onClose={handleClose}
        sx={{
          display: { xs: "flex", md: "none" },
          zIndex: 1310,
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: 250,
            }}
            onClick={handleClose}
          >
            <MenuList />
          </Box>
          <Button
            variant="outlined"
            startIcon={<Logout fontSize="small" />}
            onClick={handleLogOut}
            sx={{ my: 5, mx: 7.5 }}
          >
            Odhlásiť
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
