import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function slideMenu(state = initialState.slideMenu, action) {
  switch (action.type) {
    case types.SLIDE_MENU:
      return { ...state, ...action.payload };
    case types.DEFAULT_SLIDE_MENU:
      return initialState.slideMenu;
    default:
      return state;
  }
}
