import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { DEV_URL, ROUTE_URLS } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";

import { COOKIEDIALOG } from "../../redux/actions/actionTypes";

export default function CookieDialog() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state?.cookieDialog?.isDisplayed);

  const handleCloseDialog = () => {
    dispatch({
      type: COOKIEDIALOG,
      payload: { isDisplayed: false },
    });
    localStorage.setItem("acceptedCookies", true);
  };
  const handleCloseDialogWithoutAccept = () => {
    dispatch({
      type: COOKIEDIALOG,
      payload: { isDisplayed: false },
    });
  };

  return (
    <Dialog
      PaperComponent={(props) => {
        return <Paper style={{ position: "absolute", bottom: 0 }} {...props} />;
      }}
      open={isOpen}
      onClose={handleCloseDialogWithoutAccept}
      fullWidth
      maxWidth="lg"
      hideBackdrop={true}
      disableScrollLock
    >
      <DialogTitle id="dialog-title" variant="h7" component="h3">
        Cookies
      </DialogTitle>
      <DialogContent>
        {
          "Na našej webovej stránke používame cookies na zlepšenie vášho používateľského zážitku a analýzu návštevnosti. Kliknutím na „Súhlasím“ vyjadrujete svoj súhlas s našou politikou cookies. Viac informácií nájdete v našich "
        }

        <a
          href={DEV_URL?.PROD_URL + ROUTE_URLS?.COOKIE_POLICY}
          className="link"
        >
          {"Zásadách používania cookies"}
        </a>
        {"."}
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={() => handleCloseDialog()}>
          Súhlasím
        </Button>
      </DialogActions>
    </Dialog>
  );
}
