import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountMenu from "../account/AccountMenu";
import { NAVIGATION_BAR } from "../../redux/actions/actionTypes";

export default function AccountButton({ sx }) {
  const sessionInfo = useSelector((state) => state?.sessionInfo);
  const isUserMenuOpen = useSelector(
    (state) => state?.navBarMenu?.isUserMenuOpen
  );
  const fullName = sessionInfo?.firstName + " " + sessionInfo?.lastName;
  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.preventDefault();
    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        isUserMenuOpen: true,
        anchorElUser: event?.currentTarget?.id,
        slideAccountMenuVisible: true,
      },
    });
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <>
      {!sessionInfo.isSignIn ? (
        <>
          <Tooltip title="Prihlásiť">
            <IconButton size="large" href="/signin" sx={sx}>
              <AccountCircleIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="Nastavenia">
            <IconButton
              id="accountButton"
              onClick={handleClick}
              size="small"
              sx={{ ml: 1 }}
              aria-controls={isUserMenuOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isUserMenuOpen ? "true" : undefined}
            >
              <Avatar
                alt={fullName}
                {...stringAvatar(fullName)}
                sx={{ width: 40, height: 40, bgcolor: "#0097a1" }}
              />
            </IconButton>
          </Tooltip>
          <AccountMenu />
        </>
      )}
    </>
  );
}
