import { IconButton, Slide, Snackbar, Tooltip } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { ERROR_MESSAGE } from "../../config/constants";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { SNACKBAR_ALERT } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function SnackBarAlert(error) {
  const dispatch = useDispatch();
  const isAlert = useSelector((state) => state?.snackBarAlert?.isAlert);
  const snackBarAlert = useSelector((state) => state?.snackBarAlert);
  const messageObj = error?.messageObj;

  const snackbarType = error?.type;
  const snackbarData = error?.data;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: SNACKBAR_ALERT,
      payload: { isAlert: false },
    });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  const action = (
    <>
      {snackBarAlert?.action === "close" && (
        <Tooltip title="Zavrieť">
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseAlert}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {snackBarAlert?.action === "reload" && (
        <>
          <Tooltip title="Zavrieť">
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );

  const message = messageObj.filter(
    (message) => message.MESSAGE === snackbarData
  );

  const DisplayAlertMessage = () => {
    if (message.length > 0) {
      return message.map((message) => message.DISPLAY_MESSAGE);
    }

    return ERROR_MESSAGE.DEFAULT_ERROR_MESSAGE;
  };

  const alertDuration = snackbarType === "error" ? null : 3000;

  return (
    <>
      <Snackbar
        open={isAlert}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={SlideTransition}
        autoHideDuration={alertDuration}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={snackbarType}
          sx={{
            bgcolor:
              snackbarType === "error"
                ? "#d32f2f"
                : snackbarType === "success"
                ? "#43a047"
                : snackbarType === "warning"
                ? "#ed6c02"
                : "#00838a",
          }}
          action={action}
        >
          <DisplayAlertMessage />
        </Alert>
      </Snackbar>
    </>
  );
}
