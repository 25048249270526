import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React from "react";
import Typography from "@mui/material/Typography";

export default function ServerConnectionError() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "50vh",
      }}
    >
      <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        <Typography variant="h2" component="h1" gutterBottom mb={5}>
          Oops!
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          {"Stratili sme spojenie so serverom. Zopakujte pokus neskôr."}
        </Typography>
      </Container>
    </Box>
  );
}
