import { ValidationError } from "../helper/helper";

export async function handleResponse(response) {
  if (response.status === 200) return response.data;
  if (response.status === 201) return response.data;
  if (response.status === 204) return response.data;
  if (response.status === 400) {
    const error = await response.text();
    // const error = await response;
    throw new Error(error);
  }
  if (response.status === 401) {
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 403) {
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 404) {
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  //eslint-disable-next-line
  if (error.response && error.response.data) {
    error = new ValidationError(
      error.response.data.message
        ? error.response.data.message
        : error.response.data.detail
        ? error.response.data.detail
        : error.response.data,
      error.response.status
    );
  }
  console.error("API call failed. " + error);
  throw error;
}
