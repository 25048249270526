import { applyMiddleware, compose, createStore } from "redux";

import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import rootReducer from "../reducers";
import thunk from "redux-thunk";

export default function cofigureStore(intialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middlewares = [reduxImmutableStateInvariant(), thunk];

  return createStore(
    rootReducer,
    intialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
