import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function numberOfProducts(
  state = initialState.numberOfProducts,
  action
) {
  switch (action.type) {
    case types.NUMBER_OF_PRODUCTS:
      return { ...state, ...action.payload };
    case types.DEFAULT_NUMBER_OF_PRODUCTS:
      return initialState.numberOfProducts;
    default:
      return state;
  }
}
