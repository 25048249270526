import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function delivery(state = initialState.delivery, action) {
  switch (action.type) {
    case types.DELIVERY:
      return { ...state, ...action.payload };
    case types.DEFAULT_DELIVERY:
      return initialState.delivery;
    default:
      return state;
  }
}
