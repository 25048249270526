import { ROUTE_URLS, isTestEnviromnent } from "./config/constants";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import FallbackLoader from "./components/loader/FallbackLoader";
import { OpenRoute } from "./components/commonComponents/openroute/OpenRoute";
import { PrivateRoute } from "./components/commonComponents/privateroute/PrivateRoute";

const DataPrivacy = lazy(() =>
  import("./components/pages/dataPrivacy/DataPrivacy")
);

const IndexPage = lazy(() => import("./components/dashboard/index/IndexPage"));

const AccountActivation = lazy(() =>
  import("./components/account/AccountActivation")
);
const ResetPassword = lazy(() =>
  import("./components/forgottenPassword/ResetPassword")
);
const ProductPage = lazy(() => import("./components/products/ProductPage"));
const ProductDetail = lazy(() => import("./components/products/ProductDetail"));
const SignIn = lazy(() => import("./components/signIn/SignIn"));
const SignUp = lazy(() => import("./components/signUp/SignUp"));
const ForgottenPassword = lazy(() =>
  import("./components/forgottenPassword/ForgottenPassword")
);
const MainDashboardPage = lazy(() =>
  import("./components/dashboard/MainDashboardPage")
);
const ProductsCategories = lazy(() =>
  import("./components/pages/productsCategories/ProductsCategories")
);
const Profile = lazy(() =>
  import("./components/dashboard/updateProfile/UpdateProfile")
);
const Users = lazy(() => import("./components/dashboard/Users"));
const ChangePassword = lazy(() =>
  import("./components/dashboard/ChangePassword")
);
const MainBody = lazy(() => import("./components/pages/MainBody"));
const PageNotFound = lazy(() =>
  import("./components/pages/defaultPages/PageNotFound")
);
const SearchResultPage = lazy(() =>
  import("./components/search/SearchResultPage")
);
const Checkout = lazy(() => import("./components/checkout/Checkout"));
const Cart = lazy(() => import("./components/basket/BasketPage"));

const VerifyResponse = lazy(() =>
  import("./components/checkout/VerifyResponse")
);
const CookiePolicy = lazy(() =>
  import("./components/pages/cookiePolicy/cookiePolicy")
);

export default function routes() {
  const testingPage = isTestEnviromnent; //false
  return (
    <>
      <Suspense fallback={<FallbackLoader />}>
        <Routes>
          <Route
            path={ROUTE_URLS.PAGE_LAYOUT}
            element={<OpenRoute component={MainBody} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.SIGN_IN}
            element={<OpenRoute component={SignIn} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.SIGN_UP}
            element={<OpenRoute component={SignUp} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.FORGOTTEN_PASSWORD}
            element={
              <OpenRoute component={ForgottenPassword} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.PRODUCTS_CATEGORIES}
            element={
              <OpenRoute component={ProductsCategories} test={testingPage} />
            }
          />

          <Route
            path={ROUTE_URLS.PRODUCTS}
            element={<OpenRoute component={ProductPage} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.PRODUCTDETAIL}
            element={<OpenRoute component={ProductDetail} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.DASHBOARD}
            element={
              <PrivateRoute component={MainDashboardPage} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.PROFILE}
            element={<PrivateRoute component={Profile} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.ESHOP_USERS}
            element={<PrivateRoute component={Users} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.INDEX}
            element={<PrivateRoute component={IndexPage} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.CHANGE_PASSWORD}
            element={
              <PrivateRoute component={ChangePassword} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.RESET_PASSWORD}
            element={<OpenRoute component={ResetPassword} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.ACCOUNT_ACTIVATION}
            element={
              <OpenRoute component={AccountActivation} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.SEARCH}
            element={
              <OpenRoute component={SearchResultPage} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.CHECKOUT}
            element={<OpenRoute component={Checkout} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.CART}
            element={<OpenRoute component={Cart} test={testingPage} />}
          />
          <Route
            path={ROUTE_URLS.VERIFY}
            element={
              <OpenRoute component={VerifyResponse} test={testingPage} />
            }
          />
          <Route
            path={ROUTE_URLS.COOKIE_POLICY}
            element={<OpenRoute component={CookiePolicy} test={testingPage} />}
          />

          <Route
            path={ROUTE_URLS.DATA_PRIVACY}
            element={<OpenRoute component={DataPrivacy} test={testingPage} />}
          />

          <Route
            path={"*"}
            element={<OpenRoute component={PageNotFound} test={testingPage} />}
          />
        </Routes>
      </Suspense>
    </>
  );
}
