import "./index.css";

import { loadState, saveState } from "./helper/localStorage";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import { createRoot } from "react-dom/client";
import { hydrate } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { throttle } from "lodash";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const persistedState = loadState();
const store = configureStore(persistedState);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

const RootComponent = (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>
);

if (rootElement.hasChildNodes()) {
  hydrate(RootComponent, rootElement);
} else {
  root.render(RootComponent);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// serviceWorker.unregister();
