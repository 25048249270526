import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function apiData(state = initialState.apiData, action) {
  switch (action.type) {
    case types.API_DATA:
      return { ...state, ...action.payload };
    case types.DEFAULT_API_DATA:
      return initialState.apiData;
    default:
      return state;
  }
}
