import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function backdrop(state = initialState.backdrop, action) {
  switch (action.type) {
    case types.BACKDROP:
      return { ...state, ...action.payload };
    case types.DEFAULT_BACKDROP:
      return initialState.backdrop;
    default:
      return state;
  }
}
