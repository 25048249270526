import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DEFAULT_API_DATA,
  DEFAULT_SEARCH,
  SEARCH,
} from "../../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export default function CustomSearchBox() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const expression = useSelector((state) => state?.search?.expression);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({
      type: DEFAULT_API_DATA,
    });

    dispatch({
      type: SEARCH,
      payload: {
        isSubmited: true,
        isOpenDialog: false,
      },
    });
  };

  const handleClearExpression = () => {
    dispatch({
      type: SEARCH,
      payload: {
        result: [],
        expression: "",
        isError: false,
        noResults: false,
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: DEFAULT_SEARCH,
    });
  };

  return (
    <>
      {isSmallScreen && (
        <Box textAlign={"right"}>
          <IconButton
            aria-label="close"
            size="large"
            sx={{ p: 1 }}
            onClick={handleClose}
          >
            <ClearIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          borderRadius: "18px",
          backgroundColor: alpha("#757575", 0.15),
          "&:hover": {
            backgroundColor: alpha("#757575", 0.25),
          },
          marginTop: "10px",
          marginLeft: isSmallScreen ? 0 : "100px",
          marginRight: isSmallScreen ? 0 : "100px",
          width: "auto",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ flexGrow: 0 }} component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              padding: theme.spacing(0, 2),
              height: "100%",
              position: "absolute",
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SearchIcon sx={{ color: "#757575" }} />
          </Box>
          <InputBase
            placeholder="Čo potrebujete nájsť?"
            inputProps={{ "aria-label": "search" }}
            autoFocus
            value={expression}
            onChange={(e) => {
              dispatch({
                type: SEARCH,
                payload: {
                  expression: e?.target?.value,
                },
              });
            }}
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                padding: theme.spacing(1, 1, 1, 0),
                paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                width: "100%",
              },
            }}
            endAdornment={
              expression && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="delete"
                    sx={{ p: 1 }}
                    onClick={handleClearExpression}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </Box>
      </Box>
    </>
  );
}
