import { Box, Button, Typography } from "@mui/material";
import { DEFAULT_API_DATA, SEARCH } from "../../../redux/actions/actionTypes";
import { DEV_URL, INDEX_TOKEN } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";

import CustomHits from "./CustomHits";
import { MeiliSearch } from "meilisearch";
import NotFoundSearch from "../NotFoundSearch";
import ServerConnectionError from "../../pages/defaultPages/ServerConnectionError";
import { useEffect } from "react";

export default function ResultList() {
  const dispatch = useDispatch();
  const expression = useSelector((state) => state?.search?.expression);
  const resultData = useSelector((state) => state?.search?.result);
  const isError = useSelector((state) => state?.search?.isError);
  const noResults = useSelector((state) => state?.search?.noResults);

  const meilisearchClient = new MeiliSearch({
    host: DEV_URL?.PROD_INDEX,
    apiKey: INDEX_TOKEN?.API_KEY,
  });

  const search = async () => {
    // TODO
    // setLoading(true);

    try {
      const index = meilisearchClient.index("cards");
      const searchResults = await index.search(expression, {
        limit: 10,
        offset: 0,
      });

      if (searchResults?.hits.length === 0) {
        dispatch({
          type: SEARCH,
          payload: {
            noResults: true,
          },
        });
      } else {
        dispatch({
          type: SEARCH,
          payload: {
            result: searchResults?.hits,
            noResults: false,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH,
        payload: {
          isError: true,
        },
      });
      console.error("Search error:", err);
    }
    // TODO
    // finally {
    // setLoading(false);
    // }
  };

  useEffect(() => {
    if (expression.length > 0) {
      dispatch({
        type: SEARCH,
        payload: {
          isError: false,
        },
      });
      const timer = setTimeout(() => {
        search();
      }, 500);

      return () => clearTimeout(timer);
    } else {
      dispatch({
        type: SEARCH,
        payload: {
          result: [],
          isError: false,
          noResults: false,
        },
      });
    }
    // eslint-disable-next-line
  }, [expression]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch({
      type: DEFAULT_API_DATA,
    });

    dispatch({
      type: SEARCH,
      payload: {
        isSubmited: true,
        isOpenDialog: false,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          scrollBehavior: "smooth",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {isError && <ServerConnectionError />}
        {expression?.length > 0 ? (
          noResults ? (
            <NotFoundSearch />
          ) : (
            resultData?.length > 0 && <CustomHits />
          )
        ) : (
          <Typography textAlign={"center"} variant="subtitle1">
            Zadajte niečo, čo chcete vyhľadať
          </Typography>
        )}
      </Box>
      {!noResults && expression?.length > 0 && resultData?.length > 0 && (
        <Button
          sx={{ width: 200, mx: "auto", mt: 1 }}
          onClick={handleSubmit}
          variant="outlined"
        >
          Zobraziť viac
        </Button>
      )}
    </>
  );
}
