import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function finstat(state = initialState.finstat, action) {
  switch (action.type) {
    case types.FINSTAT:
      return { ...state, ...action.payload };
    case types.DEFAULT_FINSTAT:
      return initialState.finstat;
    default:
      return state;
  }
}
