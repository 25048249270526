import "./App.css";

import { QueryClient, QueryClientProvider } from "react-query";

import CheckCookie from "./components/cookie/CheckCookie";
import { CssBaseline } from "@mui/material";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./style/style";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CheckCookie />
          <Routes />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}
