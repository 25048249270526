import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00838a",
      light: "#e0f7f8",
      dark: "#036060",
    },
    secondary: {
      main: "#CBF3F0",
    },
    background: {
      default: "#ffffff",
      drawer: "#fdfdfd",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "Poppins",
    h1: {
      fontSize: "3.5rem",
      fontWeight: 600,
    },
    h2: {
      fontWeight: 500,
      fontSize: "2.8rem",
    },
    h3: {
      fontWeight: 400,
      fontSize: "1.8rem",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.1rem",
    },
    h6: {
      fontWeight: 300,
      fontSize: "1rem",
    },
    h7: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
    },
  },

  shape: {
    // borderRadius: 20,
  },
});
export default theme;
