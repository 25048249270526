import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_TESTING } from "../../../redux/actions/actionTypes";
import Dashboard from "../../dashboard/Dashboard";
import PageLayout from "../../../layout/PageLayout";
import { ROUTE_URLS } from "../../../config/constants";
import SnackBarAlert from "../../snackBar/snackBarAlert";
import TestLandingPage from "../../testLandingPage/TestLandingPage";
import VerifyAuthToken from "../../authenticate/VerifyAuthToken";
import { authenticate } from "../../../api/apiCalls";
import { useQuery } from "react-query";

export const PrivateRoute = ({
  component: Component,
  isAdmin,
  test,
  ...rest
}) => {
  let location = useLocation();
  const dispatch = useDispatch();

  const sessionInfo = useSelector((state) => state?.sessionInfo);
  const testToken = useSelector((state) => state?.testing?.token);
  const snackBarAlert = useSelector((state) => state?.snackBarAlert);

  // Added for testing verify test token
  useEffect(() => {
    if (testToken && test) {
      refetch();
    }
    // eslint-disable-next-line
  }, [location]);

  // Added for testing verify test token
  const { refetch } = useQuery(
    ["testAuthentication", testToken],
    () => authenticate(testToken),
    {
      enabled: false,
      onError: (error) => {
        dispatch({
          type: DEFAULT_TESTING,
        });
      },
    }
  );

  // TODO Added testing verification for public beta test
  if (test) {
    if (testToken) {
      if (sessionInfo?.isSignIn) {
        return (
          <>
            <VerifyAuthToken />
            <PageLayout>
              <Dashboard>
                <Component />
              </Dashboard>
            </PageLayout>
          </>
        );
      } else {
        return <Navigate to={ROUTE_URLS.SIGN_IN} />;
      }
    } else {
      return (
        <>
          {snackBarAlert?.isAlert && (
            <>
              <SnackBarAlert
                data={snackBarAlert?.data}
                messageObj={snackBarAlert?.messageObj}
                type={snackBarAlert?.type}
              />
            </>
          )}
          <TestLandingPage />;
        </>
      );
    }
  } else {
    if (sessionInfo?.isSignIn) {
      return (
        <>
          <VerifyAuthToken />
          <PageLayout>
            <Dashboard>
              <Component />
            </Dashboard>
          </PageLayout>
        </>
      );
    } else {
      return <Navigate to={ROUTE_URLS.SIGN_IN} />;
    }
  }

  // TODO
  // if (isAdmin && accessToken && admin) {
  // return (
  //   <Dashboard>
  //     <Component />
  //   </Dashboard>
  // );
  // }
  // else if (isAdmin && accessToken && !admin) {
  //   return <Navigate to="/dashboard" /* state=({from: location }) replace */ />;
  // } else if (!isAdmin && accessToken) {
  //   return (
  //     <Dashboard>
  //       <Component />
  //     </Dashboard>
  //   );
  // } else {
  //   return <Navigate to="/" /*state=({from: location}) replace*/ />;
  // }
};
