import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function productTechData(
  state = initialState.productTechData,
  action
) {
  switch (action.type) {
    case types.PRODUCTTECHDATA:
      return { ...state, ...action.payload };
    case types.DEFAULT_PRODUCTTECHDATA:
      return initialState.productTechData;
    default:
      return state;
  }
}
