export const MegaMenuObj = {
  items: [
    {
      label: "produkty",
      id: "9OF0000101",
      route: "#products",
      items: [
        {
          label: "Automate",
          id: "AOF0000101",
          route: "products#automate",
          items: [
            {
              label: "Cloud computing",
              id: "EPF0000101",
              route: "cloudComputing",
              items: [
                {
                  label: "Device Management Service",
                  id: "FPF0000101",
                  route: "deviceManagement",
                },
                {
                  label: "EMMA Service",
                  id: "GPF0000101",
                  route: "emmaService",
                },
                {
                  label: "Impulse Analytics Service",
                  id: "HPF0000101",
                  route: "impulseAnalytics",
                },
                {
                  label: "Time Series Data Service",
                  id: "IPF0000101",
                  route: "timeSeries",
                },
                {
                  label: "User Management Service",
                  id: "JPF0000101",
                  route: "userManagement",
                },
              ],
            },
            {
              label: "Edge computing",
              id: "KPF0000101",
              route: "edgeComputing",
            },
            {
              label: "HMIs and IPCs",
              id: "LPF0000101",
              route: "hmiIpc",
              items: [
                { label: "Box PCs", id: "MPF0000101", route: "boxPc" },
                { label: "HMIs", id: "NPF0000101", route: "hmi" },
                { label: "Panel PCs", id: "OPF0000101", route: "panelPc" },
                {
                  label: "Rackmount PCs",
                  id: "PPF0000101",
                  route: "rackmountPc",
                },
                {
                  label: "Touch monitors",
                  id: "QPF0000101",
                  route: "touchMonitors",
                },
              ],
            },
            {
              label: "Industrial communication",
              id: "RPF0000101",
              route: "industrialCommunication",
              items: [
                {
                  label: "Device servers and gateways",
                  id: "SPF0000101",
                  route: "derversGateways",
                },
                { label: "Extenders", id: "TPF0000101", route: "extenders" },
                {
                  label: "Fieldbus distributors and segment couplers",
                  id: "UPF0000101",
                  route: "fieldbusSegment",
                },
                {
                  label: "Fieldbus repeaters and serial interface converters",
                  id: "VPF0000101",
                  route: "fieldbusSegment",
                },
                {
                  label: "Industrial Ethernet Switches",
                  id: "WPF0000101",
                  route: "switches",
                },
                {
                  label: "Industrial routers and cybersecurity",
                  id: "XPF0000101",
                  route: "cybersecurity",
                },
                {
                  label: "Industrial Wireless",
                  id: "YPF0000101",
                  route: "wireless",
                },
                {
                  label: "Installation technology and accessories",
                  id: "ZPF0000101",
                  route: "installationTech",
                },
                {
                  label: "Media converters",
                  id: "0QF0000101",
                  route: "mediaConverters",
                },
                {
                  label: "Remote terminal unit (RTU)",
                  id: "1QF0000101",
                  route: "remoteTerminal",
                },
                {
                  label: "Smart Camera Box",
                  id: "2QF0000101",
                  route: "smartCamera",
                },
              ],
            },
            {
              label: "PLCs (controllers) and I/Os",
              id: "3QF0000101",
              route: "plc",
              items: [
                {
                  label: "Automation technology for PLCnext Technology",
                  id: "4QF0000101",
                  route: "automationTechnology",
                },
                {
                  label:
                    "Classic control technology in accordance with IEC 61131",
                  id: "5QF0000101",
                  route: "iec",
                },
                {
                  label: "Remote I/O systems",
                  id: "6QF0000101",
                  route: "remoteIO",
                },
              ],
            },
            {
              label: "Software",
              id: "7QF0000101",
              route: "software",
              items: [
                {
                  label: "Building management system",
                  id: "8QF0000101",
                  route: "buildingManagement",
                },
                {
                  label: "Diagnostics, configuration, and management",
                  id: "9QF0000101",
                  route: "diagnostic",
                },
                { label: "PLC programming", id: "AQF0000101", route: "plc" },
                {
                  label: "Visualization",
                  id: "BQF0000101",
                  route: "visualization",
                },
              ],
            },
          ],
        },
        {
          label: "Connect",
          id: "BOF0000101",
          route: "products#connect",
          items: [
            {
              label: "Distributors, adapters, and conductor connectors",
              id: "8PF0000101",
              route: "distributors",
            },
            {
              label: "Electronics housings",
              id: "9PF0000101",
              route: "electronics",
            },
            {
              label: "PCB terminal blocks and PCB connectors",
              id: "APF0000101",
              route: "pcb",
            },
            { label: "Plug-in test system", id: "BPF0000101", route: "plugIn" },
            {
              label: "System cabling for controllers",
              id: "CPF0000101",
              route: "systemCabling",
            },
            {
              label: "Terminal blocks",
              id: "DPF0000101",
              route: "terminalBlocks",
            },
          ],
        },
        {
          label: "Mark, assemble, and install",
          id: "COF0000101",
          route: "products#mark-assemble-install",
          items: [
            {
              label: "Automatic tools",
              id: "XOF0000101",
              route: "automaticTools",
            },
            {
              label: "Control cabinet accessories",
              id: "YOF0000101",
              route: "controlCabinet",
            },
            {
              label: "Ferrules and crimp connectors",
              id: "ZOF0000101",
              route: "ferrules",
            },
            { label: "Hand tools", id: "0PF0000101", route: "handTools" },
            {
              label: "Industrial lighting and signal lights",
              id: "1PF0000101",
              route: "industrialLighting",
            },
            {
              label: "Junction boxes and empty enclosures",
              id: "2PF0000101",
              route: "junctionBoxes",
            },
            {
              label: "Marking material",
              id: "3PF0000101",
              route: "markingMaterials",
            },
            { label: "Printers", id: "4PF0000101", route: "printers" },
            {
              label: "Software for control cabinet building",
              id: "6PF0000101",
              route: "swCabinetBuilding",
            },
            {
              label: "Worker assistance system with conductor preparation",
              id: "7PF0000101",
              route: "workerAssistance",
            },
          ],
        },
        {
          label: "Supply, charge, and protect",
          id: "DOF0000101",
          route: "products#supply-charge-protect",
          items: [
            {
              label: "Charging technology for e-mobility",
              id: "NOF0000101",
              route: "chargingTech",
            },
            {
              label: "DC/DC converters and DC/AC inverters",
              id: "OOF0000101",
              route: "dcConverters",
            },
            {
              label: "Device circuit breakers",
              id: "POF0000101",
              route: "deviceCircuit",
            },
            { label: "EMC filters", id: "QOF0000101", route: "emcFilters" },
            {
              label: "Power distributors",
              id: "ROF0000101",
              route: "powerDistributors",
            },
            {
              label: "Power supplies",
              id: "SOF0000101",
              route: "powerSupplies",
            },
            {
              label: "Protective relays for mains protection",
              id: "TOF0000101",
              route: "protectiveRelays",
            },
            {
              label: "Redundancy modules",
              id: "UOF0000101",
              route: "redundancyModules",
            },
            {
              label: "Surge protection",
              id: "VOF0000101",
              route: "surgeProtection",
            },
            {
              label: "Uninterruptible power supplies (UPS)",
              id: "WOF0000101",
              route: "ups",
            },
          ],
        },
        {
          label: "Switch, Measure and Monitor",
          id: "EOF0000101",
          route: "products#switch-measure-monitor",
          items: [
            {
              label: "Energy monitoring",
              id: "FOF0000101",
              route: "energyMonitoring",
            },
            { label: "I/O signal marshalling", id: "GOF0000101", route: "io" },
            {
              label: "Motor, machine, and system monitoring",
              id: "HOF0000101",
              route: "motorMachine",
            },
            {
              label: "Motor starters and electronic switching devices",
              id: "IOF0000101",
              route: "motorStarters",
            },
            {
              label: "Process indicators and field devices",
              id: "JOF0000101",
              route: "processIndicators",
            },
            {
              label: "Relays and optocouplers",
              id: "KOF0000101",
              route: "optocouplers",
            },
            {
              label: "Safety relay modules and sensors",
              id: "LOF0000101",
              route: "safetyRelay",
            },
            {
              label: "Signal conditioners and measuring transducers",
              id: "MOF0000101",
              route: "products",
            },
          ],
        },
      ],
    },
    { label: "o nas", id: "", route: "#aboutus" },
    { label: "kontakt", id: "", route: "#contact" },
  ],
};
