import {
  ClickAwayListener,
  Container,
  Link,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { HashLink } from "react-router-hash-link";
import { MegaMenuObj } from "./megaMenu_fenixsk";
import { NAVIGATION_BAR } from "../../redux/actions/actionTypes";
import theme from "../../style/style";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ProductMenu() {
  const navigate = useNavigate();
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  let menuItems = MegaMenuObj;
  const dispatch = useDispatch();

  const openMenu = (event) => {
    event.preventDefault();
    setIsMenuOpen(true);

    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        menuOpen: true,
      },
    });
    setMenuOpen(true);
    setAnchorElMenu(event?.currentTarget?.id);
  };

  useEffect(() => {
    if (!isMenuOpen) {
      const timer = setTimeout(() => {
        closeMenu();
      }, 300);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isMenuOpen]);

  const closeMenu = () => {
    if (!isMenuOpen) {
      dispatch({
        type: NAVIGATION_BAR,
        payload: {
          menuOpen: false,
        },
      });
      setMenuOpen(false);
      setAnchorElMenu(null);
    }
  };

  const hangleClick = (page) => {
    setMenuOpen(false);

    setIsMenuOpen(false);

    if (page) {
      navigate("/" + page.X_URL);
    }
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -70;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Box>
      <HashLink
        smooth
        to={"/products"}
        key={"products"}
        scroll={scrollWithOffset}
        className="MenuLink"
      >
        <Button
          sx={{
            mr: 1,
            ml: 1,
            color: "black",
            display: "block",
            "&:hover": { backgroundColor: "unset" },
          }}
          id="productButton"
          className="MenuButton"
          onMouseOver={openMenu}
          onMouseLeave={() => setIsMenuOpen(false)}
          onClick={() => hangleClick()}
        >
          Produkty
        </Button>
      </HashLink>

      <Popper
        sx={{
          width: 1,
          zIndex: 1250,
        }}
        position={"fixed"}
        //TODO
        // PaperProps={{
        //   style: {
        //     width: "100%",
        //     //     height: "50%",
        //     //     maxHeight: "unset",
        //     // maxWidth: "unset",
        //   },
        //   // square: true,
        //   // border: "1px solid",
        // }}
        id="customized-menu"
        placement="bottom-start"
        open={menuOpen}
        anchorEl={document?.getElementById(anchorElMenu)}
        onMouseLeave={() => setIsMenuOpen(false)}
        onMouseEnter={() => setIsMenuOpen(true)}
      >
        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <Paper
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              // px: 4,
              // maxWidth: "xl",
              m: "auto",
            }}
          >
            <Container
              sx={{ maxWidth: "xl", display: { xs: "none", sm: "flex" } }}
            >
              {menuItems?.map((page, index) =>
                page?.group_name ? (
                  <Box key={index} sx={{ m: 2, mt: 4 }}>
                    <Typography sx={{ fontWeight: "500" }}>
                      {page?.group_name}
                    </Typography>

                    {page?.items && (
                      <>
                        {page?.items?.map((subPage) => (
                          <Box key={subPage?.id} sx={{ my: 2 }}>
                            <Link
                              component="button"
                              onClick={() => hangleClick(subPage)}
                              underline="hover"
                              color={"inherit"}
                              sx={{
                                "&:hover": {
                                  color: theme.palette.primary.main,
                                },
                              }}
                            >
                              <Typography
                                textAlign={"left"}
                                sx={{
                                  fontSize: 13,
                                }}
                              >
                                {subPage?.X_fullName}
                              </Typography>
                            </Link>
                          </Box>
                        ))}
                      </>
                    )}
                  </Box>
                ) : (
                  <Box key={page?.id} sx={{ m: 2, mt: 4 }}>
                    <Link
                      component="button"
                      onClick={() => hangleClick(page)}
                      underline="hover"
                      sx={{
                        color: "black",
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                        }}
                      >
                        {page?.X_fullName}
                      </Typography>
                    </Link>
                  </Box>
                )
              )}
            </Container>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
