import {
  AUTHENTICATE,
  DEFAULT_AUTHENTICATE,
  DEFAULT_BASKET,
  DEFAULT_FIRM_INFO,
  DEFAULT_FORM_FIELDS,
  DEFAULT_SESSION_INFO,
  SNACKBAR_ALERT,
} from "../../redux/actions/actionTypes";
import { AUTHENTICATE_MESSAGE, ROUTE_URLS } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";

import { authenticate } from "../../api/apiCalls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export default function VerifyAuthToken() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifyAuth = useSelector((state) => state?.authenticate);
  const session = useSelector((state) => state?.sessionInfo);

  useEffect(() => {
    if (!verifyAuth?.auth) {
      dispatch({
        type: DEFAULT_AUTHENTICATE,
      });
      logOut();
    }

    // eslint-disable-next-line
  }, [verifyAuth?.auth]);

  useEffect(() => {
    if (verifyAuth?.runAuth) {
      dispatch({
        type: DEFAULT_AUTHENTICATE,
      });
      dispatch({
        type: AUTHENTICATE,
        payload: {
          runAuth: false,
        },
      });

      refetch();
    }

    // eslint-disable-next-line
  }, [verifyAuth?.runAuth]);

  const token = session?.token;
  const { refetch } = useQuery(
    ["authentication", token],
    () => authenticate(token),
    {
      enabled: false,
      onSuccess: (data) => {
        checkSessionInfo(data);
      },
      onError: (error) => {
        dispatch({
          type: AUTHENTICATE,
          payload: {
            auth: false,
          },
        });
      },
    }
  );

  const checkSessionInfo = (data) => {
    if (
      data?.first_name !== session?.firstName ||
      data?.last_name !== session?.lastName ||
      data?.email !== session?.email
    ) {
      dispatch({
        type: AUTHENTICATE,
        payload: {
          auth: false,
        },
      });
    }
  };

  const logOut = () => {
    dispatch({
      type: SNACKBAR_ALERT,
      payload: {
        data: "Invalid token.",
        messageObj: AUTHENTICATE_MESSAGE,
        type: "warning",
        isAlert: true,
      },
    });

    dispatch({
      type: DEFAULT_FIRM_INFO,
    });
    dispatch({
      type: DEFAULT_FORM_FIELDS,
    });
    dispatch({
      type: DEFAULT_SESSION_INFO,
    });
    dispatch({
      type: DEFAULT_BASKET,
    });

    navigate(ROUTE_URLS.SIGN_IN);
  };
}
