import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { SEARCH } from "../redux/actions/actionTypes";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function SearchField() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: SEARCH,
      payload: {
        isOpenDialog: false,
        result: [],
      },
    });

    // eslint-disable-next-line
  }, []);

  const handleSearchDialog = () => {
    if (location?.pathname !== "/search") {
      dispatch({
        type: SEARCH,
        payload: {
          isOpenDialog: true,
          result: [],
        },
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation={true}
        sx={{
          width: "150px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          borderRadius: "15px",
          backgroundColor: alpha("#757575", 0.15),
          "&:hover": {
            backgroundColor: alpha("#757575", 0.25),
          },
        }}
        onClick={handleSearchDialog}
        startIcon={<SearchIcon sx={{ color: "#757575" }} />}
      >
        <Typography sx={{ color: "#757575" }}>Hľadaj…</Typography>
      </Button>
    </>
  );
}
