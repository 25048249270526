import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { ROUTE_URLS } from "../../config/constants";
import React from "react";
import Typography from "@mui/material/Typography";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" textAlign={"center"}>
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href={ROUTE_URLS.PAGE_LAYOUT} underline="none">
        FENIX SK, s.r.o.
      </Link>
    </Typography>
  );
}

export default function StickyFooter() {
  return (
    <Box
      sx={{
        position: "fixed",

        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1200,
      }}
    >
      <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          backgroundColor: "#e8eaed",
        }}
      >
        <Container>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}
