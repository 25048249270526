import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function formFields(state = initialState.formFields, action) {
  switch (action.type) {
    case types.FORM_FIELDS:
      return { ...state, ...action.payload };
    case types.DEFAULT_FORM_FIELDS:
      return initialState.formFields;
    default:
      return state;
  }
}
