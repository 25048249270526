import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function dialog(state = initialState.dialog, action) {
  switch (action.type) {
    case types.DIALOG:
      return { ...state, ...action.payload };
    case types.DEFAULT_DIALOG:
      return initialState.dialog;
    default:
      return state;
  }
}
