import { Box, Drawer, Toolbar } from "@mui/material";

import { DEFAULT_SNACKBAR_ALERT } from "../../redux/actions/actionTypes";
import MenuList from "./MenuList";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function DashboardMenuDrawer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: DEFAULT_SNACKBAR_ALERT,
    });
    // eslint-disable-next-line
  }, []);

  const drawerWidth = 240;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        display: { xs: "none", md: "flex" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <MenuList />
      </Box>
    </Drawer>
  );
}
