import {
  BACKDROP,
  DEFAULT_BACKDROP,
  DEFAULT_FORM_FIELDS,
  DEFAULT_TESTING,
  FORM_FIELDS,
  SNACKBAR_ALERT,
  TESTING,
} from "../../redux/actions/actionTypes";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonInputTextFiled from "../commonComponents/commonInputTextField/CommonInputTextFiled";
import PasswordSignIn from "../formsFields/PasswordSignIn";
import { SIGNIN_ERROR_MESSAGE } from "../../config/constants";
import SimpleBackdrop from "../loader/SimpleBackdrop";
import ValidateFields from "../formsFields/ValidateFields";
import { getSignInToken } from "../../api/apiCalls";
import { useQuery } from "react-query";

export default function TestLandingPage() {
  const dispatch = useDispatch();
  const formFields = useSelector((state) => state?.formFields);
  const [errorQuery, setErrorQuery] = useState(false);
  const [successQuery, setSuccessQuery] = useState(false);

  useEffect(() => {
    dispatch({
      type: DEFAULT_FORM_FIELDS,
    });

    dispatch({
      type: DEFAULT_BACKDROP,
    });
    dispatch({
      type: DEFAULT_TESTING,
    });
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    const result = ValidateFields({
      email: formFields?.email,
      passwordSignIn: formFields?.passwordSignIn,
    });
    dispatch({
      type: FORM_FIELDS,
      payload: result?.fields,
    });
    dispatch({
      type: FORM_FIELDS,
      payload: result?.error_message,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formFields?.isValidForm) {
      refetch();

      dispatch({
        type: BACKDROP,
        payload: { isOpen: true },
      });
    }
  };

  useEffect(() => {
    if (successQuery || errorQuery) {
      handleAlert();
    }
    // eslint-disable-next-line
  }, [successQuery, errorQuery]);

  const handleAlert = () => {
    return new Promise(() =>
      setTimeout(() => {
        displayAlert();
      }, 100)
    );
  };

  const displayAlert = () => {
    dispatch({
      type: BACKDROP,
      payload: { isOpen: false },
    });
    if (errorQuery) {
      dispatch({
        type: SNACKBAR_ALERT,
        payload: { isAlert: true },
      });
      setErrorQuery(false);
      setSuccessQuery(false);
    }
    if (successQuery) {
      dispatch({
        type: FORM_FIELDS,
        payload: { passwordSignIn: "" },
      });
      setErrorQuery(false);
      setSuccessQuery(false);
    }
  };

  const login_body = {
    email: formFields?.email,
    password: formFields?.passwordSignIn,
    type: "test",
  };

  const { refetch } = useQuery(
    ["signInTest", login_body],
    () => getSignInToken(login_body),
    {
      enabled: false,
      cacheTime: 5,
      onSuccess: (data) => {
        dispatch({
          type: TESTING,
          payload: {
            token: data?.token,
            name: data?.first_name,

            role: data?.role,
            expiry: data?.expiry,
          },
        });
        setSuccessQuery(true);
      },
      onError: (error) => {
        dispatch({
          type: SNACKBAR_ALERT,
          payload: {
            data: error?.message,
            messageObj: SIGNIN_ERROR_MESSAGE,
            action: "close",
            type: "error",
          },
        });
        setErrorQuery(true);
      },
    }
  );

  return (
    <>
      <SimpleBackdrop />

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={10}
        flexWrap="wrap"
        useFlexGap
        height={"100vh"}
        my={"auto"}
      >
        <Box maxWidth="500px" m={5}>
          <Typography
            mb={4}
            color={"primary"}
            component="h1"
            align="center"
            sx={{
              letterSpacing: "-1px",
              fontSize: "2rem",
              fontWeight: "900",
            }}
          >
            Už čoskoro spúšťame náš úplne nový e-shop!
          </Typography>
          <Typography
            mb={2}
            component="h1"
            variant="h2"
            align="center"
            sx={{
              letterSpacing: "-1px",
              fontSize: "1.5rem",
              fontWeight: "700",
            }}
          >
            Môžete sa tešiť na širokú ponuku skvelých produktov, ktoré sme pre
            vás starostlivo vybrali.
          </Typography>
        </Box>

        <Box maxWidth="400px" m={5}>
          <Box
            sx={{
              my: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography component="h1" variant="h4">
              Prihlásenie
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2} columnSpacing={4}>
                <Grid item xs={12}>
                  <CommonInputTextFiled
                    autoComplete="email"
                    id="email"
                    name="Email"
                    error={formFields?.emailError}
                    errorMessage={formFields?.emailErrorMessage}
                    placeholder="príklad@príklad.sk"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordSignIn />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleClick}
              >
                Prihlásiť
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
