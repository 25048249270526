import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function authenticate(
  state = initialState.authenticate,
  action
) {
  switch (action.type) {
    case types.AUTHENTICATE:
      return { ...state, ...action.payload };
    case types.DEFAULT_AUTHENTICATE:
      return initialState.authenticate;
    default:
      return state;
  }
}
