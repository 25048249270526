import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function orders(state = initialState.orders, action) {
  switch (action.type) {
    case types.ORDERS:
      return { ...state, ...action.payload };
    case types.DEFAULT_ORDERS:
      return initialState.orders;
    default:
      return state;
  }
}
