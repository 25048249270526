import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function basket(state = initialState.basket, action) {
  switch (action.type) {
    case types.BASKET:
      return { ...state, ...action.payload };
    case types.DEFAULT_BASKET:
      return initialState.basket;
    default:
      return state;
  }
}
