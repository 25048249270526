import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function testing(state = initialState.testing, action) {
  switch (action.type) {
    case types.TESTING:
      return { ...state, ...action.payload };
    case types.DEFAULT_TESTING:
      return initialState.testing;
    default:
      return state;
  }
}
