import { Badge, IconButton, Tooltip } from "@mui/material";

import { ROUTE_URLS } from "../../config/constants";
import React from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function BasketIcon({ sx }) {
  const basketQuantity = useSelector((state) => state.basket.quantity);

  const location = useLocation();
  const navigate = useNavigate();

  //TODO
  // dispatch({
  //   type: DEFAULT_BASKETDIALOG,
  // });

  const handleOpenBasket = () => {
    if (location?.pathname !== ROUTE_URLS.CART) {
      navigate(ROUTE_URLS.CART);
    }
  };

  return (
    <>
      <Tooltip title="Košík">
        <IconButton size="large" onClick={handleOpenBasket} sx={sx}>
          <Badge badgeContent={basketQuantity} color="primary">
            <ShoppingBagIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
}
