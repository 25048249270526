import Box from "@mui/material/Box";
import DashboardMenuDrawer from "./DashboardMenuDrawer";
import React from "react";
import StickyFooter from "./Footer";

export default function Dashboard({ children }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardMenuDrawer />
        <Box
          component="main"
          sx={{
            flexGrow: 1,

            mb: 5,

            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
          <StickyFooter />
        </Box>
      </Box>
    </>
  );
}
