import { useDispatch, useSelector } from "react-redux";

import Drawer from "@mui/material/Drawer";
import { NAVIGATION_BAR } from "../../redux/actions/actionTypes";
import React from "react";
import SlideMenuList from "./SlideMenuList";

const drawerWidth = "100vw";

export default function SlideMenuDrawer() {
  const dispatch = useDispatch();
  const slideMenuVisible = useSelector(
    (state) => state?.navBarMenu?.slideMenuVisible
  );

  const closeMenu = () => {
    dispatch({
      type: NAVIGATION_BAR,
      payload: {
        slideMenuVisible: false,
      },
    });
  };

  return (
    <>
      <Drawer
        variant="temporary"
        open={slideMenuVisible}
        onClose={closeMenu}
        ModalProps={{
          keepMounted: false,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          zIndex: 1500,
        }}
        key="slideMenuDrawer"
      >
        <SlideMenuList />
      </Drawer>
    </>
  );
}
