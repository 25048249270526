import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function navBarMenu(state = initialState.navBarMenu, action) {
  switch (action.type) {
    case types.NAVIGATION_BAR:
      return { ...state, ...action.payload };
    case types.DEFAULT_NAVIGATION_BAR:
      return initialState.navBarMenu;
    default:
      return state;
  }
}
