import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { FORM_FIELDS } from "../../redux/actions/actionTypes";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default function PasswordSignIn() {
  const dispatch = useDispatch();
  const formFields = useSelector((state) => state?.formFields);

  const toggleShowPassword = () => {
    dispatch({
      type: FORM_FIELDS,
      payload: { showPasswordSignIn: !formFields?.showPasswordSignIn },
    });
  };

  return (
    <TextField
      required
      fullWidth
      name="password"
      label="Heslo"
      type={formFields?.showPasswordSignIn ? "text" : "password"}
      id="password"
      autoComplete="current-password"
      value={formFields?.passwordSignIn}
      onChange={(e) => {
        dispatch({
          type: FORM_FIELDS,
          payload: {
            passwordSignIn: e?.target?.value,
          },
        });
      }}
      error={formFields?.passwordSignInError}
      helperText={
        formFields?.passwordSignInError
          ? formFields?.passwordSignInErrorMessage
          : ""
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {formFields?.showPasswordSignIn ? (
                <VisibilityOff />
              ) : (
                <Visibility />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
