import { Box, Typography } from "@mui/material";

export default function NotFoundSearch() {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
      <Typography variant="h5" color="text.secondary" textAlign={"center"}>
        Nepodarilo sa nám nájsť hľadaný výraz
      </Typography>
    </Box>
  );
}
