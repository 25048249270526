import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function sessionInfo(state = initialState.sessionInfo, action) {
  switch (action.type) {
    case types.SESSION_INFO:
      return { ...state, ...action.payload };
    case types.DEFAULT_SESSION_INFO:
      return initialState.sessionInfo;
    default:
      return state;
  }
}
