import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function displayProducts(
  state = initialState.displayProducts,
  action
) {
  switch (action.type) {
    case types.DISPLAYPRODUCTS:
      return { ...state, ...action.payload };
    case types.DEFAULT_DISPLAYPRODUCTS:
      return initialState.displayProducts;
    default:
      return state;
  }
}
