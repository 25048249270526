import { Box, Fade, Modal, useMediaQuery } from "@mui/material";
import { DEFAULT_SEARCH, SEARCH } from "../../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import CustomSearchBox from "./CustomSearchBox";
import { ROUTE_URLS } from "../../../config/constants";
import ResultList from "./ResultList";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function SearchDialog() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector((state) => state?.search?.isOpenDialog);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSubmited = useSelector((state) => state?.search?.isSubmited);
  const navigate = useNavigate();

  // TODO
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  // const [noResults, setNoResults] = useState(false);

  const handleClose = () => {
    dispatch({
      type: DEFAULT_SEARCH,
    });
  };
  // TODO
  // useEffect(() => {
  //   if (open) {
  //     dispatch({
  //       type: SEARCH,
  //       payload: {
  //         result: [],
  //         expression: "",
  //         isError: false,
  //         noResults: false,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [open]);

  useEffect(() => {
    if (isSubmited) {
      dispatch({
        type: SEARCH,
        payload: {
          isSubmited: false,
          isError: false,
          noResults: false,
        },
      });
      setTimeout(() => {
        navigate(ROUTE_URLS?.SEARCH);
      }, 300);
    }
    // eslint-disable-next-line
  }, [isSubmited]);

  const style = {
    position: "absolute",
    top: isSmallScreen ? 0 : "6%",
    left: "50%",
    transform: "translate(-50%)",
    width: isSmallScreen ? "100vw" : "740px",
    bgcolor: "background.paper",
    boxShadow: "rgba(9, 11, 11, 0.8) 0px 4px 16px",
    borderRadius: isSmallScreen ? 0 : "8px",
    p: 2,
    minHeight: isSmallScreen ? "100vh" : "40vh",
    maxHeight: isSmallScreen ? "100vh" : "80vh",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Modal
      disablePortal
      aria-labelledby="fast-search"
      aria-describedby="fast-search"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: { backdropFilter: "blur(2px)" },
        },
      }}
      sx={{ zIndex: 1350 }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <CustomSearchBox />
          <ResultList />
        </Box>
      </Fade>
    </Modal>
  );
}
