import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";
import { FORM_FIELDS } from "../../../redux/actions/actionTypes";
import React from "react";
import TextField from "@mui/material/TextField";

export default function CommonInputTextFiled({
  id,
  error,
  name,
  placeholder,
  disabled,
  errorMessage,
  loading,
  required,
  autoFocus,
  sx,
  variant,
  inputRef,
  autoComplete,
}) {
  const dispatch = useDispatch();
  const formFields = useSelector((state) => state?.formFields);

  return (
    <TextField
      autoComplete={autoComplete}
      sx={sx}
      name={id}
      required={required}
      fullWidth
      label={name}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      value={formFields?.[id]}
      variant={variant}
      inputRef={inputRef}
      onChange={(e) => {
        dispatch({
          type: FORM_FIELDS,
          payload: {
            [id]: e?.target?.value,
          },
        });
      }}
      error={error}
      helperText={error ? errorMessage : ""}
      InputProps={{
        endAdornment: (
          <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>
        ),
      }}
    />
  );
}
