import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function payGateway(state = initialState.payGateway, action) {
  switch (action.type) {
    case types.PAYGATEWAY:
      return { ...state, ...action.payload };
    case types.DEFAULT_PAYGATEWAY:
      return initialState.payGateway;
    default:
      return state;
  }
}
