import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../logos/fenix_sk_transparent.png";
import { ROUTE_URLS } from "../../config/constants";

export default function LogoContainer({ sx }) {
  return (
    <Box sx={sx}>
      <Link key={"home"} to={ROUTE_URLS.PAGE_LAYOUT}>
        <img src={Logo} width={"100%"} alt="Fenix SK s.r.o." />
      </Link>
    </Box>
  );
}
