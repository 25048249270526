import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function firmInfo(state = initialState.firmInfo, action) {
  switch (action.type) {
    case types.FIRM_INFO:
      return { ...state, ...action.payload };
    case types.DEFAULT_FIRM_INFO:
      return initialState.firmInfo;
    default:
      return state;
  }
}
