// eslint-disable-next-line
export default {
  formFields: {
    address: "",
    addressError: false,
    addressErrorMessage: "",
    showShippingAddress: true,
    shouldUseShippingAddressForBilling: true,
    address2: "",
    address2Error: false,
    address2ErrorMessage: "",
    firstName: "",
    firstNameError: false,
    firstNameErrorMessage: "",
    lastName: "",
    lastNameError: false,
    lastNameErrorMessage: "",
    email: "",
    emailError: false,
    emailErrorMessage: "",
    showInvoiceEmail: false,
    invoiceEmail: "",
    invoiceEmailError: false,
    invoiceEmailErrorMessage: "",
    telephone: "",
    telephoneError: false,
    telephoneErrorMessage: "",
    companyName: "",
    companyNameError: false,
    companyNameErrorMessage: "",
    ico: "",
    icoError: false,
    icoErrorMessage: "",
    city: "",
    cityError: false,
    cityErrorMessage: "",
    city2: "",
    city2Error: false,
    city2ErrorMessage: "",
    zipCode: "",
    zipCodeError: false,
    zipCodeErrorMessage: "",
    zipCode2: "",
    zipCode2Error: false,
    zipCode2ErrorMessage: "",
    country: null,
    countryError: false,
    countryErrorMessage: "",
    country2: null,
    country2Error: false,
    country2ErrorMessage: "",
    password: "",
    passwordError: false,
    passwordErrorMessage: "",
    confirmPassword: "",
    confirmPasswordError: false,
    confirmPasswordErrorMessage: "",
    showPassword: false,
    showConfirmPassword: false,
    agreeTermsChecked: false,
    agreeTermsCheckedError: false,
    agreeTermsCheckedErrorMessage: "",
    agreeInvoiceEmail: false,
    agreeInvoiceEmailError: false,
    agreeInvoiceEmailErrorMessage: "",
    passwordSignIn: "",
    passwordSignInError: false,
    passwordSignInErrorMessage: "",
    showPasswordSignIn: false,
    buyForCompany: false,
    isValidForm: false,
    // isAlert: false,
    isCaptchaOpen: false,
    captchaToken: "",
    isValidCaptcha: false,
    paymentMethod: "",
    paymentMethodError: false,
    paymentMethodErrorMessage: "",
    externalNumber: "",
    cardHolder: "",
    cardHolderError: false,
    cardHolderErrorMessage: "",
    // TODO
    // customerType: "",
    customerType: "firm",
    customerTypeError: false,
    customerTypeErrorMessage: "",
    addProduct: "",
    addProductError: false,
    addProductErrorMessage: "",
    icDph: "",
    dic: "",
  },
  navBarMenu: {
    height: 0,
    slideMenuVisible: false,
    logoVisible: "flex",
    menuOpen: false,
    isUserMenuOpen: false,
    anchorElUser: null,
    slideAccountMenuVisible: false,
    searchOpen: false,
  },
  slideMenu: {
    menuItems: "",
    // menuItemsHistory: "",
    parentItem: "",
    backButton: "",
    showBackButton: "none",
  },
  apiData: {
    data: [],
    prices: [],
    quantity: [],
  },
  apiProductDetail: {
    data: [],
    prices: [],
    quantity: [],
  },
  basket: {
    productsForImport: "",

    items: [],
    quantity: 0,
    totalPrice: 0,
    totalVatPrice: 0,
    currency: "€",
  },
  productPiece: {
    quantity: 1,
  },
  dialog: {
    isOpen: false,
    importBasketIsOpen: false,
    accepted: false,
    //   isCashButtonDisabled: true,
  },
  sessionInfo: {
    token: null,
    firstName: "",
    lastName: "",
    abraId: "",
    personId: "",
    firmPersonId: "",
    email: "",
    isSignIn: false,
    role: "",
    isSuperuser: false,
    customerType: "",
  },
  firmInfo: {
    abraId: "",
    companyName: "",
    ico: "",
    mainPriceList: "3K00000101",
    priceList: "",
    address: "",
    zipCode: "",
    country: "",
    city: "",
    differentShippingAddressForBilling: false,
    address2: "",
    zipCode2: "",
    country2: null,
    city2: "",
    telephone: "",
    firstName: "",
    lastName: "",
    email: "",
    credit: 0,
    creditExceeded: false /* false */,
    dueTerm: 0,
    periodId: "",
    firmOfficeId: "",
    choosenFirmOfficeId: "",
    shippingAddressName: "",
    shippingAddresses: [],
    assortmentDiscounts: [],
    mainAssortmentDiscounts: [],
    firmDiscount: "",
  },
  backdrop: {
    isOpen: false,
  },
  snackBarAlert: {
    isAlert: false,
    data: {},
    messageObj: {},
    action: "",
    type: "",
  },
  category: {
    id: "",
    name: "",
    subCategories: "",
    categoryTab: 0,
    patentCategory: "",
    patentCategoryUrl: "",
    isLoading: false,
    path: "",
    status: "",
  },
  productMenu: { items: [] },
  numberOfProducts: { count: 0, page: 1 },
  search: {
    expression: "",
    isOpenDialog: false,
    result: [],
    processingTime: 0,
    totalHits: 0,
    offset: 0,
    isLast: true,
    page: 1,
    totalPages: 0,
    isError: false,
    noResults: false,
    isSubmited: false,
  },
  dashboard: {
    menu: "",
    defaultMenu: true,
  },
  finstat: {
    hash: "",
  },
  checkout: {
    activeStep: 0,
  },
  orders: {
    status: "",
    name: "",
    id: "",
    vs: "",
    amount: "",
  },
  payGateway: {
    used: false,
    status: "",
    message: "",
    payRes: "",
  },
  authenticate: {
    auth: true,
    runAuth: false,
  },
  testing: {
    name: "",
    role: "",
    token: "",
    expiry: "",
  },
  productTechData: {
    data: [],
  },
  cookieDialog: {
    isDisplayed: true,
  },
  displayProducts: {
    display: "row",
  },
  index: {
    results: [],
    totalResults: 0,
    pageSize: 20,
    pageIndex: 0,
    taskDetai: {},
    isError: false,
    isLoading: false,
    isLoadingTaskDetail: false,
    error: "",
    type: "",
  },
  delivery: {
    defaultVatPrice: 6,
    defaultPrice: 5,
    id: "1N44000101",
  },
};
