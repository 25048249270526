import {
  INPUT_FIELD_ERROR_MESSAGE,
  PAYMENT_ERROR_MESSAGE,
} from "../../config/constants";
import {
  PASSWORD_RULES,
  cardHolder,
  cityPattern,
  companyPattern,
  emailPattern,
  icoPattern,
  pattern,
  telephonePattern,
  zipPattern,
} from "../../config/regexConstants";

export default function ValidateFields(obj) {
  let fields = {};
  let error_message = {};

  if (typeof obj?.firstName !== "undefined") {
    if (obj?.firstName === "") {
      fields = {
        ...fields,
        firstNameError: true,
      };
      error_message = {
        ...error_message,
        firstNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_FIRST_NAME,
      };
    } else if (!pattern.test(obj?.firstName)) {
      fields = {
        ...fields,
        firstNameError: true,
      };
      error_message = {
        ...error_message,
        firstNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_FIRST_NAME,
      };
    } else {
      fields = {
        ...fields,
        firstNameError: false,
      };
      error_message = {
        ...error_message,
        firstNameErrorMessage: "",
      };
    }
  }

  if (typeof obj?.lastName !== "undefined") {
    if (obj?.lastName === "") {
      fields = {
        ...fields,
        lastNameError: true,
      };
      error_message = {
        ...error_message,
        lastNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_LAST_NAME,
      };
    } else if (!pattern.test(obj?.lastName)) {
      fields = {
        ...fields,
        lastNameError: true,
      };
      error_message = {
        ...error_message,
        lastNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_LAST_NAME,
      };
    } else {
      fields = {
        ...fields,
        lastNameError: false,
      };
      error_message = {
        ...error_message,
        lastNameErrorMessage: "",
      };
    }
  }

  if (typeof obj?.email !== "undefined") {
    if (obj?.email === "") {
      fields = {
        ...fields,
        emailError: true,
      };
      error_message = {
        ...error_message,
        emailErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_EMAIL,
      };
    } else if (!emailPattern.test(obj?.email)) {
      fields = {
        ...fields,
        emailError: true,
      };
      error_message = {
        ...error_message,
        emailErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_EMAIL,
      };
    } else {
      fields = {
        ...fields,
        emailError: false,
      };
      error_message = {
        ...error_message,
        emailErrorMessage: "",
      };
    }
  }

  if (typeof obj?.telephone !== "undefined") {
    if (obj?.telephone === "") {
      fields = {
        ...fields,
        telephoneError: true,
      };
      error_message = {
        ...error_message,
        telephoneErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_TELEPHONE,
      };
    } else if (!telephonePattern.test(obj?.telephone)) {
      fields = {
        ...fields,
        telephoneError: true,
      };
      error_message = {
        ...error_message,
        telephoneErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_TELEPHONE,
      };
    } else {
      fields = {
        ...fields,
        telephoneError: false,
      };
      error_message = {
        ...error_message,
        telephoneErrorMessage: "",
      };
    }
  }

  if (typeof obj?.password !== "undefined") {
    if (obj?.password === "") {
      fields = {
        ...fields,
        passwordError: true,
      };
      error_message = {
        ...error_message,
        passwordErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_PASSWORD,
      };
    } else if (!PASSWORD_RULES[0].regex.test(obj?.password)) {
      fields = {
        ...fields,
        passwordError: true,
      };
      error_message = {
        ...error_message,
        passwordErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_PASSWORD_LENGTH,
      };
    } else if (!PASSWORD_RULES[1].regex.test(obj?.password)) {
      fields = {
        ...fields,
        passwordError: true,
      };
      error_message = {
        ...error_message,
        passwordErrorMessage:
          INPUT_FIELD_ERROR_MESSAGE.ERROR_PASSWORD_SPECIAL_CHAR,
      };
    } else if (!PASSWORD_RULES[2].regex.test(obj?.password)) {
      fields = {
        ...fields,
        passwordError: true,
      };
      error_message = {
        ...error_message,
        passwordErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_PASSWORD_STARTS,
      };
    } else {
      fields = {
        ...fields,
        passwordError: false,
      };
      error_message = {
        ...error_message,
        passwordErrorMessage: "",
      };
    }
  }

  if (typeof obj?.confirmPassword !== "undefined") {
    if (obj?.confirmPassword === "") {
      fields = {
        ...fields,
        confirmPasswordError: true,
      };
      error_message = {
        ...error_message,
        confirmPasswordErrorMessage:
          INPUT_FIELD_ERROR_MESSAGE.EMPTY_CONFIRM_PASSWORD,
      };
    } else if (obj?.password !== obj?.confirmPassword) {
      fields = {
        ...fields,
        confirmPasswordError: true,
      };
      error_message = {
        ...error_message,
        confirmPasswordErrorMessage:
          INPUT_FIELD_ERROR_MESSAGE.PASSWORD_DOES_NOT_MATCH,
      };
    } else {
      fields = {
        ...fields,
        confirmPasswordError: false,
      };
      error_message = {
        ...error_message,
        confirmPasswordErrorMessage: "",
      };
    }
  }

  if (typeof obj?.invoiceEmail !== "undefined") {
    if (obj?.invoiceEmail === "") {
      fields = {
        ...fields,
        invoiceEmailError: true,
      };
      error_message = {
        ...error_message,
        invoiceEmailErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_EMAIL,
      };
    } else if (!emailPattern.test(obj?.invoiceEmail)) {
      fields = {
        ...fields,
        invoiceEmailError: true,
      };
      error_message = {
        ...error_message,
        invoiceEmailErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_EMAIL,
      };
    } else {
      fields = {
        ...fields,
        invoiceEmailError: false,
      };
      error_message = {
        ...error_message,
        invoiceEmailErrorMessage: "",
      };
    }
  }

  if (typeof obj?.agreeInvoiceEmail !== "undefined") {
    if (!obj?.agreeInvoiceEmail) {
      fields = {
        ...fields,
        agreeInvoiceEmailError: true,
      };
      error_message = {
        ...error_message,
        agreeInvoiceEmailErrorMessage:
          INPUT_FIELD_ERROR_MESSAGE.INVOICE_EMAIL_DOES_NOT_CHECKED,
      };
    } else {
      fields = {
        ...fields,
        agreeInvoiceEmailError: false,
      };
      error_message = {
        ...error_message,
        agreeInvoiceEmailErrorMessage: "",
      };
    }
  }

  if (typeof obj?.customerType !== "undefined") {
    if (obj?.customerType === "") {
      fields = {
        ...fields,
        customerTypeError: true,
      };
      error_message = {
        ...error_message,
        customerTypeErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_CUSTOMERTYPE,
      };
    } else {
      fields = {
        ...fields,
        customerTypeError: false,
      };
      error_message = {
        ...error_message,
        customerTypeErrorMessage: "",
      };
    }
  }

  if (typeof obj?.companyName !== "undefined") {
    if (obj?.companyName === "") {
      fields = {
        ...fields,
        companyNameError: true,
      };
      error_message = {
        ...error_message,
        companyNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_COMPANY_NAME,
      };
    } else if (!companyPattern.test(obj?.companyName)) {
      fields = {
        ...fields,
        companyNameError: true,
      };
      error_message = {
        ...error_message,
        companyNameErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_COMPANY_NAME,
      };
    } else {
      fields = {
        ...fields,
        companyNameError: false,
      };
      error_message = {
        ...error_message,
        companyNameErrorMessage: "",
      };
    }
  }

  if (typeof obj?.ico !== "undefined") {
    if (obj?.ico === "") {
      fields = {
        ...fields,
        icoError: true,
      };
      error_message = {
        ...error_message,
        icoErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_ICO,
      };
    } else if (!icoPattern.test(obj?.ico)) {
      fields = {
        ...fields,
        icoError: true,
      };
      error_message = {
        ...error_message,
        icoErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_ICO,
      };
    } else {
      fields = {
        ...fields,
        icoError: false,
      };
      error_message = {
        ...error_message,
        icoErrorMessage: "",
      };
    }
  }

  if (typeof obj?.address !== "undefined") {
    if (obj?.address === "") {
      fields = {
        ...fields,
        addressError: true,
      };
      error_message = {
        ...error_message,
        addressErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_ADDRESS,
      };
    } else if (!companyPattern.test(obj?.address)) {
      fields = {
        ...fields,
        addressError: true,
      };
      error_message = {
        ...error_message,
        addressErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_ADDRESS,
      };
    } else {
      fields = {
        ...fields,
        addressError: false,
      };
      error_message = {
        ...error_message,
        addressErrorMessage: "",
      };
    }
  }

  if (typeof obj?.city !== "undefined") {
    if (obj?.city === "") {
      fields = {
        ...fields,
        cityError: true,
      };
      error_message = {
        ...error_message,
        cityErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_CITY,
      };
    } else if (!cityPattern.test(obj?.city)) {
      fields = {
        ...fields,
        cityError: true,
      };
      error_message = {
        ...error_message,
        cityErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_CITY,
      };
    } else {
      fields = {
        ...fields,
        cityError: false,
      };
      error_message = {
        ...error_message,
        cityErrorMessage: "",
      };
    }
  }

  if (typeof obj?.zipCode !== "undefined") {
    if (obj?.zipCode === "") {
      fields = {
        ...fields,
        zipCodeError: true,
      };
      error_message = {
        ...error_message,
        zipCodeErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_ZIP_CODE,
      };
    } else if (!zipPattern.test(obj?.zipCode)) {
      fields = {
        ...fields,
        zipCodeError: true,
      };
      error_message = {
        ...error_message,
        zipCodeErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_ZIP_CODE,
      };
    } else {
      fields = {
        ...fields,
        zipCodeError: false,
      };
      error_message = {
        ...error_message,
        zipCodeErrorMessage: "",
      };
    }
  }

  if (typeof obj?.country !== "undefined") {
    if (obj?.country === "" || obj?.country === null) {
      fields = {
        ...fields,
        countryError: true,
      };
      error_message = {
        ...error_message,
        countryErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_COUNTRY,
      };
    } else {
      fields = {
        ...fields,
        countryError: false,
      };
      error_message = {
        ...error_message,
        countryErrorMessage: "",
      };
    }
  }

  if (typeof obj?.address2 !== "undefined") {
    if (obj?.address2 === "") {
      fields = {
        ...fields,
        address2Error: true,
      };
      error_message = {
        ...error_message,
        address2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_ADDRESS,
      };
    } else if (!companyPattern.test(obj?.address2)) {
      fields = {
        ...fields,
        address2Error: true,
      };
      error_message = {
        ...error_message,
        address2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_ADDRESS,
      };
    } else {
      fields = {
        ...fields,
        address2Error: false,
      };
      error_message = {
        ...error_message,
        address2ErrorMessage: "",
      };
    }
  }

  if (typeof obj?.city2 !== "undefined") {
    if (obj?.city2 === "") {
      fields = {
        ...fields,
        city2Error: true,
      };
      error_message = {
        ...error_message,
        city2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_CITY,
      };
    } else if (!pattern.test(obj?.city2)) {
      fields = {
        ...fields,
        city2Error: true,
      };
      error_message = {
        ...error_message,
        city2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_CITY,
      };
    } else {
      fields = {
        ...fields,
        city2Error: false,
      };
      error_message = {
        ...error_message,
        city2ErrorMessage: "",
      };
    }
  }

  if (typeof obj?.zipCode2 !== "undefined") {
    if (obj?.zipCode2 === "") {
      fields = {
        ...fields,
        zipCode2Error: true,
      };
      error_message = {
        ...error_message,
        zipCode2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_ZIP_CODE,
      };
    } else if (!zipPattern.test(obj?.zipCode2)) {
      fields = {
        ...fields,
        zipCode2Error: true,
      };
      error_message = {
        ...error_message,
        zipCode2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_ZIP_CODE,
      };
    } else {
      fields = {
        ...fields,
        zipCode2Error: false,
      };
      error_message = {
        ...error_message,
        zipCode2ErrorMessage: "",
      };
    }
  }

  if (typeof obj?.country2 !== "undefined") {
    if (obj?.country2 === "" || obj?.country2 === null) {
      fields = {
        ...fields,
        country2Error: true,
      };
      error_message = {
        ...error_message,
        country2ErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_COUNTRY,
      };
    } else {
      fields = {
        ...fields,
        country2Error: false,
      };
      error_message = {
        ...error_message,
        country2ErrorMessage: "",
      };
    }
  }

  if (typeof obj?.agreeTermsChecked !== "undefined") {
    if (!obj?.agreeTermsChecked) {
      fields = {
        ...fields,
        agreeTermsCheckedError: true,
      };
      error_message = {
        ...error_message,
        agreeTermsCheckedErrorMessage: obj?.agreeTermsCheckout
          ? INPUT_FIELD_ERROR_MESSAGE.AGREE_TERMS_DOES_NOT_CHECKED_CHECKOUT
          : INPUT_FIELD_ERROR_MESSAGE.AGREE_TERMS_DOES_NOT_CHECKED_SIGN_UP,
      };
    } else {
      fields = {
        ...fields,
        agreeTermsCheckedError: false,
      };
      error_message = {
        ...error_message,
        agreeTermsCheckedErrorMessage: "",
      };
    }
  }

  if (typeof obj?.passwordSignIn !== "undefined") {
    if (obj?.passwordSignIn === "") {
      fields = {
        ...fields,
        passwordSignInError: true,
      };
      error_message = {
        ...error_message,
        passwordSignInErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_PASSWORD,
      };
    } else {
      fields = {
        ...fields,
        passwordSignInError: false,
      };
      error_message = {
        ...error_message,
        passwordSignInErrorMessage: "",
      };
    }
  }

  if (typeof obj?.paymentMethod !== "undefined") {
    if (obj?.paymentMethod === "") {
      fields = {
        ...fields,
        paymentMethodError: true,
      };
      error_message = {
        ...error_message,
        paymentMethodErrorMessage: PAYMENT_ERROR_MESSAGE.EMPTY_PAYMENT_METHOD,
      };
    } else {
      fields = {
        ...fields,
        paymentMethodError: false,
      };
      error_message = {
        ...error_message,
        paymentMethodErrorMessage: "",
      };
    }
  }

  if (typeof obj?.cardHolder !== "undefined") {
    if (obj?.cardHolder === "") {
      fields = {
        ...fields,
        cardHolderError: true,
      };
      error_message = {
        ...error_message,
        cardHolderErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_CARDHOLDER,
      };
    } else if (!cardHolder.test(obj?.cardHolder)) {
      fields = {
        ...fields,
        cardHolderError: true,
      };
      error_message = {
        ...error_message,
        cardHolderErrorMessage: INPUT_FIELD_ERROR_MESSAGE.ERROR_CARDHOLDER,
      };
    } else {
      fields = {
        ...fields,
        cardHolderError: false,
      };
      error_message = {
        ...error_message,
        cardHolderErrorMessage: "",
      };
    }
  }

  if (typeof obj?.addProduct !== "undefined") {
    if (obj?.addProduct === "") {
      fields = {
        ...fields,
        addProductError: true,
      };
      error_message = {
        ...error_message,
        addProductErrorMessage: INPUT_FIELD_ERROR_MESSAGE.EMPTY_CODE_PRODUCT,
      };
    } else {
      fields = {
        ...fields,
        addProductError: false,
      };
      error_message = {
        ...error_message,
        addProductErrorMessage: "",
      };
    }
  }

  fields = {
    ...fields,
    isValidForm: Object.values(fields).every((item) => item === false),
  };

  return { fields, error_message };
}
