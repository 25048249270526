export class ValidationError extends Error {
  constructor(message, status, name = "validation") {
    super();
    this.message = message;
    this.status = status;
    this.name = name;
  }
}

export const isValid = (variable) => {
  if (variable === null || variable === undefined) {
    return false;
  }
  return true;
};

export const isFalsy = (variable) => {
  return variable === null || variable === undefined;
};
