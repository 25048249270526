import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function cookieDialog(
  state = initialState.cookieDialog,
  action
) {
  switch (action.type) {
    case types.COOKIEDIALOG:
      return { ...state, ...action.payload };
    case types.DEFAULT_COOKIEDIALOG:
      return initialState.cookieDialog;
    default:
      return state;
  }
}
