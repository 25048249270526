import { Grid } from "@mui/material";

// import loadingImage from
export default function FallbackLoader() {
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{
      minHeight: "100vh",
    }}
  >
    <Grid item xs={3}>
      {/* TODO loading gif   */}
      {/* <img src={loadingImage } alt="loading gif " width={100px}/> */}
    </Grid>
  </Grid>;
}
