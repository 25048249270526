import "./NavAppBar.css";

import { NAVIGATION_BAR, SEARCH } from "../redux/actions/actionTypes";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";

import AccountButton from "../components/account/AccountButton";
import AppBar from "@mui/material/AppBar";
import BasketIcon from "../components/basket/BasketIconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import GetMenu from "../components/menu/getMenu";
import { HashLink } from "react-router-hash-link";
import IconButton from "@mui/material/IconButton";
import LogoContainer from "../components/logo/LogoContainer";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ProductMenu from "../components/menu/ProductMenu";
import SearchDialog from "../components/search/searchDialog/SearchDialog";
import SearchField from "./SearchField";
import SearchIcon from "@mui/icons-material/Search";
import SlideMenuDrawer from "./slideMenu/SlideMenuDrawer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  if (props.menuOpen) {
    return React.cloneElement(children, {
      elevation: 0,
    });
  }

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function NavAppBar({ sx }) {
  const menuOpen = useSelector((state) => state?.navBarMenu?.menuOpen);
  const appBarHeight = useSelector((state) => state?.navBarMenu?.height);
  const pages = [
    { label: "O nás", section: "aboutus" } /* , "Produkty" */,
    { label: "Kontakt", section: "contact" },
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallestScreen = useMediaQuery(theme.breakpoints.down("460"));
  const refContainer = useRef();

  //TODO
  // dispatch({
  //   type: DEFAULT_NAVIGATION_BAR,
  // });

  GetMenu();
  useEffect(() => {
    if (refContainer.current) {
      dispatch({
        type: NAVIGATION_BAR,
        payload: {
          height: refContainer.current.offsetHeight,
        },
      });
    }
  });

  const openSlideMenu = () => {
    dispatch({
      type: NAVIGATION_BAR,
      payload: { slideMenuVisible: true },
    });
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -appBarHeight;

    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const toggleSearch = () => {
    if (location?.pathname !== "/search") {
      dispatch({
        type: SEARCH,
        payload: {
          isOpenDialog: true,
        },
      });
    }
  };

  return (
    <>
      <SearchDialog />
      {isSmallestScreen ? (
        <>
          <ElevationScroll>
            <AppBar position="sticky" sx={sx} ref={refContainer}>
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                    <LogoContainer
                      sx={{ maxWidth: "200px", pt: "13px", m: "auto" }}
                    />
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Box
                        sx={{
                          display: { xs: "flex" },
                        }}
                      >
                        <IconButton
                          size="large"
                          onClick={openSlideMenu}
                          color="inherit"
                        >
                          <MenuRoundedIcon
                            sx={{ color: "#757575" }}
                            fontSize="medium"
                          />
                        </IconButton>
                      </Box>

                      <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Hľadaj">
                          <IconButton
                            size="large"
                            onClick={toggleSearch}
                            sx={{ mx: "3px" }}
                          >
                            <SearchIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                        <BasketIcon sx={{ mx: "3px" }} />
                        <AccountButton sx={{ mx: "3px" }} />
                      </Box>
                    </Box>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </ElevationScroll>
          <SlideMenuDrawer />
        </>
      ) : isSmallScreen ? (
        <>
          <ElevationScroll>
            <AppBar position="sticky" sx={sx} ref={refContainer}>
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Box sx={{ flexGrow: 0 }}>
                      <IconButton size="large" onClick={openSlideMenu}>
                        <MenuRoundedIcon
                          sx={{ color: "#757575" }}
                          fontSize="medium"
                        />
                      </IconButton>
                    </Box>
                    <LogoContainer sx={{ maxWidth: "200px", pt: "13px" }} />
                    {/* TODO for LogoContainer margin-left: 110px; */}
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title="Hľadaj">
                        <IconButton
                          size="large"
                          onClick={toggleSearch}
                          sx={{ mx: "3px" }}
                        >
                          <SearchIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <BasketIcon sx={{ mx: "3px" }} />
                      <AccountButton sx={{ mx: "3px" }} />
                    </Box>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </ElevationScroll>
          <SlideMenuDrawer />
        </>
      ) : (
        <>
          <ElevationScroll menuOpen={menuOpen}>
            <AppBar position="sticky" sx={sx} ref={refContainer}>
              <Container maxWidth={false}>
                <Toolbar disableGutters>
                  <LogoContainer sx={{ maxWidth: "200px", pt: "6px" }} />
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}
                    justifyContent="left"
                    alignContent={"center"}
                    ml={4}
                  >
                    <ProductMenu />
                    {pages.map((page) => (
                      <HashLink
                        smooth
                        to={"/#" + page.section}
                        key={page.label}
                        scroll={scrollWithOffset}
                        className="MenuLink"
                      >
                        <Button
                          key={page.label}
                          sx={{
                            mr: 1,
                            ml: 1,
                            color: "black",
                            display: "block",
                            "&:hover": {
                              backgroundColor: "unset",
                            },
                          }}
                          className="MenuButton classes.button"
                        >
                          {page.label}
                        </Button>
                      </HashLink>
                    ))}
                  </Box>
                  <SearchField />
                  <Box sx={{ flexGrow: 0 }} ml={2}>
                    <BasketIcon />
                    <AccountButton />
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </ElevationScroll>
        </>
      )}
    </>
  );
}
