export const MegaMenuObj = [
  {
    group_name: "Automate",
    X_fullName: "Automate",
    X_URL: "products#automate",
    picturelink: "mimes/img/00030793_1x1_b408.jpg",
    items: [
      {
        id: "6II0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\HMIs and industrial PCs",
        text: "HMIs and industrial PCs",
        X_fullName: "HMIs and industrial PCs",
        X_Description:
          "Automation starts at the interface between human and machine. Efficient production from the start \u2013 with our HMIs and industrial PCs.",
        X_URL: "hmis-and-industrial-pcs",
        X_THUMBNAIL_ALT: "HMIs and industrial PCs",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0068631_int.jpg",
      },
      {
        id: "HII0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Industrial communication technology",
        text: "Industrial communication technology",
        X_fullName: "Industrial communication technology",
        X_Description:
          "Our interface devices provide consistent protection for your sensitive data interfaces and so enable you to increase the availability of your systems.",
        X_URL: "industrial-communication",
        X_THUMBNAIL_ALT: "Industrial communication technology",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0079961_int.jpg",
      },
      {
        id: "PWI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Software",
        text: "Software",
        X_fullName: "Software",
        X_Description:
          "Tailor made and user friendly: software from Phoenix Contact is your efficient and multifunctional tool for all automation tasks.",
        X_URL: "software",
        X_THUMBNAIL_ALT: "Software",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0048028_int.jpg",
      },
      {
        id: "XYI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\PLCs and I/O systems",
        text: "PLCs and I/O systems",
        X_fullName: "PLCs and I/O systems",
        X_Description:
          "All small-scale controllers, PLCs, and I/O systems for any automation task.",
        X_URL: "plcs-and-io-systems",
        X_THUMBNAIL_ALT: "PLCs and I/O systems",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088525_int.jpg",
      },
    ],
  },
  {
    group_name: "Connect",
    X_fullName: "Connect",
    X_URL: "products#connect",
    picturelink: "mimes/img/00011168_1x1_b408.jpg",
    items: [
      {
        id: "IFI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Electronics housing",
        text: "Electronics housing",
        X_fullName: "Electronics housing",
        X_Description:
          "Packaging as an all-round solution. Versatile electronics housings from Phoenix Contact transform your PCB into a compact device.",
        X_URL: "electronics-housing",
        X_THUMBNAIL_ALT: "Electronics housings",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088828_int.jpg",
      },
      {
        id: "JMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Cables and lines",
        text: "Cables and lines",
        X_fullName: "Cables and lines",
        X_Description:
          "Are you looking for a flexible assembly solution? With the right copper or FO cable, you'll find the perfect solution for your application.",
        X_URL: "cables-and-lines-main",
        X_THUMBNAIL_ALT: "Cables and lines",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042801_int.jpg",
      },
      {
        id: "QMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\PCB terminal blocks and connectors",
        text: "PCB terminal blocks and connectors",
        X_fullName: "PCB terminal blocks and connectors",
        X_Description:
          "Everything is possible with arguably the largest range of PCB connection technology: COMBICON from Phoenix Contact.",
        X_URL: "pcb-terminal-blocks-connectors",
        X_THUMBNAIL_ALT: "PCB terminal blocks and PCB connectors",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088802_int.jpg",
      },
      {
        id: "XQI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Modular terminal blocks",
        text: "Modular terminal blocks",
        X_fullName: "Modular terminal blocks",
        X_Description:
          "Different connection technologies give you greater flexibility \u2013 in both the configuration and design of the control cabinet.",
        X_URL: "modular-terminal-blocks",
        X_THUMBNAIL_ALT: "Terminal blocks",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0065446_int.jpg",
      },
      {
        id: "TVI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Sensor/actuator cabling",
        text: "Sensor/actuator cabling",
        X_fullName: "Sensor/actuator cabling",
        X_Description:
          "Connect your sensors and actuators quickly and safely in the field.",
        X_URL: "sensor-actuator-cabling",
        X_THUMBNAIL_ALT: "Sensor/actuator cabling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042807_int.jpg",
      },
      {
        id: "6XI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Connectors",
        text: "Connectors",
        X_fullName: "Connectors",
        X_Description:
          "We not only offer a comprehensive selection of connectors, but also outstanding quality, expertise, and design-in support.",
        X_URL: "connectors",
        X_THUMBNAIL_ALT: "Connectors",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084482_int.jpg",
      },
      {
        id: "X0J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\System cabling for controllers",
        text: "System cabling for controllers",
        X_fullName: "System cabling for controllers",
        X_Description:
          "Quick, clear, error-free: the perfect fit for wiring single wires to your control unit at field level.",
        X_URL: "system-cabling-for-controllers",
        X_THUMBNAIL_ALT: "System cabling for controllers",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0078896_int.jpg",
      },
    ],
  },
  {
    group_name: "Mark, Assemble and Install",
    X_fullName: "Mark, Assemble and Install",
    X_URL: "products#mark-assemble-install",
    picturelink: "mimes/img/00169450_1x1_b408.jpg",
    items: [
      {
        id: "BLI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Installation and mounting material",
        text: "Installation and mounting material",
        X_fullName: "Installation and mounting material",
        X_Description:
          "Space saving and extremely easy. Our installation and mounting material stands for modern control cabinet technology.",
        X_URL: "installation-mounting-material",
        X_THUMBNAIL_ALT: "Installation and mounting material",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0044986_int.jpg",
      },
      {
        id: "ZMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Marking and labeling",
        text: "Marking and labeling",
        X_fullName: "Marking and labeling",
        X_Description:
          "The easy way of marking. \u2013 Our solutions for marking and labeling can be tailored to your specific requirements.",
        X_URL: "marking-and-labeling",
        X_THUMBNAIL_ALT: "Marking and labeling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084449_int.jpg",
      },
      {
        id: "D2J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Tools",
        text: "Tools",
        X_fullName: "Tools",
        X_Description:
          "Using the right tool is key to achieving optimum results. Our products are all about quality, precision, and durability.",
        X_URL: "tools",
        X_THUMBNAIL_ALT: "Tools",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084450_int.jpg",
      },
      {
        id: "D3J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Lighting and signaling",
        text: "Lighting and signaling",
        X_fullName: "Lighting and signaling",
        X_Description:
          "Whether you are dealing with the system state or product quality \u2013 with intelligent lighting and signaling technology, you always have everything within view.",
        X_URL: "lighting-and-signaling",
        X_THUMBNAIL_ALT: "Lighting and signaling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0057150_int.jpg",
      },
    ],
  },
  {
    group_name: "Supply, Charge and Protect",
    X_fullName: "Supply, Charge and Protect",
    X_URL: "products#supply-charge-protect",
    picturelink: "mimes/img/00042194_1x1_b408.jpg",
    items: [
      {
        id: "TGI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Electronic switchgear and motor control",
        text: "Electronic switchgear and motor control",
        X_fullName: "Electronic switchgear and motor control",
        X_Description:
          "Do not take any risks: protect your motors and systems with durable motor controls and switching devices.",
        X_URL: "slectronic-switchgear-and-motor",
        X_THUMBNAIL_ALT: "Electronic switching devices and motor control",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088904_int.jpg",
      },
      {
        id: "HVI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Protective devices",
        text: "Protective devices",
        X_fullName: "Protective devices",
        X_Description:
          "Do not take any chances: protective devices provide optimum protection for your cables, systems, and devices.",
        X_URL: "protective-devices",
        X_THUMBNAIL_ALT: "Protective devices",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042861_int.jpg",
      },
      {
        id: "LZI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Power supply units and UPS",
        text: "Power supply units and UPS",
        X_fullName: "Power supply units and UPS",
        X_Description:
          "Do you want to run your system without interruption? Then you can fully rely on our power supplies to do the job.",
        X_URL: "power-supply-units-and-ups",
        X_THUMBNAIL_ALT: "Power supplies and UPS",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084371_int.jpg",
      },
      {
        id: "91J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Surge protection and interference filters",
        text: "Surge protection and interference filters",
        X_fullName: "Surge protection and interference filters",
        X_Description:
          "Ensure that operation is not interrupted. Our surge protective devices and interference filters provide effective protection for your systems.",
        X_URL: "surge-protection-and-filters",
        X_THUMBNAIL_ALT: "Surge protection and interference filters",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0059732_int.jpg",
      },
      {
        id: "T3J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Charging technology for E-Mobility",
        text: "Charging technology for E-Mobility",
        X_fullName: "Charging technology for E-Mobility",
        X_Description:
          "We offer charging technology that is setting new standards in electromobility.",
        X_URL: "charging-technology-e-mobility",
        X_THUMBNAIL_ALT: "Charging technology for electromobility",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0074360_int.jpg",
      },
    ],
  },
  {
    group_name: "Switch, Measure and Monitor",
    X_fullName: "Switch, Measure and Monitor",
    X_URL: "products#switch-measure-monitor",
    picturelink: "mimes/img/00013499_1x1_b408.jpg",
    items: [
      {
        id: "CHI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Functional Safety",
        text: "Functional Safety",
        X_fullName: "Functional Safety",
        X_Description:
          "For Phoenix Contact, safety does not just mean safe operation, it also means straightforward handling. Comprehensive and tested protection for personnel and machinery.",
        X_URL: "functional-safety",
        X_THUMBNAIL_ALT: "Functional safety",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0060348_int.jpg",
      },
      {
        id: "YNI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\MCR technology",
        text: "MCR technology",
        X_fullName: "MCR technology",
        X_Description:
          "Measurement and control components from Phoenix Contact offer innovative solutions for the automation of your processes \u2013 from sensor level to control level.",
        X_URL: "mcr-technology",
        X_THUMBNAIL_ALT: "Measurement and control technology",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0076657_int.jpg",
      },
      {
        id: "KPI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Monitoring",
        text: "Monitoring",
        X_fullName: "Monitoring",
        X_Description:
          "Safeguard your system operation and prevent system failure: with our monitoring products you can detect errors before they become a problem.",
        X_URL: "monitoring",
        X_THUMBNAIL_ALT: "Monitoring",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042344_int.jpg",
      },
      {
        id: "UUI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Relay modules",
        text: "Relay modules",
        X_fullName: "Relay modules",
        X_Description:
          "Whether switching, isolating or monitoring. Whatever the function you require \u2013 we offer custom relay modules.",
        X_URL: "relay-modules",
        X_THUMBNAIL_ALT: "Relay modules",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0051197_int.jpg",
      },
    ],
  },
  {
    id: "A4J0000101",
    parent_id: "HFI0000101",
    FULLPATH: "PhoenixContact\\null",
    text: "null",
    X_fullName: "Others",
    X_Description: "",
    X_URL: "others",
    X_THUMBNAIL_ALT: "",
    picturelink: "mimes/img/00003336_1x1_b408.jpg",
  },
];
