import CookieDialog from "./CookieDialog";
import { DEFAULT_COOKIEDIALOG } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function CheckCookie() {
  const dispatch = useDispatch();
  const location = useLocation();
  const acceptedCookies = localStorage.getItem("acceptedCookies");
  useEffect(() => {
    if (!acceptedCookies) {
      dispatch({
        type: DEFAULT_COOKIEDIALOG,
      });
    }
    // eslint-disable-next-line
  }, [location?.pathname]);

  return <CookieDialog />;
}
