import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function productPiece(
  state = initialState.productPiece,
  action
) {
  switch (action.type) {
    case types.PRODUCT_PIECE:
      return { ...state, ...action.payload };
    case types.DEFAULT_PRODUCT_PIECE:
      return initialState.productPiece;
    default:
      return state;
  }
}
