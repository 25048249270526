import { PRODUCT_MENU, SLIDE_MENU } from "../../redux/actions/actionTypes";

import { MegaMenuObj } from "./fenixsk_menu";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function GetMenu() {
  const dispatch = useDispatch();
  //   TODO
  // eslint-disable-next-line
  // const queryResult = useQuery(["Menu"], () => getMenu(), {
  //   onSuccess: (data) => {
  //     createMenu(data);
  //     dispatch({
  //       type: PRODUCT_MENU,
  //       payload: {
  //         items: menuObj,
  //       },
  //     });
  //     dispatch({
  //       type: SLIDE_MENU,
  //       payload: {
  //         menuItems: menuObj,
  //         menuItemsHistory: "",
  //         showBackButton: "none",
  //       },
  //     });
  //   },
  // });
  //   let menuObj;

  useEffect(() => {
    // createMenu(MegaMenuObj);

    dispatch({
      type: PRODUCT_MENU,
      payload: {
        // items: menuObj,
        items: MegaMenuObj,
      },
    });
    dispatch({
      type: SLIDE_MENU,
      payload: {
        // menuItems: menuObj,
        menuItems: MegaMenuObj[0]?.items,
        menuItemsHistory: "",
        showBackButton: "none",
      },
    });

    // eslint-disable-next-line
  }, []);

  //TODO
  // dispatch({
  //   type: DEFAULT_PRODUCT_MENU,
  // });
  // TODO
  // const createMenu = (data) => {
  //   const filtered = data.filter((obj) => {
  //     return obj.parent_id === null;
  //   });
  //   if (filtered.length !== 0) {
  //     menuObj = filtered;

  //     let id = filtered[0].id;
  //     let children = findChildren(id, data);
  //     if (children !== undefined) {
  //       let subMenu = children;
  //       menuObj[0].items = subMenu;
  //     }
  //   }
  // };

  // const findChildren = (id, data) => {
  //   const filtered = data.filter((obj) => {
  //     return obj.parent_id === id;
  //   });

  //   if (filtered.length !== 0) {
  //     let subMenu = filtered;

  //     filtered.forEach((child, index) => {
  //       let id = child.id;
  //       let children = findChildren(id, data);
  //       if (children !== undefined) {
  //         let childrenSubMenu = children;
  //         child.items = childrenSubMenu;
  //       }
  //     });
  //     return subMenu;
  //   }
  //   return undefined;
  // };
}
