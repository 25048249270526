import { Fab, Fade, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function ScrollToTop() {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {backToTopButton && (
        <Fade in={backToTopButton}>
          <Tooltip title="Späť na začiatok" placement="bottom">
            <Box
              onClick={scrollUp}
              role="presentation"
              sx={{ position: "fixed", bottom: 46, right: 16, zIndex: 1250 }}
            >
              <Fab size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
            </Box>
          </Tooltip>
        </Fade>
      )}
    </>
  );
}
