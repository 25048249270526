import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function category(state = initialState.category, action) {
  switch (action.type) {
    case types.CATEGORY:
      return { ...state, ...action.payload };
    case types.DEFAULT_CATEGORY:
      return initialState.category;
    default:
      return state;
  }
}
