import { DASHBOARD, DEFAULT_TESTING } from "../../../redux/actions/actionTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageLayout from "../../../layout/PageLayout";
import SnackBarAlert from "../../snackBar/snackBarAlert";
import TestLandingPage from "../../testLandingPage/TestLandingPage";
import VerifyAuthToken from "../../authenticate/VerifyAuthToken";
import { authenticate } from "../../../api/apiCalls";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

export const OpenRoute = ({ component: Component, test }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const dashboardMenu = useSelector((state) => state?.dashboard?.menu);
  const testToken = useSelector((state) => state?.testing?.token);
  const snackBarAlert = useSelector((state) => state?.snackBarAlert);

  useEffect(() => {
    if (dashboardMenu !== "") {
      dispatch({
        type: DASHBOARD,
        payload: {
          menu: "",
        },
      });
    }
    // eslint-disable-next-line
  }, [dashboardMenu]);

  // Added for testing verify test token
  useEffect(() => {
    if (testToken && test) {
      refetch();
    }
    // eslint-disable-next-line
  }, [location]);

  // Added for testing verify test token
  const { refetch } = useQuery(
    ["testAuthentication", testToken],
    () => authenticate(testToken),
    {
      enabled: false,
      onError: (error) => {
        dispatch({
          type: DEFAULT_TESTING,
        });
      },
    }
  );

  // TODO Added testing verification for public beta test
  if (test) {
    if (testToken) {
      //refetch();
      return (
        <>
          <VerifyAuthToken />
          <PageLayout>
            <Component />
          </PageLayout>
        </>
      );
    } else {
      return (
        <>
          {snackBarAlert?.isAlert && (
            <>
              <SnackBarAlert
                data={snackBarAlert?.data}
                messageObj={snackBarAlert?.messageObj}
                type={snackBarAlert?.type}
              />
            </>
          )}
          <TestLandingPage />;
        </>
      );
    }
  } else {
    return (
      <>
        <VerifyAuthToken />
        <PageLayout>
          <Component />
        </PageLayout>
      </>
    );
  }
};
