import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function checkout(state = initialState.checkout, action) {
  switch (action.type) {
    case types.CHECKOUT:
      return { ...state, ...action.payload };
    case types.DEFAULT_CHECKOUT:
      return initialState.checkout;
    default:
      return state;
  }
}
