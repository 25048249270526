import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ROLE_NAMES, ROUTE_URLS } from "../../config/constants";

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MenuList() {
  const navigate = useNavigate();
  const sessionInfo = useSelector((state) => state?.sessionInfo);
  const fullName = sessionInfo?.firstName + " " + sessionInfo?.lastName;
  const dashboardMenu = useSelector((state) => state?.dashboard?.menu);

  const handleListItemClick = (url) => {
    setTimeout(() => {
      navigate(url);
    }, 300);
  };

  const style = {
    "&.Mui-selected": {
      color: "primary.main",
      backgroundColor: "#f3fbfc",
      borderLeft: "4px solid",
    },
    "&:hover": {
      backgroundColor: "#ddeeef",
    },
  };

  return (
    <>
      <List>
        <ListItem key={"profile"}>
          <ListItemIcon>
            <Avatar fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={fullName}
            secondary={
              sessionInfo?.role === "admin"
                ? ROLE_NAMES?.admin
                : ROLE_NAMES?.customer
            }
          />
        </ListItem>
      </List>
      <Divider variant="middle" light />
      <List>
        <ListItem key={"Dashboard"} disablePadding>
          <ListItemButton
            id={"Dashboard"}
            selected={dashboardMenu === "Dashboard"}
            onClick={(event) => handleListItemClick(ROUTE_URLS.DASHBOARD)}
            sx={style}
          >
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"UpdateProfile"} disablePadding>
          <ListItemButton
            id={"UpdateProfile"}
            selected={dashboardMenu === "UpdateProfile"}
            onClick={(event) => handleListItemClick(ROUTE_URLS.PROFILE)}
            sx={style}
          >
            <ListItemText primary={"Profil"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"ChangePassword"} disablePadding>
          <ListItemButton
            id={"ChangePassword"}
            selected={dashboardMenu === "ChangePassword"}
            onClick={(event) => handleListItemClick(ROUTE_URLS.CHANGE_PASSWORD)}
            sx={style}
          >
            <ListItemText primary={"Zmeniť heslo"} />
          </ListItemButton>
        </ListItem>
        {(sessionInfo?.role === "admin" || sessionInfo?.isSuperuser) && (
          <>
            <ListItem key={"EshopUsers"} disablePadding>
              <ListItemButton
                id={"EshopUsers"}
                selected={dashboardMenu === "EshopUsers"}
                onClick={(event) => handleListItemClick(ROUTE_URLS.ESHOP_USERS)}
                sx={style}
              >
                <ListItemText primary={"Používatelia"} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"Index"} disablePadding>
              <ListItemButton
                id={"Index"}
                selected={dashboardMenu === "Index"}
                onClick={(event) => handleListItemClick(ROUTE_URLS.INDEX)}
                sx={style}
              >
                <ListItemText primary={"Index"} />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
}
