import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_SEARCH } from "../../../redux/actions/actionTypes";
import { DEV_URL } from "../../../config/constants";
import Logo from "../../../logos/LOGO.jpg";

export default function CustomHits() {
  const resultData = useSelector((state) => state?.search?.result);
  const dispatch = useDispatch();

  const addDefaultImg = (ev) => {
    ev.target.src = Logo;
  };

  const truncate = (str) => {
    return str.length > 80 ? str.substring(0, 80) + "..." : str;
  };

  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
      {resultData?.map((card, index) => (
        <Card
          elevation={4}
          sx={{
            mx: 3,
            my: 1,
            display: "flex",
            "&:hover": {
              boxShadow: "1px 2px 8px 1px rgba(34, 126, 147, 0.67)",
              backgroundColor: "#ffffff",
            },
          }}
          key={card?.StoreCard_ID ? card?.StoreCard_ID?.ID : card?.ID}
        >
          <CardActionArea
            href={"/product/" + card?.ID}
            onClick={() =>
              dispatch({
                type: DEFAULT_SEARCH,
              })
            }
            //   TODO
            //   onClick={handleClose}
            //   href={
            //     "/product/" +
            //     (card?.StoreCard_ID ? card?.StoreCard_ID?.ID : card?.ID)
            //   }
            sx={{ display: "flex" }}
          >
            <CardMedia
              component="img"
              sx={{
                maxWidth: "200px",
                height: "auto",
                px: 4,
              }}
              image={
                card?.StoreCard_ID
                  ? card?.StoreCard_ID?.X_Image === ""
                    ? Logo
                    : DEV_URL?.PROD_IMG + "/" + card?.StoreCard_ID?.X_Image
                  : card?.X_Image === ""
                  ? Logo
                  : DEV_URL?.PROD_IMG + "/" + card?.X_Image
              }
              alt={card?.StoreCard_ID ? card?.StoreCard_ID?.Name : card?.Name}
              onError={addDefaultImg}
            />

            <CardContent sx={{ flexGrow: 1 }}>
              <Box>
                <Typography gutterBottom variant="h5" component="h2">
                  {card?.StoreCard_ID ? card?.StoreCard_ID?.Name : card?.Name}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography>
                  {card?.StoreCard_ID ? card?.StoreCard_ID?.Code : card?.Code}
                </Typography>
              </Box>
              <Box sx={{ minHeight: 72, mt: 2 }}>
                <Typography>
                  {truncate(
                    card?.StoreCard_ID ? card?.StoreCard_ID?.Note : card?.Note
                  )}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
}
