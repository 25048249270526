import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function dashboard(state = initialState.dashboard, action) {
  switch (action.type) {
    case types.DASHBOARD:
      return { ...state, ...action.payload };
    case types.DEFAULT_DASHBOARD:
      return initialState.dashboard;
    default:
      return state;
  }
}
