export const MegaMenuObj = [
  {
    id: "HFI0000101",
    parent_id: "null",
    FULLPATH: "PhoenixContact",
    text: "PhoenixContact",
    X_fullName: "PhoenixContact",
    X_Description: "",
    X_URL: "",
    X_THUMBNAIL_ALT: "",
    X_picturelink: "",
    items: [
      {
        id: "IFI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Electronics housing",
        text: "Electronics housing",
        X_fullName: "Electronics housing",
        X_Description:
          "Packaging as an all-round solution. Versatile electronics housings from Phoenix Contact transform your PCB into a compact device.",
        X_URL: "electronics-housing",
        X_THUMBNAIL_ALT: "Electronics housings",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088828_int.jpg",
        items: [
          {
            id: "JFI0000101",
            parent_id: "IFI0000101",
            FULLPATH: "PhoenixContact\\Electronics housing\\Field enclosure",
            text: "Field enclosure",
            X_fullName: "Field enclosure",
            X_Description:
              "IP-protected electronics housings provide ideal solutions for use in field applications.",
            X_URL: "field-enclosure",
            X_THUMBNAIL_ALT: "Field housings",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088830_int.jpg",
            items: [
              {
                id: "LFI0000101",
                parent_id: "JFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\DCS display carriers",
                text: "DCS display carriers",
                X_fullName: "DCS display carriers",
                X_Description:
                  "DCS series display carriers protect displays used in the field.",
                X_URL: "dcs-display-carriers",
                X_THUMBNAIL_ALT: "Product list DCS display carriers",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076692_int.jpg",
              },
              {
                id: "NFI0000101",
                parent_id: "JFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\ECS outdoor housings",
                text: "ECS outdoor housings",
                X_fullName: "ECS outdoor housings",
                X_Description:
                  "Electronics housings from the ECS series provide maximum protection for electronics in harsh areas of application.",
                X_URL: "ecs-outdoor-housings",
                X_THUMBNAIL_ALT: "Product list ECS outdoor housings",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076693_int.jpg",
              },
              {
                id: "PFI0000101",
                parent_id: "JFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\Profile housing HC-ALU",
                text: "Profile housing HC-ALU",
                X_fullName: "Profile housing HC-ALU",
                X_Description:
                  "HC-ALU aluminum profile housings protect electronics from splash water, as well as thermal and mechanical influences in the field.",
                X_URL: "profile-housing-hc-alu",
                X_THUMBNAIL_ALT: "Product list HC-ALU profile housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062281_int.jpg",
              },
              {
                id: "RFI0000101",
                parent_id: "JFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\Handheld housing HCS",
                text: "Handheld housing HCS",
                X_fullName: "Handheld housing HCS",
                X_Description:
                  "The electronics housings from the HCS series are ideally suited for mobile operator panels.",
                X_URL: "handheld-housing-hcs",
                X_THUMBNAIL_ALT: "Product list HCS handheld housings",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076690_int.jpg",
              },
              {
                id: "TFI0000101",
                parent_id: "JFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\Field enclosure\\UCS universal housings",
                text: "UCS universal housings",
                X_fullName: "UCS universal housings",
                X_Description:
                  "Universal housings from the UCS series are the ideal solution for embedded systems.",
                X_URL: "usc-universal-housings",
                X_THUMBNAIL_ALT: "Product list UCS universal housings",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076689_int.jpg",
              },
            ],
          },
          {
            id: "UFI0000101",
            parent_id: "IFI0000101",
            FULLPATH: "PhoenixContact\\Electronics housing\\DIN rail housing",
            text: "DIN rail housing",
            X_fullName: "DIN rail housing",
            X_Description:
              "From basic housings and multifunctional housings to products for Raspberry Pi computers: our DIN rail housings offer optimum solutions for your electronics.",
            X_URL: "din-rail-housing",
            X_THUMBNAIL_ALT: "DIN rail housings",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088829_int.jpg",
            items: [
              {
                id: "VFI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits",
                text: "Development kits",
                X_fullName: "Development kits",
                X_Description:
                  "Mount THT PCB components such as diodes, fuses or resistors on the DIN rail using the development kits for the electronics housings.",
                X_URL: "development-kits",
                X_THUMBNAIL_ALT: "Development kits",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0067004_int.jpg",
                items: [
                  {
                    id: "WFI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\BC development kit",
                    text: "BC development kit",
                    X_fullName: "BC development kit",
                    X_Description:
                      "Integrate your electronics for building automation into modern BC housing.",
                    X_URL: "bc-development-kit",
                    X_THUMBNAIL_ALT: "Product list BC development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067319_int.jpg",
                  },
                  {
                    id: "XFI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\EH development kit",
                    text: "EH development kit",
                    X_fullName: "EH development kit",
                    X_Description:
                      "Develop and design application-specific, compact device solutions with the EH development kit.",
                    X_URL: "eh-development-kit",
                    X_THUMBNAIL_ALT: "Product list EH development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067318_int.jpg",
                  },
                  {
                    id: "YFI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-IO development kit",
                    text: "ME-IO development kit",
                    X_fullName: "ME-IO development kit",
                    X_Description:
                      "The development kit from the ME-IO series can be used to develop a control and I/O system with a wide range of signal inputs and outputs.",
                    X_URL: "me-io-development-kit",
                    X_THUMBNAIL_ALT: "Product list ME-IO development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067317_int.jpg",
                  },
                  {
                    id: "ZFI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-MAX development kit",
                    text: "ME-MAX development kit",
                    X_fullName: "ME-MAX development kit",
                    X_Description:
                      "The ME-MAX development kit is ideal when the electronics need to be configured in a narrow space, but a large assembly area is required on the PCB.",
                    X_URL: "me-max-development-kit",
                    X_THUMBNAIL_ALT: "Product list ME-MAX development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067316_int.jpg",
                  },
                  {
                    id: "0GI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\ME-PLC development kit",
                    text: "ME-PLC development kit",
                    X_fullName: "ME-PLC development kit",
                    X_Description:
                      "The ME-PLC development kit offers maximum flexibility, lots of space for the electronics, and convenient connection options.",
                    X_URL: "me-plc-development-kit",
                    X_THUMBNAIL_ALT: "Product list ME-PLC development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067315_int.jpg",
                  },
                  {
                    id: "1GI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\UM-BASIC development kit",
                    text: "UM-BASIC development kit",
                    X_fullName: "UM-BASIC development kit",
                    X_Description:
                      "The UM-BASIC development kit is available for electronics with highly structured electronics components. The profile housing can be mounted quickly and easily by inserting the electronics and snapping on the side parts.",
                    X_URL: "um-basic-development-kit",
                    X_THUMBNAIL_ALT: "Product list UM-BASIC development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067320_int.jpg",
                  },
                  {
                    id: "2GI0000101",
                    parent_id: "VFI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronics housing\\DIN rail housing\\Development kits\\RPI-BC development kit",
                    text: "RPI-BC development kit",
                    X_fullName: "RPI-BC development kit",
                    X_Description:
                      "The RPI-BC development kit allows you to build your own minicomputer quickly and easily. The DIN rail housing from Phoenix Contact has been designed specifically to house Raspberry Pi PCBs.",
                    X_URL: "rpi-bc-development-kit",
                    X_THUMBNAIL_ALT: "Product list RPI-BC development kit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070224_int.jpg",
                  },
                ],
              },
              {
                id: "4GI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing BC",
                text: "Modular housing BC",
                X_fullName: "Modular housing BC",
                X_Description: "Electronics enclosure for building automation",
                X_URL: "modular-housing-bc",
                X_THUMBNAIL_ALT: "Product list Modular housings BC",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062277_int.jpg",
              },
              {
                id: "6GI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ICS",
                text: "Modular housing ICS",
                X_fullName: "Modular housing ICS",
                X_Description:
                  "ICS modular electronics housings are the basis for IoT devices of tomorrow.",
                X_URL: "modular-housing-ics",
                X_THUMBNAIL_ALT: "Product list Modular housings ICS",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0082205_int.jpg",
              },
              {
                id: "8GI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ME",
                text: "Modular housing ME",
                X_fullName: "Modular housing ME",
                X_Description:
                  "Modular housing system in easy-to-install cup shape",
                X_URL: "modular-housing-me",
                X_THUMBNAIL_ALT: "Product list ME modular housings",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0070221_int.jpg",
              },
              {
                id: "AGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Modular housing ME-MAX",
                text: "Modular housing ME-MAX",
                X_fullName: "Modular housing ME-MAX",
                X_Description:
                  "Modular housing with plenty of space in compact dimensions",
                X_URL: "modular-housing-me-max",
                X_THUMBNAIL_ALT: "Product list Modular housings ME-MAX",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062333_int.jpg",
              },
              {
                id: "CGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Multifunctional housings ME-IO",
                text: "Multifunctional housings ME-IO",
                X_fullName: "Multifunctional housings ME-IO",
                X_Description:
                  "Compact housing system with high-pos. front connection technology",
                X_URL: "multifunctional-housings-me-io",
                X_THUMBNAIL_ALT: "Product list ME-IO multifunctional housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062328_int.jpg",
              },
              {
                id: "EGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Multifunctional housings ME-PLC",
                text: "Multifunctional housings ME-PLC",
                X_fullName: "Multifunctional housings ME-PLC",
                X_Description: "The housing system for complex controllers",
                X_URL: "multifunctional-housings",
                X_THUMBNAIL_ALT: "Product list ME-PLC multifunctional housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051852_int.jpg",
              },
              {
                id: "GGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-BASIC",
                text: "Profile housing UM-BASIC",
                X_fullName: "Profile housing UM-BASIC",
                X_Description:
                  "Modular press-drawn section housings made from PVC, heat resistant up to 50\u00b0C. For tool-free device mounting.",
                X_URL: "um-basic-housing",
                X_THUMBNAIL_ALT:
                  "Product list UM-BASIC press-drawn section housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062332_int.jpg",
              },
              {
                id: "IGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-PRO",
                text: "Profile housing UM-PRO",
                X_fullName: "Profile housing UM-PRO",
                X_Description:
                  "Flexible housing systems for fast installation and special applications.",
                X_URL: "profile-housing-um-pro",
                X_THUMBNAIL_ALT:
                  "Product list UM-PRO press-drawn section housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062331_int.jpg",
              },
              {
                id: "KGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Profile housing UM-ALU",
                text: "Profile housing UM-ALU",
                X_fullName: "Profile housing UM-ALU",
                X_Description:
                  "Panel mounting bases made from aluminum, for use under thermal or electromagnetic loads.",
                X_URL: "profile-housing-um-alu",
                X_THUMBNAIL_ALT:
                  "Product list UM-ALU press-drawn section housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062330_int.jpg",
              },
              {
                id: "MGI0000101",
                parent_id: "UFI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\DIN rail housing\\Basic housing EH",
                text: "Basic housing EH",
                X_fullName: "Basic housing EH",
                X_Description:
                  "EH: Universal housing system with integrated connection technology",
                X_URL: "basic-housing-eh",
                X_THUMBNAIL_ALT: "Product list EH basic housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062278_int.jpg",
              },
            ],
          },
          {
            id: "NGI0000101",
            parent_id: "IFI0000101",
            FULLPATH:
              "PhoenixContact\\Electronics housing\\HMI components and accessories",
            text: "HMI components and accessories",
            X_fullName: "HMI components and accessories",
            X_Description:
              "HMI components and accessories: a wide range of accessories for maximum functionality",
            X_URL: "hmi-components-accessories",
            X_THUMBNAIL_ALT: "HMI components and accessories",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0081109_int.jpg",
            items: [
              {
                id: "PGI0000101",
                parent_id: "NGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\DIN rail adapter UTA",
                text: "DIN rail adapter UTA",
                X_fullName: "DIN rail adapter UTA",
                X_Description:
                  "Adapter plate made of plastic for securely fixing devices on standard DIN rails.",
                X_URL: "din-rail-adapter-uta",
                X_THUMBNAIL_ALT: "Product list UTA DIN rail adapters",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062295_int.jpg",
              },
              {
                id: "QGI0000101",
                parent_id: "NGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Displays",
                text: "Displays",
                X_fullName: "Displays",
                X_Description:
                  "Displays with screen diagonals of 2.4 to 15 inches for use in mobile and static electronics housings.",
                X_URL: "displays",
                X_THUMBNAIL_ALT: "Product list Displays",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0081647_int.jpg",
              },
              {
                id: "RGI0000101",
                parent_id: "NGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Membrane keypads",
                text: "Membrane keypads",
                X_fullName: "Membrane keypads",
                X_Description:
                  "Membrane keypads for time-saving integration into electronics housings.",
                X_URL: "membrane-keypads",
                X_THUMBNAIL_ALT: "Product list Membrane keypads",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0081648_en.jpg",
              },
              {
                id: "SGI0000101",
                parent_id: "NGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronics housing\\HMI components and accessories\\Fiber optic",
                text: "Fiber optic",
                X_fullName: "Fiber optic",
                X_Description:
                  "Light guides for space-saving implementation of status indicators.",
                X_URL: "fiber-optic",
                X_THUMBNAIL_ALT: "Product list Light guides",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0081649_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "TGI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Electronic switchgear and motor control",
        text: "Electronic switchgear and motor control",
        X_fullName: "Electronic switchgear and motor control",
        X_Description:
          "Do not take any risks: protect your motors and systems with durable motor controls and switching devices.",
        X_URL: "slectronic-switchgear-and-motor",
        X_THUMBNAIL_ALT: "Electronic switching devices and motor control",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088904_int.jpg",
        items: [
          {
            id: "UGI0000101",
            parent_id: "TGI0000101",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Motor starter",
            text: "Motor starter",
            X_fullName: "Motor starter",
            X_Description:
              "Hybrid motor starters consisting of direct and reversing starters available with a variety of functions such as emergency stop and motor protection.",
            X_URL: "motor-starter",
            X_THUMBNAIL_ALT: "Motor starters",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088914_int.jpg",
            items: [
              {
                id: "VGI0000101",
                parent_id: "UGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone",
                text: "Hybrid motor starters \u2013 Stand-alone",
                X_fullName: "Hybrid motor starters \u2013 Stand-alone",
                X_Description:
                  "Hybrid motor starters consisting of direct and reversing starters available with a variety of functions such as emergency stop and motor protection.",
                X_URL: "hybrid-motor-starters-stand",
                X_THUMBNAIL_ALT: "Hybrid motor starters - standalone",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0088920_int.jpg",
                items: [
                  {
                    id: "WGI0000101",
                    parent_id: "VGI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone\\Hybrid motor starters \u2013 Stand-alone",
                    text: "Hybrid motor starters \u2013 Stand-alone",
                    X_fullName: "Hybrid motor starters \u2013 Stand-alone",
                    X_Description:
                      "Switch motors safely and reliably with compact hybrid motor starters.",
                    X_URL: "hybrid-motor-starters-standalone",
                    X_THUMBNAIL_ALT:
                      "Product list Hybrid motor starters - standalone",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089088_int.jpg",
                  },
                  {
                    id: "XGI0000101",
                    parent_id: "VGI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Stand-alone\\Accessories for hybrid motor starters \u2013 Stand-alon",
                    text: "Accessories for hybrid motor starters \u2013 Stand-alon",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Stand-alon",
                    X_Description:
                      "This versatile set of accessories for hybrid motor starters makes handling, service, and installation easier for you.",
                    X_URL: "hybrid-motor-starters-acc",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for hybrid motor starters - standalone",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089089_int.jpg",
                  },
                ],
              },
              {
                id: "YGI0000101",
                parent_id: "UGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular",
                text: "Hybrid motor starters \u2013 Modular",
                X_fullName: "Hybrid motor starters \u2013 Modular",
                X_Description:
                  "CONTACTRON pro is the new product range from Phoenix Contact that offers simple safety integration and modular extension options. Based entirely on hybrid technology.",
                X_URL: "hybrid-motor-starters-mod",
                X_THUMBNAIL_ALT: "Modular hybrid motor starters",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0088933_int.jpg",
                items: [
                  {
                    id: "ZGI0000101",
                    parent_id: "YGI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular\\Hybrid motor starters \u2013 Modular",
                    text: "Hybrid motor starters \u2013 Modular",
                    X_fullName: "Hybrid motor starters \u2013 Modular",
                    X_Description:
                      "CONTACTRON pro is the new product range from Phoenix Contact that offers simple safety integration and modular extension options.",
                    X_URL: "hybrid-motor-starters-modular",
                    X_THUMBNAIL_ALT:
                      "Product list Hybrid motor starters \u2013 Modular",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089088_int.jpg",
                  },
                  {
                    id: "0HI0000101",
                    parent_id: "YGI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Modular\\Accessories for hybrid motor starters \u2013 Modular",
                    text: "Accessories for hybrid motor starters \u2013 Modular",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Modular",
                    X_Description:
                      "This versatile set of accessories for hybrid motor starters makes handling, service, and installation easier for you.",
                    X_URL: "hybrid-motor-starters-mod-acc",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for hybrid motor starters \u2013 Modular",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089089_int.jpg",
                  },
                ],
              },
              {
                id: "1HI0000101",
                parent_id: "UGI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable",
                text: "Hybrid motor starters \u2013 Network-capable",
                X_fullName: "Hybrid motor starters \u2013 Network-capable",
                X_Description:
                  "Integration into fieldbus systems using the INTERFACE system connection. Transfer process data and network devices quickly with the Interface system and the IO-Link versions.",
                X_URL: "hybrid-motor-starters-net",
                X_THUMBNAIL_ALT: "Hybrid motor starters \u2013 Network-capable",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0088950_int.jpg",
                items: [
                  {
                    id: "2HI0000101",
                    parent_id: "1HI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable\\Hybrid motor starters \u2013 Network-capable",
                    text: "Hybrid motor starters \u2013 Network-capable",
                    X_fullName: "Hybrid motor starters \u2013 Network-capable",
                    X_Description:
                      "Integration into fieldbus systems is implemented using the INTERFACE system connection. Corresponding gateways are available for all common fieldbus systems. Transfer your process data easily and network your devices quickly.",
                    X_URL: "hybrid-motor-starters-network",
                    X_THUMBNAIL_ALT:
                      "Product list Hybrid motor starters \u2013 Network-capable",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089091_int.jpg",
                  },
                  {
                    id: "3HI0000101",
                    parent_id: "1HI0000101",
                    FULLPATH:
                      "PhoenixContact\\Electronic switchgear and motor control\\Motor starter\\Hybrid motor starters \u2013 Network-capable\\Accessories for hybrid motor starters \u2013 Network-ca",
                    text: "Accessories for hybrid motor starters \u2013 Network-ca",
                    X_fullName:
                      "Accessories for hybrid motor starters \u2013 Network-ca",
                    X_Description:
                      "This versatile set of accessories for network-ready hybrid motor starters makes handling, service, and installation easier for you.",
                    X_URL: "net-hybrid-motor-starters-acc",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for hybrid motor starters - network-ready",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0089089_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "4HI0000101",
            parent_id: "TGI0000101",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management",
            text: "Motor and machine management",
            X_fullName: "Motor and machine management",
            X_Description:
              "Motor manager for protecting motors and systems, Electronic machine management for monitoring motors and machines",
            X_URL: "motor-and-machine-mngmt",
            X_THUMBNAIL_ALT: "Motor and machine management",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088958_int.jpg",
            items: [
              {
                id: "5HI0000101",
                parent_id: "4HI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management\\Motor and machine management",
                text: "Motor and machine management",
                X_fullName: "Motor and machine management",
                X_Description:
                  "The motor manager combines overload and underload detection in a single device. In the event of an emergency, it protects the motor and shuts down the drive. Electronic machine management combines precise power measurement with the display and monito",
                X_URL: "motor-and-machine-management",
                X_THUMBNAIL_ALT: "Product list Motor and machine management",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0089094_int.jpg",
              },
              {
                id: "6HI0000101",
                parent_id: "4HI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Motor and machine management\\Accessories for motor and machine management",
                text: "Accessories for motor and machine management",
                X_fullName: "Accessories for motor and machine management",
                X_Description:
                  "This versatile set of accessories for motor and machine management makes handling, service, and installation easier for you.",
                X_URL: "machine-management-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for motor and machine management",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0089098_int.jpg",
              },
            ],
          },
          {
            id: "7HI0000101",
            parent_id: "TGI0000101",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors",
            text: "Solid-state contactors",
            X_fullName: "Solid-state contactors",
            X_Description:
              "Wear-free and frequent switching with robust single or three-phase solid-state contactors.",
            X_URL: "solid-state-contactors-main",
            X_THUMBNAIL_ALT: "Solid-state contactors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088971_int.jpg",
            items: [
              {
                id: "8HI0000101",
                parent_id: "7HI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors\\Solid-state contactors",
                text: "Solid-state contactors",
                X_fullName: "Solid-state contactors",
                X_Description:
                  "Solid-state contactors as single or three-phase devices, also with reversing function depending on the type. With a practically unlimited service life, resistance to vibrations and harmful substances, they can be used anywhere.",
                X_URL: "solid-state-contactors",
                X_THUMBNAIL_ALT: "Product list Solid-state contactors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0089099_int.jpg",
              },
              {
                id: "9HI0000101",
                parent_id: "7HI0000101",
                FULLPATH:
                  "PhoenixContact\\Electronic switchgear and motor control\\Solid-state contactors\\Accessories for solid-state contactors",
                text: "Accessories for solid-state contactors",
                X_fullName: "Accessories for solid-state contactors",
                X_Description:
                  "This versatile set of accessories for solid-state contactors makes handling, service, and installation easier for you.",
                X_URL: "solid-state-contactor-acc",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for solid-state contactors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0089100_int.jpg",
              },
            ],
          },
          {
            id: "BHI0000101",
            parent_id: "TGI0000101",
            FULLPATH:
              "PhoenixContact\\Electronic switchgear and motor control\\Power distribution board",
            text: "Power distribution board",
            X_fullName: "Power distribution board",
            X_Description:
              "Not only can you set up motor starters reliably with the power distribution board, you can also implement modular and functional solutions.",
            X_URL: "power-distribution-board",
            X_THUMBNAIL_ALT: "Product list Power distribution board",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0089102_int.jpg",
          },
        ],
      },
      {
        id: "CHI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Functional Safety",
        text: "Functional Safety",
        X_fullName: "Functional Safety",
        X_Description:
          "For Phoenix Contact, safety does not just mean safe operation, it also means straightforward handling. Comprehensive and tested protection for personnel and machinery.",
        X_URL: "functional-safety",
        X_THUMBNAIL_ALT: "Functional safety",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0060348_int.jpg",
        items: [
          {
            id: "DHI0000101",
            parent_id: "CHI0000101",
            FULLPATH: "PhoenixContact\\Functional Safety\\Safety devices",
            text: "Safety devices",
            X_fullName: "Safety devices",
            X_Description:
              "Versatile safety relay modules with proven technology for various safety functions.",
            X_URL: "safety-devices",
            X_THUMBNAIL_ALT: "Safety relay modules",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0062937_int.jpg",
            items: [
              {
                id: "EHI0000101",
                parent_id: "DHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays",
                text: "Safety relays",
                X_fullName: "Safety relays",
                X_Description:
                  "Safe relays for safety functions, such as emergency stop, light grids or two-hand control devices.",
                X_URL: "safety-relays",
                X_THUMBNAIL_ALT: "Safety relays",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0063797_int.jpg",
                items: [
                  {
                    id: "GHI0000101",
                    parent_id: "EHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Conventional safety relays",
                    text: "Conventional safety relays",
                    X_fullName: "Conventional safety relays",
                    X_Description:
                      "Classical safety relays for basic functions, such as emergency stop, light grids, and two-hand control devices.",
                    X_URL: "conventional-safety-relays",
                    X_THUMBNAIL_ALT: "Product list Classical safety relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043936_int.jpg",
                  },
                  {
                    id: "IHI0000101",
                    parent_id: "EHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Highly compact safety relays",
                    text: "Highly compact safety relays",
                    X_fullName: "Highly compact safety relays",
                    X_Description:
                      "Highly compact safety relays for basic functions, such as emergency stop, door switches, and light grids.",
                    X_URL: "highly-compact-safety-relays",
                    X_THUMBNAIL_ALT:
                      "Product list Highly compact safety relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062949_int.jpg",
                  },
                  {
                    id: "JHI0000101",
                    parent_id: "EHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system",
                    text: "Modular safety relay system",
                    X_fullName: "Modular safety relay system",
                    X_Description:
                      "Adaptable safety system with DIN rail connector, master safety relay, and safety relays for numerous safety functions.",
                    X_URL: "modular-safety-relay",
                    X_THUMBNAIL_ALT: "Modular safety relay system",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0044026_int.jpg",
                    items: [
                      {
                        id: "KHI0000101",
                        parent_id: "JHI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system\\Modular safety relay system",
                        text: "Modular safety relay system",
                        X_fullName: "Modular safety relay system",
                        X_Description:
                          "Adaptable safety system with DIN rail connector, master safety relay, and safety relays for numerous safety functions.",
                        X_URL: "modular-safety-relay-system",
                        X_THUMBNAIL_ALT:
                          "Product list Modular safety relay system",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043939_int.jpg",
                      },
                      {
                        id: "LHI0000101",
                        parent_id: "JHI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safety relays\\Modular safety relay system\\Accessories for modular safety relay system",
                        text: "Accessories for modular safety relay system",
                        X_fullName:
                          "Accessories for modular safety relay system",
                        X_Description:
                          "Accessory list for the adaptable safety relay system with DIN rail connector.",
                        X_URL: "modular-safety-relay-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list Modular safety relay system accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043939_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "MHI0000101",
                parent_id: "DHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays",
                text: "Zero speed and overspeed safety relays",
                X_fullName: "Zero speed and overspeed safety relays",
                X_Description:
                  "Safety relay modules for reliably monitoring the speed and downtime of rotating parts in systems and machines.",
                X_URL: "speed-safety-relays-main",
                X_THUMBNAIL_ALT: "Zero-speed and over-speed safety relays",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069744_int.jpg",
                items: [
                  {
                    id: "NHI0000101",
                    parent_id: "MHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays\\Zero speed and overspeed safety relays",
                    text: "Zero speed and overspeed safety relays",
                    X_fullName: "Zero speed and overspeed safety relays",
                    X_Description:
                      "Safety devices for monitoring the speed and downtime of rotating parts in systems and machines.",
                    X_URL: "speed-safety-relays",
                    X_THUMBNAIL_ALT:
                      "Product list Zero-speed and over-speed safety relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0088034_int.jpg",
                  },
                  {
                    id: "OHI0000101",
                    parent_id: "MHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Zero speed and overspeed safety relays\\Accessories for zero speed and overspeed safety re",
                    text: "Accessories for zero speed and overspeed safety re",
                    X_fullName:
                      "Accessories for zero speed and overspeed safety re",
                    X_Description:
                      "Accessories for zero-speed and over-speed safety relays.",
                    X_URL: "speed-safety-relays-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for zero-speed and over-speed safety relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0088035_int.jpg",
                  },
                ],
              },
              {
                id: "PHI0000101",
                parent_id: "DHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays",
                text: "Safe coupling relays",
                X_fullName: "Safe coupling relays",
                X_Description:
                  "Relays with force-guided contacts for safe signal coupling.",
                X_URL: "safe-coupling-relays",
                X_THUMBNAIL_ALT: "Safe coupling relays",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062947_int.jpg",
                items: [
                  {
                    id: "QHI0000101",
                    parent_id: "PHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays",
                    text: "Classical safe coupling relays",
                    X_fullName: "Classical safe coupling relays",
                    X_Description:
                      "Coupling relays with force-guided contacts for safe signal coupling.",
                    X_URL: "classical-safe-coupling-rel",
                    X_THUMBNAIL_ALT: "Classic safe coupling relays",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0065620_int.jpg",
                    items: [
                      {
                        id: "RHI0000101",
                        parent_id: "QHI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays\\Classical safe coupling relays",
                        text: "Classical safe coupling relays",
                        X_fullName: "Classical safe coupling relays",
                        X_Description:
                          "Classical safe coupling relays for safe switching on and off.",
                        X_URL: "classical-safe-coupling-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Classical safe coupling relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043944_int.jpg",
                      },
                      {
                        id: "SHI0000101",
                        parent_id: "QHI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Classical safe coupling relays\\Accessories for classical safe coupling relays",
                        text: "Accessories for classical safe coupling relays",
                        X_fullName:
                          "Accessories for classical safe coupling relays",
                        X_Description:
                          "Accessories for classical safe coupling relays.",
                        X_URL: "safe-coupling-relays-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list Accessories for classical safe coupling relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0065855_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "THI0000101",
                    parent_id: "PHI0000101",
                    FULLPATH:
                      "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays",
                    text: "Highly compact, safe coupling relays",
                    X_fullName: "Highly compact, safe coupling relays",
                    X_Description:
                      "Highly compact, safe coupling relays for electrical isolation and power adaptation.",
                    X_URL: "compact-safe-coupling-rel",
                    X_THUMBNAIL_ALT: "Highly compact, safe coupling relays",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0062901_int.jpg",
                    items: [
                      {
                        id: "UHI0000101",
                        parent_id: "THI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays\\Highly compact, safe coupling relays",
                        text: "Highly compact, safe coupling relays",
                        X_fullName: "Highly compact, safe coupling relays",
                        X_Description:
                          "Highly compact, safe coupling relays for safe switching on and off.",
                        X_URL: "compact-safe-coupling-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Highly compact safe coupling relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062948_int.jpg",
                      },
                      {
                        id: "VHI0000101",
                        parent_id: "THI0000101",
                        FULLPATH:
                          "PhoenixContact\\Functional Safety\\Safety devices\\Safe coupling relays\\Highly compact, safe coupling relays\\Accessories for highly compact, safe coupling rela",
                        text: "Accessories for highly compact, safe coupling rela",
                        X_fullName:
                          "Accessories for highly compact, safe coupling rela",
                        X_Description:
                          "Accessories for highly compact, safe coupling relays.",
                        X_URL: "compact-safe-relays-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list Highly compact safe coupling relay accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0065854_int.jpg",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "WHI0000101",
            parent_id: "CHI0000101",
            FULLPATH:
              "PhoenixContact\\Functional Safety\\Configurable safety modules",
            text: "Configurable safety modules",
            X_fullName: "Configurable safety modules",
            X_Description:
              "Versatile safety modules for the DIN rail. For configuring multiple safety functions with just one device.",
            X_URL: "configurable-safety-modules-main",
            X_THUMBNAIL_ALT: "Configurable safety modules",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042255_int.jpg",
            items: [
              {
                id: "XHI0000101",
                parent_id: "WHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Configurable safety modules\\Configurable safety modules",
                text: "Configurable safety modules",
                X_fullName: "Configurable safety modules",
                X_Description:
                  "Safety devices with diagnostic options which support modular extension. For monitoring multiple safety functions.",
                X_URL: "configurable-safety-modules",
                X_THUMBNAIL_ALT: "Product list Configurable safety modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0045178_int.jpg",
              },
              {
                id: "YHI0000101",
                parent_id: "WHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Configurable safety modules\\Accessories for configurable safety modules",
                text: "Accessories for configurable safety modules",
                X_fullName: "Accessories for configurable safety modules",
                X_Description: "Accessories for configurable safety modules",
                X_URL: "config-safety-modules-acc",
                X_THUMBNAIL_ALT:
                  "Product list Configurable safety module accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0045178_int.jpg",
              },
            ],
          },
          {
            id: "ZHI0000101",
            parent_id: "CHI0000101",
            FULLPATH:
              "PhoenixContact\\Functional Safety\\Safe control technology",
            text: "Safe control technology",
            X_fullName: "Safe control technology",
            X_Description:
              "Programmable high-performance safety controllers and proxies for integrating functional safety.",
            X_URL: "safe-control-technology",
            X_THUMBNAIL_ALT: "Safe control technology",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0044029_int.jpg",
            items: [
              {
                id: "0II0000101",
                parent_id: "ZHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Accessories for safe control technology",
                text: "Accessories for safe control technology",
                X_fullName: "Accessories for safe control technology",
                X_Description:
                  "Accessories for the installation and operation of safety controllers and proxies in complex network structures.",
                X_URL: "safe-control-tech-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories safe control technology",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043949_int.jpg",
              },
              {
                id: "1II0000101",
                parent_id: "ZHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Programming for safety controllers",
                text: "Programming for safety controllers",
                X_fullName: "Programming for safety controllers",
                X_Description:
                  "Software for configuring and parameterizing programmable safety controllers.",
                X_URL: "programming-safety-controllers",
                X_THUMBNAIL_ALT:
                  "Product list Programming for safety controllers",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043951_int.jpg",
              },
              {
                id: "2II0000101",
                parent_id: "ZHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\I/O modules",
                text: "I/O modules",
                X_fullName: "I/O modules",
                X_Description:
                  "I/Os for the flexible integration of functional safety at input and output level.",
                X_URL: "io-modules",
                X_THUMBNAIL_ALT: "Product list I/O modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042997_int.jpg",
              },
              {
                id: "3II0000101",
                parent_id: "ZHI0000101",
                FULLPATH:
                  "PhoenixContact\\Functional Safety\\Safe control technology\\Safe analog value processing",
                text: "Safe analog value processing",
                X_fullName: "Safe analog value processing",
                X_Description:
                  "Software-based, safe analog value processing for safety applications up to SIL 3 and PL e.",
                X_URL: "safe-analog-value-processing",
                X_THUMBNAIL_ALT: "Product list Safe analog value processing",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0056611_int.jpg",
              },
            ],
          },
          {
            id: "5II0000101",
            parent_id: "CHI0000101",
            FULLPATH: "PhoenixContact\\Functional Safety\\Safety switch",
            text: "Safety switch",
            X_fullName: "Safety switch",
            X_Description:
              "Non-contact PSRswitch safety switches with RFID transponder technology for reliable safety door and position monitoring",
            X_URL: "safety-switch",
            X_THUMBNAIL_ALT: "Product list safety switches",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0083124_int.jpg",
          },
        ],
      },
      {
        id: "6II0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\HMIs and industrial PCs",
        text: "HMIs and industrial PCs",
        X_fullName: "HMIs and industrial PCs",
        X_Description:
          "Automation starts at the interface between human and machine. Efficient production from the start \u2013 with our HMIs and industrial PCs.",
        X_URL: "hmis-and-industrial-pcs",
        X_THUMBNAIL_ALT: "HMIs and industrial PCs",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0068631_int.jpg",
        items: [
          {
            id: "7II0000101",
            parent_id: "6II0000101",
            FULLPATH: "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs",
            text: "Industrial PCs",
            X_fullName: "Industrial PCs",
            X_Description:
              "Energy-efficient IPCs for a wide range of applications in all areas of automation.",
            X_URL: "industrial-pcs",
            X_THUMBNAIL_ALT: "Industrial PCs",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0059858_int.jpg",
            items: [
              {
                id: "8II0000101",
                parent_id: "7II0000101",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs",
                text: "Box PCs",
                X_fullName: "Box PCs",
                X_Description:
                  "Space-saving and high-performance box PCs for use in the control cabinet and terminal box.",
                X_URL: "box-pcs-main",
                X_THUMBNAIL_ALT: "Box PCs",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042382_int.jpg",
                items: [
                  {
                    id: "9II0000101",
                    parent_id: "8II0000101",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs\\Box PCs",
                    text: "Box PCs",
                    X_fullName: "Box PCs",
                    X_Description:
                      "Space-saving and high-performance box PCs for use in the control cabinet and terminal box.",
                    X_URL: "box-pcs",
                    X_THUMBNAIL_ALT: "Product list Box PCs",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046506_int.jpg",
                  },
                  {
                    id: "AII0000101",
                    parent_id: "8II0000101",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Box PCs\\Remote monitoring",
                    text: "Remote monitoring",
                    X_fullName: "Remote monitoring",
                    X_Description:
                      "Data transmission for distances up to 90 meters - products and solutions for remote operator interfaces.",
                    X_URL: "remote-monitoring",
                    X_THUMBNAIL_ALT: "Product list Remote monitoring",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0084204_int.jpg",
                  },
                ],
              },
              {
                id: "BII0000101",
                parent_id: "7II0000101",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Monitors with touch function",
                text: "Monitors with touch function",
                X_fullName: "Monitors with touch function",
                X_Description:
                  "Monitors with touch function which are suitable for industrial applications - ideal as a remote control panel for industrial PCs.",
                X_URL: "monitors-with-touch-function",
                X_THUMBNAIL_ALT: "Monitors with touch function",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042401_int.jpg",
                items: [
                  {
                    id: "CII0000101",
                    parent_id: "BII0000101",
                    FULLPATH:
                      "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Monitors with touch function\\Multi-touch monitors",
                    text: "Multi-touch monitors",
                    X_fullName: "Multi-touch monitors",
                    X_Description:
                      "Monitors with multi-touch function are the ideal extension to the industrial PC - operation and monitoring without mouse and keyboard.",
                    X_URL: "multi-touch-monitors",
                    X_THUMBNAIL_ALT: "Product list Multi-touch monitors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0084242_int.jpg",
                  },
                ],
              },
              {
                id: "DII0000101",
                parent_id: "7II0000101",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\Industrial PCs\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for quick and easy installation and efficient operation of industrial PCs.",
                X_URL: "ipc-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046495_int.jpg",
              },
            ],
          },
          {
            id: "EII0000101",
            parent_id: "6II0000101",
            FULLPATH: "PhoenixContact\\HMIs and industrial PCs\\HMIs",
            text: "HMIs",
            X_fullName: "HMIs",
            X_Description:
              "Numerous devices \u2013 mobile or stationary \u2013 for operating and monitoring machines and systems.",
            X_URL: "hmis",
            X_THUMBNAIL_ALT: "HMIs",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042391_int.jpg",
            items: [
              {
                id: "FII0000101",
                parent_id: "EII0000101",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\HMIs\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for quick and easy installation and efficient operation of HMI devices.",
                X_URL: "hmi-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046495_int.jpg",
              },
              {
                id: "GII0000101",
                parent_id: "EII0000101",
                FULLPATH:
                  "PhoenixContact\\HMIs and industrial PCs\\HMIs\\HMIs for Visu+",
                text: "HMIs for Visu+",
                X_fullName: "HMIs for Visu+",
                X_Description:
                  "Touch panels for the visualization of sophisticated applications with the Visu+ software.",
                X_URL: "hmis-for-visu",
                X_THUMBNAIL_ALT: "Product list HMIs for Visu+",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076294_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "HII0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Industrial communication technology",
        text: "Industrial communication technology",
        X_fullName: "Industrial communication technology",
        X_Description:
          "Our interface devices provide consistent protection for your sensitive data interfaces and so enable you to increase the availability of your systems.",
        X_URL: "industrial-communication",
        X_THUMBNAIL_ALT: "Industrial communication technology",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0079961_int.jpg",
        items: [
          {
            id: "III0000101",
            parent_id: "HII0000101",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Remote communication",
            text: "Remote communication",
            X_fullName: "Remote communication",
            X_Description:
              "Access machines and systems worldwide via telecommunications networks quickly and without interference.",
            X_URL: "remote-communication",
            X_THUMBNAIL_ALT: "Remote communication",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0074272_int.jpg",
            items: [
              {
                id: "JII0000101",
                parent_id: "III0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance",
                text: "Remote maintenance",
                X_fullName: "Remote maintenance",
                X_Description:
                  "With mGuard technology, Phoenix Contact offers a remote maintenance solution that can be easily integrated into machines and systems.",
                X_URL: "remote-maintenance-main",
                X_THUMBNAIL_ALT: "Remote maintenance",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0084279_int.jpg",
                items: [
                  {
                    id: "KII0000101",
                    parent_id: "JII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance\\Remote maintenance",
                    text: "Remote maintenance",
                    X_fullName: "Remote maintenance",
                    X_Description:
                      "Solutions from Phoenix Contact for secure remote maintenance of machines and systems.",
                    X_URL: "remote-maintenance",
                    X_THUMBNAIL_ALT: "Product list Remote maintenance",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0074274_int.jpg",
                  },
                  {
                    id: "MII0000101",
                    parent_id: "JII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote maintenance\\Cloud-based remote maintenance",
                    text: "Cloud-based remote maintenance",
                    X_fullName: "Cloud-based remote maintenance",
                    X_Description:
                      "The mGuard Secure Cloud securely connects service personnel and remote maintenance locations via the Internet.",
                    X_URL: "cloud-based-remote-maintenance",
                    X_THUMBNAIL_ALT:
                      "Product list Cloud-based remote maintenance",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084029_int.jpg",
                  },
                ],
              },
              {
                id: "OII0000101",
                parent_id: "III0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Alarm generation",
                text: "Alarm generation",
                X_fullName: "Alarm generation",
                X_Description:
                  "Industrial devices from Phoenix Contact provide automatic alarm generation for instant remote error detection.",
                X_URL: "alarm-generation",
                X_THUMBNAIL_ALT: "Product list Alarm generation",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0067088_int.jpg",
              },
              {
                id: "QII0000101",
                parent_id: "III0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Remote communication\\Remote control",
                text: "Remote control",
                X_fullName: "Remote control",
                X_Description:
                  "Remote control technology from Phoenix Contact for mobile networks or copper-based solutions.",
                X_URL: "remote-control",
                X_THUMBNAIL_ALT: "Product list Remote control",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0070909_int.jpg",
              },
            ],
          },
          {
            id: "RII0000101",
            parent_id: "HII0000101",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Industrial Ethernet",
            text: "Industrial Ethernet",
            X_fullName: "Industrial Ethernet",
            X_Description:
              "Design your infrastructure flexibly, quickly, and consistently. Not a problem when you base your automation network on Industrial Ethernet.",
            X_URL: "industrial-ethernet",
            X_THUMBNAIL_ALT: "Industrial Ethernet",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0086832_int.jpg",
            items: [
              {
                id: "SII0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet",
                text: "Installation technology for Industrial Ethernet",
                X_fullName: "Installation technology for Industrial Ethernet",
                X_Description:
                  "A wide range of installation materials for setting up your industrial network",
                X_URL: "installation-tech-for-ethernet",
                X_THUMBNAIL_ALT:
                  "Installation technology for Industrial Ethernet",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0086706_int.jpg",
                items: [
                  {
                    id: "TII0000101",
                    parent_id: "SII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Fiber optic installation",
                    text: "Fiber optic installation",
                    X_fullName: "Fiber optic installation",
                    X_Description:
                      "Installation components for fiber optic networks, such as cables, plugs, measuring instruments, and comprehensive accessories.",
                    X_URL: "fiber-optic-installation",
                    X_THUMBNAIL_ALT: "Product list Fiber optic installation",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042487_int.jpg",
                  },
                  {
                    id: "UII0000101",
                    parent_id: "SII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Copper installation",
                    text: "Copper installation",
                    X_fullName: "Copper installation",
                    X_Description:
                      "Components for copper-based network installation, such as cables, plugs, and network isolators, as well as tools.",
                    X_URL: "copper-installation",
                    X_THUMBNAIL_ALT: "Product list Copper installation",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043658_int.jpg",
                  },
                  {
                    id: "VII0000101",
                    parent_id: "SII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Accessories for industrial Ethernet",
                    text: "Accessories for industrial Ethernet",
                    X_fullName: "Accessories for industrial Ethernet",
                    X_Description:
                      "Accessories for Phoenix Contact Ethernet components.",
                    X_URL: "industrial-ethernet-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for industrial Ethernet",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0050060_int.jpg",
                  },
                  {
                    id: "WII0000101",
                    parent_id: "SII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\SFP modules",
                    text: "SFP modules",
                    X_fullName: "SFP modules",
                    X_Description:
                      "SFP modules for flexible FO and copper transmission in Fast Ethernet or Gigabit.",
                    X_URL: "sfp-modules",
                    X_THUMBNAIL_ALT: "Product list SFP modules",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0083222_int.jpg",
                  },
                  {
                    id: "XII0000101",
                    parent_id: "SII0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Installation technology for Industrial Ethernet\\Patch panels",
                    text: "Patch panels",
                    X_fullName: "Patch panels",
                    X_Description:
                      "Interface modules between field- and control cabinet cabling",
                    X_URL: "patch-panels",
                    X_THUMBNAIL_ALT: "Product list Patch panels",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0083978_int.jpg",
                  },
                ],
              },
              {
                id: "ZII0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Network management software",
                text: "Network management software",
                X_fullName: "Network management software",
                X_Description:
                  "Powerful software for configuring and monitoring Ethernet networks in automation systems.",
                X_URL: "network-management-software",
                X_THUMBNAIL_ALT: "Product list Network management software",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042930_int.jpg",
              },
              {
                id: "0JI0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet",
                text: "Power over Ethernet",
                X_fullName: "Power over Ethernet",
                X_Description:
                  "You will find our comprehensive range of PoE devices, from PoE injectors, through PoE switches, right through to PoE splitters, here",
                X_URL: "power-over-ethernet",
                X_THUMBNAIL_ALT: "Power over Ethernet",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0086242_int.jpg",
                items: [
                  {
                    id: "1JI0000101",
                    parent_id: "0JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\PoE injectors",
                    text: "PoE injectors",
                    X_fullName: "PoE injectors",
                    X_Description:
                      "PoE injectors for easy transmission of data and electricity.",
                    X_URL: "poe-injectors",
                    X_THUMBNAIL_ALT: "Product list PoE injectors",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0086158_int.jpg",
                  },
                  {
                    id: "2JI0000101",
                    parent_id: "0JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\PoE splitter",
                    text: "PoE splitter",
                    X_fullName: "PoE splitter",
                    X_Description:
                      "PoE splitters separate data and power locally.",
                    X_URL: "poe-splitter",
                    X_THUMBNAIL_ALT: "Product list PoE splitters",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0086159_int.jpg",
                  },
                  {
                    id: "3JI0000101",
                    parent_id: "0JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\Unmanaged PoE Switches",
                    text: "Unmanaged PoE Switches",
                    X_fullName: "Unmanaged PoE Switches",
                    X_Description:
                      "Unmanaged PoE Switches with extended temperature range, 30 W power budget, full Gigabit ports and jumbo frames for monitoring cameras",
                    X_URL: "unmanaged-poe-switches",
                    X_THUMBNAIL_ALT: "Product list Unmanaged PoE Switches",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0051532_int.jpg",
                  },
                  {
                    id: "4JI0000101",
                    parent_id: "0JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Power over Ethernet\\Managed PoE Switches",
                    text: "Managed PoE Switches",
                    X_fullName: "Managed PoE Switches",
                    X_Description:
                      "Managed PoE Switches in accordance with IEEE 802.3 af/at and bt for the supply and control of several end devices with PoE.",
                    X_URL: "managed-poe-switches",
                    X_THUMBNAIL_ALT: "Product list Managed PoE Switches",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0086162_int.jpg",
                  },
                ],
              },
              {
                id: "5JI0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security",
                text: "Cyber security",
                X_fullName: "Cyber security",
                X_Description:
                  "Security appliances regulate access to networks and therefore protect the system against harmful influences.",
                X_URL: "cyber-security",
                X_THUMBNAIL_ALT: "Cyber Security",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0043219_int.jpg",
                items: [
                  {
                    id: "7JI0000101",
                    parent_id: "5JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Security routers and firewalls",
                    text: "Security routers and firewalls",
                    X_fullName: "Security routers and firewalls",
                    X_Description:
                      "Security appliances from Phoenix Contact offer firewall functions and a secure remote maintenance infrastructure in various designs.",
                    X_URL: "security-routers-and-firewalls",
                    X_THUMBNAIL_ALT:
                      "Product list Security routers and firewalls",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0043219_int.jpg",
                  },
                  {
                    id: "9JI0000101",
                    parent_id: "5JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Firmware extensions for security routers and firew",
                    text: "Firmware extensions for security routers and firew",
                    X_fullName:
                      "Firmware extensions for security routers and firew",
                    X_Description:
                      "Extend mGuard security appliances with additional security functions at any time by means of licenses.",
                    X_URL: "firmware-extensions",
                    X_THUMBNAIL_ALT:
                      "Product list Firmware extensions for security routers and firewalls",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0073043_int.jpg",
                  },
                  {
                    id: "BJI0000101",
                    parent_id: "5JI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Cyber security\\Device management software for security routers an",
                    text: "Device management software for security routers an",
                    X_fullName:
                      "Device management software for security routers an",
                    X_Description:
                      "You can configure, roll out, and manage all mGuard security appliances from Phoenix Contact centrally with the central device management software.",
                    X_URL: "security-routers-device-mgmt",
                    X_THUMBNAIL_ALT:
                      "Product list Device management software for security routers and firewalls",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0072607_int.jpg",
                  },
                ],
              },
              {
                id: "DJI0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Media converters for Ethernet",
                text: "Media converters for Ethernet",
                X_fullName: "Media converters for Ethernet",
                X_Description:
                  "Fiber optic media converters for Ethernet in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                X_URL: "ethernet-converters",
                X_THUMBNAIL_ALT: "Product list Ethernet media converters",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042858_int.jpg",
              },
              {
                id: "EJI0000101",
                parent_id: "RII0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches",
                text: "Ethernet switches",
                X_fullName: "Ethernet switches",
                X_Description:
                  "Here you will find our comprehensive portfolio of Industrial Ethernet Switches: from Unmanaged to Managed Switches and Layer 3 switches.",
                X_URL: "ethernet-switches",
                X_THUMBNAIL_ALT: "Ethernet switches",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0086235_int.jpg",
                items: [
                  {
                    id: "GJI0000101",
                    parent_id: "EJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches\\Managed switches",
                    text: "Managed switches",
                    X_fullName: "Managed switches",
                    X_Description:
                      "Managed Switches with Gigabit and Fast Ethernet, PROFINET, EtherNet/IP\u2122, IT functions, PoE and much more.",
                    X_URL: "managed-switches",
                    X_THUMBNAIL_ALT: "Product list Managed Switches",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0079891_int.jpg",
                  },
                  {
                    id: "IJI0000101",
                    parent_id: "EJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Ethernet\\Ethernet switches\\Unmanaged switches",
                    text: "Unmanaged switches",
                    X_fullName: "Unmanaged switches",
                    X_Description:
                      "Unmanaged switches in various designs and with a variable number of ports.",
                    X_URL: "unmanaged-switches",
                    X_THUMBNAIL_ALT: "Product list Unmanaged switches",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043405_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "JJI0000101",
            parent_id: "HII0000101",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Industrial Wireless",
            text: "Industrial Wireless",
            X_fullName: "Industrial Wireless",
            X_Description:
              "Reliable and efficient data transmission \u2013 without high-maintenance cables. Benefit from the advantages of high-performance wireless technology for your application.",
            X_URL: "industrial-wireless",
            X_THUMBNAIL_ALT: "Industrial Wireless",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0081198_int.jpg",
            items: [
              {
                id: "KJI0000101",
                parent_id: "JJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables",
                text: "Antennas and cables",
                X_fullName: "Antennas and cables",
                X_Description:
                  "Various antennas, corresponding cables, and accessories complete the range for wireless data communication.",
                X_URL: "antennas-and-cables-main",
                X_THUMBNAIL_ALT: "Antennas and cables",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042657_int.jpg",
                items: [
                  {
                    id: "LJI0000101",
                    parent_id: "KJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables\\Antennas and cables",
                    text: "Antennas and cables",
                    X_fullName: "Antennas and cables",
                    X_Description:
                      "Antennas and cables for optimally adapting wireless devices to individual requirements.",
                    X_URL: "antennas-and-cables",
                    X_THUMBNAIL_ALT: "Product list Antennas and cables",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044427_int.jpg",
                  },
                  {
                    id: "MJI0000101",
                    parent_id: "KJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Antennas and cables\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description:
                      "Accessories for installing antennas and cables for wireless networks.",
                    X_URL: "antena-cables-accessories",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044424_int.jpg",
                  },
                ],
              },
              {
                id: "NJI0000101",
                parent_id: "JJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet",
                text: "Wireless Ethernet",
                X_fullName: "Wireless Ethernet",
                X_Description:
                  "Components for high-performance Ethernet networks via WLAN or Bluetooth.",
                X_URL: "wireless-ethernet",
                X_THUMBNAIL_ALT: "Wireless Ethernet",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0081905_int.jpg",
                items: [
                  {
                    id: "PJI0000101",
                    parent_id: "NJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet\\Industrial Bluetooth",
                    text: "Industrial Bluetooth",
                    X_fullName: "Industrial Bluetooth",
                    X_Description:
                      "Devices for the Bluetooth-based integration of mobile or interchangeable automation components into Ethernet networks.",
                    X_URL: "industrial-bluetooth",
                    X_THUMBNAIL_ALT: "Product list Industrial Bluetooth",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0081912_int.jpg",
                  },
                  {
                    id: "RJI0000101",
                    parent_id: "NJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless Ethernet\\Industrial WLAN",
                    text: "Industrial WLAN",
                    X_fullName: "Industrial WLAN",
                    X_Description:
                      "Devices for industrial WLAN networks for the integration of mobile or interchangeable automation components in Ethernet networks.",
                    X_URL: "industrial-wlan",
                    X_THUMBNAIL_ALT: "Product list Industrial WLAN",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043923_int.jpg",
                  },
                ],
              },
              {
                id: "SJI0000101",
                parent_id: "JJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O",
                text: "Wireless I/O",
                X_fullName: "Wireless I/O",
                X_Description:
                  "Wireless I/O devices for the wireless transmission of digital or analog I/O signals.",
                X_URL: "wireless-io",
                X_THUMBNAIL_ALT: "Wireless I/O",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0074225_int.jpg",
                items: [
                  {
                    id: "TJI0000101",
                    parent_id: "SJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O\\Wireless multiplexer",
                    text: "Wireless multiplexer",
                    X_fullName: "Wireless multiplexer",
                    X_Description:
                      "Bluetooth I/O devices for reliable, secure I/O communication to rotating or hard-to-reach system parts.",
                    X_URL: "wireless-multiplexer",
                    X_THUMBNAIL_ALT: "Product list Wireless multiplexers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044422_int.jpg",
                  },
                  {
                    id: "UJI0000101",
                    parent_id: "SJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Industrial Wireless\\Wireless I/O\\Radioline",
                    text: "Radioline",
                    X_fullName: "Radioline",
                    X_Description:
                      "Radioline with Trusted Wireless 2.0 technology for the reliable acquisition and distribution of I/O signals in large industrial systems.",
                    X_URL: "radioline",
                    X_THUMBNAIL_ALT: "Product list Radioline",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0047881_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "VJI0000101",
            parent_id: "HII0000101",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Products by protocols",
            text: "Products by protocols",
            X_fullName: "Products by protocols",
            X_Description:
              "Fieldbus components and systems reduce cabling effort in your systems and save time during planning and installation.",
            X_URL: "products-by-protocols",
            X_THUMBNAIL_ALT: "Products by protocols",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0051908_int.jpg",
            items: [
              {
                id: "WJI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\AS-Interface",
                text: "AS-Interface",
                X_fullName: "AS-Interface",
                X_Description:
                  "AS-Interface supports quick, easy, and error-free installation.",
                X_URL: "as-interface",
                X_THUMBNAIL_ALT: "AS-Interface",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0044649_int.jpg",
                items: [
                  {
                    id: "XJI0000101",
                    parent_id: "WJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\AS-Interface\\AS-i network components",
                    text: "AS-i network components",
                    X_fullName: "AS-i network components",
                    X_Description:
                      "Wide range of components for constructing an AS-Interface network.",
                    X_URL: "as-i-network-components",
                    X_THUMBNAIL_ALT: "Product list AS-i network components",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0045349_int.jpg",
                  },
                ],
              },
              {
                id: "YJI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae",
                text: "CANopen\u00ae",
                X_fullName: "CANopen\u00ae",
                X_Description:
                  "The CANopen fieldbus system is particularly suitable for reliable data transmission.",
                X_URL: "canopen",
                X_THUMBNAIL_ALT: "CANopen",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069235_int.jpg",
                items: [
                  {
                    id: "ZJI0000101",
                    parent_id: "YJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "CANopen\u00ae cables and connectors in various lengths and with different degrees of protection, pre-assembled or sold by the meter.",
                    X_URL: "canopen-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044954_int.jpg",
                  },
                  {
                    id: "0KI0000101",
                    parent_id: "YJI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\CANopen\u00ae\\Converters and repeaters",
                    text: "Converters and repeaters",
                    X_fullName: "Converters and repeaters",
                    X_Description:
                      "Converters and repeaters for CANopen convert copper interfaces to fiber optics, amplify signals, and create segments.",
                    X_URL: "converters-and-repeaters",
                    X_THUMBNAIL_ALT: "Product list Converters and repeaters",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042485_int.jpg",
                  },
                ],
              },
              {
                id: "1KI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\EtherNet/IP\u2122",
                text: "EtherNet/IP\u2122",
                X_fullName: "EtherNet/IP\u2122",
                X_Description:
                  "EtherNet/IP\u2122 creates consistency between office and industrial networks.",
                X_URL: "ethernet-ip",
                X_THUMBNAIL_ALT: "EtherNet/IP\u2122",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069872_int.jpg",
                items: [
                  {
                    id: "2KI0000101",
                    parent_id: "1KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\EtherNet/IP\u2122\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "EtherNet/IP\u2122 cables and connectors in various lengths and with different degrees of protection, pre-assembled or sold by the meter.",
                    X_URL: "ethernet-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044964_int.jpg",
                  },
                ],
              },
              {
                id: "3KI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART",
                text: "HART",
                X_fullName: "HART",
                X_Description:
                  "HART and WirelessHART components for communication between sensor and control.",
                X_URL: "hart",
                X_THUMBNAIL_ALT: "HART",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0074730_int.jpg",
                items: [
                  {
                    id: "4KI0000101",
                    parent_id: "3KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART",
                    text: "Wired HART",
                    X_fullName: "Wired HART",
                    X_Description:
                      "Wired HART components: multiplexers, isolators, surge protection",
                    X_URL: "wired-hart",
                    X_THUMBNAIL_ALT: "Wired HART",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0073168_int.jpg",
                    items: [
                      {
                        id: "5KI0000101",
                        parent_id: "4KI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Multiplexer for HART signals",
                        text: "Multiplexer for HART signals",
                        X_fullName: "Multiplexer for HART signals",
                        X_Description:
                          "Multiplexers for the digital connection of HART-compatible field devices to a PC or management system.",
                        X_URL: "multiplexer-for-hart-signals",
                        X_THUMBNAIL_ALT:
                          "Product list Multiplexers for HART signals",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044140_int.jpg",
                      },
                      {
                        id: "6KI0000101",
                        parent_id: "4KI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Signal conditioner",
                        text: "Signal conditioner",
                        X_fullName: "Signal conditioner",
                        X_Description:
                          "HART-transparent and electrically isolated isolating amplifiers for error-free bidirectional data exchange.",
                        X_URL: "hart-signal-conditioner",
                        X_THUMBNAIL_ALT: "Product list Isolating amplifiers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044116_int.jpg",
                      },
                      {
                        id: "7KI0000101",
                        parent_id: "4KI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Products by protocols\\HART\\Wired HART\\Process indicators and field devices",
                        text: "Process indicators and field devices",
                        X_fullName: "Process indicators and field devices",
                        X_Description:
                          "Record temperatures directly in the field, monitor, display, and control process values with HART-capable process indicators and field devices.",
                        X_URL: "process-indicators-and-field-dev",
                        X_THUMBNAIL_ALT:
                          "Product list Process indicators and field devices",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0081390_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "8KI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS",
                text: "INTERBUS",
                X_fullName: "INTERBUS",
                X_Description:
                  "INTERBUS connects all process peripherals to all common controllers.",
                X_URL: "interbus",
                X_THUMBNAIL_ALT: "INTERBUS",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0044555_int.jpg",
                items: [
                  {
                    id: "9KI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "Bus system cables and connectors for INTERBUS in various lengths, pre-assembled or sold by the meter.",
                    X_URL: "interbus-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046596_int.jpg",
                  },
                  {
                    id: "AKI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Converters",
                    text: "Converters",
                    X_fullName: "Converters",
                    X_Description:
                      "Converters for INTERBUS convert wire interfaces to fiber optics.",
                    X_URL: "converters",
                    X_THUMBNAIL_ALT: "Product list Converters",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042854_int.jpg",
                  },
                  {
                    id: "BKI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\Bus modules",
                    text: "Bus modules",
                    X_fullName: "Bus modules",
                    X_Description:
                      "INTERBUS bus couplers connect an I/O station to the INTERBUS network.",
                    X_URL: "bus-modules",
                    X_THUMBNAIL_ALT: "Product list Bus modules",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046598_int.jpg",
                  },
                  {
                    id: "CKI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\INTERBUS master",
                    text: "INTERBUS master",
                    X_fullName: "INTERBUS master",
                    X_Description:
                      "The INTERBUS master controls the bus system and monitors the telegrams sent and received within the bus configuration.",
                    X_URL: "interbus-master",
                    X_THUMBNAIL_ALT: "Product list INTERBUS master",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046509_int.jpg",
                  },
                  {
                    id: "DKI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\INTERBUS slave",
                    text: "INTERBUS slave",
                    X_fullName: "INTERBUS slave",
                    X_Description:
                      "INTERBUS slaves connect lower-level INTERBUS devices to the higher-level network.",
                    X_URL: "interbus-slave",
                    X_THUMBNAIL_ALT: "Product list INTERBUS slave",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046509_int.jpg",
                  },
                  {
                    id: "EKI0000101",
                    parent_id: "8KI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\INTERBUS\\PC controller boards",
                    text: "PC controller boards",
                    X_fullName: "PC controller boards",
                    X_Description:
                      "PC master/slave controller boards integrate remote bus devices into an INTERBUS system.",
                    X_URL: "controller-boards",
                    X_THUMBNAIL_ALT: "Product list PC controller boards",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046600_int.jpg",
                  },
                ],
              },
              {
                id: "FKI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\Modbus",
                text: "Modbus",
                X_fullName: "Modbus",
                X_Description:
                  "The versatile Modbus communication protocol is a proven and user-friendly solution.",
                X_URL: "modbus",
                X_THUMBNAIL_ALT: "Modbus",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051964_int.jpg",
                items: [
                  {
                    id: "GKI0000101",
                    parent_id: "FKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\Modbus\\Signal conditioners with Modbus connection",
                    text: "Signal conditioners with Modbus connection",
                    X_fullName: "Signal conditioners with Modbus connection",
                    X_Description:
                      "Signal conditioners and gateways for the direct integration of up to eight analog and digital process values into a Modbus/RTU or Modbus/TCP network.",
                    X_URL: "signal-conditioners-Modbus ",
                    X_THUMBNAIL_ALT:
                      "Product list Signal conditioners with Modbus connection",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0076784_int.jpg",
                  },
                ],
              },
              {
                id: "HKI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS",
                text: "PROFIBUS",
                X_fullName: "PROFIBUS",
                X_Description:
                  "PROFIBUS is a worldwide leading field system for the automation of machines and production systems.",
                X_URL: "profibus",
                X_THUMBNAIL_ALT: "PROFIBUS",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056280_int.jpg",
                items: [
                  {
                    id: "IKI0000101",
                    parent_id: "HKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Gateways and proxies",
                    text: "Gateways and proxies",
                    X_fullName: "Gateways and proxies",
                    X_Description:
                      "PROFIBUS proxies are the link between PROFIBUS and PROFINET. Gateways establish connections to other bus systems.",
                    X_URL: "gateways-proxies",
                    X_THUMBNAIL_ALT: "Product list Gateways and proxies",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0045394_int.jpg",
                  },
                  {
                    id: "JKI0000101",
                    parent_id: "HKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "Bus systems cables and connectors for PROFIBUS in various lengths, pre-assembled or sold by the meter.",
                    X_URL: "profibus-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0045009_int.jpg",
                  },
                  {
                    id: "KKI0000101",
                    parent_id: "HKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Fieldbus components for PROFIBUS PA",
                    text: "Fieldbus components for PROFIBUS PA",
                    X_fullName: "Fieldbus components for PROFIBUS PA",
                    X_Description:
                      "Modular fieldbus components for PROFIBUS PA applications for communication from the process controller to the field devices.",
                    X_URL: "fieldbus-components-profibus-pa",
                    X_THUMBNAIL_ALT:
                      "Product list Fieldbus components for PROFIBUS PA",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0057689_int.jpg",
                  },
                  {
                    id: "LKI0000101",
                    parent_id: "HKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFIBUS\\Segment coupler",
                    text: "Segment coupler",
                    X_fullName: "Segment coupler",
                    X_Description:
                      "Segment coupler for reliable connection of PROFIBUS PA to PROFIBUS DP networks.",
                    X_URL: "segment-coupler",
                    X_THUMBNAIL_ALT: "Product list Segment couplers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062959_int.jpg",
                  },
                ],
              },
              {
                id: "MKI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET",
                text: "PROFINET",
                X_fullName: "PROFINET",
                X_Description:
                  "PROFINET is an Ethernet-based fieldbus system and provides consistent data transmission across all levels.",
                X_URL: "profinet",
                X_THUMBNAIL_ALT: "PROFINET",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0044697_int.jpg",
                items: [
                  {
                    id: "NKI0000101",
                    parent_id: "MKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET\\Gateways and proxies",
                    text: "Gateways and proxies",
                    X_fullName: "Gateways and proxies",
                    X_Description:
                      "Proxies integrate existing fieldbus installations into PROFINET. Gateways link fieldbus networks.",
                    X_URL: "gateways-and-proxies",
                    X_THUMBNAIL_ALT: "Product list Gateways and proxies",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046602_int.jpg",
                  },
                  {
                    id: "OKI0000101",
                    parent_id: "MKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\PROFINET\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "PROFINET cables and connectors in various lengths and degrees of protection, pre-assembled or sold by the meter.",
                    X_URL: "profinet-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0046546_int.jpg",
                  },
                ],
              },
              {
                id: "PKI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\Sercos",
                text: "Sercos",
                X_fullName: "Sercos",
                X_Description:
                  "Sercos components for fast, synchronous data transmission in automation technology.",
                X_URL: "sercos",
                X_THUMBNAIL_ALT: "Sercos",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051919_int.jpg",
                items: [
                  {
                    id: "QKI0000101",
                    parent_id: "PKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\Sercos\\Cables and connectors",
                    text: "Cables and connectors",
                    X_fullName: "Cables and connectors",
                    X_Description:
                      "Sercos cables and connectors in various lengths and degrees of protection, pre-assembled or sold by the meter.",
                    X_URL: "seros-cables-and-connectors",
                    X_THUMBNAIL_ALT: "Product list Cables and connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044966_int.jpg",
                  },
                ],
              },
              {
                id: "RKI0000101",
                parent_id: "VJI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus",
                text: "FOUNDATION Fieldbus",
                X_fullName: "FOUNDATION Fieldbus",
                X_Description:
                  "Fieldbus components for the modular configuration of FOUNDATION Fieldbus applications.",
                X_URL: "foundation-fieldbus",
                X_THUMBNAIL_ALT: "FOUNDATION Fieldbus",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0073168_int.jpg",
                items: [
                  {
                    id: "SKI0000101",
                    parent_id: "RKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Fieldbus distributor boxes",
                    text: "Fieldbus distributor boxes",
                    X_fullName: "Fieldbus distributor boxes",
                    X_Description:
                      "Field distributor boxes in various versions, developed specifically for field device coupler systems.",
                    X_URL: "fieldbus-distributor-boxes",
                    X_THUMBNAIL_ALT: "Product list Fieldbus distributor boxes",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044182_int.jpg",
                  },
                  {
                    id: "TKI0000101",
                    parent_id: "RKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Device couplers",
                    text: "Device couplers",
                    X_fullName: "Device couplers",
                    X_Description:
                      "Device couplers in various versions for the field and the control cabinet.",
                    X_URL: "device-couplers",
                    X_THUMBNAIL_ALT: "Product list Device couplers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044180_int.jpg",
                  },
                  {
                    id: "UKI0000101",
                    parent_id: "RKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Products by protocols\\FOUNDATION Fieldbus\\Power supply units",
                    text: "Power supply units",
                    X_fullName: "Power supply units",
                    X_Description:
                      "Redundant or single-channel power supply units for the DIN rail supply voltage for a FOUNDATION Fieldbus segment.",
                    X_URL: "fieldbus-psu",
                    X_THUMBNAIL_ALT: "Product list Power supply units",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044181_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "VKI0000101",
            parent_id: "HII0000101",
            FULLPATH:
              "PhoenixContact\\Industrial communication technology\\Fieldbus communication",
            text: "Fieldbus communication",
            X_fullName: "Fieldbus communication",
            X_Description:
              "Universal communication with Phoenix Contact interface devices",
            X_URL: "fieldbus-communication",
            X_THUMBNAIL_ALT: "Fieldbus communication",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0080789_int.jpg",
            items: [
              {
                id: "WKI0000101",
                parent_id: "VKI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology",
                text: "Installation technology",
                X_fullName: "Installation technology",
                X_Description:
                  "Phoenix Contact installation technology provides you with all the cables, connectors, and tools you need to create fiber-optic and copper fieldbus networks that are suitable for industrial applications.",
                X_URL: "installation-technology",
                X_THUMBNAIL_ALT: "Installation technology",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0081525_int.jpg",
                items: [
                  {
                    id: "XKI0000101",
                    parent_id: "WKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology\\Fast connection plugs",
                    text: "Fast connection plugs",
                    X_fullName: "Fast connection plugs",
                    X_Description:
                      "Comprehensive product range for user-friendly cable connection using D-SUB fast connection plugs.",
                    X_URL: "fast-connection-plugs",
                    X_THUMBNAIL_ALT: "Fast connection plugs",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0057804_int.jpg",
                    items: [
                      {
                        id: "YKI0000101",
                        parent_id: "XKI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Installation technology\\Fast connection plugs\\D-SUB fast connectors",
                        text: "D-SUB fast connectors",
                        X_fullName: "D-SUB fast connectors",
                        X_Description:
                          "Fast connectors in various versions for convenient connection on site.",
                        X_URL: "d-sub-fast-connectors",
                        X_THUMBNAIL_ALT: "Product list D-SUB fast connectors",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042959_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "ZKI0000101",
                parent_id: "VKI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators",
                text: "Converters and isolators",
                X_fullName: "Converters and isolators",
                X_Description:
                  "Converters enable you to convert various serial interfaces to other standards using a protocol-transparent method.",
                X_URL: "converters-and-isolators",
                X_THUMBNAIL_ALT: "Converters and isolators",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0081528_int.jpg",
                items: [
                  {
                    id: "0LI0000101",
                    parent_id: "ZKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter",
                    text: "Media converter",
                    X_fullName: "Media converter",
                    X_Description:
                      "Media converters enable you to convert your copper-based interfaces to interference-free fiber optics.",
                    X_URL: "media-converter",
                    X_THUMBNAIL_ALT: "Media converters",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042852_int.jpg",
                    items: [
                      {
                        id: "1LI0000101",
                        parent_id: "0LI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter\\Media converters for fieldbuses",
                        text: "Media converters for fieldbuses",
                        X_fullName: "Media converters for fieldbuses",
                        X_Description:
                          "Fiber optic media converters for fieldbuses in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                        X_URL: "media-converters-for-fieldbuses",
                        X_THUMBNAIL_ALT:
                          "Product list Media converters for fieldbuses",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042481_int.jpg",
                      },
                      {
                        id: "2LI0000101",
                        parent_id: "0LI0000101",
                        FULLPATH:
                          "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Media converter\\Serial media converters",
                        text: "Serial media converters",
                        X_fullName: "Serial media converters",
                        X_Description:
                          "Fiber optic media converters for serial interfaces in various different versions can be used with polymer fiber, HCS fiber or fiberglass.",
                        X_URL: "serial-media-converters",
                        X_THUMBNAIL_ALT: "Product list Serial media converters",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042485_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "4LI0000101",
                    parent_id: "ZKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Interface converters",
                    text: "Interface converters",
                    X_fullName: "Interface converters",
                    X_Description:
                      "Interface converters, available in different versions, for adapting serial interfaces.",
                    X_URL: "interface-converters",
                    X_THUMBNAIL_ALT: "Product list Interface converters",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042496_int.jpg",
                  },
                  {
                    id: "6LI0000101",
                    parent_id: "ZKI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Converters and isolators\\Field device couplers",
                    text: "Field device couplers",
                    X_fullName: "Field device couplers",
                    X_Description:
                      "Field device couplers for distributed fieldbus extension.",
                    X_URL: "field-device-couplers",
                    X_THUMBNAIL_ALT: "Product list Field device couplers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044182_int.jpg",
                  },
                ],
              },
              {
                id: "7LI0000101",
                parent_id: "VKI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Extenders and repeaters",
                text: "Extenders and repeaters",
                X_fullName: "Extenders and repeaters",
                X_Description:
                  "Repeaters and extenders increase network performance and extend the number of devices.",
                X_URL: "extenders-and-repeaters",
                X_THUMBNAIL_ALT: "Extenders and repeaters",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0081530_int.jpg",
                items: [
                  {
                    id: "8LI0000101",
                    parent_id: "7LI0000101",
                    FULLPATH:
                      "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Extenders and repeaters\\Repeater",
                    text: "Repeater",
                    X_fullName: "Repeater",
                    X_Description:
                      "Repeaters for amplifying signals and organizing segments.",
                    X_URL: "repeater",
                    X_THUMBNAIL_ALT: "Product list Repeaters",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042485_int.jpg",
                  },
                ],
              },
              {
                id: "ALI0000101",
                parent_id: "VKI0000101",
                FULLPATH:
                  "PhoenixContact\\Industrial communication technology\\Fieldbus communication\\Gateways",
                text: "Gateways",
                X_fullName: "Gateways",
                X_Description:
                  "Gateways for connecting automation devices with serial interfaces to Ethernet networks.",
                X_URL: "gateways",
                X_THUMBNAIL_ALT: "Product list Gateways",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0079189_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "BLI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Installation and mounting material",
        text: "Installation and mounting material",
        X_fullName: "Installation and mounting material",
        X_Description:
          "Space saving and extremely easy. Our installation and mounting material stands for modern control cabinet technology.",
        X_URL: "installation-mounting-material",
        X_THUMBNAIL_ALT: "Installation and mounting material",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0044986_int.jpg",
        items: [
          {
            id: "CLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet",
            text: "Installation material for the control cabinet",
            X_fullName: "Installation material for the control cabinet",
            X_Description:
              "Installation material for the control cabinet simplifies mounting and ensures that connections are fixed securely.",
            X_URL: "control-cabinet-installation",
            X_THUMBNAIL_ALT: "Installation material for the control cabinet",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0044990_int.jpg",
            items: [
              {
                id: "DLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Branch terminals",
                text: "Branch terminals",
                X_fullName: "Branch terminals",
                X_Description:
                  "Branch terminals provide an easy solution for branching flat copper busbars.",
                X_URL: "branch-terminals",
                X_THUMBNAIL_ALT: "Product list Branch terminals",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046952_int.jpg",
              },
              {
                id: "ELI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Supports",
                text: "Supports",
                X_fullName: "Supports",
                X_Description:
                  "Supports for shield connection clamps are simply snapped onto the DIN rail.",
                X_URL: "supports",
                X_THUMBNAIL_ALT: "Product list Supports",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046953_int.jpg",
              },
              {
                id: "FLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Power terminal blocks",
                text: "Power terminal blocks",
                X_fullName: "Power terminal blocks",
                X_Description:
                  "Various power terminals are available for busbars in switchgear.",
                X_URL: "power-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Power terminals",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046954_int.jpg",
              },
              {
                id: "GLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Neutral busbars",
                text: "Neutral busbars",
                X_fullName: "Neutral busbars",
                X_Description:
                  "Neutral busbars, 1000 mm long, are available in two widths: 6 x 6 or 3 x 10 mm.",
                X_URL: "neutral-busbars",
                X_THUMBNAIL_ALT: "Product list Neutral busbars",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046955_int.jpg",
              },
              {
                id: "HLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Sockets",
                text: "Sockets",
                X_fullName: "Sockets",
                X_Description:
                  "DIN rail-mountable sockets provide a user-friendly connection for assembly tools, servicing devices, and other equipment.",
                X_URL: "installation-sockets",
                X_THUMBNAIL_ALT: "Product list Sockets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046957_int.jpg",
              },
              {
                id: "ILI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\DIN rails",
                text: "DIN rails",
                X_fullName: "DIN rails",
                X_Description:
                  "Standardized DIN rails provide the basis for a secure hold for DIN rail-mountable components for the control cabinet.",
                X_URL: "din-rails",
                X_THUMBNAIL_ALT: "Product list DIN rails",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046958_int.jpg",
              },
              {
                id: "JLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cable ducts",
                text: "Cable ducts",
                X_fullName: "Cable ducts",
                X_Description:
                  "Cable ducts enable flexible and easy cable installation without tools.",
                X_URL: "cable-ducts",
                X_THUMBNAIL_ALT: "Product list Cable ducts",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046959_int.jpg",
              },
              {
                id: "KLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cover profiles",
                text: "Cover profiles",
                X_fullName: "Cover profiles",
                X_Description:
                  "Cover profiles prevent accidental contact with terminal strips or unauthorized actuation of connections.",
                X_URL: "cover-profiles",
                X_THUMBNAIL_ALT: "Product list Cover profiles",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046961_int.jpg",
              },
              {
                id: "LLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\End clamps",
                text: "End clamps",
                X_fullName: "End clamps",
                X_Description:
                  "Depending on the application, end clamps are fastened using screws or are simply snapped onto the DIN rail.",
                X_URL: "end-clamps",
                X_THUMBNAIL_ALT: "Product list End clamps",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046962_int.jpg",
              },
              {
                id: "MLI0000101",
                parent_id: "CLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Installation material for the control cabinet\\Cable bundling",
                text: "Cable bundling",
                X_fullName: "Cable bundling",
                X_Description:
                  "Cable binder, cable driller or cable binder base made from plastic or stainless steel.",
                X_URL: "cable-bundling",
                X_THUMBNAIL_ALT: "Product list Cable bundling",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055356_int.jpg",
              },
            ],
          },
          {
            id: "NLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Grounding and shielding",
            text: "Grounding and shielding",
            X_fullName: "Grounding and shielding",
            X_Description:
              "Appropriate shielding protects systems against electromagnetic interference.",
            X_URL: "grounding-and-shielding",
            X_THUMBNAIL_ALT: "Grounding and shielding",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0044995_int.jpg",
            items: [
              {
                id: "OLI0000101",
                parent_id: "NLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Grounding and shielding\\Grounding terminal blocks",
                text: "Grounding terminal blocks",
                X_fullName: "Grounding terminal blocks",
                X_Description:
                  "Ground terminal blocks and connection terminal blocks for PE distribution via DIN rails and busbars.",
                X_URL: "grounding-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Grounding terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046963_int.jpg",
              },
              {
                id: "PLI0000101",
                parent_id: "NLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Grounding and shielding\\Shield connection clamps",
                text: "Shield connection clamps",
                X_fullName: "Shield connection clamps",
                X_Description:
                  "Shield connection clamps are suitable for all standard cable shielding and enable EMC-compliant wiring.",
                X_URL: "shield-connection-clamps",
                X_THUMBNAIL_ALT: "Product list Shield connection clamps",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046964_int.jpg",
              },
            ],
          },
          {
            id: "QLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable entry system",
            text: "Cable entry system",
            X_fullName: "Cable entry system",
            X_Description:
              "Cable entries simplify mounting and ensure the safe routing of cables.",
            X_URL: "cable-entry-system",
            X_THUMBNAIL_ALT: "Cable entry system",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0055247_int.jpg",
            items: [
              {
                id: "RLI0000101",
                parent_id: "QLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable entry system\\Modular cable entry system",
                text: "Modular cable entry system",
                X_fullName: "Modular cable entry system",
                X_Description:
                  "The modular entry system for pre-assembled cables provides a simple solution for control cabinet cable feed-throughs.",
                X_URL: "modular-cable-entry-system",
                X_THUMBNAIL_ALT: "Product list Modular entry system",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043496_int.jpg",
              },
              {
                id: "SLI0000101",
                parent_id: "QLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable entry system\\Cable entry plates",
                text: "Cable entry plates",
                X_fullName: "Cable entry plates",
                X_Description:
                  "Metal-reinforced cable entry plates for unassembled cables.",
                X_URL: "cable-entry-plates",
                X_THUMBNAIL_ALT: "Product list Cable entry plates",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062960_int.jpg",
              },
            ],
          },
          {
            id: "TLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces",
            text: "Sockets and service interfaces",
            X_fullName: "Sockets and service interfaces",
            X_Description:
              "Comprehensive product range for reliable maintenance and testing.",
            X_URL: "sockets-and-service-interfaces",
            X_THUMBNAIL_ALT: "Sockets and service interfaces",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076855_int.jpg",
            items: [
              {
                id: "ULI0000101",
                parent_id: "TLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Service sockets",
                text: "Service sockets",
                X_fullName: "Service sockets",
                X_Description:
                  "Service sockets as a compact programming interface in various versions.",
                X_URL: "service-sockets",
                X_THUMBNAIL_ALT: "Product list Service sockets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055359_int.jpg",
              },
              {
                id: "VLI0000101",
                parent_id: "TLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Service interfaces",
                text: "Service interfaces",
                X_fullName: "Service interfaces",
                X_Description:
                  "Comprehensive product range for reliable maintenance or testing.",
                X_URL: "service-interfaces",
                X_THUMBNAIL_ALT: "Product list Service interfaces",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055428_int.jpg",
              },
              {
                id: "WLI0000101",
                parent_id: "TLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Sockets and service interfaces\\Sockets",
                text: "Sockets",
                X_fullName: "Sockets",
                X_Description:
                  "Control cabinet sockets with various connector geometries for universal use and numerous additional functions.",
                X_URL: "sockets",
                X_THUMBNAIL_ALT: "Product list Sockets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077359_int.jpg",
              },
            ],
          },
          {
            id: "YLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\DIN rail mounting frame",
            text: "DIN rail mounting frame",
            X_fullName: "DIN rail mounting frame",
            X_Description:
              "Plastic and metal DIN rail mounting frames for the space-saving connection of contact inserts directly on the DIN rail.",
            X_URL: "din-rail-mounting-frame",
            X_THUMBNAIL_ALT: "Product list DIN rail mounting frames",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0047678_int.jpg",
          },
          {
            id: "ZLI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable protection systems",
            text: "Cable protection systems",
            X_fullName: "Cable protection systems",
            X_Description:
              "Plastic or metal protection systems for cables and lines.",
            X_URL: "cable-protection-systems",
            X_THUMBNAIL_ALT: "Cable protection systems",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0048150_int.jpg",
            items: [
              {
                id: "0MI0000101",
                parent_id: "ZLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable protection systems\\Protective sleeves",
                text: "Protective sleeves",
                X_fullName: "Protective sleeves",
                X_Description:
                  "Protective hoses provide optimum protection for cables against various external influences.",
                X_URL: "protective-sleeves",
                X_THUMBNAIL_ALT: "Product list Protective hoses",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0048154_int.jpg",
              },
              {
                id: "1MI0000101",
                parent_id: "ZLI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable protection systems\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for cable protection systems, for example, protective hose holder, fixing bracket, O-ring.",
                X_URL: "cable-protection-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055360_int.jpg",
              },
            ],
          },
          {
            id: "2MI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable glands",
            text: "Cable glands",
            X_fullName: "Cable glands",
            X_Description:
              "Reliable screw connections seal cables against water and dust.",
            X_URL: "cable-glands",
            X_THUMBNAIL_ALT: "Cable glands",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0056805_int.jpg",
            items: [
              {
                id: "3MI0000101",
                parent_id: "2MI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable glands\\Standard cable glands",
                text: "Standard cable glands",
                X_fullName: "Standard cable glands",
                X_Description:
                  "Cable glands are used for the watertight and dust-proof routing of cables through control cabinet panels.",
                X_URL: "standard-cable-glands",
                X_THUMBNAIL_ALT: "Product list Standard cable glands",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0044562_int.jpg",
              },
              {
                id: "4MI0000101",
                parent_id: "2MI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Cable glands\\Cable glands for hazardous areas",
                text: "Cable glands for hazardous areas",
                X_fullName: "Cable glands for hazardous areas",
                X_Description: "Seal cables and lines reliably in the ex area.",
                X_URL: "cable-glands-for-hazardous-areas",
                X_THUMBNAIL_ALT: "Product list Cable glands for the ex area",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0056851_int.jpg",
              },
            ],
          },
          {
            id: "5MI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures",
            text: "Junction boxes and empty enclosures",
            X_fullName: "Junction boxes and empty enclosures",
            X_Description:
              "With CLIPSAFE, you can get your custom control cabinet solution quickly and from a single source.",
            X_URL: "junction-boxes-and-enclosures",
            X_THUMBNAIL_ALT: "Junction boxes and empty enclosures",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0071398_int.jpg",
            items: [
              {
                id: "6MI0000101",
                parent_id: "5MI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure",
                text: "Ex stainless steel empty enclosure",
                X_fullName: "Ex stainless steel empty enclosure",
                X_Description:
                  "Individually customizable stainless steel enclosures for use in hazardous areas.",
                X_URL: "stainless-steel-empty-enclosure",
                X_THUMBNAIL_ALT: "Ex stainless steel empty enclosure",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060623_int.jpg",
                items: [
                  {
                    id: "7MI0000101",
                    parent_id: "6MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure\\Ex empty enclosure with cover",
                    text: "Ex empty enclosure with cover",
                    X_fullName: "Ex empty enclosure with cover",
                    X_Description:
                      "Standardized CLIPSAFE empty enclosure for hazardous areas.",
                    X_URL: "ex-stainless-empty-enclosure-cov",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosure with cover",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070239_int.jpg",
                  },
                  {
                    id: "8MI0000101",
                    parent_id: "6MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex stainless steel empty enclosure\\Ex empty enclosure with door",
                    text: "Ex empty enclosure with door",
                    X_fullName: "Ex empty enclosure with door",
                    X_Description:
                      "Stainless steel empty enclosure with door for safe use in hazardous areas",
                    X_URL: "ex-stainless-empty-enclosure",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosure with door",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062961_int.jpg",
                  },
                ],
              },
              {
                id: "9MI0000101",
                parent_id: "5MI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure",
                text: "Ex polyester empty enclosure",
                X_fullName: "Ex polyester empty enclosure",
                X_Description:
                  "With CLIPSAFE polyester Ex enclosures, get your custom control cabinet solution for hazardous areas.",
                X_URL: "ex-polyester-empty-enclosure",
                X_THUMBNAIL_ALT: "Ex polyester empty enclosure",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069873_int.jpg",
                items: [
                  {
                    id: "AMI0000101",
                    parent_id: "9MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with cover",
                    text: "Ex empty enclosure with cover",
                    X_fullName: "Ex empty enclosure with cover",
                    X_Description:
                      "Polyester empty enclosures with cover for safe use in hazardous areas",
                    X_URL: "ex-empty-enclosure-with-cover",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosures with cover",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069966_int.jpg",
                  },
                  {
                    id: "BMI0000101",
                    parent_id: "9MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with door",
                    text: "Ex empty enclosure with door",
                    X_fullName: "Ex empty enclosure with door",
                    X_Description:
                      "Polyester empty enclosures with door for safe use in hazardous areas",
                    X_URL: "ex-empty-enclosure-with-door",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosures with door",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069969_int.jpg",
                  },
                  {
                    id: "CMI0000101",
                    parent_id: "9MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure with two wiring levels",
                    text: "Ex empty enclosure with two wiring levels",
                    X_fullName: "Ex empty enclosure with two wiring levels",
                    X_Description:
                      "Polyester empty enclosure with two wiring levels for safe use in the ex area",
                    X_URL: "2-wiringlevels-empty-enclosure",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosure with two wiring levels",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069970_int.jpg",
                  },
                  {
                    id: "DMI0000101",
                    parent_id: "9MI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Ex polyester empty enclosure\\Ex empty enclosure, octagonal",
                    text: "Ex empty enclosure, octagonal",
                    X_fullName: "Ex empty enclosure, octagonal",
                    X_Description:
                      "Polyester empty enclosure in octagonal design for safe use in the ex area",
                    X_URL: "octagonal-enclosure",
                    X_THUMBNAIL_ALT:
                      "Product list Ex empty enclosure octagonal",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069971_int.jpg",
                  },
                ],
              },
              {
                id: "EMI0000101",
                parent_id: "5MI0000101",
                FULLPATH:
                  "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure",
                text: "Empty polyester enclosure",
                X_fullName: "Empty polyester enclosure",
                X_Description:
                  "You will be completely satisfied with our extensive and consistent range of CLIPSAFE polyester enclosures.",
                X_URL: "empty-enclosure",
                X_THUMBNAIL_ALT: "Empty polyester enclosure",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069975_int.jpg",
                items: [
                  {
                    id: "FMI0000101",
                    parent_id: "EMI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure\\Empty enclosure with cover",
                    text: "Empty enclosure with cover",
                    X_fullName: "Empty enclosure with cover",
                    X_Description: "Empty polyester enclosure",
                    X_URL: "empty-enclosure-cover",
                    X_THUMBNAIL_ALT: "Product list Empty enclosure with cover",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069977_int.jpg",
                  },
                  {
                    id: "GMI0000101",
                    parent_id: "EMI0000101",
                    FULLPATH:
                      "PhoenixContact\\Installation and mounting material\\Junction boxes and empty enclosures\\Empty polyester enclosure\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description: "Accessories for polyester empty enclosures",
                    X_URL: "empty-enclosure-accessories",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069979_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "IMI0000101",
            parent_id: "BLI0000101",
            FULLPATH:
              "PhoenixContact\\Installation and mounting material\\Cable routing system",
            text: "Cable routing system",
            X_fullName: "Cable routing system",
            X_Description:
              "Cable and conductor routing for the control cabinet door",
            X_URL: "cable-routing-system",
            X_THUMBNAIL_ALT: "Product list Cable routing system",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0074628_int.jpg",
          },
        ],
      },
      {
        id: "JMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Cables and lines",
        text: "Cables and lines",
        X_fullName: "Cables and lines",
        X_Description:
          "Are you looking for a flexible assembly solution? With the right copper or FO cable, you'll find the perfect solution for your application.",
        X_URL: "cables-and-lines-main",
        X_THUMBNAIL_ALT: "Cables and lines",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042801_int.jpg",
        items: [
          {
            id: "KMI0000101",
            parent_id: "JMI0000101",
            FULLPATH: "PhoenixContact\\Cables and lines\\Copper cables",
            text: "Copper cables",
            X_fullName: "Copper cables",
            X_Description:
              "Copper cables are available in different versions for a number of applications.",
            X_URL: "copper-cables",
            X_THUMBNAIL_ALT: "Copper cables",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042805_int.jpg",
            items: [
              {
                id: "LMI0000101",
                parent_id: "KMI0000101",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\Assembled cables",
                text: "Assembled cables",
                X_fullName: "Assembled cables",
                X_Description:
                  "Assembled copper cables are available in various lengths and with different connector designs.",
                X_URL: "assembled-cables-cooper",
                X_THUMBNAIL_ALT: "Product list Assembled cables",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043210_int.jpg",
              },
              {
                id: "MMI0000101",
                parent_id: "KMI0000101",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\By the meter",
                text: "By the meter",
                X_fullName: "By the meter",
                X_Description:
                  "Copper cables are available with various numbers of positions and cross sections in variable lengths sold by the meter.",
                X_URL: "cables-by-the-meter",
                X_THUMBNAIL_ALT: "Product list Cables sold by the meter",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043211_int.jpg",
              },
              {
                id: "NMI0000101",
                parent_id: "KMI0000101",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Copper cables\\Tools and accessories",
                text: "Tools and accessories",
                X_fullName: "Tools and accessories",
                X_Description:
                  "Tools and accessories for cables, such as torque screwdrivers, nuts, stripping pliers, marking accessories.",
                X_URL: "tools-and-accessories",
                X_THUMBNAIL_ALT: "Product list Tools and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043213_int.jpg",
              },
            ],
          },
          {
            id: "OMI0000101",
            parent_id: "JMI0000101",
            FULLPATH: "PhoenixContact\\Cables and lines\\Fiber optic cables",
            text: "Fiber optic cables",
            X_fullName: "Fiber optic cables",
            X_Description:
              "FO cables for various applications: sold by the meter or pre-assembled.",
            X_URL: "fiber-optic-cables",
            X_THUMBNAIL_ALT: "FO cables",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042806_int.jpg",
            items: [
              {
                id: "PMI0000101",
                parent_id: "OMI0000101",
                FULLPATH:
                  "PhoenixContact\\Cables and lines\\Fiber optic cables\\Assembled cables",
                text: "Assembled cables",
                X_fullName: "Assembled cables",
                X_Description:
                  "Assembled fiber optic cables are available with various connector designs and fiber types.",
                X_URL: "assembled-cables-fiber",
                X_THUMBNAIL_ALT: "Product list Assembled cables",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043203_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "QMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\PCB terminal blocks and connectors",
        text: "PCB terminal blocks and connectors",
        X_fullName: "PCB terminal blocks and connectors",
        X_Description:
          "Everything is possible with arguably the largest range of PCB connection technology: COMBICON from Phoenix Contact.",
        X_URL: "pcb-terminal-blocks-connectors",
        X_THUMBNAIL_ALT: "PCB terminal blocks and PCB connectors",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088802_int.jpg",
        items: [
          {
            id: "SMI0000101",
            parent_id: "QMI0000101",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\PCB terminal blocks",
            text: "PCB terminal blocks",
            X_fullName: "PCB terminal blocks",
            X_Description:
              "PCB terminal blocks for transmission of signals, data, and power directly to the PCB.",
            X_URL: "pcb-terminal-blocks",
            X_THUMBNAIL_ALT: "Product list PCB terminal blocks",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0062874_int.jpg",
          },
          {
            id: "UMI0000101",
            parent_id: "QMI0000101",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\PCB connectors",
            text: "PCB connectors",
            X_fullName: "PCB connectors",
            X_Description:
              "PCB connectors for virtually all device designs of various different sectors and markets.",
            X_URL: "pcb-connectors",
            X_THUMBNAIL_ALT: "Product list PCB connectors",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0062875_int.jpg",
          },
          {
            id: "WMI0000101",
            parent_id: "QMI0000101",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\High-current panel feed-through terminal blocks",
            text: "High-current panel feed-through terminal blocks",
            X_fullName: "High-current panel feed-through terminal blocks",
            X_Description:
              "High-current feed-through terminal blocks for transmitting high currents and voltages through the housing wall.",
            X_URL: "pcb-high-current-terminal-blocks",
            X_THUMBNAIL_ALT:
              "Product list High-current feed-through terminal blocks",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0068506_int.jpg",
          },
          {
            id: "YMI0000101",
            parent_id: "QMI0000101",
            FULLPATH:
              "PhoenixContact\\PCB terminal blocks and connectors\\Board-to-board connectors",
            text: "Board-to-board connectors",
            X_fullName: "Board-to-board connectors",
            X_Description:
              "FINEPITCH board-to-board connectors provide solutions for signal and data transmission.",
            X_URL: "board-to-board-connectors",
            X_THUMBNAIL_ALT: "Product list Board-to-board connectors",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0088113_int.jpg",
          },
        ],
      },
      {
        id: "ZMI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Marking and labeling",
        text: "Marking and labeling",
        X_fullName: "Marking and labeling",
        X_Description:
          "The easy way of marking. \u2013 Our solutions for marking and labeling can be tailored to your specific requirements.",
        X_URL: "marking-and-labeling",
        X_THUMBNAIL_ALT: "Marking and labeling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084449_int.jpg",
        items: [
          {
            id: "0NI0000101",
            parent_id: "ZMI0000101",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\Printing and marking systems",
            text: "Printing and marking systems",
            X_fullName: "Printing and marking systems",
            X_Description:
              "The printing systems are versatile: they can be used for low and high print volumes and for various different materials and formats.",
            X_URL: "printing-and-marking-systems",
            X_THUMBNAIL_ALT: "Printing and marking systems",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0083922_int.jpg",
            items: [
              {
                id: "1NI0000101",
                parent_id: "0NI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system",
                text: "UV LED printing system",
                X_fullName: "UV LED printing system",
                X_Description:
                  "The UV LED printing system labels large quantities quickly and easily, with high-quality results.",
                X_URL: "uv-led-printing-system",
                X_THUMBNAIL_ALT: "UV LED printing system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0083944_int.jpg",
                items: [
                  {
                    id: "2NI0000101",
                    parent_id: "1NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\BLUEMARK",
                    text: "BLUEMARK",
                    X_fullName: "BLUEMARK",
                    X_Description:
                      "The BLUEMARK CLED high-speed printer prints materials in UniCard format quickly and easily.",
                    X_URL: "bluemark",
                    X_THUMBNAIL_ALT: "Product list BLUEMARK",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0083944_int.jpg",
                  },
                  {
                    id: "3NI0000101",
                    parent_id: "1NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\Accessories and consumables",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_Description:
                      "Accessories and consumables for the high-speed printer, such as fluid cartridges, card chutes, and transport case.",
                    X_URL: "uv-accessories-and-consumables",
                    X_THUMBNAIL_ALT: "Product list Accessories and consumables",
                    X_picturelink:
                      "mimes/general/images/pic_gall_thb_a_0083943_int.jpg",
                  },
                  {
                    id: "4NI0000101",
                    parent_id: "1NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\UV LED printing system\\Marking material for BLUEMARK",
                    text: "Marking material for BLUEMARK",
                    X_fullName: "Marking material for BLUEMARK",
                    X_Description:
                      "UniCard formats and aluminum markers for marking with the BLUEMARK CLED high-speed printer.",
                    X_URL: "marking-material-for-bluemark",
                    X_THUMBNAIL_ALT:
                      "Product list Marking material for BLUEMARK",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067360_int.jpg",
                  },
                ],
              },
              {
                id: "5NI0000101",
                parent_id: "0NI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system",
                text: "Plotter and engraving system",
                X_fullName: "Plotter and engraving system",
                X_Description:
                  "The plotter with engraving system for markings in challenging environments is very easy to use.",
                X_URL: "plotter-and-engraving-system",
                X_THUMBNAIL_ALT: "Plotter and engraving system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0076765_int.jpg",
                items: [
                  {
                    id: "6NI0000101",
                    parent_id: "5NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Plotter for labeling",
                    text: "Plotter for labeling",
                    X_fullName: "Plotter for labeling",
                    X_Description:
                      "The plotter for labeling is operated easily using just a few keys.",
                    X_URL: "plotter-for-labeling",
                    X_THUMBNAIL_ALT: "Product list Plotter for labeling",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042673_int.jpg",
                  },
                  {
                    id: "7NI0000101",
                    parent_id: "5NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Plotter with engraving unit",
                    text: "Plotter with engraving unit",
                    X_fullName: "Plotter with engraving unit",
                    X_Description:
                      "The plotter with engraving unit is the ideal marking solution for devices and components subjected to high levels of mechanical and chemical stress.",
                    X_URL: "plotter-with-engraving-unit",
                    X_THUMBNAIL_ALT: "Product list Plotter with engraving unit",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042675_int.jpg",
                  },
                  {
                    id: "8NI0000101",
                    parent_id: "5NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Plotter and engraving system\\Accessories and consumables",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_Description:
                      "The range includes exchangeable magazines, plotter pens, and marker pens.",
                    X_URL: "plot-accessories-and-consumables",
                    X_THUMBNAIL_ALT: "Product list Accessories and consumables",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042674_int.jpg",
                  },
                ],
              },
              {
                id: "9NI0000101",
                parent_id: "0NI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system",
                text: "Thermal transfer printing system",
                X_fullName: "Thermal transfer printing system",
                X_Description:
                  "The thermal transfer printing system prints on numerous materials quickly and easily. The device is available individually or as part of a set including notebook.",
                X_URL: "thermal-transfer-printing-system",
                X_THUMBNAIL_ALT: "Thermal transfer printing system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042186_int.jpg",
                items: [
                  {
                    id: "ANI0000101",
                    parent_id: "9NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Individual devices",
                    text: "Individual devices",
                    X_fullName: "Individual devices",
                    X_Description:
                      "THERMOMARK CARD prints markings in card format, while THERMOMARK ROLL prints markings in roll format.",
                    X_URL: "individual-devices",
                    X_THUMBNAIL_ALT: "Product list Individual devices",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042186_int.jpg",
                  },
                  {
                    id: "BNI0000101",
                    parent_id: "9NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Accessories and consumables",
                    text: "Accessories and consumables",
                    X_fullName: "Accessories and consumables",
                    X_Description:
                      "Accessories and consumables for thermal transfer printers, such as magazines, cutters, and ink ribbons.",
                    X_URL: "accessories-and-consumables",
                    X_THUMBNAIL_ALT: "Product list Accessories and consumables",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0045233_int.jpg",
                  },
                  {
                    id: "CNI0000101",
                    parent_id: "9NI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Thermal transfer printing system\\Marking material for THERMOMARK ROLL",
                    text: "Marking material for THERMOMARK ROLL",
                    X_fullName: "Marking material for THERMOMARK ROLL",
                    X_Description:
                      "Material off the roll and continuous media for marking with the THERMOMARK ROLL.",
                    X_URL: "thermomark-roll-marking-material",
                    X_THUMBNAIL_ALT:
                      "Product list Marking material for THERMOMARK ROLL",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067362_int.jpg",
                  },
                ],
              },
              {
                id: "DNI0000101",
                parent_id: "0NI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system",
                text: "Laser marking system",
                X_fullName: "Laser marking system",
                X_Description:
                  "Fast direct laser marking for challenging industrial identification.",
                X_URL: "laser-marking-system",
                X_THUMBNAIL_ALT: "Laser marking system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0084042_int.jpg",
                items: [
                  {
                    id: "ENI0000101",
                    parent_id: "DNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Laser marking systems",
                    text: "Laser marking systems",
                    X_fullName: "Laser marking systems",
                    X_Description:
                      "TOPMARK LASER \u2013 the fastest desktop laser marker in its class.",
                    X_URL: "laser-marking-systems",
                    X_THUMBNAIL_ALT: "Product list Laser marking systems",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084042_int.jpg",
                  },
                  {
                    id: "FNI0000101",
                    parent_id: "DNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Marking material",
                    text: "Marking material",
                    X_fullName: "Marking material",
                    X_Description:
                      "Marking material for direct laser marking using the TOPMARK LASER.",
                    X_URL: "laser-marking-material",
                    X_THUMBNAIL_ALT: "Product list Marking material",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056855_int.jpg",
                  },
                  {
                    id: "GNI0000101",
                    parent_id: "DNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Laser marking system\\Accessories for the direct laser marking system",
                    text: "Accessories for the direct laser marking system",
                    X_fullName:
                      "Accessories for the direct laser marking system",
                    X_Description:
                      "Carriage, magazines, and notebook for direct laser marking using the TOPMARK LASER.",
                    X_URL: "laser-marking-system-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list Accessories for the direct laser marking system",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084042_int.jpg",
                  },
                ],
              },
              {
                id: "HNI0000101",
                parent_id: "0NI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system",
                text: "Mobile thermal transfer printing system",
                X_fullName: "Mobile thermal transfer printing system",
                X_Description:
                  "Thanks to the mobile printing systems, you can print markings right where they are needed",
                X_URL: "mobile-thermal-transfer-printing",
                X_THUMBNAIL_ALT: "Mobile thermal transfer printing system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069847_int.jpg",
                items: [
                  {
                    id: "INI0000101",
                    parent_id: "HNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\THERMOMARK PRIME accessories",
                    text: "THERMOMARK PRIME accessories",
                    X_fullName: "THERMOMARK PRIME accessories",
                    X_Description:
                      "Accessories such as power supply unit, replaceable battery, transport case or rucksack facilitate on-site operation of the printer.",
                    X_URL: "thermomark-prime-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list THERMOMARK PRIME accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070021_int.jpg",
                  },
                  {
                    id: "JNI0000101",
                    parent_id: "HNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\THERMOFOX accessories",
                    text: "THERMOFOX accessories",
                    X_fullName: "THERMOFOX accessories",
                    X_Description:
                      "Whether in a transport case, in the shoulder bag or on the practical belt clip, various accessories enable the device to be transported conveniently.",
                    X_URL: "thermofox-accessories",
                    X_THUMBNAIL_ALT: "Product list THERMOFOX accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070024_int.jpg",
                  },
                  {
                    id: "KNI0000101",
                    parent_id: "HNI0000101",
                    FULLPATH:
                      "PhoenixContact\\Marking and labeling\\Printing and marking systems\\Mobile thermal transfer printing system\\Marking material for THERMOFOX",
                    text: "Marking material for THERMOFOX",
                    X_fullName: "Marking material for THERMOFOX",
                    X_Description:
                      "The THERMOFOX can be used to mark a variety of materials for terminal, conductor, and equipment marking.",
                    X_URL: "marking-material-thermofox",
                    X_THUMBNAIL_ALT:
                      "Product list Marking material for THERMOFOX",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070025_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "LNI0000101",
            parent_id: "ZMI0000101",
            FULLPATH: "PhoenixContact\\Marking and labeling\\Device marking",
            text: "Device marking",
            X_fullName: "Device marking",
            X_Description:
              "With various materials and mounting types, the equipment markings cover all requirements.",
            X_URL: "device-marking-main",
            X_THUMBNAIL_ALT: "Equipment marking",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042188_int.jpg",
            items: [
              {
                id: "MNI0000101",
                parent_id: "LNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Device marking",
                text: "Device marking",
                X_fullName: "Device marking",
                X_Description:
                  "Self-adhesive device markings feature high adhesive strength and can be labeled quickly and easily.",
                X_URL: "device-marking",
                X_THUMBNAIL_ALT: "Product list Equipment marking",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042188_int.jpg",
              },
              {
                id: "NNI0000101",
                parent_id: "LNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Marker carrier",
                text: "Marker carrier",
                X_fullName: "Marker carrier",
                X_Description:
                  "The plastic labels are used in marker carriers for marking.",
                X_URL: "marker-carrier",
                X_THUMBNAIL_ALT: "Product list Marker carriers",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0045237_int.jpg",
              },
              {
                id: "ONI0000101",
                parent_id: "LNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Device marking\\Device marking, marked according to customer requi",
                text: "Device marking, marked according to customer requi",
                X_fullName:
                  "Device marking, marked according to customer requi",
                X_Description:
                  "Device marking, individually marked according to customer requirements",
                X_URL: "marked-according-to-customer",
                X_THUMBNAIL_ALT:
                  "Product list Equipment marking, marked according to customer requirements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0065596_int.jpg",
              },
            ],
          },
          {
            id: "QNI0000101",
            parent_id: "ZMI0000101",
            FULLPATH: "PhoenixContact\\Marking and labeling\\Terminal marking",
            text: "Terminal marking",
            X_fullName: "Terminal marking",
            X_Description:
              "Labeled and unlabeled terminal markings are available in various formats, including roll and sheet form.",
            X_URL: "terminal-marking",
            X_THUMBNAIL_ALT: "Product list Terminal marking",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042189_int.jpg",
          },
          {
            id: "RNI0000101",
            parent_id: "ZMI0000101",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\Conductor and cable marking",
            text: "Conductor and cable marking",
            X_fullName: "Conductor and cable marking",
            X_Description:
              "Wire markings are suitable for every application, whether for fine single wires or heavy-duty cables.",
            X_URL: "conductor-and-cable-marking-main",
            X_THUMBNAIL_ALT: "Wire and cable marking",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042190_int.jpg",
            items: [
              {
                id: "SNI0000101",
                parent_id: "RNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Conductor and cable marking\\Conductor and cable marking",
                text: "Conductor and cable marking",
                X_fullName: "Conductor and cable marking",
                X_Description:
                  "Wires and cables can be labeled in various ways, for example, using thread-on, slide-on or adhesive markings.",
                X_URL: "conductor-and-cable-marking",
                X_THUMBNAIL_ALT: "Product list Wire and cable marking",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0045240_int.jpg",
              },
              {
                id: "TNI0000101",
                parent_id: "RNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\Conductor and cable marking\\Conductor and cable marking, marked according to c",
                text: "Conductor and cable marking, marked according to c",
                X_fullName:
                  "Conductor and cable marking, marked according to c",
                X_Description:
                  "Wire and cable marking, individually marked according to customer requirements",
                X_URL: "customer-requirement-marking",
                X_THUMBNAIL_ALT:
                  "Product list Wire and cable marking, marked according to customer requirements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0065592_int.jpg",
              },
            ],
          },
          {
            id: "VNI0000101",
            parent_id: "ZMI0000101",
            FULLPATH: "PhoenixContact\\Marking and labeling\\System marking",
            text: "System marking",
            X_fullName: "System marking",
            X_Description: "Plant marking for greater safety and orientation.",
            X_URL: "system-marking",
            X_THUMBNAIL_ALT: "Product list Plant marking",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0051565_int.jpg",
          },
          {
            id: "WNI0000101",
            parent_id: "ZMI0000101",
            FULLPATH:
              "PhoenixContact\\Marking and labeling\\RFID system for system and device marking",
            text: "RFID system for system and device marking",
            X_fullName: "RFID system for system and device marking",
            X_Description:
              "Store and read information on marking material with the integrated RFID transponders.",
            X_URL: "rfid-for-system-device-marking",
            X_THUMBNAIL_ALT: "RFID system for system and device marking",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0056751_int.jpg",
            items: [
              {
                id: "XNI0000101",
                parent_id: "WNI0000101",
                FULLPATH:
                  "PhoenixContact\\Marking and labeling\\RFID system for system and device marking\\Marking material",
                text: "Marking material",
                X_fullName: "Marking material",
                X_Description:
                  "UniCard marking material, with integrated RFID transponder.",
                X_URL: "marking-material",
                X_THUMBNAIL_ALT: "Product list Marking material",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0073880_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "YNI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\MCR technology",
        text: "MCR technology",
        X_fullName: "MCR technology",
        X_Description:
          "Measurement and control components from Phoenix Contact offer innovative solutions for the automation of your processes \u2013 from sensor level to control level.",
        X_URL: "mcr-technology",
        X_THUMBNAIL_ALT: "Measurement and control technology",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0076657_int.jpg",
        items: [
          {
            id: "ZNI0000101",
            parent_id: "YNI0000101",
            FULLPATH: "PhoenixContact\\MCR technology\\Signal conditioner",
            text: "Signal conditioner",
            X_fullName: "Signal conditioner",
            X_Description:
              "Reliable signal transmission, isolation, conversion, filtering, and amplification \u2013 our signal conditioners ensure interference-free transmission of analog signals.",
            X_URL: "signal-conditioner",
            X_THUMBNAIL_ALT: "Signal conditioners",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076648_int.jpg",
            items: [
              {
                id: "0OI0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners",
                text: "Highly compact signal conditioners",
                X_fullName: "Highly compact signal conditioners",
                X_Description:
                  "Isolate, convert, filter, and amplify with the highly-compact signal conditioners from the MINI Analog range.",
                X_URL: "highly-compact-sig-conditioners",
                X_THUMBNAIL_ALT: "Highly compact signal conditioners",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051601_int.jpg",
                items: [
                  {
                    id: "1OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Active input and output signal conditioners",
                    text: "Active input and output signal conditioners",
                    X_fullName: "Active input and output signal conditioners",
                    X_Description:
                      "Ultra-compact isolating amplifiers for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_URL: "active-io-signal-conditioners",
                    X_THUMBNAIL_ALT:
                      "Product list Active input and output signal conditioners",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044103_int.jpg",
                  },
                  {
                    id: "2OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Signal duplicators",
                    text: "Signal duplicators",
                    X_fullName: "Signal duplicators",
                    X_Description:
                      "Ultra-compact signal duplicators for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_URL: "highly-signal-duplicators",
                    X_THUMBNAIL_ALT: "Product list Signal duplicators",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044104_int.jpg",
                  },
                  {
                    id: "3OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Repeater power supplies",
                    text: "Repeater power supplies",
                    X_fullName: "Repeater power supplies",
                    X_Description:
                      "Ultra-compact repeater power supplies for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_URL: "repeater-power-supplies",
                    X_THUMBNAIL_ALT: "Product list Repeater power supplies",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044105_int.jpg",
                  },
                  {
                    id: "4OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Loop-powered isolators",
                    text: "Loop-powered isolators",
                    X_fullName: "Loop-powered isolators",
                    X_Description:
                      "Ultra-compact 2-wire passive isolators for electrical isolation and filtering of standard analog signals.",
                    X_URL: "loop-powered-isolators",
                    X_THUMBNAIL_ALT: "Product list Passive isolators",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044106_int.jpg",
                  },
                  {
                    id: "5OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Temperature transducers",
                    text: "Temperature transducers",
                    X_fullName: "Temperature transducers",
                    X_Description:
                      "Ultra-compact temperature transducers for electrical isolation, conversion, amplification, and filtering of temperature signals to create standard analog signals.",
                    X_URL: "compact-temperature-transducers",
                    X_THUMBNAIL_ALT: "Product list Temperature transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044107_int.jpg",
                  },
                  {
                    id: "6OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Frequency transducers",
                    text: "Frequency transducers",
                    X_fullName: "Frequency transducers",
                    X_Description:
                      "Ultra-compact analog-to-frequency transducer for electrical isolation, amplification, conversion, and filtering of standard signals to create frequencies or PWM signals.",
                    X_URL: "frequency-transducers",
                    X_THUMBNAIL_ALT: "Product list Frequency transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044108_int.jpg",
                  },
                  {
                    id: "7OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Potiposition transducers",
                    text: "Potiposition transducers",
                    X_fullName: "Potiposition transducers",
                    X_Description:
                      "Ultra-compact potentiometer measuring transducers for electrical isolation, conversion, amplification, and filtering of potentiometer signals to create standard signals.",
                    X_URL: "potiposition-transducers",
                    X_THUMBNAIL_ALT:
                      "Product list Potentiometer measuring transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044109_int.jpg",
                  },
                  {
                    id: "8OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Threshold value switches",
                    text: "Threshold value switches",
                    X_fullName: "Threshold value switches",
                    X_Description:
                      "Ultra-compact threshold value switch for switching analog limit values.",
                    X_URL: "threshold-value-switches",
                    X_THUMBNAIL_ALT: "Product list Threshold value switches",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044110_int.jpg",
                  },
                  {
                    id: "9OI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\Isolation amplifiers",
                    text: "Isolation amplifiers",
                    X_fullName: "Isolation amplifiers",
                    X_Description:
                      "Ultra-compact isolation amplifier for electrical isolation, amplification, and duplication of proximity sensor signals.",
                    X_URL: "isolation-amplifiers",
                    X_THUMBNAIL_ALT: "Product list Isolation amplifiers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044112_int.jpg",
                  },
                  {
                    id: "AOI0000101",
                    parent_id: "0OI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners\\System cabling and accessories",
                    text: "System cabling and accessories",
                    X_fullName: "System cabling and accessories",
                    X_Description:
                      "Accessories for installing and operating signal conditioners, such as DIN rail connectors, system adapters, and multiplexers.",
                    X_URL: "system-cabling-and-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list System cabling and accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044113_int.jpg",
                  },
                ],
              },
              {
                id: "BOI0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co",
                text: "Highly compact signal conditioners with plug-in co",
                X_fullName:
                  "Highly compact signal conditioners with plug-in co",
                X_Description:
                  "Easier than ever but as slim as before. The highly compact MINI Analog Pro signal conditioners offer the easiest installation and startup in a confined space.",
                X_URL: "compact-signal-conditioners",
                X_THUMBNAIL_ALT:
                  "Highly compact signal conditioners with plug-in connection technology",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056037_int.jpg",
                items: [
                  {
                    id: "COI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Active input and output signal conditioners",
                    text: "Active input and output signal conditioners",
                    X_fullName: "Active input and output signal conditioners",
                    X_Description:
                      "Highly compact, active signal conditioners for electrical isolation, conversion, filtering, and amplification of standard analog signals.",
                    X_URL: "active-io-signal-conditioners-w",
                    X_THUMBNAIL_ALT:
                      "Product list Active input and output signal conditioners",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056962_int.jpg",
                  },
                  {
                    id: "DOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Repeater power supplies",
                    text: "Repeater power supplies",
                    X_fullName: "Repeater power supplies",
                    X_Description:
                      "Highly compact repeater power supplies for electrical isolation, conversion, filtering, and amplification of standard analog signals.",
                    X_URL: "plug-repeater-power-supplies",
                    X_THUMBNAIL_ALT: "Product list Repeater power supplies",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056963_int.jpg",
                  },
                  {
                    id: "EOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Temperature transducer",
                    text: "Temperature transducer",
                    X_fullName: "Temperature transducer",
                    X_Description:
                      "Highly compact temperature transducers for electrical isolation, conversion, filtering, and amplification of temperature signals to create standard analog signals.",
                    X_URL: "temperature-transducer",
                    X_THUMBNAIL_ALT: "Product list Temperature transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056964_int.jpg",
                  },
                  {
                    id: "FOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Potiposition transducers",
                    text: "Potiposition transducers",
                    X_fullName: "Potiposition transducers",
                    X_Description:
                      "Highly compact potiposition transducers for electrical isolation, conversion, filtering, and amplification of potentiometer signals to create standard analog signals.",
                    X_URL: "potiposition-transducers-w",
                    X_THUMBNAIL_ALT: "Product list Potiposition transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056965_int.jpg",
                  },
                  {
                    id: "GOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\System cabling and accessories",
                    text: "System cabling and accessories",
                    X_fullName: "System cabling and accessories",
                    X_Description:
                      "System cabling and accessories for installation and operation of signal conditioners such as DIN rail connectors, supply components, marking material, and fault monitoring modules.",
                    X_URL: "system-cabling-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list System cabling and accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056966_int.jpg",
                  },
                  {
                    id: "HOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Loop-powered isolators",
                    text: "Loop-powered isolators",
                    X_fullName: "Loop-powered isolators",
                    X_Description:
                      "Highly compact 2-way passive isolators for electrical isolation and filtering of analog standardized signals.",
                    X_URL: "plug-loop-powered-isolators",
                    X_THUMBNAIL_ALT: "Product list Loop-powered isolators",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062787_int.jpg",
                  },
                  {
                    id: "IOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Frequency transducer",
                    text: "Frequency transducer",
                    X_fullName: "Frequency transducer",
                    X_Description:
                      "Highly compact frequency transducers for electrical isolation, amplifying, converting, and filtering of analog standardized signals to frequencies or PWM signals.",
                    X_URL: "frequency-transducer",
                    X_THUMBNAIL_ALT: "Product list Frequency transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062788_int.jpg",
                  },
                  {
                    id: "JOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Threshold value switch",
                    text: "Threshold value switch",
                    X_fullName: "Threshold value switch",
                    X_Description:
                      "Highly compact threshold value switches for switching analog threshold values.",
                    X_URL: "threshold-value-switch",
                    X_THUMBNAIL_ALT: "Product list Threshold value switches",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062789_int.jpg",
                  },
                  {
                    id: "KOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Isolation amplifier",
                    text: "Isolation amplifier",
                    X_fullName: "Isolation amplifier",
                    X_Description:
                      "Highly compact signal conditioners for electrical isolation, amplifying and duplicating proximity switch signals.",
                    X_URL: "isolation-amplifier",
                    X_THUMBNAIL_ALT: "Product list Isolation amplifiers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062790_int.jpg",
                  },
                  {
                    id: "LOI0000101",
                    parent_id: "BOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Highly compact signal conditioners with plug-in co\\Signal duplicators",
                    text: "Signal duplicators",
                    X_fullName: "Signal duplicators",
                    X_Description:
                      "Highly-compact signal duplicators for electrical isolation, conversion, amplification, and filtering of standard analog signals.",
                    X_URL: "highly-signal-plug-duplicators",
                    X_THUMBNAIL_ALT: "Product list Signal duplicators",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0070036_int.jpg",
                  },
                ],
              },
              {
                id: "MOI0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety",
                text: "Signal conditioners with functional safety",
                X_fullName: "Signal conditioners with functional safety",
                X_Description:
                  "Maximum safety for your machines and systems: MACX signal conditioners are all SIL-certified.",
                X_URL: "safety-signal-conditioners",
                X_THUMBNAIL_ALT: "Signal conditioners with functional safety",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042368_int.jpg",
                items: [
                  {
                    id: "NOI0000101",
                    parent_id: "MOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety",
                    text: "Signal conditioners with SIL functional safety",
                    X_fullName:
                      "Signal conditioners with SIL functional safety",
                    X_Description:
                      "Safe isolation, conditioning, filtering, and amplifying with SIL-certified MACX Analog signal conditioners.",
                    X_URL: "sil-safety-signal-conditioners",
                    X_THUMBNAIL_ALT:
                      "Signal conditioner with SIL functional safety",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042368_int.jpg",
                    items: [
                      {
                        id: "OOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Active input signal conditioners",
                        text: "Active input signal conditioners",
                        X_fullName: "Active input signal conditioners",
                        X_Description:
                          "Active input signal conditioners for the operation of 4-wire measuring transducers for isolating, filtering, amplifying, and converting standard analog signals.",
                        X_URL: "active-input-signal-conditioners",
                        X_THUMBNAIL_ALT:
                          "Product list Active Input signal conditioners",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044114_int.jpg",
                      },
                      {
                        id: "POI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Signal duplicators",
                        text: "Signal duplicators",
                        X_fullName: "Signal duplicators",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers as signal duplicators for the operation of 2 and 4-wire measuring transducers and mA current sources.",
                        X_URL: "signal-duplicators",
                        X_THUMBNAIL_ALT: "Product list Signal duplicators",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044115_int.jpg",
                      },
                      {
                        id: "QOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Repeater power supplies",
                        text: "Repeater power supplies",
                        X_fullName: "Repeater power supplies",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers for the operation of 2 and 4-wire measuring transducers and mA current sources.",
                        X_URL: "sil-repeater-power-supplies",
                        X_THUMBNAIL_ALT: "Product list Repeater power supplies",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044116_int.jpg",
                      },
                      {
                        id: "ROI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Active output signal conditioners",
                        text: "Active output signal conditioners",
                        X_fullName: "Active output signal conditioners",
                        X_Description:
                          "Output isolating amplifiers for controlling I/P converters, control valves, and displays.",
                        X_URL: "active-output-signal-conditioner",
                        X_THUMBNAIL_ALT:
                          "Product list Active Output signal conditioners",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044117_int.jpg",
                      },
                      {
                        id: "SOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Temperature transducers",
                        text: "Temperature transducers",
                        X_fullName: "Temperature transducers",
                        X_Description:
                          "Temperature transducers for operating resistance thermometers, resistance-type sensors, thermocouples, potentiometers, and mV sources.",
                        X_URL: "sil-temperature-transducers",
                        X_THUMBNAIL_ALT: "Product list Temperature transducers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044118_int.jpg",
                      },
                      {
                        id: "TOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Threshold value switches",
                        text: "Threshold value switches",
                        X_fullName: "Threshold value switches",
                        X_Description:
                          "Universal temperature transducers with up to three limit value relays - can be used in combination as a safe limit value relay.",
                        X_URL: "sil-threshold-value-switches",
                        X_THUMBNAIL_ALT:
                          "Product list Threshold value switches",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0085135_int.jpg",
                      },
                      {
                        id: "UOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Isolation amplifiers",
                        text: "Isolation amplifiers",
                        X_fullName: "Isolation amplifiers",
                        X_Description:
                          "NAMUR isolation amplifiers for operating proximity sensors and mechanical contacts.",
                        X_URL: "sil-isolation-amplifiers",
                        X_THUMBNAIL_ALT: "Product list Isolation amplifiers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044119_int.jpg",
                      },
                      {
                        id: "VOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\System cabling and accessories",
                        text: "System cabling and accessories",
                        X_fullName: "System cabling and accessories",
                        X_Description:
                          "System cabling and accessories for the installation and operation of signal conditioners, such as DIN rail connectors or power supply modules.",
                        X_URL: "sil-system-cabling-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list System cabling and accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044120_int.jpg",
                      },
                      {
                        id: "WOI0000101",
                        parent_id: "NOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with functional safety\\Signal conditioners with SIL functional safety\\Loop-powered isolators",
                        text: "Loop-powered isolators",
                        X_fullName: "Loop-powered isolators",
                        X_Description:
                          "Single- and dual-channel input loop powered 2-way isolators with increased isolation voltage for the electrical isolation of analog signals.",
                        X_URL: "sil-loop-powered-isolators",
                        X_THUMBNAIL_ALT: "Product list Loop-powered isolators",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0070037_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "XOI0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety",
                text: "Ex i signal conditioners with functional safety",
                X_fullName: "Ex i signal conditioners with functional safety",
                X_Description:
                  "Maximum safety for your machines and systems: MACX signal conditioners are all SIL-certified.",
                X_URL: "ex-i-signal-conditioners",
                X_THUMBNAIL_ALT:
                  "Ex i signal conditioners with functional safety",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042366_int.jpg",
                items: [
                  {
                    id: "YOI0000101",
                    parent_id: "XOI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet",
                    text: "Ex i signal conditioners with SIL functional safet",
                    X_fullName:
                      "Ex i signal conditioners with SIL functional safet",
                    X_Description:
                      "Single and two-channel Ex i signal conditioners for intrinsically safe signal circuits in potentially explosive areas.",
                    X_URL: "sil-ex-i-signal-conditioners",
                    X_THUMBNAIL_ALT:
                      "Ex i signal conditioners with SIL functional safety",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0059774_int.jpg",
                    items: [
                      {
                        id: "ZOI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Active input signal conditioners",
                        text: "Active input signal conditioners",
                        X_fullName: "Active input signal conditioners",
                        X_Description:
                          "Active input signal conditioners for the operation of intrinsically safe 2-wire and 4-wire measuring transducers and mA current sources installed in the Ex area.",
                        X_URL: "ex-active-input-signal-con",
                        X_THUMBNAIL_ALT:
                          "Product list Active Input signal conditioners",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044132_int.jpg",
                      },
                      {
                        id: "0PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Active output signal conditioners",
                        text: "Active output signal conditioners",
                        X_fullName: "Active output signal conditioners",
                        X_Description:
                          "Active output isolating amplifiers for controlling intrinsically safe I/P converters, control valves, and displays installed in the Ex area.",
                        X_URL: "ex-active-output-signal-con",
                        X_THUMBNAIL_ALT:
                          "Product list Active Output signal conditioners",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044133_int.jpg",
                      },
                      {
                        id: "1PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Repeater power supplies",
                        text: "Repeater power supplies",
                        X_fullName: "Repeater power supplies",
                        X_Description:
                          "Repeater power supplies and input isolating amplifiers for the operation of intrinsically safe 2-wire and 4-wire measuring transducers and mA current sources installed in the Ex area.",
                        X_URL: "ex-repeater-power-supplies",
                        X_THUMBNAIL_ALT: "Product list Repeater power supplies",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044132_int.jpg",
                      },
                      {
                        id: "2PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Isolation amplifiers",
                        text: "Isolation amplifiers",
                        X_fullName: "Isolation amplifiers",
                        X_Description:
                          "NAMUR isolation amplifiers for the intrinsically safe operation of proximity sensors and mechanical contacts installed in the Ex area.",
                        X_URL: "ex-isolation-amplifiers",
                        X_THUMBNAIL_ALT: "Product list Isolation amplifiers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044135_int.jpg",
                      },
                      {
                        id: "3PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Solenoid drivers",
                        text: "Solenoid drivers",
                        X_fullName: "Solenoid drivers",
                        X_Description:
                          "Solenoid drivers for controlling intrinsically safe solenoid valves, alarm transmitters, and displays installed in the Ex area.",
                        X_URL: "solenoid-drivers",
                        X_THUMBNAIL_ALT: "Product list Solenoid drivers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044136_int.jpg",
                      },
                      {
                        id: "4PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Temperature transducers",
                        text: "Temperature transducers",
                        X_fullName: "Temperature transducers",
                        X_Description:
                          "Temperature transducers for the intrinsically safe operation of resistance thermometers, resistance-type sensors, and thermocouples installed in the Ex area.",
                        X_URL: "ex-temperature-transducers",
                        X_THUMBNAIL_ALT: "Product list Temperature transducers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044137_int.jpg",
                      },
                      {
                        id: "5PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\System cabling and accessories",
                        text: "System cabling and accessories",
                        X_fullName: "System cabling and accessories",
                        X_Description:
                          "System cabling and accessories for the installation and operation of Ex i signal conditioners, such as DIN rail connectors or power supply modules.",
                        X_URL: "ex-system-cabling-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list System cabling and accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044138_int.jpg",
                      },
                      {
                        id: "6PI0000101",
                        parent_id: "YOI0000101",
                        FULLPATH:
                          "PhoenixContact\\MCR technology\\Signal conditioner\\Ex i signal conditioners with functional safety\\Ex i signal conditioners with SIL functional safet\\Threshold value switch",
                        text: "Threshold value switch",
                        X_fullName: "Threshold value switch",
                        X_Description:
                          "Universal Ex i temperature transducers with up to three limit value relays. Can be used in combination as safe limit value relays.",
                        X_URL: "ex-threshold-value-switch",
                        X_THUMBNAIL_ALT:
                          "Product list Threshold value switches",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0085134_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "7PI0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners",
                text: "System cabling for signal conditioners",
                X_fullName: "System cabling for signal conditioners",
                X_Description:
                  "Termination Carriers and system adapters from Phoenix Contact for quick, error-free connection of standard DIN rail devices to your automation system.",
                X_URL: "system-cabling-for-conditioners",
                X_THUMBNAIL_ALT: "System cabling for signal conditioners",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0059864_int.jpg",
                items: [
                  {
                    id: "8PI0000101",
                    parent_id: "7PI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners\\Termination Carriers",
                    text: "Termination Carriers",
                    X_fullName: "Termination Carriers",
                    X_Description:
                      "Termination Carriers are system solutions for the Plug and Play connection of signal isolators to the control level using pre-assembled system cables.",
                    X_URL: "termination-carriers",
                    X_THUMBNAIL_ALT: "Product list Termination Carriers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0047943_int.jpg",
                  },
                  {
                    id: "9PI0000101",
                    parent_id: "7PI0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\System cabling for signal conditioners\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description:
                      "Various accessories for the Termination Carrier.",
                    X_URL: "system-cabling-acc",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060510_int.jpg",
                  },
                ],
              },
              {
                id: "API0000101",
                parent_id: "ZNI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with bus and network connectio",
                text: "Signal conditioners with bus and network connectio",
                X_fullName:
                  "Signal conditioners with bus and network connectio",
                X_Description:
                  "Signal conditioners and gateways for the direct connection of up to eight analog and digital process values to a controller via an industrial network.",
                X_URL: "signal-conditioners-with-conn",
                X_THUMBNAIL_ALT:
                  "Signal conditioners with bus and network connection",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0077532_int.jpg",
                items: [
                  {
                    id: "BPI0000101",
                    parent_id: "API0000101",
                    FULLPATH:
                      "PhoenixContact\\MCR technology\\Signal conditioner\\Signal conditioners with bus and network connectio\\Gateways for bus and network connection",
                    text: "Gateways for bus and network connection",
                    X_fullName: "Gateways for bus and network connection",
                    X_Description:
                      "Gateways for the direct connection of up to eight analog and digital process values to a controller via an industrial network.",
                    X_URL: "gateways-for-bus-and-network-con",
                    X_THUMBNAIL_ALT:
                      "Product list Gateways for bus and network connection",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069823_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "CPI0000101",
            parent_id: "YNI0000101",
            FULLPATH: "PhoenixContact\\MCR technology\\Timer relays",
            text: "Timer relays",
            X_fullName: "Timer relays",
            X_Description:
              "Ultra-narrow or multifunctional time relays for simple time control applications.",
            X_URL: "mcr-timer-relays",
            X_THUMBNAIL_ALT: "Time relays",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042137_int.jpg",
            items: [
              {
                id: "DPI0000101",
                parent_id: "CPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Timer relays\\Timer relays, 6 mm",
                text: "Timer relays, 6 mm",
                X_fullName: "Timer relays, 6 mm",
                X_Description:
                  "Ultra-narrow timer relays for controllers, each with one time range and one function.",
                X_URL: "timer-relays",
                X_THUMBNAIL_ALT: "Product list Timer relays, 6 mm",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043850_int.jpg",
              },
              {
                id: "EPI0000101",
                parent_id: "CPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Timer relays\\Compact timer relays",
                text: "Compact timer relays",
                X_fullName: "Compact timer relays",
                X_Description:
                  "Multi-functional timer relays with compact installation housing.",
                X_URL: "compact-timer-relays",
                X_THUMBNAIL_ALT: "Product list Compact timer relays",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062884_int.jpg",
              },
            ],
          },
          {
            id: "FPI0000101",
            parent_id: "YNI0000101",
            FULLPATH:
              "PhoenixContact\\MCR technology\\Process indicators and field devices",
            text: "Process indicators and field devices",
            X_fullName: "Process indicators and field devices",
            X_Description:
              "Record temperatures directly in the field, monitor, display, and control process values with process indicators and field devices.",
            X_URL: "indicators-and-field-devices",
            X_THUMBNAIL_ALT: "Process indicators and field devices",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076003_int.jpg",
            items: [
              {
                id: "GPI0000101",
                parent_id: "FPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Process indicators and accessories",
                text: "Process indicators and accessories",
                X_fullName: "Process indicators and accessories",
                X_Description:
                  "Process indicators for monitoring, displaying, and controlling analog and temperature signals. For control panel installation and field assembly.",
                X_URL: "indicators-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Process indicators and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0069697_int.jpg",
              },
              {
                id: "HPI0000101",
                parent_id: "FPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Ex i process indicators and accessories",
                text: "Ex i process indicators and accessories",
                X_fullName: "Ex i process indicators and accessories",
                X_Description:
                  "Ex i process indicators for monitoring, displaying, and controlling analog and temperature signals in the ex area. For control panel installation and field assembly.",
                X_URL: "process-indicators-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Ex i process indicators and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0069702_int.jpg",
              },
              {
                id: "IPI0000101",
                parent_id: "FPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Sensor-head measuring transducers and accessories",
                text: "Sensor-head measuring transducers and accessories",
                X_fullName: "Sensor-head measuring transducers and accessories",
                X_Description:
                  "Temperature sensor-head measuring transducer for resistance thermometer, thermocouples, resistance and voltage encoder, programmable, loop powered",
                X_URL: "measuring-transducers-accessory",
                X_THUMBNAIL_ALT:
                  "Product list Sensor-head measuring transducer and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077539_int.jpg",
              },
              {
                id: "JPI0000101",
                parent_id: "FPI0000101",
                FULLPATH:
                  "PhoenixContact\\MCR technology\\Process indicators and field devices\\Loop-powered temperature transducers and accessori",
                text: "Loop-powered temperature transducers and accessori",
                X_fullName:
                  "Loop-powered temperature transducers and accessori",
                X_Description:
                  "Programmable, output-loop-fed 2-wire measuring transducers with two measuring inputs for resistance thermometers, thermocouples, resistors, and voltage sensors.",
                X_URL: "temperature-transducers",
                X_THUMBNAIL_ALT:
                  "Product list Loop-fed temperature transducers and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076785_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "KPI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Monitoring",
        text: "Monitoring",
        X_fullName: "Monitoring",
        X_Description:
          "Safeguard your system operation and prevent system failure: with our monitoring products you can detect errors before they become a problem.",
        X_URL: "monitoring",
        X_THUMBNAIL_ALT: "Monitoring",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042344_int.jpg",
        items: [
          {
            id: "LPI0000101",
            parent_id: "KPI0000101",
            FULLPATH: "PhoenixContact\\Monitoring\\Energy and power measuring",
            text: "Energy and power measuring",
            X_fullName: "Energy and power measuring",
            X_Description:
              "Measuring devices, data loggers, sensors, and meters for energy monitoring in machines and systems.",
            X_URL: "energy-and-power-measuring",
            X_THUMBNAIL_ALT: "Energy and power measuring",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042164_int.jpg",
            items: [
              {
                id: "MPI0000101",
                parent_id: "LPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters",
                text: "Energy measuring devices and energy meters",
                X_fullName: "Energy measuring devices and energy meters",
                X_Description:
                  "EMpro energy meters measure the characteristic electrical data of your systems.",
                X_URL: "energy-measuring-devices",
                X_THUMBNAIL_ALT: "Energy measuring devices and energy meters",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042127_int.jpg",
                items: [
                  {
                    id: "NPI0000101",
                    parent_id: "MPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description:
                      "Modules for extending functions and integrating energy meters into network structures and fieldbus systems.",
                    X_URL: "measuring-devices-accessories",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043853_int.jpg",
                  },
                  {
                    id: "OPI0000101",
                    parent_id: "MPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Energy and power measuring\\Energy measuring devices and energy meters\\Power meter",
                    text: "Power meter",
                    X_fullName: "Power meter",
                    X_Description:
                      "Digital three-phase energy meters for active power measurement in networks up to 500 V, certified according to the MID directive.",
                    X_URL: "power-meter",
                    X_THUMBNAIL_ALT: "Product list Power meters",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0084230_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "PPI0000101",
            parent_id: "KPI0000101",
            FULLPATH:
              "PhoenixContact\\Monitoring\\Current and voltage measuring",
            text: "Current and voltage measuring",
            X_fullName: "Current and voltage measuring",
            X_Description:
              "Measuring transducers for the acquisition of direct, alternating, and distorted currents and voltages.",
            X_URL: "current-and-voltage-measuring",
            X_THUMBNAIL_ALT: "Current and voltage measurement",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0056974_int.jpg",
            items: [
              {
                id: "QPI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC",
                text: "Current transducers, AC/DC",
                X_fullName: "Current transducers, AC/DC",
                X_Description:
                  "Current transducers in a range of different designs. For converting direct and alternating currents into analog rated currents.",
                X_URL: "current-transducers-ac-dc",
                X_THUMBNAIL_ALT: "Current transducers, AC/DC",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042132_int.jpg",
                items: [
                  {
                    id: "RPI0000101",
                    parent_id: "QPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Current transducers up to 100 A AC/DC, progr.",
                    text: "Current transducers up to 100 A AC/DC, progr.",
                    X_fullName: "Current transducers up to 100 A AC/DC, progr.",
                    X_Description:
                      "Programmable current transducers convert AC/DC currents up to 100 A into standard analog signals.",
                    X_URL: "100-a-ac-dc-current-transducers",
                    X_THUMBNAIL_ALT:
                      "Product list Current transducers up to 100 A AC/DC, progr.",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043858_int.jpg",
                  },
                  {
                    id: "SPI0000101",
                    parent_id: "QPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Current transducers up to 600 A AC/DC",
                    text: "Current transducers up to 600 A AC/DC",
                    X_fullName: "Current transducers up to 600 A AC/DC",
                    X_Description:
                      "Current transducers convert currents up to 600 A AC/DC into standard analog signals.",
                    X_URL: "600-a-ac-dc-current-transducers",
                    X_THUMBNAIL_ALT:
                      "Product list Current transducers up to 600 A AC/DC",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043859_int.jpg",
                  },
                  {
                    id: "TPI0000101",
                    parent_id: "QPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC/DC\\Accessories for current transducers",
                    text: "Accessories for current transducers",
                    X_fullName: "Accessories for current transducers",
                    X_Description:
                      "Software adapter cable for programming MCR-T-..., MCR-S-... and MCR-f-... modules",
                    X_URL: "current-transducers-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list accessories for current transducers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0084231_int.jpg",
                  },
                ],
              },
              {
                id: "UPI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC",
                text: "Current transducers, AC",
                X_fullName: "Current transducers, AC",
                X_Description:
                  "Various types of current transducers for converting alternating currents into analog rated currents.",
                X_URL: "current-transducers-ac",
                X_THUMBNAIL_ALT: "Current transducers, AC",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042133_int.jpg",
                items: [
                  {
                    id: "VPI0000101",
                    parent_id: "UPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC\\Current transducers up to 12 A AC, sinusoidal",
                    text: "Current transducers up to 12 A AC, sinusoidal",
                    X_fullName: "Current transducers up to 12 A AC, sinusoidal",
                    X_Description:
                      "Current transducers convert sinusoidal alternating currents up to 12 A AC into standard analog signals.",
                    X_URL: "current-transducer-sinusoidal",
                    X_THUMBNAIL_ALT:
                      "Product list Current transducers up to 12 A AC, sinusoidal",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043861_int.jpg",
                  },
                  {
                    id: "WPI0000101",
                    parent_id: "UPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transducers, AC\\Current transducers up to 400 A AC, distorted",
                    text: "Current transducers up to 400 A AC, distorted",
                    X_fullName: "Current transducers up to 400 A AC, distorted",
                    X_Description:
                      "Current transducers convert sinusoidal and non-sinusoidal currents up to 400 A AC into standard analog signals.",
                    X_URL: "400-a-ac-current-transducers",
                    X_THUMBNAIL_ALT:
                      "Product list Current transducers up to 400 A AC, distorted",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043862_int.jpg",
                  },
                ],
              },
              {
                id: "XPI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current protectors up to 16 A AC",
                text: "Current protectors up to 16 A AC",
                X_fullName: "Current protectors up to 16 A AC",
                X_Description:
                  "Current protectors for limit value monitoring of sinusoidal alternating currents from 0 to 16 A.",
                X_URL: "current-protectors-up-to-16-a-ac",
                X_THUMBNAIL_ALT:
                  "Product list Current protectors up to 16 A AC",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043863_int.jpg",
              },
              {
                id: "YPI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Voltage transducers, AC and DC",
                text: "Voltage transducers, AC and DC",
                X_fullName: "Voltage transducers, AC and DC",
                X_Description:
                  "Voltage transducers convert AC and DC voltages for measurement into standard analog signals.",
                X_URL: "ac-dc-voltage-transducers",
                X_THUMBNAIL_ALT: "Product list Voltage transducers, AC and DC",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043864_int.jpg",
              },
              {
                id: "ZPI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in",
                text: "Current transformer, plug-in",
                X_fullName: "Current transformer, plug-in",
                X_Description:
                  "PACT current transformers convert large currents into smaller rated currents.",
                X_URL: " plug-in-current-transformer",
                X_THUMBNAIL_ALT: "Current transformer, plug-in",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042134_int.jpg",
                items: [
                  {
                    id: "0QI0000101",
                    parent_id: "ZPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in\\Current transformer, plug-in",
                    text: "Current transformer, plug-in",
                    X_fullName: "Current transformer, plug-in",
                    X_Description:
                      "Current transformers for converting large currents up to 4000 A into secondary currents of 1 to 5 A.",
                    X_URL: "plug-in-current-transformer",
                    X_THUMBNAIL_ALT:
                      "Product list Current transformers, plug-in",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043865_int.jpg",
                  },
                  {
                    id: "1QI0000101",
                    parent_id: "ZPI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformer, plug-in\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description:
                      "Accessories for the installation and operation of current transformers that can and cannot be calibrated.",
                    X_URL: "transformer-accessories",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043866_int.jpg",
                  },
                ],
              },
              {
                id: "2QI0000101",
                parent_id: "PPI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting",
                text: "Current transformers for retrofitting",
                X_fullName: "Current transformers for retrofitting",
                X_Description:
                  "Fast installation in a confined space: PACT RCP current transformers for retrofitting can be conveniently mounted where there is not enough space for split core current transformers.",
                X_URL: "retrofitting-current-transformer",
                X_THUMBNAIL_ALT: "Current transformers for retrofitting",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056041_int.jpg",
                items: [
                  {
                    id: "3QI0000101",
                    parent_id: "2QI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting\\Current transformers for retrofitting",
                    text: "Current transformers for retrofitting",
                    X_fullName: "Current transformers for retrofitting",
                    X_Description:
                      "Current transformers for retrofitting enable fast installation that does not require you to remove system parts \u2013 even where there is not enough space for split core current transformers.",
                    X_URL: "transformers-for-retrofitting",
                    X_THUMBNAIL_ALT:
                      "Product list Current transformers for retrofitting",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056971_int.jpg",
                  },
                  {
                    id: "4QI0000101",
                    parent_id: "2QI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Current and voltage measuring\\Current transformers for retrofitting\\Accessories",
                    text: "Accessories",
                    X_fullName: "Accessories",
                    X_Description:
                      "Holding device to secure the Rogowski coil on power rails.",
                    X_URL: "transformer-retro-accessories",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056972_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "5QI0000101",
            parent_id: "KPI0000101",
            FULLPATH: "PhoenixContact\\Monitoring\\Monitoring and diagnostics",
            text: "Monitoring and diagnostics",
            X_fullName: "Monitoring and diagnostics",
            X_Description:
              "Always up to date: log, follow, and monitor the processes of individual modules or entire system parts using our products and solutions for monitoring and diagnostics.",
            X_URL: "monitoring-and-diagnostics",
            X_THUMBNAIL_ALT: "Monitoring and diagnostics",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0057058_int.jpg",
            items: [
              {
                id: "7QI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Lightning monitoring",
                text: "Lightning monitoring",
                X_fullName: "Lightning monitoring",
                X_Description:
                  "The LM-S lightning current measuring system can detect, evaluate, and remotely monitor lightning strikes in realtime.",
                X_URL: "lightning-monitoring",
                X_THUMBNAIL_ALT: "Product list Lightning current measurement",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043006_int.jpg",
              },
              {
                id: "8QI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management",
                text: "Photovoltaic system management",
                X_fullName: "Photovoltaic system management",
                X_Description:
                  "Switch off your PV system safely in the event of maintenance or danger and increase efficiency by always being informed about the state of your system.",
                X_URL: "photovoltaic-system-management",
                X_THUMBNAIL_ALT: "Photovoltaic system management",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0066963_int.jpg",
                items: [
                  {
                    id: "AQI0000101",
                    parent_id: "8QI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management\\String combiner for photovoltaic systems",
                    text: "String combiner for photovoltaic systems",
                    X_fullName: "String combiner for photovoltaic systems",
                    X_Description:
                      "Reliably monitor the performance of your photovoltaic system with string combiner boxes from Phoenix Contact.",
                    X_URL: "string-combiner-for-photovoltaic",
                    X_THUMBNAIL_ALT:
                      "Product list String combiner for photovoltaic systems",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0066964_int.jpg",
                  },
                  {
                    id: "CQI0000101",
                    parent_id: "8QI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Photovoltaic system management\\Photovoltaics string monitoring",
                    text: "Photovoltaics string monitoring",
                    X_fullName: "Photovoltaics string monitoring",
                    X_Description:
                      "Two-piece system for string monitoring in photovoltaic systems.",
                    X_URL: "photovoltaics-string-monitoring",
                    X_THUMBNAIL_ALT:
                      "Product list Photovoltaic string monitoring",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043857_int.jpg",
                  },
                ],
              },
              {
                id: "DQI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays",
                text: "Monitoring relays",
                X_fullName: "Monitoring relays",
                X_Description:
                  "Relays for monitoring currents, voltages, and other power grid parameters.",
                X_URL: "monitoring-relays",
                X_THUMBNAIL_ALT: "Monitoring relays",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051615_int.jpg",
                items: [
                  {
                    id: "EQI0000101",
                    parent_id: "DQI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays",
                    text: "Compact monitoring relays",
                    X_fullName: "Compact monitoring relays",
                    X_Description:
                      "Compact monitoring relays for monitoring current, voltage, and phase parameters.",
                    X_URL: "compact-monitoring-relays",
                    X_THUMBNAIL_ALT: "Compact monitoring relays",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0051621_int.jpg",
                    items: [
                      {
                        id: "FQI0000101",
                        parent_id: "EQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Current monitoring relays",
                        text: "Current monitoring relays",
                        X_fullName: "Current monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring single-phase direct and alternating currents from 0 to 10 A.",
                        X_URL: "current-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Current monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0051622_int.jpg",
                      },
                      {
                        id: "GQI0000101",
                        parent_id: "EQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Voltage monitoring relays",
                        text: "Voltage monitoring relays",
                        X_fullName: "Voltage monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring DC and AC voltages in single and three-phase networks.",
                        X_URL: "voltage-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Voltage monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0051624_int.jpg",
                      },
                      {
                        id: "HQI0000101",
                        parent_id: "EQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Phase monitoring relays",
                        text: "Phase monitoring relays",
                        X_fullName: "Phase monitoring relays",
                        X_Description:
                          "Compact monitoring relays for phase parameter monitoring in three-phase power grids.",
                        X_URL: "phase-monitoring-relays",
                        X_THUMBNAIL_ALT: "Product list Phase monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0051625_int.jpg",
                      },
                      {
                        id: "IQI0000101",
                        parent_id: "EQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Compact monitoring relays\\Temperature monitoring relay (thermistor)",
                        text: "Temperature monitoring relay (thermistor)",
                        X_fullName: "Temperature monitoring relay (thermistor)",
                        X_Description:
                          "Compact monitoring relay for monitoring the temperature of motor windings using a thermistor.",
                        X_URL: "temperature-monitoring-relay",
                        X_THUMBNAIL_ALT:
                          "Product list Temperature monitoring relay (thermistor)",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062889_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "JQI0000101",
                    parent_id: "DQI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays",
                    text: "Multifunctional monitoring relays",
                    X_fullName: "Multifunctional monitoring relays",
                    X_Description:
                      "Multifunctional monitoring relays for monitoring currents, voltages, and other network parameters.",
                    X_URL: "multifunctional-monitoring-relay",
                    X_THUMBNAIL_ALT: "Multifunctional monitoring relays",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042136_int.jpg",
                    items: [
                      {
                        id: "KQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Current monitoring relays",
                        text: "Current monitoring relays",
                        X_fullName: "Current monitoring relays",
                        X_Description:
                          "Compact monitoring relays for monitoring single-phase direct and alternating currents from 0 to 10 A.",
                        X_URL: "multi-current-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Current monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043867_int.jpg",
                      },
                      {
                        id: "LQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Voltage monitoring relays",
                        text: "Voltage monitoring relays",
                        X_fullName: "Voltage monitoring relays",
                        X_Description:
                          "Multifunctional monitoring relay for monitoring DC and AC voltages in single and three-phase networks.",
                        X_URL: "multi-voltage-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Voltage monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043868_int.jpg",
                      },
                      {
                        id: "MQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Phase monitoring relays",
                        text: "Phase monitoring relays",
                        X_fullName: "Phase monitoring relays",
                        X_Description:
                          "Relays for phase parameter monitoring of three-phase AC voltages \u2013 with and without neutral conductor.",
                        X_URL: "multi-phase-monitoring-relays",
                        X_THUMBNAIL_ALT: "Product list Phase monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043869_int.jpg",
                      },
                      {
                        id: "NQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Load monitoring relays (cos \u03d5)",
                        text: "Load monitoring relays (cos \u03d5)",
                        X_fullName: "Load monitoring relays (cos \u03d5)",
                        X_Description:
                          "Monitoring relays for monitoring loads (cos \u03d5) in single and three-phase networks.",
                        X_URL: "load-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Load monitoring relays (cos \u03d5)",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043871_int.jpg",
                      },
                      {
                        id: "OQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Temperature monitoring relays (termistor)",
                        text: "Temperature monitoring relays (termistor)",
                        X_fullName: "Temperature monitoring relays (termistor)",
                        X_Description:
                          "Relays for monitoring motor winding temperatures using PTC according to DIN 44081.",
                        X_URL: "temperature-monitoring-relays",
                        X_THUMBNAIL_ALT:
                          "Product list Temperature monitoring relays (thermistor)",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043872_int.jpg",
                      },
                      {
                        id: "PQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Level monitoring relay",
                        text: "Level monitoring relay",
                        X_fullName: "Level monitoring relay",
                        X_Description:
                          "Relays for minimum and maximum level monitoring of conductive liquids.",
                        X_URL: "level-monitoring-relay",
                        X_THUMBNAIL_ALT: "Product list Level monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043873_int.jpg",
                      },
                      {
                        id: "QQI0000101",
                        parent_id: "JQI0000101",
                        FULLPATH:
                          "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Monitoring relays\\Multifunctional monitoring relays\\Accessories for monitoring relays",
                        text: "Accessories for monitoring relays",
                        X_fullName: "Accessories for monitoring relays",
                        X_Description:
                          "Accessories for the installation and operation of electronic monitoring relays.",
                        X_URL: "monitoring-relays-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list Accessories for monitoring relays",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0044494_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "SQI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Lamp testing modules",
                text: "Lamp testing modules",
                X_fullName: "Lamp testing modules",
                X_Description:
                  "Modules for testing lamps individually or for testing lamps centrally.",
                X_URL: "lamp-testing-modules",
                X_THUMBNAIL_ALT: "Product list Lamp testing modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043855_int.jpg",
              },
              {
                id: "UQI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Diode modules",
                text: "Diode modules",
                X_fullName: "Diode modules",
                X_Description:
                  "Modules for diode circuits which perform a wide range of tasks in electrical systems.",
                X_URL: "diode-modules",
                X_THUMBNAIL_ALT: "Product list Diode modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043854_int.jpg",
              },
              {
                id: "VQI0000101",
                parent_id: "5QI0000101",
                FULLPATH:
                  "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Rotor blade monitoring for wind turbine generators",
                text: "Rotor blade monitoring for wind turbine generators",
                X_fullName:
                  "Rotor blade monitoring for wind turbine generators",
                X_Description:
                  "Rotor blade tension monitoring (RBTM) from Phoenix Contact continuously monitors the vibrations in the rotor blades of a wind turbine generator.",
                X_URL: "rotor-blade-monitoring-main",
                X_THUMBNAIL_ALT:
                  "Rotor blade monitoring for wind turbine generators",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0067200_int.jpg",
                items: [
                  {
                    id: "WQI0000101",
                    parent_id: "VQI0000101",
                    FULLPATH:
                      "PhoenixContact\\Monitoring\\Monitoring and diagnostics\\Rotor blade monitoring for wind turbine generators\\Rotor blade monitoring",
                    text: "Rotor blade monitoring",
                    X_fullName: "Rotor blade monitoring",
                    X_Description:
                      "Complete solution for continuously monitoring the vibrations in the rotor blades in a wind turbine generator.",
                    X_URL: "rotor-blade-monitoring",
                    X_THUMBNAIL_ALT: "Product list Rotor blade monitoring",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0067200_int.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "XQI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Modular terminal blocks",
        text: "Modular terminal blocks",
        X_fullName: "Modular terminal blocks",
        X_Description:
          "Different connection technologies give you greater flexibility \u2013 in both the configuration and design of the control cabinet.",
        X_URL: "modular-terminal-blocks",
        X_THUMBNAIL_ALT: "Terminal blocks",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0065446_int.jpg",
        items: [
          {
            id: "YQI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Screw connection",
            text: "Screw connection",
            X_fullName: "Screw connection",
            X_Description:
              "The internationally recognized screw connection meets the most stringent requirements and covers a wide area of application.",
            X_URL: "screw-connection",
            X_THUMBNAIL_ALT: "Screw connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042698_int.jpg",
            items: [
              {
                id: "0RI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Component terminal blocks",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_URL: "screw-component-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Component terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046965_int.jpg",
              },
              {
                id: "2RI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Feed-through terminal blocks",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with screw connection in various sizes are ideal for both solid and stranded conductors.",
                X_URL: "screw-feed-through-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Feed-through terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046966_int.jpg",
              },
              {
                id: "4RI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\High-temperature terminal blocks",
                text: "High-temperature terminal blocks",
                X_fullName: "High-temperature terminal blocks",
                X_Description:
                  "Compact high-temperature terminal blocks with screw connection for various cross sections.",
                X_URL: "high-temperature-terminal-blocks",
                X_THUMBNAIL_ALT:
                  "Product list High-temperature terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046967_int.jpg",
              },
              {
                id: "6RI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Hybrid terminal blocks",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks for various conductor cross sections combine screw connection with spring-cage or fast connection.",
                X_URL: "screw-hybrid-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Hybrid terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046968_int.jpg",
              },
              {
                id: "8RI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Sensor/actuator terminal blocks",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with screw connection in various lengths, with or without LED.",
                X_URL: "screw-sensor-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Sensor/actuator terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046969_int.jpg",
              },
              {
                id: "ARI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Installation terminal blocks",
                text: "Installation terminal blocks",
                X_fullName: "Installation terminal blocks",
                X_Description:
                  "Installation terminal blocks with screw connection in various sizes and for a range of conductor cross sections.",
                X_URL: "installation-terminal-bl-main",
                X_THUMBNAIL_ALT: "Product list Installation terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046970_int.jpg",
              },
              {
                id: "CRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Multi-conductor terminal blocks",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with screw connection for three or four conductors with various conductor cross sections.",
                X_URL: "screw-multi-conductor-terminal",
                X_THUMBNAIL_ALT: "Product list Multi-conductor terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046971_int.jpg",
              },
              {
                id: "ERI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Multi-level terminal blocks",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_URL: "screw-multi-level-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Multi-level terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046972_int.jpg",
              },
              {
                id: "GRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Test disconnect terminal blocks",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with screw connection in various sizes.",
                X_URL: "screw-test-disconnect-terminal",
                X_THUMBNAIL_ALT: "Product list Test disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046973_int.jpg",
              },
              {
                id: "IRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Mini and micro terminal blocks",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_Description:
                  "Mini and micro terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in a confined space.",
                X_URL: "mini-micro-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Mini and micro terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046974_int.jpg",
              },
              {
                id: "KRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Ground terminal blocks",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with screw connection in various sizes and for a range of conductor cross sections.",
                X_URL: "screw-ground-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Ground terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046975_int.jpg",
              },
              {
                id: "MRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Fuse terminal blocks",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with screw connection in various designs. Versions with LED complete the product range.",
                X_URL: "screw-fuse-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Fuse terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046976_int.jpg",
              },
              {
                id: "ORI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Plug-in terminal blocks",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with screw connection in various designs and for a range of conductor cross sections.",
                X_URL: "screw-plug-in-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Plug-in terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046977_int.jpg",
              },
              {
                id: "QRI0000101",
                parent_id: "YQI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Screw connection\\Disconnect and knife disconnect terminal blocks",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_URL: " knife-disconnect-terminal-block",
                X_THUMBNAIL_ALT:
                  "Product list Disconnect and knife disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046978_int.jpg",
              },
            ],
          },
          {
            id: "RRI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Push-in connection",
            text: "Push-in connection",
            X_fullName: "Push-in connection",
            X_Description:
              "The low insertion forces of the Push-in connection terminal blocks enable the conductors to be inserted easily and directly.",
            X_URL: "push-in-connection",
            X_THUMBNAIL_ALT: "Push-in connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042714_int.jpg",
            items: [
              {
                id: "TRI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Component terminal blocks",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_URL: "push-component-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Component terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046980_int.jpg",
              },
              {
                id: "VRI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Feed-through terminal blocks",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with push-in connection in various sizes are ideal for both solid and stranded conductors.",
                X_URL: "push-feed-through-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Feed-through terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046982_int.jpg",
              },
              {
                id: "XRI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Sensor/actuator terminal blocks",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with push-in connection in various lengths, with or without LED.",
                X_URL: "push-sensor-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Sensor/actuator terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046984_int.jpg",
              },
              {
                id: "ZRI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Installation terminal blocks",
                text: "Installation terminal blocks",
                X_fullName: "Installation terminal blocks",
                X_Description:
                  "Installation terminal blocks with push-in connection in various sizes and for a range of conductor cross sections.",
                X_URL: "installation-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Installation terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046985_int.jpg",
              },
              {
                id: "1SI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Multi-conductor terminal blocks",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with push-in connection for three or four conductors with various conductor cross sections.",
                X_URL: "push-multi-conductor-terminal",
                X_THUMBNAIL_ALT: "Product list Multi-conductor terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046986_int.jpg",
              },
              {
                id: "3SI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Multi-level terminal blocks",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_URL: "push-multi-level-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Multi-level terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046987_int.jpg",
              },
              {
                id: "5SI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Test disconnect terminal blocks",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with push-in connection in various sizes.",
                X_URL: "push-test-disconnect-terminal",
                X_THUMBNAIL_ALT: "Product list Test disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046989_int.jpg",
              },
              {
                id: "7SI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Ground terminal blocks",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with push-in connection in various sizes and for a range of conductor cross sections.",
                X_URL: "push-ground-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Ground terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046988_int.jpg",
              },
              {
                id: "9SI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Fuse terminal blocks",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with push-in connection in various designs. Versions with LED complete the product range.",
                X_URL: "push-fuse-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Fuse terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046994_int.jpg",
              },
              {
                id: "BSI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Plug-in terminal blocks",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with push-in connection in various designs and for a range of conductor cross sections.",
                X_URL: "push-plug-in-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Plug-in terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046995_int.jpg",
              },
              {
                id: "DSI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Disconnect and knife disconnect terminal blocks",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_URL: "push-disconnect-terminal-blocks",
                X_THUMBNAIL_ALT:
                  "Product list Disconnect and knife disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0046999_int.jpg",
              },
              {
                id: "ESI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_Description:
                  "Miniature and micro terminal blocks with limited space requirements for all applications",
                X_URL: "terminal-blocks",
                X_THUMBNAIL_ALT: "Miniature and micro terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051560_int.jpg",
                items: [
                  {
                    id: "FSI0000101",
                    parent_id: "ESI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks\\Mini terminal blocks",
                    text: "Mini terminal blocks",
                    X_fullName: "Mini terminal blocks",
                    X_Description:
                      "Miniature terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in the most confined spaces.",
                    X_URL: "mini-terminal-blocks",
                    X_THUMBNAIL_ALT:
                      "Product list \u2013 miniature terminal blocks",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0073109_int.jpg",
                  },
                  {
                    id: "GSI0000101",
                    parent_id: "ESI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Mini and micro terminal blocks\\Micro terminal blocks",
                    text: "Micro terminal blocks",
                    X_fullName: "Micro terminal blocks",
                    X_Description:
                      "Miniature and micro terminal blocks from Phoenix Contact provide convenient wiring and potential distribution in the most confined spaces.",
                    X_URL: "micro-terminal-blocks",
                    X_THUMBNAIL_ALT:
                      "Product list \u2013 micro terminal blocks",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0073110_int.jpg",
                  },
                ],
              },
              {
                id: "ISI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\High-current terminal blocks",
                text: "High-current terminal blocks",
                X_fullName: "High-current terminal blocks",
                X_Description:
                  "PTPOWER high-current spring-cage terminal blocks for large conductor cross sections up to 150 mm\u00b2.",
                X_URL: "high-current-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list High-current terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062969_int.jpg",
              },
              {
                id: "JSI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors",
                text: "Marshalling terminals and potential distributors",
                X_fullName: "Marshalling terminals and potential distributors",
                X_Description:
                  "Marshal signals safely with an innovative color coding system and up to 20% less space required.",
                X_URL: "marshalling-terminals-main",
                X_THUMBNAIL_ALT:
                  "Marshalling terminals and potential distributors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062605_int.jpg",
                items: [
                  {
                    id: "KSI0000101",
                    parent_id: "JSI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors\\Marshalling terminals",
                    text: "Marshalling terminals",
                    X_fullName: "Marshalling terminals",
                    X_Description:
                      "PTRV marshalling terminals - compact, safe, and intuitive signal marshalling.",
                    X_URL: "marshalling-terminals",
                    X_THUMBNAIL_ALT: "Product list Marshalling terminals",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062970_int.jpg",
                  },
                  {
                    id: "LSI0000101",
                    parent_id: "JSI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling terminals and potential distributors\\Potential distributors",
                    text: "Potential distributors",
                    X_fullName: "Potential distributors",
                    X_Description:
                      "Potential distributors for distributing up to 32 connections.",
                    X_URL: "push-potential-distributors",
                    X_THUMBNAIL_ALT: "Product list Potential distributors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062972_int.jpg",
                  },
                ],
              },
              {
                id: "NSI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Hybrid terminal blocks",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks from Phoenix Contact combine two connection technologies in a single terminal block: push-in and screw connection.",
                X_URL: "push-hybrid-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Hybrid terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062979_int.jpg",
              },
              {
                id: "OSI0000101",
                parent_id: "RRI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards",
                text: "Marshalling patchboards",
                X_fullName: "Marshalling patchboards",
                X_Description:
                  "Marshal signals reliably with an innovative color control system with up to 20% more packing density.",
                X_URL: "marshalling-patchboards-main",
                X_THUMBNAIL_ALT: "Marshalling patchboards",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060592_int.jpg",
                items: [
                  {
                    id: "PSI0000101",
                    parent_id: "OSI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards\\Marshalling patchboards",
                    text: "Marshalling patchboards",
                    X_fullName: "Marshalling patchboards",
                    X_Description:
                      "Pre-assembled standard marshalling patchboards for the highest signal density during marshalling in automation applications.",
                    X_URL: "marshalling-patchboards",
                    X_THUMBNAIL_ALT: "Product list Marshalling patchboards",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062981_int.jpg",
                  },
                  {
                    id: "QSI0000101",
                    parent_id: "OSI0000101",
                    FULLPATH:
                      'PhoenixContact\\Modular terminal blocks\\Push-in connection\\Marshalling patchboards\\Marshalling patchboards for 19" racks',
                    text: 'Marshalling patchboards for 19" racks',
                    X_fullName: 'Marshalling patchboards for 19" racks',
                    X_Description:
                      'Pre-assembled marshalling patchboards for 19" racks for space-saving, clearly arranged, and error-free wiring.',
                    X_URL: "19-marshalling-patchboards",
                    X_THUMBNAIL_ALT:
                      'Product list Marshalling patchboards for 19" racks',
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062983_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "RSI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Spring-cage connection",
            text: "Spring-cage connection",
            X_fullName: "Spring-cage connection",
            X_Description:
              "The compact spring-cage connection offers a reliable, constant connection when subjected to strong vibrations.",
            X_URL: "spring-cage-connection",
            X_THUMBNAIL_ALT: "Spring-cage connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042726_int.jpg",
            items: [
              {
                id: "TSI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Component terminal blocks",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_URL: "component-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Component terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047000_int.jpg",
              },
              {
                id: "VSI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Feed-through terminal blocks",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with spring-cage connection in various sizes are ideal for both solid and stranded conductors.",
                X_URL: "feed-through-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Feed-through terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047001_int.jpg",
              },
              {
                id: "XSI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Sensor/actuator terminal blocks",
                text: "Sensor/actuator terminal blocks",
                X_fullName: "Sensor/actuator terminal blocks",
                X_Description:
                  "Sensor/actuator terminal blocks with spring-cage connection in various lengths, with or without LED.",
                X_URL: "sensor-actuator-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Sensor/actuator terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047003_int.jpg",
              },
              {
                id: "ZSI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Multi-conductor terminal blocks",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with spring-cage connection for three or four conductors with various conductor cross sections.",
                X_URL: "multi-conductor-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Multi-conductor terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047005_int.jpg",
              },
              {
                id: "1TI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Multi-level terminal blocks",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_URL: "multi-level-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Multi-level terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047006_int.jpg",
              },
              {
                id: "3TI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Test disconnect terminal blocks",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with spring-cage connection in various sizes.",
                X_URL: "test-disconnect-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Test disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047007_int.jpg",
              },
              {
                id: "5TI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Mini and micro terminal blocks",
                text: "Mini and micro terminal blocks",
                X_fullName: "Mini and micro terminal blocks",
                X_Description:
                  "Mini and micro terminal blocks with a connection cross section of 1.5 mm\u00b2 permit convenient wiring in a confined space.",
                X_URL: "mini-and-micro-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Mini and micro terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047008_int.jpg",
              },
              {
                id: "7TI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Ground terminal blocks",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with spring-cage connection in various sizes and for a range of conductor cross sections.",
                X_URL: "spring-ground-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Ground terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047009_int.jpg",
              },
              {
                id: "9TI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Fuse terminal blocks",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with spring-cage connection in various designs. Versions with LED complete the product range.",
                X_URL: "fuse-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Fuse terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047010_int.jpg",
              },
              {
                id: "BTI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Plug-in terminal blocks",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with spring-cage connection in various designs and for a range of conductor cross sections.",
                X_URL: "plug-in-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Plug-in terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047011_int.jpg",
              },
              {
                id: "DTI0000101",
                parent_id: "RSI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Spring-cage connection\\Disconnect and knife disconnect terminal blocks",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_URL: "disconnect-terminal-blocks",
                X_THUMBNAIL_ALT:
                  "Product list Disconnect and knife disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047012_int.jpg",
              },
            ],
          },
          {
            id: "ETI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Fast connection",
            text: "Fast connection",
            X_fullName: "Fast connection",
            X_Description:
              "The patented insulation displacement contact allows for a quick connection without conductor pretreatment.",
            X_URL: "fast-connection",
            X_THUMBNAIL_ALT: "Fast connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042740_int.jpg",
            items: [
              {
                id: "GTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Component terminal blocks",
                text: "Component terminal blocks",
                X_fullName: "Component terminal blocks",
                X_Description:
                  "Component terminal blocks with LEDs or blocking diodes for individual mounting.",
                X_URL: "fast-component-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Component terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047896_int.jpg",
              },
              {
                id: "ITI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Feed-through terminal blocks",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with fast connection in various sizes are ideal for both solid and stranded conductors.",
                X_URL: "fast-feed-through-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Feed-through terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047897_int.jpg",
              },
              {
                id: "KTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Hybrid terminal blocks",
                text: "Hybrid terminal blocks",
                X_fullName: "Hybrid terminal blocks",
                X_Description:
                  "Hybrid terminal blocks for various conductor cross sections combine fast connection with spring-cage or fast connection.",
                X_URL: "hybrid-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Hybrid terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047898_int.jpg",
              },
              {
                id: "MTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Multi-conductor terminal blocks",
                text: "Multi-conductor terminal blocks",
                X_fullName: "Multi-conductor terminal blocks",
                X_Description:
                  "Multi-conductor terminal blocks with fast connection for three or four conductors with various conductor cross sections.",
                X_URL: "fast-multi-conductor-terminal",
                X_THUMBNAIL_ALT: "Product list Multi-conductor terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047899_int.jpg",
              },
              {
                id: "OTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Multi-level terminal blocks",
                text: "Multi-level terminal blocks",
                X_fullName: "Multi-level terminal blocks",
                X_Description:
                  "Double- or three-level terminal blocks with offset levels for various conductor cross sections.",
                X_URL: "fast-multi-level-terminal-bl",
                X_THUMBNAIL_ALT: "Product list Multi-level terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047903_int.jpg",
              },
              {
                id: "QTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Ground terminal blocks",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with fast connection in various sizes and for a range of conductor cross sections.",
                X_URL: "fast-ground-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Ground terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047904_int.jpg",
              },
              {
                id: "STI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Fuse terminal blocks",
                text: "Fuse terminal blocks",
                X_fullName: "Fuse terminal blocks",
                X_Description:
                  "Fuse terminal blocks with fast connection in various designs. Versions with LED complete the product range.",
                X_URL: "fast-fuse-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Fuse terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047905_int.jpg",
              },
              {
                id: "UTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Plug-in terminal blocks",
                text: "Plug-in terminal blocks",
                X_fullName: "Plug-in terminal blocks",
                X_Description:
                  "Plug-in terminal blocks with fast connection in various designs and for a range of conductor cross sections.",
                X_URL: "fast-plug-in-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Plug-in terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047906_int.jpg",
              },
              {
                id: "WTI0000101",
                parent_id: "ETI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Fast connection\\Disconnect and knife disconnect terminal blocks",
                text: "Disconnect and knife disconnect terminal blocks",
                X_fullName: "Disconnect and knife disconnect terminal blocks",
                X_Description:
                  "Disconnect and knife disconnect terminal blocks with universal disconnect zone for accommodating isolating or function plugs.",
                X_URL: "knife-disconnect-terminal-block",
                X_THUMBNAIL_ALT:
                  "Product list Disconnect and knife disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047907_int.jpg",
              },
            ],
          },
          {
            id: "XTI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Bolt connection",
            text: "Bolt connection",
            X_fullName: "Bolt connection",
            X_Description:
              "Connect all types of conductors safely with long-term stability: multi-conductor connection with several cable lugs per bolt.",
            X_URL: "bolt-connection",
            X_THUMBNAIL_ALT: "Bolt connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042750_int.jpg",
            items: [
              {
                id: "ZTI0000101",
                parent_id: "XTI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Feed-through terminal blocks",
                text: "Feed-through terminal blocks",
                X_fullName: "Feed-through terminal blocks",
                X_Description:
                  "Compact feed-through terminal blocks with bolt connection in various sizes are ideal for both solid and stranded conductors.",
                X_URL: "feed-through-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Feed-through terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047908_int.jpg",
              },
              {
                id: "1UI0000101",
                parent_id: "XTI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Ground terminal blocks",
                text: "Ground terminal blocks",
                X_fullName: "Ground terminal blocks",
                X_Description:
                  "Ground terminal blocks with bolt connection in various sizes and for a range of conductor cross sections.",
                X_URL: "ground-terminal-blocks",
                X_THUMBNAIL_ALT: "Product list Ground terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047908_int.jpg",
              },
              {
                id: "3UI0000101",
                parent_id: "XTI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Bolt connection\\Test disconnect terminal blocks",
                text: "Test disconnect terminal blocks",
                X_fullName: "Test disconnect terminal blocks",
                X_Description:
                  "Test disconnect terminal blocks and plugs with bolt connection in various sizes.",
                X_URL: "bolt-test-disconnect-terminal",
                X_THUMBNAIL_ALT: "Product list Test disconnect terminal blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051571_int.jpg",
              },
            ],
          },
          {
            id: "4UI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Special connection",
            text: "Special connection",
            X_fullName: "Special connection",
            X_Description:
              "Special connection technologies for special industrial requirements.",
            X_URL: "special-connection",
            X_THUMBNAIL_ALT: "Special connection",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0060592_int.jpg",
            items: [
              {
                id: "5UI0000101",
                parent_id: "4UI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Special connection\\Slip-on plug-in connection",
                text: "Slip-on plug-in connection",
                X_fullName: "Slip-on plug-in connection",
                X_Description:
                  "Single- or double-level special terminal blocks with front slip-on plug-in connection. Can also be combined with screw connection.",
                X_URL: "slip-on-plug-in-connection",
                X_THUMBNAIL_ALT: "Product list Slip-on plug-in connection",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047909_int.jpg",
              },
            ],
          },
          {
            id: "6UI0000101",
            parent_id: "XQI0000101",
            FULLPATH: "PhoenixContact\\Modular terminal blocks\\Accessories",
            text: "Accessories",
            X_fullName: "Accessories",
            X_Description:
              "Accessories for modular terminal blocks, for example, bridges, covers, and test accessories.",
            X_URL: "mtb-accessories",
            X_THUMBNAIL_ALT: "Product list Accessories",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0055365_int.jpg",
          },
          {
            id: "7UI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol",
            text: "Terminal blocks for protection and control technol",
            X_fullName: "Terminal blocks for protection and control technol",
            X_Description:
              "Terminal blocks for the special requirements of protection and control technology.",
            X_URL: "protection-terminal-blocks",
            X_THUMBNAIL_ALT:
              "Terminal blocks for protection and control technology",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0056750_int.jpg",
            items: [
              {
                id: "8UI0000101",
                parent_id: "7UI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system",
                text: "Plug-in test system",
                X_fullName: "Plug-in test system",
                X_Description:
                  "The FAME test system simplifies the regular testing of power switchgear.",
                X_URL: "plug-in-test-system",
                X_THUMBNAIL_ALT: "Test disconnect system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056837_int.jpg",
                items: [
                  {
                    id: "9UI0000101",
                    parent_id: "8UI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra",
                    text: "Test disconnect system with operating plug and tra",
                    X_fullName:
                      "Test disconnect system with operating plug and tra",
                    X_Description:
                      "With FAME 1 you can combine testing processes in individual blocks.",
                    X_URL: "operating-plug-disconnect-system",
                    X_THUMBNAIL_ALT:
                      "Test disconnect system with operating plug and transformer short circuit in the ",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056913_int.jpg",
                    items: [
                      {
                        id: "AUI0000101",
                        parent_id: "9UI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Test plugs",
                        text: "Test plugs",
                        X_fullName: "Test plugs",
                        X_Description:
                          "Test plugs for safe and easy, manual testing operations.",
                        X_URL: "test-plugs-tra",
                        X_THUMBNAIL_ALT: "Product list Test plugs",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056907_int.jpg",
                      },
                      {
                        id: "BUI0000101",
                        parent_id: "9UI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Power plug",
                        text: "Power plug",
                        X_fullName: "Power plug",
                        X_Description:
                          "Power plugs for the plug-in test system with power plug.",
                        X_URL: "power-plug",
                        X_THUMBNAIL_ALT: "Product list Power plugs",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056908_int.jpg",
                      },
                      {
                        id: "CUI0000101",
                        parent_id: "9UI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Test terminal strips",
                        text: "Test terminal strips",
                        X_fullName: "Test terminal strips",
                        X_Description:
                          "Test terminal strips for the control cabinet panel for safe and easy, manual testing operations.",
                        X_URL: "test-terminal-strips-tra",
                        X_THUMBNAIL_ALT: "Product list Test terminal strips",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056909_int.jpg",
                      },
                      {
                        id: "DUI0000101",
                        parent_id: "9UI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system with operating plug and tra\\Accessories",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_Description:
                          "Accessories for the FAME plug-in test system for safe and easy manual testing operations.",
                        X_URL: "disconnect-sys-with-accessories",
                        X_THUMBNAIL_ALT: "Product list Accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056910_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "EUI0000101",
                    parent_id: "8UI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and ",
                    text: "Test disconnect system without operating plug and ",
                    X_fullName:
                      "Test disconnect system without operating plug and ",
                    X_Description:
                      "With FAME 2 you can combine complex testing processes in just one block.",
                    X_URL: "test-disconnect-system",
                    X_THUMBNAIL_ALT:
                      "Test disconnect system without operating plug and transformer short circuit in t",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056914_int.jpg",
                    items: [
                      {
                        id: "FUI0000101",
                        parent_id: "EUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strips",
                        text: "Test terminal strips",
                        X_fullName: "Test terminal strips",
                        X_Description:
                          "Test terminal strips for the control cabinet panel for safe and easy manual testing.",
                        X_URL: "test-terminal-strips",
                        X_THUMBNAIL_ALT: "Product list Test terminal strips",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056909_int.jpg",
                      },
                      {
                        id: "GUI0000101",
                        parent_id: "EUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strips according to VDE",
                        text: "Test terminal strips according to VDE",
                        X_fullName: "Test terminal strips according to VDE",
                        X_Description:
                          "Test terminal strips according to VDE for safe and easy manual testing.",
                        X_URL: "test-terminal-strips-vde",
                        X_THUMBNAIL_ALT:
                          "Product list Test terminal strips according to VDE",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0071976_int.jpg",
                      },
                      {
                        id: "HUI0000101",
                        parent_id: "EUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test plugs according to VDE",
                        text: "Test plugs according to VDE",
                        X_fullName: "Test plugs according to VDE",
                        X_Description:
                          "Test plugs according to VDE for safe and easy manual testing.",
                        X_URL: "test-plugs-vde",
                        X_THUMBNAIL_ALT:
                          "Product list Test plugs according to VDE",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056912_int.jpg",
                      },
                      {
                        id: "IUI0000101",
                        parent_id: "EUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Accessories",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_Description:
                          "Accessories for the FAME plug-in test system for safe and easy manual testing.",
                        X_URL: "disconnect-sys-without-acc",
                        X_THUMBNAIL_ALT: "Product list Accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056910_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "JUI0000101",
                    parent_id: "8UI0000101",
                    FULLPATH:
                      "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and ",
                    text: "Test disconnect system without operating plug and ",
                    X_fullName:
                      "Test disconnect system without operating plug and ",
                    X_Description:
                      "With FAME 3 you can combine complex testing processes into individual blocks.",
                    X_URL: "disconnect-system-without-plug",
                    X_THUMBNAIL_ALT:
                      "Test disconnect system without operating plug and transformer short circuit in t",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0069981_int.jpg",
                    items: [
                      {
                        id: "KUI0000101",
                        parent_id: "JUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test terminal strip",
                        text: "Test terminal strip",
                        X_fullName: "Test terminal strip",
                        X_Description:
                          "Test disconnect socket for the control cabinet panel. For safe, service-friendly testing.",
                        X_URL: "test-terminal-strip",
                        X_THUMBNAIL_ALT: "Product list Test disconnect socket",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0069982_int.jpg",
                      },
                      {
                        id: "LUI0000101",
                        parent_id: "JUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Test plugs",
                        text: "Test plugs",
                        X_fullName: "Test plugs",
                        X_Description:
                          "Test plugs for safe, service-friendly testing.",
                        X_URL: "test-plugs",
                        X_THUMBNAIL_ALT: "Product list Test plugs",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0069983_int.jpg",
                      },
                      {
                        id: "MUI0000101",
                        parent_id: "JUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Service connectors",
                        text: "Service connectors",
                        X_fullName: "Service connectors",
                        X_Description:
                          "Service plugs for special measuring and switching tasks.",
                        X_URL: "service-connectors",
                        X_THUMBNAIL_ALT: "Product list Service plugs",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0069984_int.jpg",
                      },
                      {
                        id: "NUI0000101",
                        parent_id: "JUI0000101",
                        FULLPATH:
                          "PhoenixContact\\Modular terminal blocks\\Terminal blocks for protection and control technol\\Plug-in test system\\Test disconnect system without operating plug and \\Accessories",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_Description:
                          "Accessories for the FAME test disconnect system. For safe, service-friendly testing.",
                        X_URL: "disconnect-system-accessories",
                        X_THUMBNAIL_ALT: "Product list Accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0069985_int.jpg",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "OUI0000101",
            parent_id: "XQI0000101",
            FULLPATH:
              "PhoenixContact\\Modular terminal blocks\\Distributor blocks",
            text: "Distributor blocks",
            X_fullName: "Distributor blocks",
            X_Description:
              "You can now save even more time and space during installation with the ready-to-connect distribution blocks in Push-in and screw connection technology.",
            X_URL: "distributor-blocks",
            X_THUMBNAIL_ALT: "Distribution blocks",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076854_int.jpg",
            items: [
              {
                id: "PUI0000101",
                parent_id: "OUI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks for DIN rail mounting",
                text: "Push-in distribution blocks for DIN rail mounting",
                X_fullName: "Push-in distribution blocks for DIN rail mounting",
                X_Description:
                  "Distribution blocks for mounting on NS 15 and NS 35 DIN rails and space-saving transverse mounting on NS 35.",
                X_URL: "push-in-distribution-blocks-din",
                X_THUMBNAIL_ALT:
                  "Product list Push-in distribution blocks for DIN rail mounting",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076787_int.jpg",
              },
              {
                id: "QUI0000101",
                parent_id: "OUI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks for adhesive mounting",
                text: "Push-in distribution blocks for adhesive mounting",
                X_fullName: "Push-in distribution blocks for adhesive mounting",
                X_Description:
                  "Distribution blocks with adhesive pads enable tool-free mounting.",
                X_URL: "push-in-adhesive-mounting",
                X_THUMBNAIL_ALT:
                  "Product list Push-in distribution blocks for adhesive mounting",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076788_int.jpg",
              },
              {
                id: "RUI0000101",
                parent_id: "OUI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks",
                text: "Push-in distribution blocks",
                X_fullName: "Push-in distribution blocks",
                X_Description:
                  "The Push-in distributor base blocks can be self-assembled using mounting accessories for direct or DIN rail mounting.",
                X_URL: "push-in-dir-distribution-blocks",
                X_THUMBNAIL_ALT: "Product list Push-in distribution blocks",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076789_int.jpg",
              },
              {
                id: "SUI0000101",
                parent_id: "OUI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\DIN rail and direct mounting adapters for Push-in ",
                text: "DIN rail and direct mounting adapters for Push-in ",
                X_fullName:
                  "DIN rail and direct mounting adapters for Push-in ",
                X_Description:
                  "The Push-in distributor base blocks can be self-assembled using mounting accessories for direct or DIN rail mounting.",
                X_URL: "din-rail-and-mounting-push-in",
                X_THUMBNAIL_ALT:
                  "Product list DIN rail and direct mounting adapters for Push-in distribution bloc",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076790_int.jpg",
              },
              {
                id: "TUI0000101",
                parent_id: "OUI0000101",
                FULLPATH:
                  "PhoenixContact\\Modular terminal blocks\\Distributor blocks\\Push-in distribution blocks",
                text: "Push-in distribution blocks",
                X_fullName: "Push-in distribution blocks",
                X_Description:
                  "Space-saving potential distributor terminals with terminal points for different conductor cross-section ranges.",
                X_URL: "push-in-distribution-blocks",
                X_THUMBNAIL_ALT: "Product list Push-in distributor terminals",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0076792_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "UUI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Relay modules",
        text: "Relay modules",
        X_fullName: "Relay modules",
        X_Description:
          "Whether switching, isolating or monitoring. Whatever the function you require \u2013 we offer custom relay modules.",
        X_URL: "relay-modules",
        X_THUMBNAIL_ALT: "Relay modules",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0051197_int.jpg",
        items: [
          {
            id: "VUI0000101",
            parent_id: "UUI0000101",
            FULLPATH:
              "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules",
            text: "Electromechanical and solid-state relay modules",
            X_fullName: "Electromechanical and solid-state relay modules",
            X_Description:
              "Use the relays to switch, isolate, amplify, and multiply signals.",
            X_URL: "electromechanical-relay-modules",
            X_THUMBNAIL_ALT: "Electromechanical and solid-state relay modules",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0051198_int.jpg",
            items: [
              {
                id: "WUI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules",
                text: "Highly compact relay modules",
                X_fullName: "Highly compact relay modules",
                X_Description:
                  "High-performance interface between control and field level with all the advantages of proven modular terminal block technology.",
                X_URL: "highly-compact-relay-modules",
                X_THUMBNAIL_ALT: "Highly-compact relay modules",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051344_int.jpg",
                items: [
                  {
                    id: "XUI0000101",
                    parent_id: "WUI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Complete modules",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_Description:
                      "Complete modules can be used universally and consist of a basic terminal block and plug-in electromechanical relay.",
                    X_URL: "highly-complete-modules",
                    X_THUMBNAIL_ALT: "Product list Complete modules",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042836_int.jpg",
                  },
                  {
                    id: "YUI0000101",
                    parent_id: "WUI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Single relays",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_URL: "highly-single-relays",
                    X_THUMBNAIL_ALT: "Product list Single relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0052012_int.jpg",
                  },
                  {
                    id: "ZUI0000101",
                    parent_id: "WUI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Base",
                    text: "Base",
                    X_fullName: "Base",
                    X_Description:
                      "Relay bases with screw connection or also with spring-cage connection for miniature power relays.",
                    X_URL: "base",
                    X_THUMBNAIL_ALT: "Product list Bases",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0052009_int.jpg",
                  },
                  {
                    id: "0VI0000101",
                    parent_id: "WUI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Highly compact relay modules\\Accessories and marking",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_Description:
                      "The accessories range from relay retaining brackets and marking labels to loop bridges in various colors.",
                    X_URL: "highly-accessories-and-marking",
                    X_THUMBNAIL_ALT: "Product list Accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042841_int.jpg",
                  },
                ],
              },
              {
                id: "1VI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system",
                text: "Universal industrial relay system",
                X_fullName: "Universal industrial relay system",
                X_Description:
                  "The industrial relay system consists of either complete modules or a modular system.",
                X_URL: "universal-industrial-relay-sys",
                X_THUMBNAIL_ALT: "Universal industrial relay system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051683_int.jpg",
                items: [
                  {
                    id: "2VI0000101",
                    parent_id: "1VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Complete modules",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_Description:
                      "Complete modules can be used universally and consist of a basic terminal block and plug-in electromechanical relay.",
                    X_URL: "universal-complete-modules",
                    X_THUMBNAIL_ALT: "Product list Complete modules",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0051978_int.jpg",
                  },
                  {
                    id: "3VI0000101",
                    parent_id: "1VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Single relays",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_URL: "universal-single-relays",
                    X_THUMBNAIL_ALT: "Product list Single relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0051979_int.jpg",
                  },
                  {
                    id: "4VI0000101",
                    parent_id: "1VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Base",
                    text: "Base",
                    X_fullName: "Base",
                    X_Description:
                      "Relay bases with screw connection or also with spring-cage connection for miniature power relays.",
                    X_URL: "universal-base",
                    X_THUMBNAIL_ALT: "Product list Bases",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0051980_int.jpg",
                  },
                  {
                    id: "5VI0000101",
                    parent_id: "1VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Universal industrial relay system\\Accessories and marking",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_Description:
                      "Wide range of accessories and marking material. Including, for example, bridges, zack marker strips, and special function modules.",
                    X_URL: "accessories-and-marking",
                    X_THUMBNAIL_ALT: "Product list Accessories and marking",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0051981_int.jpg",
                  },
                ],
              },
              {
                id: "6VI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Multi-channel relay modules",
                text: "Multi-channel relay modules",
                X_fullName: "Multi-channel relay modules",
                X_Description:
                  "Multi-channel relay modules are designed as 4, 8, and 16-channel interfaces.",
                X_URL: "multi-channel-relay-modules",
                X_THUMBNAIL_ALT: "Product list Multi-channel relay modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042840_int.jpg",
              },
              {
                id: "7VI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules with soldered-in relays",
                text: "Relay modules with soldered-in relays",
                X_fullName: "Relay modules with soldered-in relays",
                X_Description:
                  "The modular Interface system contains a wide range of interface modules that can be combined flexibly.",
                X_URL: "soldered-in-relays-modules",
                X_THUMBNAIL_ALT:
                  "Product list Relay modules with soldered-in relay",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051720_int.jpg",
              },
              {
                id: "8VI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules in modular terminal block design",
                text: "Relay modules in modular terminal block design",
                X_fullName: "Relay modules in modular terminal block design",
                X_Description:
                  "High-performance interfaces for binary signal processing in 6.2 mm terminal block format.",
                X_URL: "relay-in-modular-terminal-block",
                X_THUMBNAIL_ALT:
                  "Product list Relay modules in terminal block design",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051709_int.jpg",
              },
              {
                id: "9VI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas",
                text: "Relay modules for potentially explosive areas",
                X_fullName: "Relay modules for potentially explosive areas",
                X_Description:
                  "The relay modules are suitable for use in zone 2 potentially explosive areas.",
                X_URL: "explosive-areas-relay-modules",
                X_THUMBNAIL_ALT:
                  "Relay modules for potentially explosive areas",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0087149_int.jpg",
                items: [
                  {
                    id: "AVI0000101",
                    parent_id: "9VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Complete modules",
                    text: "Complete modules",
                    X_fullName: "Complete modules",
                    X_Description:
                      "The modules can be used universally and consist of a basic terminal block and plug-in relay.",
                    X_URL: "complete-modules",
                    X_THUMBNAIL_ALT: "Product list Complete modules",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0062888_int.jpg",
                  },
                  {
                    id: "BVI0000101",
                    parent_id: "9VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Single relays",
                    text: "Single relays",
                    X_fullName: "Single relays",
                    X_Description:
                      "Single relays in the modular system can be ordered individually and custom assembled.",
                    X_URL: "single-relays",
                    X_THUMBNAIL_ALT: "Product list Single relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067229_int.jpg",
                  },
                  {
                    id: "CVI0000101",
                    parent_id: "9VI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Relay modules for potentially explosive areas\\Accessories and marking",
                    text: "Accessories and marking",
                    X_fullName: "Accessories and marking",
                    X_Description:
                      "The accessories range from relay retaining brackets and marking labels to loop bridges in various colors.",
                    X_URL: "relay-accessories-and-marking",
                    X_THUMBNAIL_ALT: "Product list Accessories and marking",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0067228_int.jpg",
                  },
                ],
              },
              {
                id: "DVI0000101",
                parent_id: "VUI0000101",
                FULLPATH:
                  "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Force-guided coupling relays",
                text: "Force-guided coupling relays",
                X_fullName: "Force-guided coupling relays",
                X_Description:
                  "Coupling relays with force-guided contacts for universal use.",
                X_URL: "force-guided-relays",
                X_THUMBNAIL_ALT: "Force-guided coupling relays",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0065615_int.jpg",
                items: [
                  {
                    id: "EVI0000101",
                    parent_id: "DVI0000101",
                    FULLPATH:
                      "PhoenixContact\\Relay modules\\Electromechanical and solid-state relay modules\\Force-guided coupling relays\\Positively driven coupling relays",
                    text: "Positively driven coupling relays",
                    X_fullName: "Positively driven coupling relays",
                    X_Description:
                      "Forcibly guided coupling relays for universal use.",
                    X_URL: "positively-driven-relay",
                    X_THUMBNAIL_ALT:
                      "Product list Forcibly guided coupling relays",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0065619_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "GVI0000101",
            parent_id: "UUI0000101",
            FULLPATH:
              "PhoenixContact\\Relay modules\\Relay modules with lockable manual actuation",
            text: "Relay modules with lockable manual actuation",
            X_fullName: "Relay modules with lockable manual actuation",
            X_Description:
              "The PLC-INTERFACE relay system is the high-performance interface between the controller and system peripherals.",
            X_URL: "relay-modules-lockable",
            X_THUMBNAIL_ALT:
              "Product list Relay modules with lockable manual actuation",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0086127_int.jpg",
          },
        ],
      },
      {
        id: "HVI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Protective devices",
        text: "Protective devices",
        X_fullName: "Protective devices",
        X_Description:
          "Do not take any chances: protective devices provide optimum protection for your cables, systems, and devices.",
        X_URL: "protective-devices",
        X_THUMBNAIL_ALT: "Protective devices",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042861_int.jpg",
        items: [
          {
            id: "IVI0000101",
            parent_id: "HVI0000101",
            FULLPATH:
              "PhoenixContact\\Protective devices\\Device circuit breakers",
            text: "Device circuit breakers",
            X_fullName: "Device circuit breakers",
            X_Description:
              "Device circuit breakers ensure a high level of system availability thanks to the selective protection of operating equipment.",
            X_URL: "device-circuit-breakers-main",
            X_THUMBNAIL_ALT: "Device circuit breakers",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0081597_int.jpg",
            items: [
              {
                id: "JVI0000101",
                parent_id: "IVI0000101",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers",
                text: "Electronic device circuit breakers",
                X_fullName: "Electronic device circuit breakers",
                X_Description:
                  "Discover our product line of single-channel and multi-channel electronic circuit breakers.",
                X_URL: "device-circuit-breakers",
                X_THUMBNAIL_ALT: "Electronic circuit breakers",
                X_picturelink:
                  "mimes/general/images/pic_gall_thb_a_0081623_int.jpg",
                items: [
                  {
                    id: "KVI0000101",
                    parent_id: "JVI0000101",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker",
                    text: "Single-channel electronic device circuit breaker",
                    X_fullName:
                      "Single-channel electronic device circuit breaker",
                    X_Description:
                      "Organize your system protection precisely in accordance with your needs with single-channel circuit breakers",
                    X_URL: "single-channel-circuit-break",
                    X_THUMBNAIL_ALT:
                      "Single-channel electronic circuit breakers",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0081583_int.jpg",
                    items: [
                      {
                        id: "LVI0000101",
                        parent_id: "KVI0000101",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker\\Single-channel electronic device circuit breaker",
                        text: "Single-channel electronic device circuit breaker",
                        X_fullName:
                          "Single-channel electronic device circuit breaker",
                        X_Description:
                          "Organize your system protection precisely in accordance with your needs with single-channel circuit breakers.",
                        X_URL: "single-channel-circuit-breaker",
                        X_THUMBNAIL_ALT:
                          "Product list Single-channel electronic circuit breakers",
                        X_picturelink:
                          "mimes/general/images/pic_stage_thb_a_0081583_int.jpg",
                      },
                      {
                        id: "MVI0000101",
                        parent_id: "KVI0000101",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Single-channel electronic device circuit breaker\\Accessories for device circuit breakers",
                        text: "Accessories for device circuit breakers",
                        X_fullName: "Accessories for device circuit breakers",
                        X_Description:
                          "Accessories and additional products for the entire range of device circuit breakers.",
                        X_URL: "circuit-breakers-accessories ",
                        X_THUMBNAIL_ALT:
                          "Product list Accessories for device circuit breakers",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0047619_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "NVI0000101",
                    parent_id: "JVI0000101",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Multi-channel electronic circuit breakers",
                    text: "Multi-channel electronic circuit breakers",
                    X_fullName: "Multi-channel electronic circuit breakers",
                    X_Description:
                      "The multi-channel circuit breakers can be adjusted individually for each channel and provide a functional, space-saving solution for every application.",
                    X_URL: "multi-channel-circuit-break",
                    X_THUMBNAIL_ALT:
                      "Multi-channel electronic circuit breakers",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0074376_int.jpg",
                    items: [
                      {
                        id: "OVI0000101",
                        parent_id: "NVI0000101",
                        FULLPATH:
                          "PhoenixContact\\Protective devices\\Device circuit breakers\\Electronic device circuit breakers\\Multi-channel electronic circuit breakers\\Multi-channel electronic device circuit breakers",
                        text: "Multi-channel electronic device circuit breakers",
                        X_fullName:
                          "Multi-channel electronic device circuit breakers",
                        X_Description:
                          "Multi-channel circuit breakers can be adjusted individually for each channel and provide a functional, space-saving solution for every application.",
                        X_URL: "multi-channel-circuit-breakers",
                        X_THUMBNAIL_ALT:
                          "Product list Multi-channel electronic circuit breakers",
                        X_picturelink:
                          "mimes/general/images/pic_stage_thb_a_0074376_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "PVI0000101",
                parent_id: "IVI0000101",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Circuit Breaker",
                text: "Thermal Circuit Breaker",
                X_fullName: "Thermal Circuit Breaker",
                X_Description:
                  "Thermal device circuit breakers shut down connected loads safely in the event of overload.",
                X_URL: "thermal-circuit-breaker",
                X_THUMBNAIL_ALT: "Thermal device circuit breakers",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051878_int.jpg",
                items: [
                  {
                    id: "QVI0000101",
                    parent_id: "PVI0000101",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Circuit Breaker\\Thermal device circuit breakers",
                    text: "Thermal device circuit breakers",
                    X_fullName: "Thermal device circuit breakers",
                    X_Description:
                      "Thermal device circuit breakers protect individual pieces of equipment against overload",
                    X_URL: "thermal-device-circuit-breakers",
                    X_THUMBNAIL_ALT:
                      "Product list Thermal device circuit breakers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042888_int.jpg",
                  },
                ],
              },
              {
                id: "RVI0000101",
                parent_id: "IVI0000101",
                FULLPATH:
                  "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Magnetic Circuit Breaker",
                text: "Thermal Magnetic Circuit Breaker",
                X_fullName: "Thermal Magnetic Circuit Breaker",
                X_Description:
                  "Thermomagnetic device circuit breakers shut down connected loads safely in the event of overload or short circuit.",
                X_URL: "thermal-magnetic-circuit-breaker",
                X_THUMBNAIL_ALT: "Thermomagnetic device circuit breakers",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0081584_int.jpg",
                items: [
                  {
                    id: "SVI0000101",
                    parent_id: "RVI0000101",
                    FULLPATH:
                      "PhoenixContact\\Protective devices\\Device circuit breakers\\Thermal Magnetic Circuit Breaker\\Thermomagnetic circuit breakers",
                    text: "Thermomagnetic circuit breakers",
                    X_fullName: "Thermomagnetic circuit breakers",
                    X_Description:
                      "Thermomagnetic device circuit breakers protect individual pieces of equipment against overload and short circuit.",
                    X_URL: "thermomagnetic-circuit-breakers",
                    X_THUMBNAIL_ALT:
                      "Product list Thermomagnetic device circuit breakers",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042891_int.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "TVI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Sensor/actuator cabling",
        text: "Sensor/actuator cabling",
        X_fullName: "Sensor/actuator cabling",
        X_Description:
          "Connect your sensors and actuators quickly and safely in the field.",
        X_URL: "sensor-actuator-cabling",
        X_THUMBNAIL_ALT: "Sensor/actuator cabling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0042807_int.jpg",
        items: [
          {
            id: "UVI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Assembled cables",
            text: "Assembled cables",
            X_fullName: "Assembled cables",
            X_Description:
              "Connect sensors and actuators quickly and easily in the field using assembled cables.",
            X_URL: "assembled-cables",
            X_THUMBNAIL_ALT: "Assembled cables",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042802_int.jpg",
            items: [
              {
                id: "VVI0000101",
                parent_id: "UVI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Assembled cables\\Cable with circular connectors",
                text: "Cable with circular connectors",
                X_fullName: "Cable with circular connectors",
                X_Description:
                  'Cables with M5 to M12 plug-in connectors available in 1/2" and 7/8", as well as in various versions.',
                X_URL: "cable-with-circular-connectors",
                X_THUMBNAIL_ALT: "Product list Cables with circular connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043128_int.jpg",
              },
              {
                id: "WVI0000101",
                parent_id: "UVI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Assembled cables\\Cables with valve connectors",
                text: "Cables with valve connectors",
                X_fullName: "Cables with valve connectors",
                X_Description:
                  "Cables with valve connectors are available in numerous designs and protective circuits.",
                X_URL: "cables-with-valve-connectors",
                X_THUMBNAIL_ALT: "Product list Cables with valve connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043132_int.jpg",
              },
            ],
          },
          {
            id: "XVI0000101",
            parent_id: "TVI0000101",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\Connectors",
            text: "Connectors",
            X_fullName: "Connectors",
            X_Description:
              "Connectors that can be assembled enable you to reliably design your cabling in the field.",
            X_URL: "sensor-connectors-main",
            X_THUMBNAIL_ALT: "Connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042811_int.jpg",
            items: [
              {
                id: "YVI0000101",
                parent_id: "XVI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Connectors\\Connectors",
                text: "Connectors",
                X_fullName: "Connectors",
                X_Description:
                  "Connectors with variable connection methods are available for a wide range of applications.",
                X_URL: "sensor-connectors",
                X_THUMBNAIL_ALT: "Product list Connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043164_int.jpg",
              },
              {
                id: "ZVI0000101",
                parent_id: "XVI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Connectors\\Valve connectors",
                text: "Valve connectors",
                X_fullName: "Valve connectors",
                X_Description:
                  "Valve connectors are available in numerous designs and protective circuits.",
                X_URL: "valve-connectors",
                X_THUMBNAIL_ALT: "Product list Valve connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043166_int.jpg",
              },
            ],
          },
          {
            id: "1WI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Device connectors",
            text: "Device connectors",
            X_fullName: "Device connectors",
            X_Description:
              "Device connectors for all common applications, even with a high number of positions.",
            X_URL: "device-connectors",
            X_THUMBNAIL_ALT: "Product list for device connectors",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0043167_int.jpg",
          },
          {
            id: "2WI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes",
            text: "Distributor boxes",
            X_fullName: "Distributor boxes",
            X_Description:
              "Distributor boxes bundle and distribute signals quickly and clearly in the field.",
            X_URL: "distributor-boxes",
            X_THUMBNAIL_ALT: "Distributor boxes",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042814_int.jpg",
            items: [
              {
                id: "3WI0000101",
                parent_id: "2WI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes\\Distributor boxes with M5 to M12 slots",
                text: "Distributor boxes with M5 to M12 slots",
                X_fullName: "Distributor boxes with M5 to M12 slots",
                X_Description:
                  "Distributor boxes with M5 to M12 slots for quick and easy signal bundling.",
                X_URL: "m5-to-m12-distributor-boxes",
                X_THUMBNAIL_ALT:
                  "Product list Distributor boxes with M5 to M12 slots",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043168_int.jpg",
              },
              {
                id: "4WI0000101",
                parent_id: "2WI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributor boxes\\Connector hoods",
                text: "Connector hoods",
                X_fullName: "Connector hoods",
                X_Description:
                  "Corresponding connector hoods with and without master cable are available for headers.",
                X_URL: "connector-hoods",
                X_THUMBNAIL_ALT: "Product list Connector hoods",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043170_int.jpg",
              },
            ],
          },
          {
            id: "5WI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters",
            text: "Distributors and adapters",
            X_fullName: "Distributors and adapters",
            X_Description:
              "Distributors and adapters enable a flexible cabling design.",
            X_URL: "distributors-and-adapters",
            X_THUMBNAIL_ALT: "Distributors and adapters",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042813_int.jpg",
            items: [
              {
                id: "6WI0000101",
                parent_id: "5WI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters\\Distributors",
                text: "Distributors",
                X_fullName: "Distributors",
                X_Description:
                  "Distributors are available in various different designs with or without cable.",
                X_URL: "distributors",
                X_THUMBNAIL_ALT: "Product list Distributors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043173_int.jpg",
              },
              {
                id: "7WI0000101",
                parent_id: "5WI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Distributors and adapters\\Adapter",
                text: "Adapter",
                X_fullName: "Adapter",
                X_Description:
                  "The product range includes valve plugs as well as metric adapters.",
                X_URL: "adapter",
                X_THUMBNAIL_ALT: "Product list Adapters",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043175_int.jpg",
              },
            ],
          },
          {
            id: "9WI0000101",
            parent_id: "TVI0000101",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\By the meter",
            text: "By the meter",
            X_fullName: "By the meter",
            X_Description:
              "The range includes sensor and valve plug cables, as well as master cables for distributor boxes.",
            X_URL: "by-the-meter",
            X_THUMBNAIL_ALT: "Product list Cables sold by the meter",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0043181_int.jpg",
          },
          {
            id: "AWI0000101",
            parent_id: "TVI0000101",
            FULLPATH: "PhoenixContact\\Sensor/actuator cabling\\Accessories",
            text: "Accessories",
            X_fullName: "Accessories",
            X_Description:
              "Accessories for sensor/actuator cabling, e.g., assembly tool, sealing elements, and marking material.",
            X_URL: "sensor-accessories",
            X_THUMBNAIL_ALT: "Product list Accessories",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0043386_int.jpg",
          },
          {
            id: "BWI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements",
            text: "Cabling for North American requirements",
            X_fullName: "Cabling for North American requirements",
            X_Description:
              "Cabling for the North American market enables a high degree of flexibility thanks to the short delivery times.",
            X_URL: "cabling-for-na-requirements",
            X_THUMBNAIL_ALT: "Cabling for North American requirements",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0053131_int.jpg",
            items: [
              {
                id: "CWI0000101",
                parent_id: "BWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements\\Sensor/actuator cables",
                text: "Sensor/actuator cables",
                X_fullName: "Sensor/actuator cables",
                X_Description:
                  "Signal cables with M8 and M12 plug-in connectors with AWG cross-sections.",
                X_URL: "sensor-actuator-cables",
                X_THUMBNAIL_ALT: "Product list Sensor/actuator cables",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051226_int.jpg",
              },
              {
                id: "DWI0000101",
                parent_id: "BWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for North American requirements\\Cabling for explosion-protected areas",
                text: "Cabling for explosion-protected areas",
                X_fullName: "Cabling for explosion-protected areas",
                X_Description:
                  "M12 cabling for potentially explosive areas, with locking clip.",
                X_URL: "explosion-protected-cabling",
                X_THUMBNAIL_ALT:
                  "Product list Cabling for explosion-protected areas",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0060277_int.jpg",
              },
            ],
          },
          {
            id: "EWI0000101",
            parent_id: "TVI0000101",
            FULLPATH:
              "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications",
            text: "Cabling for special applications",
            X_fullName: "Cabling for special applications",
            X_Description:
              "Cabling products for demanding industries or special applications.",
            X_URL: "cabling-for-applications",
            X_THUMBNAIL_ALT: "Cabling for special applications",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0060335_int.jpg",
            items: [
              {
                id: "FWI0000101",
                parent_id: "EWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for the food industry",
                text: "Cabling for the food industry",
                X_fullName: "Cabling for the food industry",
                X_Description:
                  "Cabling for the food industry is corrosion resistant and features a special design.",
                X_URL: "food-industry-cabling",
                X_THUMBNAIL_ALT: "Cabling for the food industry",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042821_int.jpg",
                items: [
                  {
                    id: "GWI0000101",
                    parent_id: "FWI0000101",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for the food industry\\Connectors",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_Description:
                      "Connectors for the food industry are made from particularly resistant materials.",
                    X_URL: "cabling-connectors",
                    X_THUMBNAIL_ALT: "Product list Connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043183_int.jpg",
                  },
                ],
              },
              {
                id: "HWI0000101",
                parent_id: "EWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for outdoor applications",
                text: "Cabling for outdoor applications",
                X_fullName: "Cabling for outdoor applications",
                X_Description:
                  "Cabling for outdoor applications is made from robust and resistant materials.",
                X_URL: "cabling-for-outdoor-applications",
                X_THUMBNAIL_ALT: "Cabling for outdoor applications",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042822_int.jpg",
                items: [
                  {
                    id: "IWI0000101",
                    parent_id: "HWI0000101",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for outdoor applications\\Cables for mobile hydraulics",
                    text: "Cables for mobile hydraulics",
                    X_fullName: "Cables for mobile hydraulics",
                    X_Description:
                      "Cables with valve, Deutsch, and Superseal connectors for mobile hydraulics.",
                    X_URL: "mobile-cables-hydraulics",
                    X_THUMBNAIL_ALT:
                      "Product list Cables for mobile hydraulics",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0056861_int.jpg",
                  },
                ],
              },
              {
                id: "JWI0000101",
                parent_id: "EWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for robots and drag chains",
                text: "Cabling for robots and drag chains",
                X_fullName: "Cabling for robots and drag chains",
                X_Description:
                  "Cabling for robots and drag chains is a reliable solution in areas that are particularly subject to stress.",
                X_URL: "cabling-and-drag-chains",
                X_THUMBNAIL_ALT: "Cabling for robots and drag chains",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042820_int.jpg",
                items: [
                  {
                    id: "KWI0000101",
                    parent_id: "JWI0000101",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cabling for robots and drag chains\\Cable",
                    text: "Cable",
                    X_fullName: "Cable",
                    X_Description:
                      "Highly flexible assembled cables for robots and drag chains.",
                    X_URL: "sensor-cabling-cable",
                    X_THUMBNAIL_ALT: "Product list Cables",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043195_int.jpg",
                  },
                ],
              },
              {
                id: "MWI0000101",
                parent_id: "EWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\Cables for the railway industry",
                text: "Cables for the railway industry",
                X_fullName: "Cables for the railway industry",
                X_Description:
                  "Assembled cables with M12 connectors for signals and data.",
                X_URL: "cables-for-the-railway-industry",
                X_THUMBNAIL_ALT: "Product list Cables for the rail industry",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051228_int.jpg",
              },
              {
                id: "NWI0000101",
                parent_id: "EWI0000101",
                FULLPATH:
                  "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\M12 cabling for power applications",
                text: "M12 cabling for power applications",
                X_fullName: "M12 cabling for power applications",
                X_Description:
                  "Maximum power transmission while keeping the size to a minimum.",
                X_URL: "m12-cabling-power-applications",
                X_THUMBNAIL_ALT: "M12 cabling for power applications",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0059840_int.jpg",
                items: [
                  {
                    id: "OWI0000101",
                    parent_id: "NWI0000101",
                    FULLPATH:
                      "PhoenixContact\\Sensor/actuator cabling\\Cabling for special applications\\M12 cabling for power applications\\Distributor and distributor boxes",
                    text: "Distributor and distributor boxes",
                    X_fullName: "Distributor and distributor boxes",
                    X_Description:
                      "M12 power distributor boxes, also with diagnostic functions, and Y-distributors.",
                    X_URL: "cabling-distributor-boxes",
                    X_THUMBNAIL_ALT:
                      "Product list Distributors and distributor boxes",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060281_int.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "PWI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Software",
        text: "Software",
        X_fullName: "Software",
        X_Description:
          "Tailor made and user friendly: software from Phoenix Contact is your efficient and multifunctional tool for all automation tasks.",
        X_URL: "software",
        X_THUMBNAIL_ALT: "Software",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0048028_int.jpg",
        items: [
          {
            id: "QWI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Function blocks",
            text: "Function blocks",
            X_fullName: "Function blocks",
            X_Description:
              "Function blocks that can be used to integrate complex functions flexibly and cost-effectively in a control program.",
            X_URL: "function-blocks",
            X_THUMBNAIL_ALT: "Function blocks",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0047386_int.jpg",
            items: [
              {
                id: "RWI0000101",
                parent_id: "QWI0000101",
                FULLPATH:
                  "PhoenixContact\\Software\\Function blocks\\Water and Wastewater Treatment",
                text: "Water and Wastewater Treatment",
                X_fullName: "Water and Wastewater Treatment",
                X_Description:
                  "Function block library for wastewater treatment and drinking water treatment enables easy integration of measurement and control technology.",
                X_URL: "water-and-wastewater-treatment",
                X_THUMBNAIL_ALT: "Water and Wastewater Treatment",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0068835_int.jpg",
                items: [
                  {
                    id: "SWI0000101",
                    parent_id: "RWI0000101",
                    FULLPATH:
                      "PhoenixContact\\Software\\Function blocks\\Water and Wastewater Treatment\\Function blocks for Water and Wastewater Treatment",
                    text: "Function blocks for Water and Wastewater Treatment",
                    X_fullName:
                      "Function blocks for Water and Wastewater Treatment",
                    X_Description:
                      "Function block library for process automation in wastewater treatment and drinking water treatment.",
                    X_URL: "function-blocks-water-treatment",
                    X_THUMBNAIL_ALT:
                      "Product list Function blocks for Water and Wastewater Treatment",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0068835_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "UWI0000101",
            parent_id: "PWI0000101",
            FULLPATH:
              "PhoenixContact\\Software\\Configuration, monitoring, and diagnostics",
            text: "Configuration, monitoring, and diagnostics",
            X_fullName: "Configuration, monitoring, and diagnostics",
            X_Description:
              "Software tools for easy configuration, preventive monitoring, and fast diagnostics in the event of an error.",
            X_URL: "config-monitor-diagnostics",
            X_THUMBNAIL_ALT:
              "Product list Configuration, monitoring and diagnostics",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0046621_int.jpg",
          },
          {
            id: "WWI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Planning and configuration",
            text: "Planning and configuration",
            X_fullName: "Planning and configuration",
            X_Description:
              "Software tools for planning and configuring automation stations and markings.",
            X_URL: "planning-and-configuration",
            X_THUMBNAIL_ALT: "Product list Planning and configuration",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0046623_int.jpg",
          },
          {
            id: "YWI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Programming",
            text: "Programming",
            X_fullName: "Programming",
            X_Description:
              "Software for the efficient programming of Phoenix Contact controllers in every class.",
            X_URL: "programming",
            X_THUMBNAIL_ALT: "Product list Programming",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0046625_int.jpg",
          },
          {
            id: "ZWI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Drivers and interfaces",
            text: "Drivers and interfaces",
            X_fullName: "Drivers and interfaces",
            X_Description:
              "Open, standardized interfaces such as OPC or SNMP offer flexible integration in numerous higher-level systems.",
            X_URL: "drivers-and-interfaces",
            X_THUMBNAIL_ALT: "Drivers and interfaces",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0048028_int.jpg",
            items: [
              {
                id: "0XI0000101",
                parent_id: "ZWI0000101",
                FULLPATH:
                  "PhoenixContact\\Software\\Drivers and interfaces\\OPC communication interface",
                text: "OPC communication interface",
                X_fullName: "OPC communication interface",
                X_Description:
                  "Products for standardized connection of your controller solution to all higher-level software systems via OPC.",
                X_URL: "opc-communication-interface",
                X_THUMBNAIL_ALT: "Product list OPC communication interface",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0065606_int.jpg",
              },
            ],
          },
          {
            id: "2XI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Visualization",
            text: "Visualization",
            X_fullName: "Visualization",
            X_Description:
              "Software for a wide range of visualization tasks in automation systems. Ideal for HMI devices and industrial PCs.",
            X_URL: "visualization",
            X_THUMBNAIL_ALT: "Product list Visualization",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0057795_int.jpg",
          },
          {
            id: "3XI0000101",
            parent_id: "PWI0000101",
            FULLPATH: "PhoenixContact\\Software\\Software for E-Mobility",
            text: "Software for E-Mobility",
            X_fullName: "Software for E-Mobility",
            X_Description:
              "Software for controlling or monitoring individual charging stations or entire charging parks.",
            X_URL: "software-for-e-mobility",
            X_THUMBNAIL_ALT: "Software for electromobility",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0086575_int.jpg",
            items: [
              {
                id: "5XI0000101",
                parent_id: "3XI0000101",
                FULLPATH:
                  "PhoenixContact\\Software\\Software for E-Mobility\\Software suite for charging park management",
                text: "Software suite for charging park management",
                X_fullName: "Software suite for charging park management",
                X_Description:
                  "The EV Charging Suite combines all of the functions required for intelligent charging park management in a single software package.",
                X_URL: "charging-park-management",
                X_THUMBNAIL_ALT: "Software-Suite f\u00fcr Ladeparkmanagement",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0086575_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "6XI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Connectors",
        text: "Connectors",
        X_fullName: "Connectors",
        X_Description:
          "We not only offer a comprehensive selection of connectors, but also outstanding quality, expertise, and design-in support.",
        X_URL: "connectors",
        X_THUMBNAIL_ALT: "Connectors",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084482_int.jpg",
        items: [
          {
            id: "7XI0000101",
            parent_id: "6XI0000101",
            FULLPATH:
              'PhoenixContact\\Connectors\\19" plug-in card blocks and socket strips',
            text: '19" plug-in card blocks and socket strips',
            X_fullName: '19" plug-in card blocks and socket strips',
            X_Description:
              'Connection elements for use in the control cabinet or in 19" racks.',
            X_URL: "card-blocks-and-socket-strips-19",
            X_THUMBNAIL_ALT: '19" plug-in card blocks and socket strips',
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0041991_int.jpg",
            items: [
              {
                id: "8XI0000101",
                parent_id: "7XI0000101",
                FULLPATH:
                  'PhoenixContact\\Connectors\\19" plug-in card blocks and socket strips\\19" socket strips',
                text: '19" socket strips',
                X_fullName: '19" socket strips',
                X_Description:
                  "Socket strips with screw or spring-cage connection in various designs.",
                X_URL: "19-socket-strips",
                X_THUMBNAIL_ALT: 'Product list 19" socket strips',
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042650_int.jpg",
              },
            ],
          },
          {
            id: "9XI0000101",
            parent_id: "6XI0000101",
            FULLPATH: "PhoenixContact\\Connectors\\Data connectors",
            text: "Data connectors",
            X_fullName: "Data connectors",
            X_Description:
              "Common, standardized bus systems in industrial environments for high data transmission speeds.",
            X_URL: "data-connectors",
            X_THUMBNAIL_ALT: "Data connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042293_int.jpg",
            items: [
              {
                id: "AXI0000101",
                parent_id: "9XI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors",
                text: "Copper-based data connectors",
                X_fullName: "Copper-based data connectors",
                X_Description:
                  "Safe and reliable data transmission up to 10 Gbps by means of copper-based data connectors.",
                X_URL: "copper-based-data-connectors",
                X_THUMBNAIL_ALT: "Copper-based data connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0061346_int.jpg",
                items: [
                  {
                    id: "BXI0000101",
                    parent_id: "AXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors\\Connectors",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_Description:
                      "The extensive product range from Phoenix Contact offers numerous options for copper-based cabling.",
                    X_URL: "cooper-connectors",
                    X_THUMBNAIL_ALT: "Product list Connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060375_int.jpg",
                  },
                  {
                    id: "CXI0000101",
                    parent_id: "AXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors\\Device connections",
                    text: "Device connections",
                    X_fullName: "Device connections",
                    X_Description:
                      "Versatile panel feed-throughs are available for use in devices or control cabinets.",
                    X_URL: "device-connections",
                    X_THUMBNAIL_ALT: "Product list Device connections",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060377_int.jpg",
                  },
                ],
              },
              {
                id: "DXI0000101",
                parent_id: "9XI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors",
                text: "Fiber optic-based data connectors",
                X_fullName: "Fiber optic-based data connectors",
                X_Description:
                  "Fast and safe data transmission up to 40 Gbps by means of fiber optic-based data connectors.",
                X_URL: "fiber-data-connectors",
                X_THUMBNAIL_ALT: "Fiber optic-based data connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060058_int.jpg",
                items: [
                  {
                    id: "EXI0000101",
                    parent_id: "DXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors\\Patch cables and lines",
                    text: "Patch cables and lines",
                    X_fullName: "Patch cables and lines",
                    X_Description:
                      "The choice is yours: POF for short transmission paths, PCF for medium distances or GOF for long transmission paths.",
                    X_URL: "cables-and-lines",
                    X_THUMBNAIL_ALT: "Product list Patch cables and lines",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060384_int.jpg",
                  },
                  {
                    id: "FXI0000101",
                    parent_id: "DXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors\\Load center",
                    text: "Load center",
                    X_fullName: "Load center",
                    X_Description:
                      'Distributors for DIN rails or 19" racks support efficient connection solutions.',
                    X_URL: "load-center",
                    X_THUMBNAIL_ALT: "Product list Distributors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060379_int.jpg",
                  },
                ],
              },
              {
                id: "GXI0000101",
                parent_id: "9XI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Copper-based data connectors",
                text: "Copper-based data connectors",
                X_fullName: "Copper-based data connectors",
                X_Description:
                  "Copper-based data connectors for data rates up to 10 Gbps are available with various connection methods.",
                X_URL: "copper-data-connectors",
                X_THUMBNAIL_ALT: "Product list Copper-based data connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0061394_int.jpg",
              },
              {
                id: "HXI0000101",
                parent_id: "9XI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Data connectors\\Fiber optic-based data connectors",
                text: "Fiber optic-based data connectors",
                X_fullName: "Fiber optic-based data connectors",
                X_Description:
                  "Fiber optic-based data connectors for data rates up to 40 Gbps are available for all common fiber types.",
                X_URL: "fiber-optic-data-connectors",
                X_THUMBNAIL_ALT:
                  "Product list Fiber optic-based data connectors",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0060397_int.jpg",
              },
            ],
          },
          {
            id: "IXI0000101",
            parent_id: "6XI0000101",
            FULLPATH: "PhoenixContact\\Connectors\\Photovoltaic connectors",
            text: "Photovoltaic connectors",
            X_fullName: "Photovoltaic connectors",
            X_Description:
              "Quick and easy cabling of PV panels and inverters on site.",
            X_URL: "photovoltaic-connectors",
            X_THUMBNAIL_ALT: "Photovoltaic connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0083189_int.jpg",
            items: [
              {
                id: "KXI0000101",
                parent_id: "IXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Photovoltaic connectors\\AC connection technology",
                text: "AC connection technology",
                X_fullName: "AC connection technology",
                X_Description:
                  "Phoenix Contact offers an extensive connection range for every performance class for the AC-side installation of photovoltaic systems.",
                X_URL: "ac-connection-technology",
                X_THUMBNAIL_ALT: "Product list AC connection technology",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0060386_int.jpg",
              },
              {
                id: "LXI0000101",
                parent_id: "IXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology",
                text: "DC connection technology",
                X_fullName: "DC connection technology",
                X_Description:
                  "Panel feed-throughs, connectors, and comprehensive accessories \u2013 innovative DC connection technology from a single source.",
                X_URL: "dc-connection-technology-main",
                X_THUMBNAIL_ALT: "DC connection technology",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060527_int.jpg",
                items: [
                  {
                    id: "MXI0000101",
                    parent_id: "LXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology\\Connection technology for PV panels",
                    text: "Connection technology for PV panels",
                    X_fullName: "Connection technology for PV panels",
                    X_Description:
                      "The PCB terminal blocks from Phoenix Contact are designed for a rated current of 41 A.",
                    X_URL: "pv-panels-connection-technology",
                    X_THUMBNAIL_ALT:
                      "Product list Connection technology for PV panels",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060393_int.jpg",
                  },
                  {
                    id: "NXI0000101",
                    parent_id: "LXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Photovoltaic connectors\\DC connection technology\\DC connection technology",
                    text: "DC connection technology",
                    X_fullName: "DC connection technology",
                    X_Description:
                      "Panel feed-throughs, connectors, and comprehensive accessories: innovative DC connection technology from a single source.",
                    X_URL: "dc-connection-technology",
                    X_THUMBNAIL_ALT: "Product list DC connection technology",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0044319_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "OXI0000101",
            parent_id: "6XI0000101",
            FULLPATH: "PhoenixContact\\Connectors\\Circular connectors",
            text: "Circular connectors",
            X_fullName: "Circular connectors",
            X_Description:
              "Versatile circular connectors with variable connection technologies \u2013 for industrial applications.",
            X_URL: "circular-connectors",
            X_THUMBNAIL_ALT: "Circular connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042567_int.jpg",
            items: [
              {
                id: "PXI0000101",
                parent_id: "OXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors",
                text: "Signal connectors",
                X_fullName: "Signal connectors",
                X_Description:
                  "We offer a variety of connectors for signal transmission in sizes M5 to M23.",
                X_URL: "signal-connectors",
                X_THUMBNAIL_ALT: "Signal connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0088756_int.jpg",
                items: [
                  {
                    id: "QXI0000101",
                    parent_id: "PXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors\\Circular connectors for signals",
                    text: "Circular connectors for signals",
                    X_fullName: "Circular connectors for signals",
                    X_Description:
                      "M5 to M23 signal connectors \u2013 a large variety of sizes and housing designs enable the creation of consistent solutions for the device and cable side.",
                    X_URL: "circular-connectors-for-signals",
                    X_THUMBNAIL_ALT:
                      "Product list Circular connectors for signals",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056205_int.jpg",
                  },
                  {
                    id: "RXI0000101",
                    parent_id: "PXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Signal connectors\\Circular connectors for signals (M23 PRO series)",
                    text: "Circular connectors for signals (M23 PRO series)",
                    X_fullName:
                      "Circular connectors for signals (M23 PRO series)",
                    X_Description:
                      "M23 circular connectors from the PRO series offer solutions for signal transmission.",
                    X_URL: "circular-connectors-signals-pro",
                    X_THUMBNAIL_ALT:
                      "Product list connectors for signals (M23 PRO series)",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0088117_int.jpg",
                  },
                ],
              },
              {
                id: "SXI0000101",
                parent_id: "OXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Data connectors",
                text: "Data connectors",
                X_fullName: "Data connectors",
                X_Description:
                  "For fast and reliable data communication, we offer shielded circular connectors in sizes M8 and M12 for all common fieldbuses and networks.",
                X_URL: "circular-data-connectors",
                X_THUMBNAIL_ALT: "Data connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056212_int.jpg",
                items: [
                  {
                    id: "TXI0000101",
                    parent_id: "SXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Data connectors\\Circular connectors for data",
                    text: "Circular connectors for data",
                    X_fullName: "Circular connectors for data",
                    X_Description:
                      "M8 and M12 data connectors - cost-effectively integrate device connectors into your fieldbus and network applications",
                    X_URL: "circular-connectors-for-data",
                    X_THUMBNAIL_ALT:
                      "Product list Circular connectors for data",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056212_int.jpg",
                  },
                ],
              },
              {
                id: "UXI0000101",
                parent_id: "OXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Power connectors",
                text: "Power connectors",
                X_fullName: "Power connectors",
                X_Description:
                  "Use the power connectors to connect compact devices and electrical drives.",
                X_URL: "power-connectors",
                X_THUMBNAIL_ALT: "Power connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0083834_int.jpg",
                items: [
                  {
                    id: "VXI0000101",
                    parent_id: "UXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Power connectors\\Circular connectors for power",
                    text: "Circular connectors for power",
                    X_fullName: "Circular connectors for power",
                    X_Description:
                      "M12 to M58 power connectors \u2013 always the right choice for the easy connection of electrical drives and devices up to 150 A/630 V",
                    X_URL: "circular-connectors-for-power",
                    X_THUMBNAIL_ALT:
                      "Product list Circular connectors for power",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056213_int.jpg",
                  },
                  {
                    id: "WXI0000101",
                    parent_id: "UXI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Circular connectors\\Power connectors\\Circular connectors for power (M23 PRO series)",
                    text: "Circular connectors for power (M23 PRO series)",
                    X_fullName:
                      "Circular connectors for power (M23 PRO series)",
                    X_Description:
                      "M23 circular connectors from the PRO series offer solutions for power transmission.",
                    X_URL: "circular-connectors-power-pro",
                    X_THUMBNAIL_ALT:
                      "Product list Circular connectors for power (M23 PRO series)",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0088115_int.jpg",
                  },
                ],
              },
              {
                id: "YXI0000101",
                parent_id: "OXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Circular connectors\\Hybrid connectors",
                text: "Hybrid connectors",
                X_fullName: "Hybrid connectors",
                X_Description:
                  "Hybrid connectors for transmitting signals, data, and power in a single connector",
                X_URL: "hybrid-connectors",
                X_THUMBNAIL_ALT: "Product list Hybrid connectors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0057286_int.jpg",
              },
            ],
          },
          {
            id: "ZXI0000101",
            parent_id: "6XI0000101",
            FULLPATH: "PhoenixContact\\Connectors\\Heavy-duty connectors",
            text: "Heavy-duty connectors",
            X_fullName: "Heavy-duty connectors",
            X_Description:
              "Robust connectors \u2013 for quick startup and servicing in extreme conditions.",
            X_URL: "heavy-duty-connectors",
            X_THUMBNAIL_ALT: "Heavy-duty connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0062801_int.jpg",
            items: [
              {
                id: "0YI0000101",
                parent_id: "ZXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts",
                text: "Contact inserts and contacts",
                X_fullName: "Contact inserts and contacts",
                X_Description:
                  "Contact inserts and terminal adapters are designed to suit the individual application.",
                X_URL: "contact-inserts-and-contacts",
                X_THUMBNAIL_ALT: "Contact inserts and contacts",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0071835_int.jpg",
                items: [
                  {
                    id: "1YI0000101",
                    parent_id: "0YI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contact inserts with fixed number of positions",
                    text: "Contact inserts with fixed number of positions",
                    X_fullName:
                      "Contact inserts with fixed number of positions",
                    X_Description:
                      "Contact inserts with a fixed number of positions in different contact sizes and a wide range of connection technologies.",
                    X_URL: "fixed-positions-contact-inserts",
                    X_THUMBNAIL_ALT:
                      "Product list Contact inserts with a fixed number of positions",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043430_int.jpg",
                  },
                  {
                    id: "2YI0000101",
                    parent_id: "0YI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Modular contact inserts",
                    text: "Modular contact inserts",
                    X_fullName: "Modular contact inserts",
                    X_Description:
                      "Modular contact inserts for the individual configuration of contact inserts, even with different media.",
                    X_URL: "modular-contact-inserts",
                    X_THUMBNAIL_ALT: "Product list Modular contact inserts",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043433_int.jpg",
                  },
                  {
                    id: "3YI0000101",
                    parent_id: "0YI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts",
                    text: "Contacts",
                    X_fullName: "Contacts",
                    X_Description:
                      "Crimp contacts in various different versions for a wide range of requirements.",
                    X_URL: "contacts",
                    X_THUMBNAIL_ALT: "Contacts",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0042803_int.jpg",
                    items: [
                      {
                        id: "4YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Turned contacts",
                        text: "Turned contacts",
                        X_fullName: "Turned contacts",
                        X_Description:
                          "Turned contacts in various different versions for optimum crimp connection.",
                        X_URL: "turned-contacts",
                        X_THUMBNAIL_ALT: "Product list Turned contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043449_int.jpg",
                      },
                      {
                        id: "5YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Rolled contacts",
                        text: "Rolled contacts",
                        X_fullName: "Rolled contacts",
                        X_Description:
                          "Rolled contacts in various different versions for optimum crimp connection.",
                        X_URL: "rolled-contacts",
                        X_THUMBNAIL_ALT: "Product list Rolled contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043450_int.jpg",
                      },
                      {
                        id: "6YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\FO contacts",
                        text: "FO contacts",
                        X_fullName: "FO contacts",
                        X_Description:
                          "Fiber optic contacts for heavy-duty connectors.",
                        X_URL: "fo-contacts",
                        X_THUMBNAIL_ALT: "Product list FO contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062993_int.jpg",
                      },
                      {
                        id: "7YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Pneumatics contacts",
                        text: "Pneumatics contacts",
                        X_fullName: "Pneumatics contacts",
                        X_Description:
                          "Pneumatics pins for heavy-duty connectors.",
                        X_URL: "pneumatics-contacts",
                        X_THUMBNAIL_ALT: "Product list Pneumatics contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062994_int.jpg",
                      },
                      {
                        id: "8YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Shield connection contacts",
                        text: "Shield connection contacts",
                        X_fullName: "Shield connection contacts",
                        X_Description:
                          "Shield connection contacts for shielded cables to contact carriers.",
                        X_URL: "shield-connection-contacts",
                        X_THUMBNAIL_ALT:
                          "Product list Shield connection contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062995_int.jpg",
                      },
                      {
                        id: "9YI0000101",
                        parent_id: "3YI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Contact inserts and contacts\\Contacts\\Thermostatic contacts",
                        text: "Thermostatic contacts",
                        X_fullName: "Thermostatic contacts",
                        X_Description:
                          "Thermal contacts for heavy-duty connectors.",
                        X_URL: "thermostatic-contacts",
                        X_THUMBNAIL_ALT: "Product list Thermal contacts",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0062996_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "AYI0000101",
                parent_id: "ZXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing",
                text: "Housing",
                X_fullName: "Housing",
                X_Description:
                  "Robust and reliable housings for use in harsh, aggressive conditions.",
                X_URL: "housing",
                X_THUMBNAIL_ALT: "Housings",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062816_int.jpg",
                items: [
                  {
                    id: "BYI0000101",
                    parent_id: "AYI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings",
                    text: "EVO housings",
                    X_fullName: "EVO housings",
                    X_Description:
                      "Save costs with our innovative range of heavy-duty connectors.",
                    X_URL: "evo-housings",
                    X_THUMBNAIL_ALT: "EVO housings",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0056748_int.jpg",
                    items: [
                      {
                        id: "CYI0000101",
                        parent_id: "BYI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings\\EVO housing",
                        text: "EVO housing",
                        X_fullName: "EVO housing",
                        X_Description:
                          "Innovative EVO housing with bayonet locking in B6 to B24 sizes with single and double locking latch.",
                        X_URL: "evo-housing",
                        X_THUMBNAIL_ALT: "Product list EVO housing",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056881_int.jpg",
                      },
                      {
                        id: "DYI0000101",
                        parent_id: "BYI0000101",
                        FULLPATH:
                          "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\EVO housings\\EVO screw connections",
                        text: "EVO screw connections",
                        X_fullName: "EVO screw connections",
                        X_Description:
                          "Plastic metric screw connections for EVO housing in sizes M20 to M40.",
                        X_URL: "evo-screw-connections",
                        X_THUMBNAIL_ALT: "Product list EVO screw connections",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0056882_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "FYI0000101",
                    parent_id: "AYI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\ADVANCE housing",
                    text: "ADVANCE housing",
                    X_fullName: "ADVANCE housing",
                    X_Description:
                      "Robust housing with screw or bayonet locking in various designs for use without a panel mounting base.",
                    X_URL: "advance-housing",
                    X_THUMBNAIL_ALT: "Product list ADVANCE housings",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043435_int.jpg",
                  },
                  {
                    id: "HYI0000101",
                    parent_id: "AYI0000101",
                    FULLPATH:
                      "PhoenixContact\\Connectors\\Heavy-duty connectors\\Housing\\STANDARD housing",
                    text: "STANDARD housing",
                    X_fullName: "STANDARD housing",
                    X_Description:
                      "Robust housing with locking latch in various sizes for high mechanical loads.",
                    X_URL: "standard-housing",
                    X_THUMBNAIL_ALT: "Product list STANDARD housings",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0043436_int.jpg",
                  },
                ],
              },
              {
                id: "JYI0000101",
                parent_id: "ZXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Connector sets",
                text: "Connector sets",
                X_fullName: "Connector sets",
                X_Description:
                  "User-friendly connection for quick, tool-free connection of multi-position cables.",
                X_URL: "connector-sets",
                X_THUMBNAIL_ALT: "Product list Connector sets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0048582_int.jpg",
              },
              {
                id: "KYI0000101",
                parent_id: "ZXI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Heavy-duty connectors\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Comprehensive accessories and replacement parts for extended mounting options and repairs.",
                X_URL: "connector-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043482_int.jpg",
              },
            ],
          },
          {
            id: "LYI0000101",
            parent_id: "6XI0000101",
            FULLPATH:
              "PhoenixContact\\Connectors\\Modular rectangular connectors",
            text: "Modular rectangular connectors",
            X_fullName: "Modular rectangular connectors",
            X_Description:
              "Compact connection with modular rectangular connectors \u2013 for use in devices, terminal boxes, and control cabinets",
            X_URL: "modular-rectangular-connectors",
            X_THUMBNAIL_ALT: "Modular rectangular connectors",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042291_int.jpg",
            items: [
              {
                id: "MYI0000101",
                parent_id: "LYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Sleeve housings",
                text: "Sleeve housings",
                X_fullName: "Sleeve housings",
                X_Description:
                  "Sleeve housings made of plastic or cast aluminium for space-saving installation right on the housing wall.",
                X_URL: "sleeve-housings",
                X_THUMBNAIL_ALT: "Product list Sleeve housings",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062892_int.jpg",
              },
              {
                id: "NYI0000101",
                parent_id: "LYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Panel mounting base and coupling housing",
                text: "Panel mounting base and coupling housing",
                X_fullName: "Panel mounting base and coupling housing",
                X_Description:
                  "Panel mounting bases and coupling housings allow you to implement plug-in connections on the device wall or free-hanging connections.",
                X_URL: "panel-mounting-base",
                X_THUMBNAIL_ALT:
                  "Product list Panel mounting base and coupling housing",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062894_int.jpg",
              },
              {
                id: "OYI0000101",
                parent_id: "LYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Contact inserts and contacts",
                text: "Contact inserts and contacts",
                X_fullName: "Contact inserts and contacts",
                X_Description:
                  "Thanks to modular contact inserts, it is possible to transmit signals, data and power in individual combinations in one connector.",
                X_URL: "mod-contact-inserts-and-contacts",
                X_THUMBNAIL_ALT: "Product list Contact inserts and contacts",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062893_int.jpg",
              },
              {
                id: "PYI0000101",
                parent_id: "LYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Cable glands, adapter plates, protective covers and tools complete the modular connector range.",
                X_URL: "rectangular-conn-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062895_int.jpg",
              },
              {
                id: "RYI0000101",
                parent_id: "LYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Modular rectangular connectors\\Hybrid connectors for motor connection",
                text: "Hybrid connectors for motor connection",
                X_fullName: "Hybrid connectors for motor connection",
                X_Description:
                  "Hybrid connectors for quick, tool-free connection of motors.",
                X_URL: "hybrid-conn-motor-connection",
                X_THUMBNAIL_ALT:
                  "Product list Hybrid connectors for motor connection",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043443_int.jpg",
              },
            ],
          },
          {
            id: "SYI0000101",
            parent_id: "6XI0000101",
            FULLPATH: "PhoenixContact\\Connectors\\Installation systems",
            text: "Installation systems",
            X_fullName: "Installation systems",
            X_Description:
              "A compact connector system with fast connection technology \u2013 for easy and secure installation.",
            X_URL: "installation-systems",
            X_THUMBNAIL_ALT: "Installation systems",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088546_int.jpg",
            items: [
              {
                id: "TYI0000101",
                parent_id: "SYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\QPD installation system",
                text: "QPD installation system",
                X_fullName: "QPD installation system",
                X_Description:
                  "QPD installation system with shock-proof IDC fast connection technology.",
                X_URL: "qpd-installation-system",
                X_THUMBNAIL_ALT: "Product list QPD installation system",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043201_int.jpg",
              },
              {
                id: "UYI0000101",
                parent_id: "SYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\Accessories for QPD installation system",
                text: "Accessories for QPD installation system",
                X_fullName: "Accessories for QPD installation system",
                X_Description:
                  "Mounting and sealing material for the QPD installation system with IDC insulation displacement connection.",
                X_URL: "qpd-system-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for QPD installation system",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043404_int.jpg",
              },
              {
                id: "VYI0000101",
                parent_id: "SYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\IPD installation system",
                text: "IPD installation system",
                X_fullName: "IPD installation system",
                X_Description:
                  "IPD installation system panel feed-throughs with easy and time-saving Push-in connection",
                X_URL: "ipd-installation-system",
                X_THUMBNAIL_ALT: "Product list IPD installation system",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0088547_int.jpg",
              },
              {
                id: "WYI0000101",
                parent_id: "SYI0000101",
                FULLPATH:
                  "PhoenixContact\\Connectors\\Installation systems\\Accessories IPD installation system",
                text: "Accessories IPD installation system",
                X_fullName: "Accessories IPD installation system",
                X_Description: "",
                X_URL: "ipd-system-accessories",
                X_THUMBNAIL_ALT:
                  "Produktliste Zubeh\u00f6r Installationssystem IPD",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0042918_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "XYI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\PLCs and I/O systems",
        text: "PLCs and I/O systems",
        X_fullName: "PLCs and I/O systems",
        X_Description:
          "All small-scale controllers, PLCs, and I/O systems for any automation task.",
        X_URL: "plcs-and-io-systems",
        X_THUMBNAIL_ALT: "PLCs and I/O systems",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0088525_int.jpg",
        items: [
          {
            id: "YYI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers",
            text: "Programmable logic controllers",
            X_fullName: "Programmable logic controllers",
            X_Description:
              "PLCs in all performance classes, software PLC, and PLC programming software",
            X_URL: "programmable-logic-controllers",
            X_THUMBNAIL_ALT: "PLCs in accordance with IEC 61131",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0081144_int.jpg",
            items: [
              {
                id: "ZYI0000101",
                parent_id: "YYI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Axiocontrols",
                text: "Axiocontrols",
                X_fullName: "Axiocontrols",
                X_Description:
                  "Axiocontrol controllers with IEC 61131 programming for the Axioline F I/O system",
                X_URL: "axiocontrols",
                X_THUMBNAIL_ALT: "Product list Axiocontrols",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0060083_int.jpg",
              },
              {
                id: "0ZI0000101",
                parent_id: "YYI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Inline controllers",
                text: "Inline controllers",
                X_fullName: "Inline controllers",
                X_Description:
                  "Inline controllers with IEC 61131 programming for the Inline I/O system",
                X_URL: "inline-controllers",
                X_THUMBNAIL_ALT: "Product list Inline controllers",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0050086_int.jpg",
              },
              {
                id: "1ZI0000101",
                parent_id: "YYI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\High-performance PLCs",
                text: "High-performance PLCs",
                X_fullName: "High-performance PLCs",
                X_Description:
                  "High-performance PLCs for PROFINET and Modbus/TCP",
                X_URL: "high-performance-plcs",
                X_THUMBNAIL_ALT: "Product list High-performance PLCs",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043394_int.jpg",
              },
              {
                id: "2ZI0000101",
                parent_id: "YYI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic controllers\\Software PLC",
                text: "Software PLC",
                X_fullName: "Software PLC",
                X_Description: "Software PLCS for use on IPCs and PCs",
                X_URL: "software-plc",
                X_THUMBNAIL_ALT: "Product list Software PLCs",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0043396_int.jpg",
              },
            ],
          },
          {
            id: "3ZI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system",
            text: "Programmable logic relay system",
            X_fullName: "Programmable logic relay system",
            X_Description:
              "Carry out smaller automation tasks with the programmable logic relay system from Phoenix Contact.",
            X_URL: "programmable-logic-relay-system",
            X_THUMBNAIL_ALT: "Programmable logic relay system",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0071364_int.jpg",
            items: [
              {
                id: "4ZI0000101",
                parent_id: "3ZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Logic modules",
                text: "Logic modules",
                X_fullName: "Logic modules",
                X_Description:
                  "Smaller automation tasks can be carried out with the programmable logic modules.",
                X_URL: "logic-modules",
                X_THUMBNAIL_ALT: "Product list Logic modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0056722_int.jpg",
              },
              {
                id: "5ZI0000101",
                parent_id: "3ZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Compatible relay modules",
                text: "Compatible relay modules",
                X_fullName: "Compatible relay modules",
                X_Description:
                  "Compatible relays for the programmable logic module.",
                X_URL: "compatible-relay-modules",
                X_THUMBNAIL_ALT: "Product list Compatible relay modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0056723_int.jpg",
              },
              {
                id: "6ZI0000101",
                parent_id: "3ZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Numerous accessories for the programmable logic relay system.",
                X_URL: "logic-relay-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for the logic relay system",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0056724_int.jpg",
              },
              {
                id: "7ZI0000101",
                parent_id: "3ZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\Programmable logic relay system\\Compatible analog modules",
                text: "Compatible analog modules",
                X_fullName: "Compatible analog modules",
                X_Description:
                  "Modules for processing analog input and output signals.",
                X_URL: "compatible-analog-modules",
                X_THUMBNAIL_ALT: "Product list compatible analog modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0069687_int.jpg",
              },
            ],
          },
          {
            id: "8ZI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\PLCs for building infrastructure",
            text: "PLCs for building infrastructure",
            X_fullName: "PLCs for building infrastructure",
            X_Description:
              "Automate different subsections in building infrastructure, data centers, and distributed properties using the ILC 2050 BI Inline controller.",
            X_URL: "plcs-for-building-infrastructure",
            X_THUMBNAIL_ALT: "PLCs for building infrastructure",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0071715_int.jpg",
            items: [
              {
                id: "9ZI0000101",
                parent_id: "8ZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\PLCs for building infrastructure\\PLCs and licenses for building infrastructure",
                text: "PLCs and licenses for building infrastructure",
                X_fullName: "PLCs and licenses for building infrastructure",
                X_Description:
                  "ILC 2050 BI Inline controller and licenses for automating different utilities in building infrastructure",
                X_URL: "building-infrastructure-plcs-lic",
                X_THUMBNAIL_ALT:
                  "Product list PLCs and licenses for building infrastructure",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0071715_int.jpg",
              },
            ],
          },
          {
            id: "AZI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)",
            text: "I/O systems for the control cabinet (IP20)",
            X_fullName: "I/O systems for the control cabinet (IP20)",
            X_Description:
              "I/O devices for installation in the control cabinet with a modular or block design",
            X_URL: "io-sys-for-the-control-cabinet",
            X_THUMBNAIL_ALT: "I/O systems for the control cabinet (IP20)",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0051901_int.jpg",
            items: [
              {
                id: "BZI0000101",
                parent_id: "AZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Axioline F \u2013 the block-based I/O system",
                text: "Axioline F \u2013 the block-based I/O system",
                X_fullName: "Axioline F \u2013 the block-based I/O system",
                X_Description:
                  "The fast, easy, and robust I/O system with high signal density",
                X_URL: "axioline-f-the-block-based-io",
                X_THUMBNAIL_ALT: "Axioline F - the block-based I/O system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0075294_int.jpg",
                items: [
                  {
                    id: "CZI0000101",
                    parent_id: "BZI0000101",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Axioline F \u2013 the block-based I/O system\\Accessories for Axioline\u00a0F",
                    text: "Accessories for Axioline\u00a0F",
                    X_fullName: "Accessories for Axioline\u00a0F",
                    X_Description:
                      "Accessories for installing and operating the Axioline F I/O system",
                    X_URL: "accessories-for-axioline-f",
                    X_THUMBNAIL_ALT: "Product list Accessories for Axioline F",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042996_int.jpg",
                  },
                ],
              },
              {
                id: "DZI0000101",
                parent_id: "AZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Inline \u2013 the highly modular I/O system",
                text: "Inline \u2013 the highly modular I/O system",
                X_fullName: "Inline \u2013 the highly modular I/O system",
                X_Description:
                  "Inline \u2013 the particularly flexible I/O system with fine granularity",
                X_URL: "inline-io-system",
                X_THUMBNAIL_ALT: "Inline \u2013 the highly modular I/O system",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0075295_int.jpg",
                items: [
                  {
                    id: "EZI0000101",
                    parent_id: "DZI0000101",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\Inline \u2013 the highly modular I/O system\\Accessories for Inline",
                    text: "Accessories for Inline",
                    X_fullName: "Accessories for Inline",
                    X_Description:
                      "Accessories for the installation and operation of the Inline I/O system",
                    X_URL: "accessories-for-inline",
                    X_THUMBNAIL_ALT: "Product list Accessories for Inline",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0076780_int.jpg",
                  },
                ],
              },
              {
                id: "FZI0000101",
                parent_id: "AZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)",
                text: "I/O systems for field installation (IP65/IP67)",
                X_fullName: "I/O systems for field installation (IP65/IP67)",
                X_Description:
                  "I/O devices for installation directly in the field with a modular or block design",
                X_URL: "io-systems",
                X_THUMBNAIL_ALT: "I/O systems for field installation (IP65/67)",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0056776_int.jpg",
                items: [
                  {
                    id: "GZI0000101",
                    parent_id: "FZI0000101",
                    FULLPATH:
                      "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)\\Axioline\u00a0E \u2013 the block-based modular I/O system",
                    text: "Axioline\u00a0E \u2013 the block-based modular I/O system",
                    X_fullName:
                      "Axioline\u00a0E \u2013 the block-based modular I/O system",
                    X_Description:
                      "Axioline E \u2013 the fast, easy, and robust I/O system",
                    X_URL: "axioline-e",
                    X_THUMBNAIL_ALT:
                      "Axioline E - the block-based modular I/O system",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0051904_int.jpg",
                    items: [
                      {
                        id: "HZI0000101",
                        parent_id: "GZI0000101",
                        FULLPATH:
                          "PhoenixContact\\PLCs and I/O systems\\I/O systems for the control cabinet (IP20)\\I/O systems for field installation (IP65/IP67)\\Axioline\u00a0E \u2013 the block-based modular I/O system\\Accessories for Axioline\u00a0E",
                        text: "Accessories for Axioline\u00a0E",
                        X_fullName: "Accessories for Axioline\u00a0E",
                        X_Description:
                          "Accessories for the installation and operation of the Axioline E I/O system.",
                        X_URL: "axioline-e-accessories",
                        X_THUMBNAIL_ALT:
                          "Product list Accessories for Axioline E",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0079826_int.jpg",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: "IZI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\Components for SPS and I/O systems",
            text: "Components for SPS and I/O systems",
            X_fullName: "Components for SPS and I/O systems",
            X_Description:
              "Components for PLC and I/O systems, as well as accessories for automation.",
            X_URL: "sps-io-components",
            X_THUMBNAIL_ALT: "Product list Components for PLC and I/O systems",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0042991_int.jpg",
          },
          {
            id: "JZI0000101",
            parent_id: "XYI0000101",
            FULLPATH:
              "PhoenixContact\\PLCs and I/O systems\\PLCnext Control devices",
            text: "PLCnext Control devices",
            X_fullName: "PLCnext Control devices",
            X_Description:
              "Open PLCs with PLCnext Technology for high-level language programming, PLC programming software, and apps in the PLCnext Store.",
            X_URL: "plcnext-control-devices",
            X_THUMBNAIL_ALT: "PLCnext Control devices",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0088529_int.jpg",
            items: [
              {
                id: "KZI0000101",
                parent_id: "JZI0000101",
                FULLPATH:
                  "PhoenixContact\\PLCs and I/O systems\\PLCnext Control devices\\PLCnext Technology components",
                text: "PLCnext Technology components",
                X_fullName: "PLCnext Technology components",
                X_Description:
                  "Components for the new PLCnext Technology open control platform",
                X_URL: "plcnext-technology-components",
                X_THUMBNAIL_ALT: "Product list PLCnext Technology components",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0088538_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "LZI0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Power supply units and UPS",
        text: "Power supply units and UPS",
        X_fullName: "Power supply units and UPS",
        X_Description:
          "Do you want to run your system without interruption? Then you can fully rely on our power supplies to do the job.",
        X_URL: "power-supply-units-and-ups",
        X_THUMBNAIL_ALT: "Power supplies and UPS",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084371_int.jpg",
        items: [
          {
            id: "MZI0000101",
            parent_id: "LZI0000101",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Redundancy modules",
            text: "Redundancy modules",
            X_fullName: "Redundancy modules",
            X_Description:
              "Decoupling, monitoring, and controlling redundancy modules. For maximized availability of power supplies operated in parallel.",
            X_URL: "redundancy-modules",
            X_THUMBNAIL_ALT: "Redundancy modules",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0069729_int.jpg",
            items: [
              {
                id: "NZI0000101",
                parent_id: "MZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Active redundancy modules",
                text: "Active redundancy modules",
                X_fullName: "Active redundancy modules",
                X_Description:
                  "ORING modules with ACB technology provide the best possible protection for power supplies \u2014 for superior system availability.",
                X_URL: "active-redundancy-modules",
                X_THUMBNAIL_ALT: "Active redundancy modules",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0041984_int.jpg",
                items: [
                  {
                    id: "OZI0000101",
                    parent_id: "NZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Active redundancy modules\\QUINT ORING",
                    text: "QUINT ORING",
                    X_fullName: "QUINT ORING",
                    X_Description:
                      "QUINT ORING modules with ACB technology double the service life of your redundant power supply units.",
                    X_URL: "qunit-oring",
                    X_THUMBNAIL_ALT: "Product list QUINT ORING",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0041957_int.jpg",
                  },
                ],
              },
              {
                id: "PZI0000101",
                parent_id: "MZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules",
                text: "Diode modules",
                X_fullName: "Diode modules",
                X_Description:
                  "Diode modules decouple power supplies that are operated in parallel: a short circuit no longer affects the load.",
                X_URL: "ups-diode-modules",
                X_THUMBNAIL_ALT: "Diode modules",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069729_int.jpg",
                items: [
                  {
                    id: "QZI0000101",
                    parent_id: "PZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\QUINT DIODE",
                    text: "QUINT DIODE",
                    X_fullName: "QUINT DIODE",
                    X_Description:
                      "QUINT DIODE diode modules feature full redundancy up to the consumer and increase system availability even under demanding ambient conditions.",
                    X_URL: "qunit-diode",
                    X_THUMBNAIL_ALT: "Product list QUINT DIODE",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0075699_int.jpg",
                  },
                  {
                    id: "RZI0000101",
                    parent_id: "PZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\TRIO DIODE",
                    text: "TRIO DIODE",
                    X_fullName: "TRIO DIODE",
                    X_Description:
                      "TRIO DIODE redundancy modules permanently monitor redundancy from the output voltage to the redundancy module.",
                    X_URL: "trio-diode",
                    X_THUMBNAIL_ALT: "Product list TRIO DIODE",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0075679_int.jpg",
                  },
                  {
                    id: "SZI0000101",
                    parent_id: "PZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Redundancy modules\\Diode modules\\STEP / UNO DIODE",
                    text: "STEP / UNO DIODE",
                    X_fullName: "STEP / UNO DIODE",
                    X_Description:
                      "STEP / UNO DIODE narrow diode modules decouple power supply units that are connected in parallel and provide 100% redundancy.",
                    X_URL: "step-uno-diode",
                    X_THUMBNAIL_ALT: "Product list STEP / UNO DIODE",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042967_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "TZI0000101",
            parent_id: "LZI0000101",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Power supply units",
            text: "Power supply units",
            X_fullName: "Power supply units",
            X_Description:
              "Maximize the availability of your systems with high-quality power supplies featuring leading technology, optimized for superior functionality and performance.",
            X_URL: "power-supply-units",
            X_THUMBNAIL_ALT: "Power supplies",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0074391_int.jpg",
            items: [
              {
                id: "UZI0000101",
                parent_id: "TZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality",
                text: "Power supplies with maximum functionality",
                X_fullName: "Power supplies with maximum functionality",
                X_Description:
                  "The QUINT POWER power supplies with SFB Technology feature the highest level of functionality for maximum system availability.",
                X_URL: "ps-with-maximum-functionality",
                X_THUMBNAIL_ALT: "Power supplies with maximum functionality",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069730_int.jpg",
                items: [
                  {
                    id: "VZI0000101",
                    parent_id: "UZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality\\QUINT POWER",
                    text: "QUINT POWER",
                    X_fullName: "QUINT POWER",
                    X_Description:
                      "QUINT POWER with SFB technology and an NFC interface increases the availability of systems and can be adapted on an individual basis.",
                    X_URL: "psu-qunit-power",
                    X_THUMBNAIL_ALT: "Product list QUINT POWER",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0069201_int.jpg",
                  },
                  {
                    id: "WZI0000101",
                    parent_id: "UZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with maximum functionality\\QUINT POWER for extreme conditions",
                    text: "QUINT POWER for extreme conditions",
                    X_fullName: "QUINT POWER for extreme conditions",
                    X_Description:
                      "QUINT POWER with protective coating for extreme environmental conditions, such as 100% humidity.",
                    X_URL: "qunit-power-extreme-conditions",
                    X_THUMBNAIL_ALT:
                      "Product list QUINT POWER for extreme conditions",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042969_int.jpg",
                  },
                ],
              },
              {
                id: "XZI0000101",
                parent_id: "TZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality",
                text: "Power supplies with standard functionality",
                X_fullName: "Power supplies with standard functionality",
                X_Description:
                  "The TRIO POWER power supplies with standard functionality are particularly robust and therefore ideal for use in machine building.",
                X_URL: "standard-power-supplies",
                X_THUMBNAIL_ALT: "Power supplies with standard functionality",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060492_int.jpg",
                items: [
                  {
                    id: "YZI0000101",
                    parent_id: "XZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO POWER with screw connection",
                    text: "TRIO POWER with screw connection",
                    X_fullName: "TRIO POWER with screw connection",
                    X_Description:
                      "The functions and the space-saving design of the robust power supplies are tailored to machine building requirements.",
                    X_URL: "trio-power-with-screw-connection",
                    X_THUMBNAIL_ALT:
                      "Product list TRIO POWER with screw connection",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0041937_int.jpg",
                  },
                  {
                    id: "ZZI0000101",
                    parent_id: "XZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO POWER with push-in connection",
                    text: "TRIO POWER with push-in connection",
                    X_fullName: "TRIO POWER with push-in connection",
                    X_Description:
                      "The TRIO POWER range of power supplies with push-in connection. Extremely robust and therefore perfectly suited for machine building.",
                    X_URL: "push-in-trio-power",
                    X_THUMBNAIL_ALT:
                      "Product list TRIO POWER with push-in connection",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0065604_int.jpg",
                  },
                  {
                    id: "00J0000101",
                    parent_id: "XZI0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with standard functionality\\TRIO CROSS POWER",
                    text: "TRIO CROSS POWER",
                    X_fullName: "TRIO CROSS POWER",
                    X_Description:
                      "The power supply for the CrossPowerSystem power distribution board is perfectly suited for use in machine building.",
                    X_URL: "trio-cross-power",
                    X_THUMBNAIL_ALT: "Product list TRIO CROSS POWER",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0086561_int.jpg",
                  },
                ],
              },
              {
                id: "10J0000101",
                parent_id: "TZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supply units for installation distributors",
                text: "Power supply units for installation distributors",
                X_fullName: "Power supply units for installation distributors",
                X_Description:
                  "With their flat design, STEP POWER power supplies are particularly suitable for building automation.",
                X_URL: "psu-for-installation-distributor",
                X_THUMBNAIL_ALT: "Power supplies for installation distributors",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0041944_int.jpg",
                items: [
                  {
                    id: "20J0000101",
                    parent_id: "10J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supply units for installation distributors\\STEP POWER",
                    text: "STEP POWER",
                    X_fullName: "STEP POWER",
                    X_Description:
                      "STEP POWER features a flat design and offers maximum energy efficiency \u2013 this makes it ideal for use in building automation.",
                    X_URL: "step-power",
                    X_THUMBNAIL_ALT: "Product list STEP POWER",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0042971_int.jpg",
                  },
                ],
              },
              {
                id: "30J0000101",
                parent_id: "TZI0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with basic functionality",
                text: "Power supplies with basic functionality",
                X_fullName: "Power supplies with basic functionality",
                X_Description:
                  "Power supplies with basic functionality are the perfect solution for compact control boxes.",
                X_URL: "psu-with-basic-functionality",
                X_THUMBNAIL_ALT: "Power supplies with basic functionality",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0060342_int.jpg",
                items: [
                  {
                    id: "40J0000101",
                    parent_id: "30J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Power supply units\\Power supplies with basic functionality\\UNO POWER",
                    text: "UNO POWER",
                    X_fullName: "UNO POWER",
                    X_Description:
                      "UNO POWER power supply units offer the ideal solution for loads up to 240 W in compact control boxes.",
                    X_URL: "psu-uno-power",
                    X_THUMBNAIL_ALT: "Product list UNO POWER",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0051349_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "50J0000101",
            parent_id: "LZI0000101",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\DC/DC converters",
            text: "DC/DC converters",
            X_fullName: "DC/DC converters",
            X_Description:
              "DC/DC converters alter the voltage level, regenerate the voltage at the end of long cables, or enable the creation of independent supply systems.",
            X_URL: "dc-dc-converters",
            X_THUMBNAIL_ALT: "DC/DC converters",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0074516_int.jpg",
            items: [
              {
                id: "60J0000101",
                parent_id: "50J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\QUINT POWER",
                text: "QUINT POWER",
                X_fullName: "QUINT POWER",
                X_Description:
                  "QUINT DC/DC converters trip standard circuit breakers quickly and reliably \u2013 thanks to SFB technology.",
                X_URL: "qunit-power",
                X_THUMBNAIL_ALT: "Product list QUINT POWER",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0041956_int.jpg",
              },
              {
                id: "70J0000101",
                parent_id: "50J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\MINI POWER",
                text: "MINI POWER",
                X_fullName: "MINI POWER",
                X_Description:
                  "MINI DC/DC converters support conversion to various voltage levels.",
                X_URL: "mini-power",
                X_THUMBNAIL_ALT: "Product list MINI POWER",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042973_int.jpg",
              },
              {
                id: "80J0000101",
                parent_id: "50J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "The AC power terminal block supplies MINI DC/DC converters with rectified and filtered voltage.",
                X_URL: "dc-dc-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042974_int.jpg",
              },
              {
                id: "90J0000101",
                parent_id: "50J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\DC/DC converters\\UNO POWER",
                text: "UNO POWER",
                X_fullName: "UNO POWER",
                X_Description:
                  "UNO POWER DC/DC converters supply the control cabinet directly from the photovoltaic system.",
                X_URL: "uno-power",
                X_THUMBNAIL_ALT: "Product list UNO POWER",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0073051_int.jpg",
              },
            ],
          },
          {
            id: "A0J0000101",
            parent_id: "LZI0000101",
            FULLPATH:
              "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units",
            text: "Uninterruptible power supply units",
            X_fullName: "Uninterruptible power supply units",
            X_Description:
              "Uninterruptible power supplies for DC and AC loads ensure optimum system availability with tailor-made solutions.",
            X_URL: "uninterruptible-psu",
            X_THUMBNAIL_ALT: "Uninterruptible power supplies",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0084371_int.jpg",
            items: [
              {
                id: "B0J0000101",
                parent_id: "A0J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules",
                text: "Buffer modules",
                X_fullName: "Buffer modules",
                X_Description:
                  "Buffer modules store energy in capacitors and bridge mains failures lasting several seconds.",
                X_URL: "buffer-modules",
                X_THUMBNAIL_ALT: "Buffer modules",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0077405_int.jpg",
                items: [
                  {
                    id: "C0J0000101",
                    parent_id: "B0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules\\QUINT BUFFER",
                    text: "QUINT BUFFER",
                    X_fullName: "QUINT BUFFER",
                    X_Description:
                      "The QUINT BUFFER buffer module works with maintenance-free capacitors in the case of failures lasting several seconds.",
                    X_URL: "qunit-buffer",
                    X_THUMBNAIL_ALT: "Product list QUINT BUFFER",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0088746_int.jpg",
                  },
                  {
                    id: "D0J0000101",
                    parent_id: "B0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\Buffer modules\\QUINT CAP",
                    text: "QUINT CAP",
                    X_fullName: "QUINT CAP",
                    X_Description:
                      "QUINT CAP buffer modules bridge failures lasting up to 30 seconds.",
                    X_URL: "qunit-cap",
                    X_THUMBNAIL_ALT: "Product list QUINT CAP",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0077425_int.jpg",
                  },
                ],
              },
              {
                id: "E0J0000101",
                parent_id: "A0J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications",
                text: "USV for DC applications",
                X_fullName: "USV for DC applications",
                X_Description:
                  "Uninterruptible power supplies for DC applications continue to deliver power even in the event of mains failure. Select your appropriate UPS solution.",
                X_URL: "usv-dc",
                X_THUMBNAIL_ALT: "UPS for DC applications",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0084135_int.jpg",
                items: [
                  {
                    id: "F0J0000101",
                    parent_id: "E0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC UPS with IQ technology",
                    text: "DC UPS with IQ technology",
                    X_fullName: "DC UPS with IQ technology",
                    X_Description:
                      "The intelligent DC UPS with IQ Technology ensures superior system availability. Monitor and optimize your energy storage with the QUINT DC UPS.",
                    X_URL: "dc-ups-iq",
                    X_THUMBNAIL_ALT: "DC UPS with IQ Technology",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084135_int.jpg",
                    items: [
                      {
                        id: "G0J0000101",
                        parent_id: "F0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC UPS with IQ technology\\QUINT DC-UPS",
                        text: "QUINT DC-UPS",
                        X_fullName: "QUINT DC-UPS",
                        X_Description:
                          "QUINT DC-UPS, the uninterruptible power supply unit for DC applications, ensures maximum system availability.",
                        X_URL: "qunit-dc-ups",
                        X_THUMBNAIL_ALT: "Product list QUINT DC-UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0088745_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "H0J0000101",
                    parent_id: "E0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit",
                    text: "DC-USV module with integrated power supply unit",
                    X_fullName:
                      "DC-USV module with integrated power supply unit",
                    X_Description:
                      "UPS with integrated power supply, in an ultra-slim design, combines a power supply and electronic switch-over unit.",
                    X_URL: "dc-usv-with-power-supply-unit",
                    X_THUMBNAIL_ALT: "DC UPS with integrated power supply",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084238_int.jpg",
                    items: [
                      {
                        id: "I0J0000101",
                        parent_id: "H0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit\\MINI UPS",
                        text: "MINI UPS",
                        X_fullName: "MINI UPS",
                        X_Description:
                          "The highly compact MINI UPS combines a power supply unit and electronic switchover unit in the same housing.",
                        X_URL: "mini-ups",
                        X_THUMBNAIL_ALT: "Product list MINI UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042988_int.jpg",
                      },
                      {
                        id: "J0J0000101",
                        parent_id: "H0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-USV module with integrated power supply unit\\TRIO UPS",
                        text: "TRIO UPS",
                        X_fullName: "TRIO UPS",
                        X_Description:
                          "TRIO UPS has been developed specifically for supplying industrial PCs in standard machine production.",
                        X_URL: "trio-ups",
                        X_THUMBNAIL_ALT: "Product list TRIO UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042989_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "K0J0000101",
                    parent_id: "E0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage",
                    text: "DC-UPS with integrated energy storage",
                    X_fullName: "DC-UPS with integrated energy storage",
                    X_Description:
                      "The compact DC UPS with integrated energy storage are particularly space-saving and easy to retrofit. Select your appropriate UPS solution.",
                    X_URL: "dc-ups-with-energy-storage",
                    X_THUMBNAIL_ALT: "DC UPS with integrated energy storage",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0060152_int.jpg",
                    items: [
                      {
                        id: "L0J0000101",
                        parent_id: "K0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage\\QUINT UPS",
                        text: "QUINT UPS",
                        X_fullName: "QUINT UPS",
                        X_Description:
                          "QUINT UPS with IQ Technology and integrated energy storage maximizes the availability for load currents up to 10 A.",
                        X_URL: "qunit-ups",
                        X_THUMBNAIL_ALT: "Product list QUINT UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0048423_int.jpg",
                      },
                      {
                        id: "M0J0000101",
                        parent_id: "K0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for DC applications\\DC-UPS with integrated energy storage\\UNO UPS",
                        text: "UNO UPS",
                        X_fullName: "UNO UPS",
                        X_Description:
                          "The UNO UPS uninterruptible power supply works with integrated lead AGM power storage.",
                        X_URL: "uno-ups",
                        X_THUMBNAIL_ALT: "Product list UNO UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0064265_int.jpg",
                      },
                    ],
                  },
                ],
              },
              {
                id: "N0J0000101",
                parent_id: "A0J0000101",
                FULLPATH:
                  "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications",
                text: "USV for AC applications",
                X_fullName: "USV for AC applications",
                X_Description:
                  "Uninterruptible power supplies for AC applications continue to deliver power even in the event of mains failure.",
                X_URL: "usvfor-ac-applications",
                X_THUMBNAIL_ALT: "UPS for AC applications",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0084376_int.jpg",
                items: [
                  {
                    id: "O0J0000101",
                    parent_id: "N0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology",
                    text: "AC UPS with IQ technology",
                    X_fullName: "AC UPS with IQ technology",
                    X_Description:
                      "The intelligent AC UPS with IQ Technology ensures superior system availability. Monitor and optimize your energy storage with the QUINT AC UPS.",
                    X_URL: "ac-ups-with-iq-technology",
                    X_THUMBNAIL_ALT: "AC UPS with IQ Technology",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084376_int.jpg",
                    items: [
                      {
                        id: "P0J0000101",
                        parent_id: "O0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology\\QUINT AC-UPS",
                        text: "QUINT AC-UPS",
                        X_fullName: "QUINT AC-UPS",
                        X_Description:
                          "QUINT AC-UPS, the uninterruptible power supply unit for AC applications, ensures maximum system availability.",
                        X_URL: "qunit-ac-ups",
                        X_THUMBNAIL_ALT: "Product list QUINT AC-UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0088743_int.jpg",
                      },
                      {
                        id: "Q0J0000101",
                        parent_id: "O0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC UPS with IQ technology\\Accessories",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_Description:
                          "Free configuration software, a variety of data cables, and power storage mounting kits are available for QUINT UPS.",
                        X_URL: "ac-ups-accessories",
                        X_THUMBNAIL_ALT: "Product list Accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0042987_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "R0J0000101",
                    parent_id: "N0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC-UPS with integrated energy storage",
                    text: "AC-UPS with integrated energy storage",
                    X_fullName: "AC-UPS with integrated energy storage",
                    X_Description:
                      "The TRIO AC UPS with integrated energy storage delivers a pure sine curve at the output and supplies your AC applications without interruption.",
                    X_URL: "ac-ups-with-energy-storage",
                    X_THUMBNAIL_ALT: "AC UPS with integrated energy storage",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0077396_int.jpg",
                    items: [
                      {
                        id: "S0J0000101",
                        parent_id: "R0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\AC-UPS with integrated energy storage\\TRIO AC-UPS",
                        text: "TRIO AC-UPS",
                        X_fullName: "TRIO AC-UPS",
                        X_Description:
                          "TRIO AC-UPS for the DIN rail supply AC loads reliably.",
                        X_URL: "trio-ac-ups",
                        X_THUMBNAIL_ALT: "Product list TRIO AC-UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0073047_int.jpg",
                      },
                    ],
                  },
                  {
                    id: "T0J0000101",
                    parent_id: "N0J0000101",
                    FULLPATH:
                      "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage",
                    text: "Energy storage",
                    X_fullName: "Energy storage",
                    X_Description:
                      "Different energy storage technologies are characterized by their long service lives or the fact they involve no maintenance.",
                    X_URL: "energy-storage",
                    X_THUMBNAIL_ALT: "Energy storage",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0084479_int.jpg",
                    items: [
                      {
                        id: "U0J0000101",
                        parent_id: "T0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Accessories",
                        text: "Accessories",
                        X_fullName: "Accessories",
                        X_Description:
                          "Mounting and DIN rail adapters plus flat-type plug-in fuses are available as accessories.",
                        X_URL: "energy-storage-accessories",
                        X_THUMBNAIL_ALT: "Product list Accessories",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0043009_int.jpg",
                      },
                      {
                        id: "V0J0000101",
                        parent_id: "T0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Energy storage for QUINT UPS with IQ Technology",
                        text: "Energy storage for QUINT UPS with IQ Technology",
                        X_fullName:
                          "Energy storage for QUINT UPS with IQ Technology",
                        X_Description:
                          "Power storage devices for combination with QUINT UPS with IQ technology.",
                        X_URL: "iq-qunit-energy-storage",
                        X_THUMBNAIL_ALT:
                          "Product list Power storage devices for QUINT UPS with IQ technology",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0088744_int.jpg",
                      },
                      {
                        id: "W0J0000101",
                        parent_id: "T0J0000101",
                        FULLPATH:
                          "PhoenixContact\\Power supply units and UPS\\Uninterruptible power supply units\\USV for AC applications\\Energy storage\\Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        text: "Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        X_fullName:
                          "Energy storage for TRIO UPS, MINI UPS and STEP UPS",
                        X_Description:
                          "Power storage devices for combination with TRIO UPS, MINI UPS or STEP UPS.",
                        X_URL: "ups-energy-storage",
                        X_THUMBNAIL_ALT:
                          "Product list Power storage devices for TRIO UPS, MINI UPS, and STEP UPS",
                        X_picturelink:
                          "mimes/general/images/pic_prolist_thb_a_0047900_int.jpg",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "X0J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\System cabling for controllers",
        text: "System cabling for controllers",
        X_fullName: "System cabling for controllers",
        X_Description:
          "Quick, clear, error-free: the perfect fit for wiring single wires to your control unit at field level.",
        X_URL: "system-cabling-for-controllers",
        X_THUMBNAIL_ALT: "System cabling for controllers",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0078896_int.jpg",
        items: [
          {
            id: "Y0J0000101",
            parent_id: "X0J0000101",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling",
            text: "Controller-specific system cabling",
            X_fullName: "Controller-specific system cabling",
            X_Description:
              "VARIOFACE system cabling connects the field level to the controller - easy and error-free, thanks to plug-in connections.",
            X_URL: "controller-specific-sys-cabling",
            X_THUMBNAIL_ALT: "Controller-specific system cabling",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0041715_int.jpg",
            items: [
              {
                id: "Z0J0000101",
                parent_id: "Y0J0000101",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Front adapters",
                text: "Front adapters",
                X_fullName: "Front adapters",
                X_Description:
                  "Front adapters are available for various controllers. They are connected via system cables.",
                X_URL: "front-adapters",
                X_THUMBNAIL_ALT: "Product list Front adapters",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041770_int.jpg",
              },
              {
                id: "01J0000101",
                parent_id: "Y0J0000101",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Cable",
                text: "Cable",
                X_fullName: "Cable",
                X_Description:
                  "System cables for the connection between the controller and the interface module. The cables are available in variable lengths.",
                X_URL: "cable",
                X_THUMBNAIL_ALT: "Product list Cables",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041771_int.jpg",
              },
              {
                id: "11J0000101",
                parent_id: "Y0J0000101",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Modules",
                text: "Modules",
                X_fullName: "Modules",
                X_Description:
                  "Modules are used in combination with the corresponding front adapters and are connected via system cables.",
                X_URL: "modules",
                X_THUMBNAIL_ALT: "Product list Modules",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041772_int.jpg",
              },
              {
                id: "21J0000101",
                parent_id: "Y0J0000101",
                FULLPATH:
                  "PhoenixContact\\System cabling for controllers\\Controller-specific system cabling\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories, fuses, and locking clips are available.",
                X_URL: "system-cab-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041773_int.jpg",
              },
            ],
          },
          {
            id: "41J0000101",
            parent_id: "X0J0000101",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Universal cables",
            text: "Universal cables",
            X_fullName: "Universal cables",
            X_Description:
              "Universal cables for 1:1 connection have FLK (flat-ribbon cable) connectors or D-SUB connectors.",
            X_URL: "universal-cables",
            X_THUMBNAIL_ALT: "Product list Universal cables",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0041774_int.jpg",
          },
          {
            id: "61J0000101",
            parent_id: "X0J0000101",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Universal modules",
            text: "Universal modules",
            X_fullName: "Universal modules",
            X_Description:
              "Universal termination boards with a compact design convert high-position connectors to various connection terminal blocks.",
            X_URL: "universal-modules",
            X_THUMBNAIL_ALT: "Product list Universal modules",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0041775_int.jpg",
          },
          {
            id: "81J0000101",
            parent_id: "X0J0000101",
            FULLPATH:
              "PhoenixContact\\System cabling for controllers\\Potential distributors",
            text: "Potential distributors",
            X_fullName: "Potential distributors",
            X_Description:
              "Potential distributors, with screw or spring-cage connection, provide several potential levels.",
            X_URL: "potential-distributors",
            X_THUMBNAIL_ALT: "Product list potential distributors",
            X_picturelink:
              "mimes/general/images/pic_prolist_thb_a_0041776_int.jpg",
          },
        ],
      },
      {
        id: "91J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Surge protection and interference filters",
        text: "Surge protection and interference filters",
        X_fullName: "Surge protection and interference filters",
        X_Description:
          "Ensure that operation is not interrupted. Our surge protective devices and interference filters provide effective protection for your systems.",
        X_URL: "surge-protection-and-filters",
        X_THUMBNAIL_ALT: "Surge protection and interference filters",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0059732_int.jpg",
        items: [
          {
            id: "A1J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Interference filters",
            text: "Interference filters",
            X_fullName: "Interference filters",
            X_Description:
              "Interference filters limit unwanted voltage peaks and smooth the voltage signal.",
            X_URL: "interference-filters",
            X_THUMBNAIL_ALT: "Interference filters",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0041906_int.jpg",
            items: [
              {
                id: "B1J0000101",
                parent_id: "A1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Interference filters\\Filter",
                text: "Filter",
                X_fullName: "Filter",
                X_Description:
                  "Interference filters limit both symmetrical and asymmetrical interference voltages.",
                X_URL: "filter",
                X_THUMBNAIL_ALT: "Product list Filters",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041923_int.jpg",
              },
              {
                id: "C1J0000101",
                parent_id: "A1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Interference filters\\Filters with surge protection type 3",
                text: "Filters with surge protection type 3",
                X_fullName: "Filters with surge protection type 3",
                X_Description:
                  "Interference filters with integrated surge protection protect against transients and high-frequency interference.",
                X_URL: "surge-protection-type-3-filters",
                X_THUMBNAIL_ALT:
                  "Product list Filters with surge protection, type 3",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041923_int.jpg",
              },
            ],
          },
          {
            id: "D1J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT",
            text: "Surge protection for IT",
            X_fullName: "Surge protection for IT",
            X_Description:
              "Appropriately combined surge protection provides low attenuation and, at the same time, high bandwidth for data transmission.",
            X_URL: "surge-protection",
            X_THUMBNAIL_ALT: "Surge protection for information technology",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042141_int.jpg",
            items: [
              {
                id: "E1J0000101",
                parent_id: "D1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Bus systems",
                text: "Bus systems",
                X_fullName: "Bus systems",
                X_Description:
                  "Universal surge protection with no influence on signals for information and data technology.",
                X_URL: "bus-systems",
                X_THUMBNAIL_ALT: "Product list Bus systems",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042564_int.jpg",
              },
              {
                id: "F1J0000101",
                parent_id: "D1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Telecommunications",
                text: "Telecommunications",
                X_fullName: "Telecommunications",
                X_Description:
                  "Surge protective devices with circuits specifically designed to meet the requirements of digital and analog telecommunications.",
                X_URL: "telecommunications",
                X_THUMBNAIL_ALT: "Product list Telecommunications",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042563_int.jpg",
              },
              {
                id: "G1J0000101",
                parent_id: "D1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for IT\\Surge protection accessories for information techn",
                text: "Surge protection accessories for information techn",
                X_fullName:
                  "Surge protection accessories for information techn",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of information technology.",
                X_URL: "surge-protection-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for surge protection for information technology",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062476_int.jpg",
              },
            ],
          },
          {
            id: "H1J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn",
            text: "Surge protection for measurement and control techn",
            X_fullName: "Surge protection for measurement and control techn",
            X_Description:
              "Surge protective devices for MCR technology combine a high discharge capacity with components that respond quickly.",
            X_URL: "surge-protection-measurement",
            X_THUMBNAIL_ALT: "Surge protection for MCR technology",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0077339_int.jpg",
            items: [
              {
                id: "I1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Surge protection accessories for MCR technology",
                text: "Surge protection accessories for MCR technology",
                X_fullName: "Surge protection accessories for MCR technology",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of MCR technology.",
                X_URL: "mcr-surge-protection",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for surge protection for MCR technology",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042613_int.jpg",
              },
              {
                id: "K1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Current loops and analog signals",
                text: "Current loops and analog signals",
                X_fullName: "Current loops and analog signals",
                X_Description:
                  "Protect current loops and analog signals with the narrowest surge protection starting from 3.5 mm.",
                X_URL: "current-loops-and-analog-signals",
                X_THUMBNAIL_ALT:
                  "Product list Current loops and analog signals",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077404_int.jpg",
              },
              {
                id: "M1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Digital signals and switching contacts",
                text: "Digital signals and switching contacts",
                X_fullName: "Digital signals and switching contacts",
                X_Description:
                  "Protect your digital signals and switch contacts against surge voltages with TERMITRAB complete with an overall width starting from 3.5 mm.",
                X_URL: "signals-switching-contacts",
                X_THUMBNAIL_ALT:
                  "Product list Digital signals and switch contacts",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077404_int.jpg",
              },
              {
                id: "O1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Resistance-dependent measurements",
                text: "Resistance-dependent measurements",
                X_fullName: "Resistance-dependent measurements",
                X_Description:
                  "TERMITRAB complete provides protection against surge voltages for resistance-dependent measurements, such as Pt 100 temperature measurements.",
                X_URL: "resistance-dependent-measurement",
                X_THUMBNAIL_ALT:
                  "Product list Resistance-dependent measurements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077406_int.jpg",
              },
              {
                id: "Q1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Potentially explosive applications",
                text: "Potentially explosive applications",
                X_fullName: "Potentially explosive applications",
                X_Description:
                  "Intrinsically safe and pressure-encapsulated protective devices ensure reliable signals in the Ex area.",
                X_URL: "explosive-applications",
                X_THUMBNAIL_ALT:
                  "Product list Potentially explosive applications",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077407_int.jpg",
              },
              {
                id: "S1J0000101",
                parent_id: "H1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for measurement and control techn\\Single-stage protective devices",
                text: "Single-stage protective devices",
                X_fullName: "Single-stage protective devices",
                X_Description:
                  "Single-stage protective devices provide additional protection for long cables in the field.",
                X_URL: "single-stage-protective-devices",
                X_THUMBNAIL_ALT: "Product list Single-stage protective devices",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0077408_int.jpg",
              },
            ],
          },
          {
            id: "T1J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems",
            text: "Surge protection for transceiver systems",
            X_fullName: "Surge protection for transceiver systems",
            X_Description:
              "Coaxial arresters are powerful, do not reduce signal quality, and are suitable for all standard transmission equipment.",
            X_URL: "surge-protection-for-transceiver",
            X_THUMBNAIL_ALT: "Surge protection for transceiver systems",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042140_int.jpg",
            items: [
              {
                id: "U1J0000101",
                parent_id: "T1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\TV and radio systems",
                text: "TV and radio systems",
                X_fullName: "TV and radio systems",
                X_Description:
                  "Surge protective devices with coaxial connection protect TV and radio systems against damage.",
                X_URL: "tvand-radio-systems",
                X_THUMBNAIL_ALT: "Product list TV and radio systems",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042552_int.jpg",
              },
              {
                id: "V1J0000101",
                parent_id: "T1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\Transceiver technology",
                text: "Transceiver technology",
                X_fullName: "Transceiver technology",
                X_Description:
                  "Powerful protection for the systems particularly at risk in transceiver technology.",
                X_URL: "transceiver-technology",
                X_THUMBNAIL_ALT: "Product list Transceiver technology",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0042559_int.jpg",
              },
              {
                id: "W1J0000101",
                parent_id: "T1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for transceiver systems\\Surge protection accessories for transceiver syste",
                text: "Surge protection accessories for transceiver syste",
                X_fullName:
                  "Surge protection accessories for transceiver syste",
                X_Description:
                  "Accessories and additional products for surge protection for transceiver systems.",
                X_URL: "transceiver-surge-protection",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for surge protection for transceiver systems",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062479_int.jpg",
              },
            ],
          },
          {
            id: "X1J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units",
            text: "Surge protection for power supply units",
            X_fullName: "Surge protection for power supply units",
            X_Description:
              "Surge protection for power supply units is suitable for all applications, from the feed-in through to the device.",
            X_URL: "psu-surge-protection",
            X_THUMBNAIL_ALT: "Surge protection for power supplies",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0059731_int.jpg",
            items: [
              {
                id: "Y1J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Accessories for surge protection for the power sup",
                text: "Accessories for surge protection for the power sup",
                X_fullName:
                  "Accessories for surge protection for the power sup",
                X_Description:
                  "Accessories and additional products for all surge protective devices in the field of power supply.",
                X_URL: "surge-protection-psu-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for surge protection for the power supply",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062453_int.jpg",
              },
              {
                id: "02J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Combination type 1/2 protective device",
                text: "Combination type 1/2 protective device",
                X_fullName: "Combination type 1/2 protective device",
                X_Description:
                  "Combination type 1/2 protective device \u2013 powerful protection for systems and buildings with increased risk of lightning strike.",
                X_URL: "1-2-protective-device",
                X_THUMBNAIL_ALT:
                  "Product list Combination type 1/2 protective device",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062462_int.jpg",
              },
              {
                id: "22J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 1+2 protective device combination",
                text: "Type 1+2 protective device combination",
                X_fullName: "Type 1+2 protective device combination",
                X_Description:
                  "The combination of type 1 + 2 lightning and surge protective devices provides maximum performance with minimum space.",
                X_URL: "protective-device-combination",
                X_THUMBNAIL_ALT: "Product list Type 1+2 arrester combination",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062459_int.jpg",
              },
              {
                id: "42J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 2 surge arrester",
                text: "Type 2 surge arrester",
                X_fullName: "Type 2 surge arrester",
                X_Description:
                  "Type 2 surge protective devices for industrial and building installations protect systems, machinery, and devices.",
                X_URL: "type-2-surge-arrester",
                X_THUMBNAIL_ALT: "Product list Type 2 surge protective device",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062457_int.jpg",
              },
              {
                id: "62J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Type 3 device protection",
                text: "Type 3 device protection",
                X_fullName: "Type 3 device protection",
                X_Description:
                  "High-performance type 3 device protection for protecting sensitive end devices",
                X_URL: "type-3-device-protection",
                X_THUMBNAIL_ALT: "Product list Type 3 device protection",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062455_int.jpg",
              },
              {
                id: "72J0000101",
                parent_id: "X1J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Spark gap",
                text: "Spark gap",
                X_fullName: "Spark gap",
                X_Description:
                  "The FLT-ISG\u2026 isolating spark gap for protecting insulating flanges on pipelines and indirect equipotential bonding",
                X_URL: "spark-gap",
                X_THUMBNAIL_ALT: "Isolating spark gap",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0059605_int.jpg",
                items: [
                  {
                    id: "82J0000101",
                    parent_id: "72J0000101",
                    FULLPATH:
                      "PhoenixContact\\Surge protection and interference filters\\Surge protection for power supply units\\Spark gap\\Isolating spark gap and accessories",
                    text: "Isolating spark gap and accessories",
                    X_fullName: "Isolating spark gap and accessories",
                    X_Description:
                      "Isolating spark gap for protecting insulating flanges on pipelines and corresponding accessories",
                    X_URL: "isolating-spark-gap-accessories",
                    X_THUMBNAIL_ALT:
                      "Product list Isolating spark gap and accessories",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0060467_int.jpg",
                  },
                ],
              },
            ],
          },
          {
            id: "92J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Test device for surge protective devices",
            text: "Test device for surge protective devices",
            X_fullName: "Test device for surge protective devices",
            X_Description:
              "The portable test device checks all the relevant components of pluggable surge protective devices.",
            X_URL: "test-device-for-surge-protective",
            X_THUMBNAIL_ALT: "Test device for surge protective devices",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0061801_int.jpg",
            items: [
              {
                id: "A2J0000101",
                parent_id: "92J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Test device for surge protective devices\\Test device, test sockets, and accessories",
                text: "Test device, test sockets, and accessories",
                X_fullName: "Test device, test sockets, and accessories",
                X_Description:
                  "The CHECKMASTER provides complete electrical testing for plug-in surge protective devices.",
                X_URL: "test-device-and-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Test device, test sockets and accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062513_int.jpg",
              },
            ],
          },
          {
            id: "B2J0000101",
            parent_id: "91J0000101",
            FULLPATH:
              "PhoenixContact\\Surge protection and interference filters\\Set solutions",
            text: "Set solutions",
            X_fullName: "Set solutions",
            X_Description:
              "Set solutions, for buildings or photovoltaic systems, provide comprehensive protection against damage caused by surge voltages.",
            X_URL: "set-solutions",
            X_THUMBNAIL_ALT: "Set solutions",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0041864_int.jpg",
            items: [
              {
                id: "C2J0000101",
                parent_id: "B2J0000101",
                FULLPATH:
                  "PhoenixContact\\Surge protection and interference filters\\Set solutions\\Building sets",
                text: "Building sets",
                X_fullName: "Building sets",
                X_Description:
                  "Building sets protect electrical installations, household appliances, phones, and consumer electronics against damage caused by surge voltages.",
                X_URL: "building-sets",
                X_THUMBNAIL_ALT: "Product list Building sets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0041862_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "D2J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Tools",
        text: "Tools",
        X_fullName: "Tools",
        X_Description:
          "Using the right tool is key to achieving optimum results. Our products are all about quality, precision, and durability.",
        X_URL: "tools",
        X_THUMBNAIL_ALT: "Tools",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0084450_int.jpg",
        items: [
          {
            id: "E2J0000101",
            parent_id: "D2J0000101",
            FULLPATH: "PhoenixContact\\Tools\\Automatic devices",
            text: "Automatic devices",
            X_fullName: "Automatic devices",
            X_Description:
              "Comprehensive range of innovative automatic tools for flexible and cost-effective conductor processing.",
            X_URL: "automatic-devices",
            X_THUMBNAIL_ALT: "Automatic devices",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0043077_int.jpg",
            items: [
              {
                id: "G2J0000101",
                parent_id: "E2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Stripping machines",
                text: "Stripping machines",
                X_fullName: "Stripping machines",
                X_Description:
                  "Compact stripping machine for conductors and cables with standard or special insulation.",
                X_URL: "stripping-machines",
                X_THUMBNAIL_ALT: "Product list Stripping machine",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047838_int.jpg",
              },
              {
                id: "I2J0000101",
                parent_id: "E2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Crimping machines",
                text: "Crimping machines",
                X_fullName: "Crimping machines",
                X_Description:
                  "CF 500 portable crimping machine for processing ferrules, cable lugs, and contacts.",
                X_URL: "crimping-machines",
                X_THUMBNAIL_ALT: "Product list Crimping machine",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047839_int.jpg",
              },
              {
                id: "K2J0000101",
                parent_id: "E2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Cutting machines",
                text: "Cutting machines",
                X_fullName: "Cutting machines",
                X_Description:
                  "The CUTFOX 10 compact cutting machine cuts solid and stranded conductors up to 10 mm\u00b2 to length with millimeter precision.",
                X_URL: "cutting-machines",
                X_THUMBNAIL_ALT: "Product list Cutting machine",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047840_int.jpg",
              },
              {
                id: "M2J0000101",
                parent_id: "E2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Stripping/crimping machine",
                text: "Stripping/crimping machine",
                X_fullName: "Stripping/crimping machine",
                X_Description:
                  "Portable or stationary stripping and crimping machines process ferrules \u2013 supplied loose or taped.",
                X_URL: "stripping-crimping-machine",
                X_THUMBNAIL_ALT: "Product list Stripping/crimping machine",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047841_int.jpg",
              },
              {
                id: "N2J0000101",
                parent_id: "E2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Automatic devices\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for automatic devices, for example, protective cover, spare knife, replacement locator, conversion kit.",
                X_URL: "automatic-devices-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055366_int.jpg",
              },
            ],
          },
          {
            id: "O2J0000101",
            parent_id: "D2J0000101",
            FULLPATH: "PhoenixContact\\Tools\\Hand tools",
            text: "Hand tools",
            X_fullName: "Hand tools",
            X_Description:
              "The TOOL fox range offers processing and measuring tools for all applications.",
            X_URL: "hand-tools",
            X_THUMBNAIL_ALT: "Hand tools",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0068857_int.jpg",
            items: [
              {
                id: "Q2J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Stripping tool",
                text: "Stripping tool",
                X_fullName: "Stripping tool",
                X_Description:
                  "Stripping tools for various types of insulation and conductor sizes. Also with automatic adjustment mechanism.",
                X_URL: "stripping-tool",
                X_THUMBNAIL_ALT: "Product list Stripping tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047842_int.jpg",
              },
              {
                id: "R2J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Crimping tool",
                text: "Crimping tool",
                X_fullName: "Crimping tool",
                X_Description:
                  "Mechanical crimping tools for professional results when crimping various types of contacts.",
                X_URL: "crimping-tool-main",
                X_THUMBNAIL_ALT: "Crimping tool",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0084450_int.jpg",
                items: [
                  {
                    id: "S2J0000101",
                    parent_id: "R2J0000101",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Crimping tool\\Crimping tool",
                    text: "Crimping tool",
                    X_fullName: "Crimping tool",
                    X_Description:
                      "Rugged crimping tools for fast contact processing, plus corresponding connectors in various different versions.",
                    X_URL: "crimping-tool",
                    X_THUMBNAIL_ALT: "Product list Crimping tools",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0047843_int.jpg",
                  },
                  {
                    id: "T2J0000101",
                    parent_id: "R2J0000101",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Crimping tool\\Connectors",
                    text: "Connectors",
                    X_fullName: "Connectors",
                    X_Description:
                      "The connector product range offers a comprehensive range of different versions for conductor cross sections from 0.5 to 240 mm\u00b2.",
                    X_URL: "tools-connectors",
                    X_THUMBNAIL_ALT: "Product list Connectors",
                    X_picturelink:
                      "mimes/general/images/pic_prolist_thb_a_0047844_int.jpg",
                  },
                ],
              },
              {
                id: "V2J0000101",
                parent_id: "O2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Electronics tools",
                text: "Electronics tools",
                X_fullName: "Electronics tools",
                X_Description:
                  "Tools for precise gripping, positioning, bending, and cutting in the field of electronics.",
                X_URL: "electronics-tools",
                X_THUMBNAIL_ALT: "Product list Electronics tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047845_int.jpg",
              },
              {
                id: "X2J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Riveting tools",
                text: "Riveting tools",
                X_fullName: "Riveting tools",
                X_Description:
                  "Riveting tools for quick and safe joining using plastic body-bound rivets or metal blind rivets.",
                X_URL: "riveting-tools",
                X_THUMBNAIL_ALT: "Product list Riveting tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047846_int.jpg",
              },
              {
                id: "Z2J0000101",
                parent_id: "O2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Profile and DIN-rail cutters",
                text: "Profile and DIN-rail cutters",
                X_fullName: "Profile and DIN-rail cutters",
                X_Description:
                  "Profile and DIN-rail cutters with various stamping bits that are user friendly and require minimum effort.",
                X_URL: "profile-and-din-rail-cutters",
                X_THUMBNAIL_ALT: "Product list Profile and DIN-rail cutters",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047847_int.jpg",
              },
              {
                id: "13J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Cutting tool",
                text: "Cutting tool",
                X_fullName: "Cutting tool",
                X_Description:
                  "Cutting tools that require minimum effort for cutting conductors and cables in a range of diameters cleanly and easily.",
                X_URL: "cutting-tool",
                X_THUMBNAIL_ALT: "Product list Cutting tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047848_int.jpg",
              },
              {
                id: "33J0000101",
                parent_id: "O2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Screwdriver tools",
                text: "Screwdriver tools",
                X_fullName: "Screwdriver tools",
                X_Description:
                  "Various screwdrivers in a range of different sizes. A torque that can be finely adjusted and cordless screwdrivers complete the product range.",
                X_URL: "screwdriver-tools",
                X_THUMBNAIL_ALT: "Product list Screwdriver tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047850_int.jpg",
              },
              {
                id: "53J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Universal tools",
                text: "Universal tools",
                X_fullName: "Universal tools",
                X_Description:
                  "Various universal tools with combined functions for standard and special applications.",
                X_URL: "universal-tools",
                X_THUMBNAIL_ALT: "Product list Universal tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047851_int.jpg",
              },
              {
                id: "73J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Tool sets",
                text: "Tool sets",
                X_fullName: "Tool sets",
                X_Description:
                  "Rugged and heavy-duty bags and cases with tools for various different jobs.",
                X_URL: "tool-sets",
                X_THUMBNAIL_ALT: "Product list Tool sets",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047853_int.jpg",
              },
              {
                id: "93J0000101",
                parent_id: "O2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Testing and measuring tools",
                text: "Testing and measuring tools",
                X_fullName: "Testing and measuring tools",
                X_Description:
                  "Compact multifunctional tools for error-free testing and measurement.",
                X_URL: "testing-and-measuring-tools",
                X_THUMBNAIL_ALT: "Product list Testing and measuring tools",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0047855_int.jpg",
              },
              {
                id: "A3J0000101",
                parent_id: "O2J0000101",
                FULLPATH:
                  "PhoenixContact\\Tools\\Hand tools\\Portable stripping and crimping device",
                text: "Portable stripping and crimping device",
                X_fullName: "Portable stripping and crimping device",
                X_Description:
                  "This tool makes treating conductors fun \u2013 stripping and crimping in less than 2 seconds.",
                X_URL: "portable-stripping-and-crimping",
                X_THUMBNAIL_ALT: "Portable stripping and crimping machine",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0051558_int.jpg",
                items: [
                  {
                    id: "B3J0000101",
                    parent_id: "A3J0000101",
                    FULLPATH:
                      "PhoenixContact\\Tools\\Hand tools\\Portable stripping and crimping device\\Stripping and crimping hand-held machine",
                    text: "Stripping and crimping hand-held machine",
                    X_fullName: "Stripping and crimping hand-held machine",
                    X_Description:
                      "Mobile and battery-powered hand-held device for processing taped ferrules",
                    X_URL: "stripping-and-crimping-machine",
                    X_THUMBNAIL_ALT:
                      "Product list Hand-held stripping and crimping machine",
                    X_picturelink:
                      "mimes/general/images/pic_stage_thb_a_0051558_int.jpg",
                  },
                ],
              },
              {
                id: "C3J0000101",
                parent_id: "O2J0000101",
                FULLPATH: "PhoenixContact\\Tools\\Hand tools\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for hand tools, for example, replacement blade for stripping tools or replacement retaining springs for crimping pliers.",
                X_URL: "hand-tools-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0055367_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "D3J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Lighting and signaling",
        text: "Lighting and signaling",
        X_fullName: "Lighting and signaling",
        X_Description:
          "Whether you are dealing with the system state or product quality \u2013 with intelligent lighting and signaling technology, you always have everything within view.",
        X_URL: "lighting-and-signaling",
        X_THUMBNAIL_ALT: "Lighting and signaling",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0057150_int.jpg",
        items: [
          {
            id: "E3J0000101",
            parent_id: "D3J0000101",
            FULLPATH: "PhoenixContact\\Lighting and signaling\\Machine lights",
            text: "Machine lights",
            X_fullName: "Machine lights",
            X_Description:
              "Make the most of your machine lighting and increase the efficiency of your system.",
            X_URL: "machine-lights",
            X_THUMBNAIL_ALT: "Machine lights",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076679_int.jpg",
            items: [
              {
                id: "F3J0000101",
                parent_id: "E3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Machine lights\\PLD machine lighting",
                text: "PLD machine lighting",
                X_fullName: "PLD machine lighting",
                X_Description:
                  "LED machine lights in various lengths and with different emission angles for individual solutions.",
                X_URL: "pld-lighting",
                X_THUMBNAIL_ALT: "Product list PLD machine lights",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051654_int.jpg",
              },
              {
                id: "G3J0000101",
                parent_id: "E3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Machine lights\\Accessories for PLD machine lights",
                text: "Accessories for PLD machine lights",
                X_fullName: "Accessories for PLD machine lights",
                X_Description:
                  "Accessories for installation and startup of PLD machine lights.",
                X_URL: "pld-accessories",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for PLD machine lights",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0051655_int.jpg",
              },
            ],
          },
          {
            id: "H3J0000101",
            parent_id: "D3J0000101",
            FULLPATH: "PhoenixContact\\Lighting and signaling\\Signal towers",
            text: "Signal towers",
            X_fullName: "Signal towers",
            X_Description:
              "Optical, audible, and wireless signaling for machine and system states.",
            X_URL: "signal-towers",
            X_THUMBNAIL_ALT: "Signal towers",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0042923_int.jpg",
            items: [
              {
                id: "J3J0000101",
                parent_id: "H3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Audible elements",
                text: "Audible elements",
                X_fullName: "Audible elements",
                X_Description:
                  "Audible signal elements such as buzzers, sirens or voice output to increase awareness of alarms in the event of machine and system errors.",
                X_URL: "audible-elements",
                X_THUMBNAIL_ALT: "Product list Audible elements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0044369_int.jpg",
              },
              {
                id: "L3J0000101",
                parent_id: "H3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Connection and mounting elements",
                text: "Connection and mounting elements",
                X_fullName: "Connection and mounting elements",
                X_Description:
                  "Connection and mounting elements for the individual installation of signal towers.",
                X_URL: "connection-and-mounting-elements",
                X_THUMBNAIL_ALT:
                  "Product list Connection and mounting elements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0044370_int.jpg",
              },
              {
                id: "N3J0000101",
                parent_id: "H3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Optical elements",
                text: "Optical elements",
                X_fullName: "Optical elements",
                X_Description:
                  "Optical elements for color-coded status indication of machine states.",
                X_URL: "optical-elements",
                X_THUMBNAIL_ALT: "Product list Optical elements",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0044374_int.jpg",
              },
              {
                id: "O3J0000101",
                parent_id: "H3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Signal towers\\Accessories",
                text: "Accessories",
                X_fullName: "Accessories",
                X_Description:
                  "Accessories for installing and operating signal towers, such as labeling fields, light bulbs, and cable glands.",
                X_URL: "signal-towers-accessories",
                X_THUMBNAIL_ALT: "Product list Accessories",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0044376_int.jpg",
              },
            ],
          },
          {
            id: "Q3J0000101",
            parent_id: "D3J0000101",
            FULLPATH:
              "PhoenixContact\\Lighting and signaling\\Signal lamps for marine applications",
            text: "Signal lamps for marine applications",
            X_fullName: "Signal lamps for marine applications",
            X_Description:
              "Maritime signal lights for inland and maritime navigation.",
            X_URL: "signal-lamps-marine-applications",
            X_THUMBNAIL_ALT:
              "Product list Signal lights for maritime applications",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0065433_int.jpg",
          },
          {
            id: "R3J0000101",
            parent_id: "D3J0000101",
            FULLPATH:
              "PhoenixContact\\Lighting and signaling\\Enclosure lights",
            text: "Enclosure lights",
            X_fullName: "Enclosure lights",
            X_Description:
              "Ensure optimum lighting of your control cabinet, right down to the bottom and save time during installation.",
            X_URL: "enclosure-lights",
            X_THUMBNAIL_ALT: "Control cabinet lights",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0062568_int.jpg",
            items: [
              {
                id: "S3J0000101",
                parent_id: "R3J0000101",
                FULLPATH:
                  "PhoenixContact\\Lighting and signaling\\Enclosure lights\\Class 600 LED enclosure lights",
                text: "Class 600 LED enclosure lights",
                X_fullName: "Class 600 LED enclosure lights",
                X_Description:
                  "Class 600 LED control cabinet lights with various different socket types and AC wide range input for worldwide use.",
                X_URL: "class-600-led-enclosure-lights",
                X_THUMBNAIL_ALT:
                  "Product list Class 600 PLD control cabinet lights",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062567_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "T3J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\Charging technology for E-Mobility",
        text: "Charging technology for E-Mobility",
        X_fullName: "Charging technology for E-Mobility",
        X_Description:
          "We offer charging technology that is setting new standards in electromobility.",
        X_URL: "charging-technology-e-mobility",
        X_THUMBNAIL_ALT: "Charging technology for electromobility",
        X_picturelink: "mimes/general/images/pic_stage_ent_a_0074360_int.jpg",
        items: [
          {
            id: "U3J0000101",
            parent_id: "T3J0000101",
            FULLPATH:
              "PhoenixContact\\Charging technology for E-Mobility\\Charging systems",
            text: "Charging systems",
            X_fullName: "Charging systems",
            X_Description:
              "AC charging cables, DC charging cables, and infrastructure socket outlet with corresponding hinged covers and holders.",
            X_URL: "charging-systems",
            X_THUMBNAIL_ALT: "Charging systems",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0061875_int.jpg",
            items: [
              {
                id: "W3J0000101",
                parent_id: "U3J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\DC charging cable",
                text: "DC charging cable",
                X_fullName: "DC charging cable",
                X_Description:
                  "DC vehicle connectors in accordance with Type 1, Type 2 and GB/T standard from Phoenix Contact for connecting to fast charging stations",
                X_URL: "dc-charging-cable",
                X_THUMBNAIL_ALT: "Product list DC charging cable",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062264_int.jpg",
              },
              {
                id: "Y3J0000101",
                parent_id: "U3J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\AC charging cable",
                text: "AC charging cable",
                X_fullName: "AC charging cable",
                X_Description:
                  "You can find the right AC charging cable with vehicle connector and infrastructure plug for every global standard in the product lineup from Phoenix Contact.",
                X_URL: "ac-charging-cable",
                X_THUMBNAIL_ALT: "Product list AC charging cables",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062268_int.jpg",
              },
              {
                id: "04J0000101",
                parent_id: "U3J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\AC Infrastructure Socket Outlets",
                text: "AC Infrastructure Socket Outlets",
                X_fullName: "AC Infrastructure Socket Outlets",
                X_Description:
                  "Infrastructure Socket Outlets from Phoenix Contact feature a modular design and can be used for indoor and outdoor charging points.",
                X_URL: "ac-infrastructure-socket-outlets",
                X_THUMBNAIL_ALT:
                  "Product list AC Infrastructure Socket Outlets",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062269_int.jpg",
              },
              {
                id: "24J0000101",
                parent_id: "U3J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging systems\\Accessories for Charging system",
                text: "Accessories for Charging system",
                X_fullName: "Accessories for Charging system",
                X_Description:
                  "Accessories and replacement materials for your charging systems.",
                X_URL: "accessories-for-charging-system",
                X_THUMBNAIL_ALT:
                  "Product list Accessories for charging systems",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0069960_int.jpg",
              },
            ],
          },
          {
            id: "34J0000101",
            parent_id: "T3J0000101",
            FULLPATH:
              "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers",
            text: "Charging controllers",
            X_fullName: "Charging controllers",
            X_Description:
              "Charging controllers for residential, commercial, and public charging, as well as residual current monitoring modules.",
            X_URL: "charging-controllers",
            X_THUMBNAIL_ALT: "Charging controllers",
            X_picturelink:
              "mimes/general/images/pic_stage_thb_a_0076541_int.jpg",
            items: [
              {
                id: "54J0000101",
                parent_id: "34J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\Residual current monitoring",
                text: "Residual current monitoring",
                X_fullName: "Residual current monitoring",
                X_Description:
                  "Universal residual current monitoring: RCM modules make it possible to detect AC and DC differential currents inside a charging point using a measuring sensor.",
                X_URL: "residual-current-monitoring",
                X_THUMBNAIL_ALT: "Product list Differential current monitoring",
                X_picturelink:
                  "mimes/general/images/pic_prolist_thb_a_0062919_int.jpg",
              },
              {
                id: "74J0000101",
                parent_id: "34J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\AC charging controllers",
                text: "AC charging controllers",
                X_fullName: "AC charging controllers",
                X_Description:
                  "E-mobility charging controllers for any AC applications.",
                X_URL: "ac-charging-controllers",
                X_THUMBNAIL_ALT: "Product list AC charging controllers",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0062562_int.jpg",
              },
              {
                id: "94J0000101",
                parent_id: "34J0000101",
                FULLPATH:
                  "PhoenixContact\\Charging technology for E-Mobility\\Charging controllers\\DC charging controllers",
                text: "DC charging controllers",
                X_fullName: "DC charging controllers",
                X_Description:
                  "E-Mobility charging controllers for semi-public and public applications.",
                X_URL: "dc-charging-controllers",
                X_THUMBNAIL_ALT: "Product list DC charging controllers",
                X_picturelink:
                  "mimes/general/images/pic_stage_thb_a_0069667_int.jpg",
              },
            ],
          },
        ],
      },
      {
        id: "A4J0000101",
        parent_id: "HFI0000101",
        FULLPATH: "PhoenixContact\\null",
        text: "null",
        X_fullName: "Others",
        X_Description: "",
        X_URL: "others",
        X_THUMBNAIL_ALT: "",
        X_picturelink: "",
      },
    ],
  },
];
