import * as types from "../actions/actionTypes";

import initialState from "./intialState";

export default function apiProductDetail(
  state = initialState.apiProductDetail,
  action
) {
  switch (action.type) {
    case types.API_PRODUCT_DETAIL:
      return { ...state, ...action.payload };
    case types.DEFAULT_API_PRODUCT_DETAIL:
      return initialState.apiProductDetail;
    default:
      return state;
  }
}
